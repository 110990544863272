import { createStackNavigator } from '@react-navigation/stack';

import Events from '../Screens/Events';
import EventDetails from '../Screens/EventDetails';
import SearchEvent from '../Screens/Search/SearchEvent';

import routeList from './list';
import constants from '../Config/constants';

const Stack = createStackNavigator();

const EventNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={routeList.EVENTS}
    >
      <Stack.Screen name={routeList.EVENTS} component={Events} />

      <Stack.Screen name={routeList.EVENT_DETAILS} component={EventDetails} />
      {!constants.isWeb && (
        <Stack.Screen name={routeList.SEARCH_EVENT} component={SearchEvent} />
      )}
    </Stack.Navigator>
  );
};

export default EventNavigator;
