export const MODALPROMPT = {
  addressDetails: 'AddressDetails',
  authentication: 'Authentication',
  changeTimeSchedule: 'ChangeTimeSchedule',
  changeDateAndTimeSchedule: 'ChangeDateAndTimeSchedule',
  completeInformation: 'CompleteInformation',
  eventDatePicker: 'EventDatePicker',
  distanceSelector: 'DistanceSelector',
  eventOrderSummary: 'EventOrderSummary',
  deliveryAddress: 'DeliveryAddressModalPrompt',
  errorModal: 'ErrorModal',
  eventFilter: 'EventFilter',
  listAddress: 'ListAddress',
  itemModal: 'ItemModal',
  mealPlanTypeSelector: 'MealPlanTypeSelector',
  orderAgain: 'OrderAgain',
  orderType: 'OrderType',
  ratingsSelector: 'RatingsSelector',
  reviewsModal: 'ReviewsModal',
  storeRatingsModal: 'StoreRatingsModal',
  storeInformation: 'StoreInformation',
  prompt: 'prompt',
  promotion: 'promotion',
  publicReviewsNotice: 'PublicReviewsNotice',
};
