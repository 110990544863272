/**
 * @format
 */
import 'react-native-gesture-handler';
import { AppRegistry } from 'react-native-web';
// Generate required css
import PoppinsRegular from '../App/Assets/fonts/Poppins-Regular.ttf';
import PoppinsMedium from '../App/Assets/fonts/Poppins-Medium.ttf';
import PoppinsSemiBold from '../App/Assets/fonts/Poppins-SemiBold.ttf';
import FontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import { name as appName } from '../app.json';
import App from '../App/index';

const PS = `@font-face {
  src: url(${PoppinsSemiBold});
  font-family: Poppins-SemiBold;
}`;

const PM = `@font-face {
  src: url(${PoppinsMedium});
  font-family: Poppins-Medium;
}`;

const PR = `@font-face {
  src: url(${PoppinsRegular});
  font-family: Poppins-Regular;
}`;

// importing of vector icons
const FA = `@font-face {
  src: url(${FontAwesome});
  font-family: FontAwesome;
}`;

// //Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = PR;
  style.styleSheet.cssText = PM;
  style.styleSheet.cssText = PS;
  style.styleSheet.cssText = FA;
} else {
  style.appendChild(document.createTextNode(PR));
  style.appendChild(document.createTextNode(PM));
  style.appendChild(document.createTextNode(PS));
  style.appendChild(document.createTextNode(FA));
}

// // // // Inject stylesheet
document.head.appendChild(style);

if (module.hot) {
  module.hot.accept();
}

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('root'),
});
