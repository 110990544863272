import { createSelector } from '@reduxjs/toolkit';

const utility = (state) => state?.utility;

const orderDetailsSelector = createSelector(
  utility,
  (state) => state.orderDetails || {}
);
const selectDifferentMethod = createSelector(
  utility,
  (state) => state?.chooseDifferentPaymentMethod
);
const successfulOrderSelector = createSelector(
  utility,
  (state) => state?.successfulOrder || []
);
const userAddressFetchingSelector = createSelector(
  utility,
  (state) => state?.gettingUserData.address
);
const userDetailsFetchingSelector = createSelector(
  utility,
  (state) => state?.gettingUserData.details
);
const userCartFetchingSelector = createSelector(
  utility,
  (state) => state?.gettingUserData.cart
);

export {
  orderDetailsSelector,
  selectDifferentMethod,
  successfulOrderSelector,
  userAddressFetchingSelector,
  userCartFetchingSelector,
  userDetailsFetchingSelector,
};
