import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ActivityIndicator, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Divider, Icon, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../Config/constants';

import Header from '../../Components/Header';
import List from '../../Components/List';

import Button from '../../Components/Button';
import ScreenWrapper from '../../Components/ScreenWrapper';
import Image from '../../Components/Image';
import { subTitleProps } from '../../Components/Card/styles';

import { pushRecentlySearch } from '../../RTK/search';
import { useLazyGetEventsQuery } from '../../RTK/api/events';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import DateHelper from '../../Helper/Date';

import NoResult from './NoResult';
import RecentlyViewedSection from './RecentlyViewedSection';
import SearchSection from './SearchSection';

import RouteList from '../../Routes/list';

import AnalyticsHelper from '../../Helper/Analytics';

import { SECTION_TITLE } from '../../Theme/typographyProps';
import styles from './styles';

const initialState = {
  searchValue: '',
};

const SearchEvent = () => {
  const listRef = useRef<any>();
  const searchRef = useRef<any>();
  const dispatch = useDispatch();
  const [state, setState] = useState(initialState);
  const navigation = useNavigation<any>();
  const [getEvents, { data: eventsData, isLoading, isFetching, error }] =
    useLazyGetEventsQuery();

  const hasNotResult = lodashIsEmpty(eventsData?.result);
  const hasNotSearchValue = lodashIsEmpty(state.searchValue);

  const _resetScroll = () => {
    listRef?.current._scrollToOffset?.({
      animated: true,
      offset: 0,
    });
  };

  const _apiCallForSearchContain = (searchValue: string) => {
    setState({ searchValue });
    getEvents({
      keyword: searchValue,
      event_date: constants.EVENT_RANGE_DATE,
    });
    dispatch(pushRecentlySearch({ value: searchValue, isStoreSearch: false }));
  };

  const _renderHeader = () => {
    return <Header title="Search" alignTitle="left" />;
  };

  const _onSearchChanged = (val) => {
    if (val) {
      _apiCallForSearchContain(val);
    } else {
      // when pressed the x button on the right of the search input, reset state
      //this.setState(initialState, this._resetScroll);
      setState(initialState);
      _resetScroll();
    }
  };

  const _onEventsRecentlyViewedPressed = async (val) => {
    searchRef.current._onInputChange(val, true);
    dispatch(pushRecentlySearch({ value: val, isStoreSearch: false }));
  };

  const _onItemResultPressed = (item) => () => {
    AnalyticsHelper.storeSelection({
      ...item,
      fromSection: `Search result event from '${state.searchValue}'`,
    });
    navigation.navigate(RouteList.EVENT_DETAILS, {
      eventId: item?.id,
    });
  };

  const _renderItem = ({ item }) => {
    if (item.search) {
      return (
        <SearchSection
          onRef={(r) => (searchRef.current = r)}
          onSearchChange={_onSearchChanged}
          autoFocus
        />
      );
    } else if (item.noResult) {
      return <NoResult error={error} />;
    } else if (item.recentlyView) {
      return (
        <RecentlyViewedSection
          isRecentlyStoreSearch={false}
          onPress={_onEventsRecentlyViewedPressed}
        />
      );
    } else {
      return (
        <Button
          style={[
            ThemeStyle.pageHorizontalSpacing,
            ThemeStyle.spacingTop,
            ThemeStyle.spacingBottomMedium,
          ]}
          plain
          onPress={_onItemResultPressed(item)}
        >
          <Image
            resizeMode={'cover'}
            size={0}
            source={{
              uri: item?.image,
            }}
            style={{
              width: '100%',
              aspectRatio: 2 / 1.1,
              borderRadius: 5,
            }}
          />
          <View style={[ThemeStyle.spacingTopMedium, { width: '90%' }]}>
            <Text {...SECTION_TITLE}>{item?.name}</Text>
            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Icon
                name="pin"
                fill={ThemeColor.green}
                style={[styles.icon, ThemeStyle.spacingRightSmall]}
              />

              <Text {...subTitleProps}>{item?.location_address}</Text>
            </View>
            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Icon
                name="calendar-outline"
                fill={ThemeColor.green}
                style={[styles.icon, ThemeStyle.spacingRightSmall]}
              />
              <Text {...subTitleProps}>
                {DateHelper.formatDateRange(item?.start_date, item?.end_date)}
              </Text>
            </View>
          </View>
        </Button>
      );
    }
  };

  const _renderItemSeparator = ({ leadingItem }) => {
    if (!leadingItem.search && !leadingItem.filter) {
      return <Divider style={ThemeStyle.divider} />;
    }

    return null;
  };

  let listData = [];
  if (hasNotResult && !hasNotSearchValue) {
    listData = [{ search: true }, { noResult: true }];
  } else if ((!hasNotResult || hasNotResult) && hasNotSearchValue) {
    listData = [{ search: true }, { recentlyView: true }];
  } else {
    listData = [{ search: true }, ...eventsData?.result];
  }

  return (
    <ScreenWrapper scroll={false}>
      {(isLoading || isFetching) && (
        <View style={styles.loaderWrapper}>
          <ActivityIndicator size="large" color={ThemeColor.green} />
        </View>
      )}

      <List
        ref={listRef}
        data={listData}
        ListHeaderComponent={_renderHeader}
        renderItem={_renderItem}
        ItemSeparatorComponent={_renderItemSeparator}
        stickyHeaderIndices={[1]}
        contentContainerStyle={{ flexGrow: 1 }}
        style={ThemeStyle.pageBackground}
      />
    </ScreenWrapper>
  );
};

export default SearchEvent;
