import React from 'react';
import { View, ActivityIndicator, StyleSheet } from 'react-native';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

function MapLoader() {
  return (
    <View style={styles.wrapper}>
      <ActivityIndicator color={ThemeColor.green} size="large" />
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    ...StyleSheet.absoluteFillObject,
    ...ThemeStyle.alignItemsCenter,
    ...ThemeStyle.justifyContentCenter,
    zIndex: 1,
  },
});

export default MapLoader;
