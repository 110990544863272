import { useEffect } from 'react';
import { useRoute } from '@react-navigation/native';
import lodashGet from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

import constants from '../../../Config/constants';
import useGoBack from '../../../Hooks/useGoBack';
import routeList from '../../../Routes/list';
import { useGetEventDetailsQuery } from '../../../RTK/api/events';
import { EventDetails } from '../../../RTK/api/events/types';
import { selectDifferentMethod } from '../../../RTK/utility/selectors';
import { setChooseDifferentMethod } from '../../../RTK/utility';

import useBuyTicket from '../../Events/useEventViewModel/useBuyTicket';

const useEventDetailsViewModel = () => {
  const dispatch = useDispatch();
  const route = useRoute();
  const goBack = useGoBack({ rootRoute: routeList.EVENT_TAB });
  const {
    buyEventTicketData,
    onBuyTickets,
    onScheduleSelected,
    onScheduleSelectionCancelled,
  } = useBuyTicket({ isEventDetails: true });
  const isChooseDifferentMethod = useSelector(selectDifferentMethod);
  const eventId = lodashGet(route, 'params.eventId') || '';

  const {
    data = {} as EventDetails,
    error: errorMessage,
    isFetching: isLoading,
  } = useGetEventDetailsQuery(
    {
      eventId,
    },
    { skip: !eventId }
  );

  if (constants.isWeb) {
    useEffect(() => {
      if (!eventId) {
        goBack();
      }
    }, []);
  }

  useEffect(() => {
    if (isChooseDifferentMethod) {
      dispatch(setChooseDifferentMethod(false)); // flip the flag
      onBuyTickets(data)();
    }
  }, [isChooseDifferentMethod]);

  return {
    buyEventTicketData,
    data,
    error: {
      title: errorMessage,
      message: 'Unable to load event details, Please try again.',
    },
    isLoading,
    onBuyTickets,
    onScheduleSelected,
    onScheduleSelectionCancelled,
  };
};

export default useEventDetailsViewModel;
