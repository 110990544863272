import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View } from 'react-native';
import { Icon, Divider, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import Button from '../../Components/Button';
import List from '../../Components/List';
import ListItem from '../../Components/ListItem';
import { removeRecentlySearch } from '../../RTK/search';
import {
  recentlyEventSearchedSelector,
  recentlyStoreSearchedSelector,
} from '../../RTK/search/selectors';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import constants from '../../Config/constants';

type Props = {
  isRecentlyStoreSearch: boolean;
  onPress: (data: string) => void;
};
const RecentlyViewedSection = ({
  isRecentlyStoreSearch = true,
  onPress,
}: Props) => {
  const dispatch = useDispatch();
  const searchSelector = isRecentlyStoreSearch
    ? recentlyStoreSearchedSelector
    : recentlyEventSearchedSelector;

  const recentlySearch = useSelector(searchSelector);

  const _onSearchRemove = (search) => () => {
    dispatch(
      removeRecentlySearch({
        value: search,
        isStoreSearch: isRecentlyStoreSearch,
      })
    );
  };

  const _renderItem = ({ index, item }) => (
    <ListItem
      testID={`recentlyButton${index}`}
      leftIcon="clock-outline"
      leftIconColor={ThemeColor.green}
      title={item}
      accessoryRight={
        <Button
          hitSlop={10}
          style={ThemeStyle.spacingLeft}
          onPress={_onSearchRemove(item)}
          plain
        >
          <Icon
            name="close"
            fill={ThemeColor.darkGray}
            style={{ width: 20, height: 20 }}
          />
        </Button>
      }
      onPress={() => onPress(item)}
    />
  );

  if (lodashIsEmpty(recentlySearch)) {
    return null;
  }

  const conditionalProps = constants.isWeb
    ? {
        withSeparator: false,
        ItemSeparatorComponent: () => (
          <View>
            <View style={ThemeStyle.webPageWrapper}>
              <Divider
                style={[ThemeStyle.divider, ThemeStyle.webPageContainer]}
              />
            </View>
          </View>
        ),
      }
    : { withSeparator: true };
  return (
    <View
      testID="recentlyViewed"
      style={[ThemeStyle.pageHorizontalSpacing, ThemeStyle.spacingTopMedium]}
    >
      <View
        style={[
          ThemeStyle.flexDirectionRowSpaceBetween,
          ThemeStyle.alignItemsCenter,
        ]}
      >
        <Text category={constants.isWeb ? 'p1' : 's1'}>Recent Searches</Text>

        <Button onPress={_onSearchRemove()} plain>
          <Text>Clear All</Text>
        </Button>
      </View>

      <List
        data={recentlySearch}
        renderItem={_renderItem}
        {...conditionalProps}
      />
    </View>
  );
};
export default RecentlyViewedSection;
