import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Divider, Icon, Text } from '@ui-kitten/components';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import {
  SECTION_TITLE_LIGHT,
  SECTION_SUB_TITLE,
} from '../../Theme/typographyProps';

import styles from './styles';

function DeliverAddress({ address }) {
  return (
    <Fragment>
      <View
        style={[
          ThemeStyle.pageHorizontalSpacing,
          ThemeStyle.pageVerticalSpacingMedium,
          ThemeStyle.flexDirectionRow,
        ]}
      >
        <Icon name="pin-outline" fill={ThemeColor.green} style={styles.icon} />

        <View style={ThemeStyle.flex1}>
          <Text style={ThemeStyle.spacingBottomMedium} {...SECTION_TITLE_LIGHT}>
            Delivery Address
          </Text>

          <Text {...SECTION_SUB_TITLE}>{address}</Text>
        </View>
      </View>

      <Divider style={ThemeStyle.sectionSeparator} />
    </Fragment>
  );
}

DeliverAddress.propTypes = {
  address: PropTypes.string.isRequired,
};

export default DeliverAddress;
