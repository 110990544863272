import React from 'react';
import { View } from 'react-native';
import { Divider } from '@ui-kitten/components';

import { RectangleLoader } from '../../Components/Loader';
import ScreenWrapper from '../../Components/ScreenWrapper';
import StoreCartLoader from '../../Components/StoreCart/Loader';
import ThemeStyle from '../../Theme/styles';

import styles from './styles';

function PageLoader(props) {
  const _renderFooter = () => (
    <View
      style={[ThemeStyle.pageHorizontalSpacing, ThemeStyle.spacingBottomMedium]}
    >
      <View style={{ borderRadius: 50, overflow: 'hidden' }}>
        <RectangleLoader width="100%" height={40} />
      </View>
    </View>
  );

  const _renderNewSection = (detailsWidth, detailsHeight) => (
    <View
      style={[
        ThemeStyle.pageHorizontalSpacing,
        ThemeStyle.pageVerticalSpacingMedium,
        ThemeStyle.flexDirectionRow,
      ]}
    >
      {/* section icon */}
      <View style={ThemeStyle.spacingRightMedium}>
        <RectangleLoader
          width={styles.icon.width}
          height={styles.icon.height}
        />
      </View>
      {/* section label and details */}
      <View style={ThemeStyle.flex1}>
        <View style={ThemeStyle.spacingBottomMedium}>
          <RectangleLoader width={150} height={25} />
        </View>
        <RectangleLoader width={detailsWidth} height={detailsHeight} />
      </View>
    </View>
  );

  const _renderRow = (leftWidth, rightWidth) => (
    <View
      style={[
        ThemeStyle.flexDirectionRowCenterSpaceBetween,
        ThemeStyle.spacingBottomMedium,
      ]}
    >
      <RectangleLoader width={leftWidth} height={20} />
      <RectangleLoader width={rightWidth} height={20} />
    </View>
  );

  return (
    <ScreenWrapper {...props} footer={_renderFooter()}>
      {/* Status Banner */}
      <RectangleLoader width="100%" height={100} />
      {/* Delivery Address */}
      {_renderNewSection('100%', 40)}

      <Divider style={ThemeStyle.sectionSeparator} />

      {/* Item Details */}
      <View style={ThemeStyle.pageVerticalSpacingSmall}>
        <StoreCartLoader noSubmitButton />
      </View>

      <Divider style={ThemeStyle.sectionSeparator} />

      {/* payment method used */}
      {_renderNewSection(100, 20)}

      <Divider style={ThemeStyle.sectionSeparator} />

      {/* Transaction number & date */}
      <View
        style={[
          ThemeStyle.pageHorizontalSpacing,
          ThemeStyle.pageVerticalSpacing,
        ]}
      >
        {_renderRow(160, 100)}
        {_renderRow(100, 150)}
        {_renderRow(120, 150)}
      </View>
    </ScreenWrapper>
  );
}

export default PageLoader;
