import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Spinner, Text } from '@ui-kitten/components';

import ComingSoonImage from '../Assets/coming-soon.png';
import constants from '../Config/constants';
import { useMainContext } from '../MainContext';
import ThemeStyle from '../Theme/styles';

function ComingSoon() {
  const { isRemoteConfigFetching } = useMainContext();
  if (isRemoteConfigFetching) {
    return (
      <View style={[ThemeStyle.flex1, ThemeStyle.flexDirectionRowCenterCenter]}>
        <Spinner status="success" size="large" />
      </View>
    );
  }
  return (
    <View
      style={[
        ThemeStyle.flex1,
        ThemeStyle.justifyContentCenter,
        ThemeStyle.alignItemsCenter,
        ThemeStyle.pageBackground,
      ]}
    >
      <View style={ThemeStyle.spacingBottom}>
        <Image
          source={ComingSoonImage}
          style={styles.image}
          resizeMode="contain"
        />
      </View>

      <View
        style={[ThemeStyle.pageHorizontalSpacing, ThemeStyle.alignItemsCenter]}
      >
        <View style={ThemeStyle.spacingBottom}>
          <Text category="h4">Coming Soon</Text>
        </View>

        <View style={ThemeStyle.spacingBottom}>
          <Text category="p2" style={ThemeStyle.textCenter}>
            Something amazing is on the works. Please anticipate it :-&#41;
          </Text>
        </View>

        <Text category="c2">With love - the Pickup.ph team.</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  image: {
    width: constants.DEVICE_WIDTH,
    height: 250,
  },
});

export default ComingSoon;
