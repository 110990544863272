import React from 'react';
import { connect } from 'react-redux';
import { ActivityIndicator, View } from 'react-native';
import { Divider } from '@ui-kitten/components';
import { CANCEL_ERROR } from 'apisauce';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';

import FilterTag from '../../Components/Filter/Tag';
import Header from '../../Components/Header';
import List from '../../Components/List';
import modals from '../../Components/Sheets/modals';
import ScreenWrapper from '../../Components/ScreenWrapper';
import AnalyticsHelper from '../../Helper/Analytics';
import UrlParameter from '../../Helper/UrlParameter';
import useCancellableRequest from '../../Hooks/useCancellableRequest';
import withController from '../../Hooks/withController';
import withFavourite from '../../Hooks/withFavourite';
import { filter } from '../../RTK/defaultValues';
import { removeFilter } from '../../RTK/filter';
import { pushRecentlySearch } from '../../RTK/search';
import routeList from '../../Routes/list';
import searchApi from '../../Service/api/search';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import Result from './Result';
import CuisinesSection from './CuisinesSection';
import NoResult from './NoResult';
import RecentlyViewedSection from './RecentlyViewedSection';
import ResultSuggestionItem from './ResultSuggestionItem';
import SearchSection from './SearchSection';
import styles from './styles';

const { useCase } = filter;

const initialState = {
  isLoading: false,
  listWithSeparator: false,
  searchWithTag: false,
  resultSuggestion: false,
  results: [],
  searchValue: '',
  noResult: false,
  error: '',
};

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  componentDidUpdate(prevProps) {
    const { filter: prevFilter } = prevProps;
    const { filter: currentFilter } = this.props;
    if (!lodashIsEqual(prevFilter, currentFilter)) {
      this._apiCallForSearchContain(this.searchRef._getValue());
    }
  }

  _resetScroll = () => {
    this.listRef?._scrollToOffset?.({
      animated: true,
      offset: 0,
    });
  };

  _apiCallForSearchContain = async (searchValue) => {
    const { api, filter, dispatchPushRecentlySearch } = this.props;
    const urlParams = UrlParameter.getUrlParameterFromFilter({
      tagFilter: filter.tags,
      startWithAmpersand: true,
    });

    this.setState({ isLoading: true }, async () => {
      const { ok, data, problem } = await api.createRequest(
        searchApi.getSearchResults,
        searchValue,
        urlParams,
        this.state.searchWithTag
      );

      // stop the code from going if request is cancelled
      if (problem === CANCEL_ERROR) {
        return;
      }

      this.setState(
        {
          searchValue,
          isLoading: false,
          listWithSeparator: true,
          resultSuggestion: false,
          results: ok ? data.result : [],
          noResult: !ok || data.result.length === 0,
          error: problem,
        },
        () => {
          if (!this.state.searchWithTag) {
            dispatchPushRecentlySearch({
              value: searchValue,
              isStoreSearch: true,
            });
          }
          this._resetScroll();
        }
      );
    });
  };

  _apiCallForSearchSuggestion = async (searchValue) => {
    const { api, dispatchRemoveFilter } = this.props;
    await dispatchRemoveFilter(useCase.SEARCH);
    this.setState({ isLoading: true }, async () => {
      const { ok, data, problem } = await api.createRequest(
        searchApi.getSearchResults,
        searchValue
      );
      // stop the code from going if request is cancelled
      if (problem === CANCEL_ERROR) {
        return;
      }
      // need to stringify and parse it so in test it didnt mutate on the mock data
      const resultData = ok ? JSON.parse(JSON.stringify(data.result)) : [];
      resultData.push({ searchFor: true, searchValue: searchValue });
      this.setState({
        isLoading: false,
        listWithSeparator: true,
        resultSuggestion: true,
        results: resultData,
      });
    });
  };

  _renderHeader = () => {
    return <Header title="Search" alignTitle="left" />;
  };

  _onSearchChanged = (val, immediate) => {
    if (val) {
      this.setState({ searchWithTag: false }, () => {
        this._apiCallForSearchContain(val);
      });
    } else {
      // when pressed the x button on the right of the search input, reset state
      this.setState(initialState, this._resetScroll);
    }
    return;
    if (immediate) {
      // has 2nd parameter immediate, meaning search come from text suggestion on this screen such as:
      // recently viewerd and cuisines
      this._apiCallForSearchContain(val);
    } else if (val) {
      // make the searchWithTag false, searchWithTag will only become true if user click the cuisines
      // so lets make it false when user type on the search bar
      this.setState({ searchWithTag: false }, () => {
        this._apiCallForSearchSuggestion(val);
      });
    } else {
      // when pressed the x button on the right of the search input, reset state
      this.setState(initialState, this._resetScroll);
    }
  };

  _onCuisinesOrRecentlyViewedPressed = async (val, isTag = false) => {
    this.setState({ searchWithTag: isTag }, () => {
      this.searchRef._onInputChange(val, true);

      if (!isTag) {
        this.props.dispatchPushRecentlySearch({
          value: val,
          isStoreSearch: true,
        });
      }
    });
  };

  _onItemResultPressed = (item) => () => {
    if (item.searchFor) {
      this._apiCallForSearchContain(item.searchValue);
    } else {
      AnalyticsHelper.storeSelection({
        ...item,
        fromSection: `Search result from '${this.state.searchValue}'`,
      });
      this.props.navigation.navigate(routeList.STORE, {
        id: item.id,
        distance: item.distance,
        duration: item.duration,
      });
    }
  };

  _onStoreItemResultPressed = (storeInfo) => (storeItem) => {
    AnalyticsHelper.itemSelection({
      ...storeItem,
      fromSection: `Search result from '${this.state.searchValue}'`,
    });
    modals.show(modals.PRODUCT, { id: storeItem.id });
  };

  _renderItem = ({ index, item }) => {
    if (item.search) {
      return (
        <SearchSection
          onRef={(r) => (this.searchRef = r)}
          onSearchChange={this._onSearchChanged}
          autoFocus
        />
      );
    } else if (item.filter) {
      return (
        <View>
          <View style={ThemeStyle.spacingBottomSmall}>
            <FilterTag
              isLoading={this.state.isLoading}
              resultCount={this.state.results.length}
              useIn={useCase.SEARCH}
            />
          </View>
          <Divider style={ThemeStyle.sectionSeparator} />
        </View>
      );
    } else if (item.noResult) {
      return <NoResult error={this.state.error} />;
    } else if (this.state.resultSuggestion) {
      // not using anymore
      return (
        <ResultSuggestionItem
          {...item}
          index={index}
          onPress={this._onItemResultPressed(item)}
        />
      );
    } else if (item.recentlyView) {
      return (
        <RecentlyViewedSection
          onPress={this._onCuisinesOrRecentlyViewedPressed}
        />
      );
    } else if (item.cuisines) {
      return (
        <CuisinesSection onPress={this._onCuisinesOrRecentlyViewedPressed} />
      );
    } else {
      return (
        <Result
          {...item}
          isSaved={this.props.isSavedToFavourite(item.id)}
          disableSave={this.props.disableFavourite}
          onSave={this.props.toggleFavourite(item)}
          onPress={this._onItemResultPressed(item)}
          onItemPress={this._onStoreItemResultPressed(item)}
          fullWidthOnMobile
        />
      );
    }
  };

  _renderItemSeparator = ({ leadingItem }) => {
    if (
      this.state.listWithSeparator &&
      !leadingItem.search &&
      !leadingItem.filter
    ) {
      return <Divider style={ThemeStyle.divider} />;
    }

    return null;
  };

  render() {
    const { isLoading, resultSuggestion, results, noResult } = this.state;
    let listData = [];
    if (noResult) {
      listData = [{ search: true }, { filter: true }, { noResult: true }];
    } else if (lodashIsEmpty(results)) {
      listData = [{ search: true }, { recentlyView: true }, { cuisines: true }];
    } else if (!resultSuggestion) {
      listData = [{ search: true }, { filter: true }, ...results];
    } else {
      listData = [{ search: true }, ...results];
    }

    return (
      <ScreenWrapper scroll={false}>
        {isLoading && (
          <View style={styles.loaderWrapper}>
            <ActivityIndicator size="large" color={ThemeColor.green} />
          </View>
        )}

        <List
          ref={(r) => (this.listRef = r)}
          data={listData}
          ListHeaderComponent={this._renderHeader}
          renderItem={this._renderItem}
          ItemSeparatorComponent={this._renderItemSeparator}
          stickyHeaderIndices={[1]}
          contentContainerStyle={{ flexGrow: 1 }}
          style={ThemeStyle.pageBackground}
        />
      </ScreenWrapper>
    );
  }
}

Search = withFavourite(Search);
Search = withController(Search, useCancellableRequest, 'api');

const mapStateToProps = (state) => ({
  filter: state.filter.search,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPushRecentlySearch: (data) => dispatch(pushRecentlySearch(data)),
  dispatchRemoveFilter: (data) => dispatch(removeFilter(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
