import React from 'react';
import { Linking } from 'react-native';
import { connect } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import NetInfo from '@react-native-community/netinfo';
import PushNotification from 'react-native-push-notification';
import { SheetProvider } from 'react-native-actions-sheet';
import lodashIsEmpty from 'lodash/isEmpty';

import AppUpdateModal from '../Components/AppUpdateModal';
import ConnectionIssueModal from '../Components/ConnectionIssueModal';
import DatePicker from '../Components/DatePicker';
import Toast from '../Components/Toast';
import SchedulePicker from '../Components/SchedulePicker';
import useSchedulePicker from '../Components/SchedulePicker/index.controller';
import '../Components/Sheets';
import config from '../Config';
import constants from '../Config/constants';
import withDatePicker from '../Hooks/withDatePicker';
import withController from '../Hooks/withController';
import routeList from '../Routes/list';
import utilsApi from '../Service/api/utils';

import RootNavigator from './RootNavigator';
import { getPageName } from '../Helper/Routines';
import UrlParameter from '../Helper/UrlParameter';

import { setEmailReviewDeepLinking } from '../RTK/noSession';

const webDeeplink = {
  [routeList.HOME]: {
    screens: {
      MainMenu: {
        screens: {
          [routeList.HOME_TAB]: routeList.HOME_TAB,
          [routeList.STORE]: routeList.STORE,
          [routeList.BASKET_TAB]: routeList.BASKET_TAB,
          [routeList.RESERVATION_TAB]: routeList.RESERVATION_TAB,
          [routeList.SEE_ALL]: routeList.SEE_ALL,
          [routeList.PROFILE]: routeList.PROFILE,
        },
      },
    },
  },
  [routeList.CHECKOUT_NAVIGATOR]: {
    screens: {
      [routeList.CHECKOUT]: routeList.CHECKOUT,
      [routeList.CHECKOUT_WEBVIEW]: routeList.CHECKOUT_WEBVIEW,
    },
  },
  [routeList.STORE_REVIEWS]: routeList.STORE_REVIEWS,
  [routeList.ORDER_DETAILS]: routeList.ORDER_DETAILS,
  [routeList.ORDER_RATING]: routeList.ORDER_RATING,
  [routeList.LEGAL_BINDING_CONTRACT]: routeList.LEGAL_BINDING_CONTRACT,
  [routeList.SIGNUP_VERIFICATION_SUCCESS]: 'verify-email',
};

const mobileDeeplink = {
  [routeList.ROOT_TAB]: {
    // root navigator
    screens: {
      [routeList.HOME_TAB]: {
        // home tab because navigator of store is in home navigator
        screens: {
          [routeList.STORE]: routeList.STORE,
        },
      },
      [routeList.BASKET_TAB]: {
        // basket tab because navigator of rating is in basket navigator
        screens: {
          [routeList.ORDER_RATING]: routeList.ORDER_RATING,
        },
      },
    },
  },
  // route that can directly access on deeplinking
  [routeList.RESET_PASSWORD]: 'reset',
  [routeList.SIGNUP_VERIFICATION_SUCCESS]: 'verify-email',
};

class Routes extends React.Component {
  state = {
    isConnected: true,
    isInternetReachable: true,
    showAppUpdate: false,
  };

  componentDidMount() {
    this.shouldSetState = true;
    this.netInfoSubscription();
    this.updateChecker();
  }

  netInfoSubscription() {
    this.netInfoUnsubscribe?.();
    this.netInfoUnsubscribe = NetInfo.addEventListener(
      ({ isConnected, isInternetReachable }) => {
        this.setState({ isConnected, isInternetReachable });
      }
    );
  }

  async updateChecker() {
    const isUpdated = await utilsApi.isAppUpdated();

    if (this.shouldSetState && !isUpdated) {
      this.toggleAppUpdate();
    }
  }

  toggleAppUpdate = () => {
    this.setState({ showAppUpdate: !this.state.showAppUpdate });
  };

  componentWillUnmount() {
    this.shouldSetState = false;
    this.netInfoUnsubscribe?.();
  }

  _linking = {
    prefixes: ['pickupcustomerapp://', config.WEB],
    config: {
      screens: constants.isWeb ? webDeeplink : mobileDeeplink,
    },
    getInitialURL: async () => {
      PushNotification.popInitialNotification((notification) => {
        if (!notification) return;
        const { link = null } = notification?.data || {};
        link && Linking.openURL(link);
      });
      return Linking.getInitialURL().then((url) => {
        if (!lodashIsEmpty(url)) {
          const { dispatchEmailReviewDeepLinking } = this.props;
          let params = UrlParameter.getUrlParameter(url);
          dispatchEmailReviewDeepLinking(params);
        }
      });
    },
  };

  render() {
    const {
      onConfirm,
      orderNumber,
      schedulePicker,
      shopData,
      showDatePicker,
      toggleDateTimePicker,
      whenFilter,
    } = this.props; // for date picker and redux shop data
    const { isConnected, isInternetReachable, showAppUpdate } = this.state;

    return (
      <NavigationContainer
        linking={this._linking}
        documentTitle={{
          formatter: (options, route) =>
            getPageName(route?.name, shopData?.name, orderNumber),
        }}
      >
        <SheetProvider>
          <Toast />

          <ConnectionIssueModal
            isConnected={isConnected}
            isInternetReachable={isInternetReachable}
          />

          {!constants.isWeb && (
            <AppUpdateModal
              show={showAppUpdate}
              onClose={this.toggleAppUpdate}
            />
          )}

          <RootNavigator />

          {/* This should be the only DatePicker for mobile (if intent is open the date picker to change order date), because if not it will show multiple date picker */}
          {/* Just call toggleDateTimePicker on withDatePicker hooks/hoc to open DatePicker */}
          {!constants.isWeb && (
            <DatePicker
              open={showDatePicker}
              value={whenFilter?.value}
              onCancel={() => toggleDateTimePicker('cancel')}
              onConfirm={onConfirm}
            />
          )}

          {/* Used when rescheduling */}
          {schedulePicker.showReschedule && !constants.isWeb && (
            <SchedulePicker
              scheduleTitle="Select Reschedule"
              storeId={schedulePicker.rescheduleStoreId}
              show={schedulePicker.showReschedule}
              onConfirm={schedulePicker.rescheduleOnStorePage}
            />
          )}
        </SheetProvider>
      </NavigationContainer>
    );
  }
}

Routes = withDatePicker(Routes);
Routes = withController(Routes, useSchedulePicker, 'schedulePicker');

const mapStateToProps = (state) => ({
  shopData: state.shop.data,
  orderNumber: state.checkout.trackOrder,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchEmailReviewDeepLinking: (data) =>
    dispatch(setEmailReviewDeepLinking(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
