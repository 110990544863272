import React from 'react';
import { View } from 'react-native';

import ThemeStyle from '../../../Theme/styles';

import { RectangleLoader } from '../../../Components/Loader';

import BaseLoader from '../../../Components/Card/indexLoader';
import styles, { ids } from '../../../Components/Card/styles';

function EventCardLoader({ autoWidth }: { autoWidth?: boolean }) {
  return (
    <View
      dataSet={{
        media: autoWidth
          ? ids.storeResponsiveMobileContainer
          : ids.storeResponsiveContainer,
      }}
      style={[
        styles.storeContainer,
        autoWidth
          ? styles.storeResponsiveMobileContainer
          : styles.storeResponsiveContainer,
      ]}
    >
      {/* Image */}
      <View style={ThemeStyle.spacingBottomSmall}>
        <BaseLoader widthTitle={false} withSubTitle={false} />
      </View>
      {/* Title */}
      <View style={ThemeStyle.spacingBottomSmall}>
        <RectangleLoader width="80%" height={19} />
      </View>
      {/* Description */}
      <View style={ThemeStyle.spacingBottomSmall}>
        <RectangleLoader width="100%" height={19} />
      </View>
      {/* Date and Time */}
      <View style={ThemeStyle.spacingBottomSmall}>
        <RectangleLoader width="100%" height={19} />
      </View>
      {/* Button */}
      <View style={ThemeStyle.spacingTopSmall}>
        <RectangleLoader width="100%" height={40} />
      </View>
    </View>
  );
}

export default EventCardLoader;
