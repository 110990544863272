import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CancelToken } from 'apisauce';
import { useQuery } from '@tanstack/react-query';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../../Config/constants';
import messages from '../../../Config/messages';
import validation from '../../../Config/validationRules';
import { isUserDetailsIncomplete } from '../../../Helper';
import CheckoutHelper from '../../../Helper/Checkout';
import useAlert from '../../../Hooks/useAlert';
import useCancellableRequest from '../../../Hooks/useCancellableRequest';
import { selectCart } from '../../../RTK/cart/selectors';
import { updateCheckoutDetails } from '../../../RTK/checkout';
import {
  checkoutStoreInfoSelector,
  storeCheckoutSelector,
} from '../../../RTK/checkout/selectors';
import { checkout } from '../../../RTK/defaultValues';
import { updateInformation } from '../../../RTK/user';
import checkoutApi from '../../../Service/api/checkout';
import userApi from '../../../Service/api/user';
import storeApi from '../../../Service/api/store';

import useCheckout from './useCheckout';
import Service from '../../../Service';

const { ORDER_TYPES } = constants;

const thirdPartySchema = validation.createSchema({
  firstName: validation.firstNameSchema,
  lastName: validation.lastNameSchema,
  phone: validation.contactSchema,
});

const vehicleInformationSchema = validation.createSchema({
  make: validation.fieldRequired('Vehicle Make'),
  color: validation.fieldRequired('Vehicle Color'),
  plateNumber: validation.fieldRequired('Vehicle Plate Number'),
});

// 2nd parameter is used on CheckoutNavigator of web (useCheckoutWeb.js)
function useNormalCheckout({
  mealPlanType,
  mealPlanItems,
  mealPlanCheckoutData,
  mealPlanReduxDispatch,
} = {}) {
  const cancelSource = useRef(CancelToken.source());
  const alert = useAlert();
  const checkoutStoreInfo = useSelector(checkoutStoreInfoSelector);
  const user = useSelector((state) => state.user.user);
  const whenFilter = useSelector((state) => state.filter.home.when);
  const storeCheckoutData = useSelector((state) =>
    storeCheckoutSelector(state, checkoutStoreInfo.store_id)
  );
  const storeCartData = useSelector((state) =>
    selectCart(state, checkoutStoreInfo.store_id)
  );
  const dispatch = useDispatch();
  const { createRequest } = useCancellableRequest();
  const result = useCheckout(
    checkoutStoreInfo.store_id,
    checkoutStoreInfo.delivery_payment_methods,
    mealPlanItems || storeCartData?.[0]?.items,
    mealPlanCheckoutData || storeCheckoutData,
    (key, value) => {
      const action = mealPlanReduxDispatch || updateCheckoutDetails;
      return dispatch(
        action({
          store_id: checkoutStoreInfo.store_id,
          keyToUpdate: key,
          keyValue: value,
        })
      );
    },
    mealPlanType
  );

  const {
    data: storeHoursResult,
    isFetching: storeHoursLoading,
    refetch: refetchStoreHours,
  } = useQuery({
    enabled: Boolean(checkoutStoreInfo.store_id) && !mealPlanType, // since web is using it on meal plan and non meal plan order, prevent fetch if has mealPlanType
    queryFn: () =>
      storeApi.getStoreOrderDates(
        checkoutStoreInfo.store_id,
        cancelSource.current.token
      ),
    queryKey: [checkoutStoreInfo.store_id, 'store-hours'],
    refetchOnWindowFocus: false,
  });

  const additionalChargeByOrderType = lodashFind(
    checkoutStoreInfo.additional_charges,
    (e) =>
      e.name.indexOf(
        `${storeCheckoutData[checkout.keys.ORDER_TYPE]?.value}_`
      ) === 0 // e.g: pickup_service_charge, curbside_pickup_service_charge (pickup_) will return the pickup not curbside since the indexOf is returned 0
  );
  const hasAdditionalCharge = !lodashIsEmpty(additionalChargeByOrderType);
  // only fetch if
  const { data: additionalChargeResult, isFetching: additionalChargeLoading } =
    useQuery({
      enabled:
        Boolean(checkoutStoreInfo.store_id) && // has store id
        Boolean(storeCheckoutData[checkout.keys.ORDER_TYPE]?.value) && // has order type
        hasAdditionalCharge, // has additional charges
      queryFn: () =>
        storeApi.getAdditionalCharge(
          {
            store_id: checkoutStoreInfo.store_id,
            order_type: storeCheckoutData[checkout.keys.ORDER_TYPE]?.value,
          },
          cancelSource.current.token
        ),
      queryKey: [
        'additional-charge',
        checkoutStoreInfo.store_id,
        storeCheckoutData[checkout.keys.ORDER_TYPE]?.value,
        hasAdditionalCharge,
      ],
      refetchOnWindowFocus: false,
    });
  const additionalChargeData = additionalChargeResult?.ok
    ? additionalChargeResult.data
    : {};
  const additionalChargeError = !lodashIsEmpty(
    additionalChargeResult?.data?.message
  )
    ? Service.handleErrorMessage(additionalChargeResult?.data?.message?.[0])
    : '';

  useEffect(() => {
    _setDefaultValues();
    return () => {
      cancelSource.current.cancel();
    };
  }, []);

  useEffect(() => {
    if (storeHoursResult?.ok) {
      _setDefaultScheduleValue();
    }
  }, [storeHoursResult?.ok]);

  /**
   * Used to check if can place order, this function run validation for form on normal checkout
   * @param {*} storeInfo store info passed on navigation when navigate to checkout page
   * @return {Promise<object>} return example
   * {
   *  ok: boolean, // if validation if success
   *  isClosed: boolean, // if store is already closed
   *  isClosing: boolean, // if store is closing
   * }
   */
  const _isCanPlaceOrder = async (storeInfo) => {
    const {
      [checkout.keys.DELIVERY_SCHEDULE]: schedule,
      [checkout.keys.ORDER_TYPE]: orderType,
      [checkout.keys.THIRD_PARTY_PICKUP]: thirdPartyPickup,
    } = storeCheckoutData;
    const isScheduleAsap = schedule?.value === CheckoutHelper.ASAP.value;
    const isDelivery = orderType?.value === ORDER_TYPES.DELIVERY;
    const isPickup = orderType?.value === ORDER_TYPES.PICKUP;
    const isCurbside = orderType?.value === ORDER_TYPES.CURBSIDE;
    // check if the store is already closed or closing
    const { isClosed, isClosing } = CheckoutHelper.isCanCheckout(
      storeInfo.store_hours,
      storeInfo.store_off_dates,
      storeInfo.prep_time,
      isScheduleAsap ? undefined : schedule?.value
    );
    // basic required info
    const requiredInfo = [
      { key: 'USER_DETAILS', label: 'User details' },
      { key: 'ORDER_TYPE', label: 'Order type' },
      { key: 'DELIVERY_ADDRESS', label: 'Address' },
      { key: 'DELIVERY_SCHEDULE', label: 'Schedule' },
      { key: 'PAYMENT_METHOD', message: messages.PAYMENT_METHOD.required },
    ];
    // conditional required info
    if (isDelivery) {
      requiredInfo.push(
        {
          key: 'PAYMENT_OPTIONS',
          message: 'Please choose a delivery payment option.',
        },
        { key: 'VEHICLE_OPTIONS', label: 'Delivery Vehicle Option' }
      );
    } else if (isPickup && thirdPartyPickup?.isChecked) {
      requiredInfo.push({
        key: 'THIRD_PARTY_PICKUP',
        message: 'Missing pick-up information',
        validationSchema: thirdPartySchema,
        getValidationPayload: (cdata) => ({
          phone: cdata?.contactNumber,
          lastName: cdata?.lastName,
          firstName: cdata?.firstName,
        }),
      });
    } else if (isCurbside) {
      requiredInfo.push({
        key: 'VEHICLE_INFORMATION',
        label: 'Vehicle information',
        validationSchema: vehicleInformationSchema,
        getValidationPayload: (cdata) => ({
          make: cdata?.make,
          color: cdata?.color,
          plateNumber: cdata?.plateNumber,
        }),
      });
    }
    const isOk = await result.validateForm(requiredInfo);
    return {
      ok: isOk && (!isClosed || isClosing),
      isClosed,
      isClosing,
    };
  };

  const _onRequestCheckout = async () => {
    const {
      store_id,
      [checkout.keys.ORDER_TYPE]: otype,
      [checkout.keys.THIRD_PARTY_PICKUP]: tpickup,
      [checkout.keys.DELIVERY_SCHEDULE]: dSchedule,
      [checkout.keys.DELIVERY_ADDRESS]: dAddress,
      [checkout.keys.PAYMENT_OPTIONS]: poption,
      [checkout.keys.VEHICLE_OPTIONS]: voption,
      [checkout.keys.PROMO_CODE]: promotion,
      [checkout.keys.USER_DETAILS]: userDetails,
      [checkout.keys.PAYMENT_METHOD]: paymentMethod,
      [checkout.keys.VEHICLE_INFORMATION]: vinformation,
    } = storeCheckoutData;
    if (isUserDetailsIncomplete(user)) {
      // user doesn't setup his user details yet, so on the normal checkout
      // we update user details on the api using it before making api call for placing the order
      const updateUserPayload = {
        first_name: userDetails.firstName,
        last_name: userDetails.lastName,
        phone_number: userDetails.phone,
      };
      await userApi.update(updateUserPayload);
      await dispatch(updateInformation(userDetails));
    }
    const payload = {
      store_id,
      order_type: otype.value,
      address_id: dAddress.id,
      promo_code: promotion?.code?.toUpperCase(),
      payment_method: paymentMethod?.method,
    };
    if (otype.value === ORDER_TYPES.DELIVERY) {
      payload.delivery_option = {
        delivery_payment_method: poption?.value,
        delivery_vehicle: voption?.value,
      };
    }
    if (otype.value === ORDER_TYPES.PICKUP && tpickup?.isChecked) {
      payload.order_type = ORDER_TYPES.THIRD_PARTY_PICKUP;
      payload.third_party_pickup_option = {
        first_name: tpickup.firstName,
        last_name: tpickup.lastName,
        mobile_number: `0${tpickup.contactNumber}`,
      };
    }
    if (otype.value === ORDER_TYPES.CURBSIDE) {
      payload.curbside_pickup_option = {
        vehicle_make: vinformation.make,
        vehicle_color: vinformation.color,
        vehicle_plate_number: vinformation.plateNumber,
      };
    }
    if (dSchedule?.date && dSchedule?.time) {
      payload.order_date = dSchedule.date;
      payload.order_time = dSchedule.time;
    }
    return createRequest(checkoutApi.placeOrder, payload);
  };

  const _setDefaultValues = () => {
    const selectedOrderType = storeCheckoutData[checkout.keys.ORDER_TYPE];
    if (
      lodashIsEmpty(selectedOrderType) || // if no order types has been set yet
      selectedOrderType?.value === constants.ORDER_TYPES.MEAL_PLAN // OR selected order type is meal plan (this happen if store page is open and active is meal plan there and user navigate to checkout on the same store on his cart)
    ) {
      // set the order type to the first available order type
      const firstActiveKey = lodashFind(
        Object.keys(checkoutStoreInfo.order_types),
        (key) => checkoutStoreInfo.order_types[key]
      ); // get the first active key e.g: is_delivery_active: true
      const orderType = lodashFind(constants.ORDER_TYPE_DATA, {
        apiDataKey: firstActiveKey,
      }); // find that key on order type data
      _updateCheckoutDetails(checkout.keys.ORDER_TYPE, orderType); // update order type
    }
  };

  const _setDefaultScheduleValue = async () => {
    const preOrderDate = storeCheckoutData[checkout.keys.DELIVERY_SCHEDULE];
    const storeHours = storeHoursResult.data;
    const isCanAsap = CheckoutHelper.isCanAsap(storeHours);
    if (
      !lodashIsEmpty(preOrderDate) &&
      preOrderDate?.value !== CheckoutHelper.ASAP.value
    ) {
      // has existing selected schedule AND it's not ASAP
      _setPreOrderDateOrAsapSchedule(preOrderDate.value);
    } else if (!lodashIsEmpty(whenFilter)) {
      // User set a date filter on homepage
      _setPreOrderDateOrAsapSchedule(whenFilter.value);
    } else if (isCanAsap) {
      // user doesn't have date filter or no selected schedule yet BUT can asap
      _updateCheckoutDetails(
        checkout.keys.DELIVERY_SCHEDULE,
        CheckoutHelper.ASAP
      );
    }
    result.getDeliveryQuotation(); // after setting the schedule, get delivery quotation
  };

  const _setPreOrderDateOrAsapSchedule = (dateValue) => {
    const storeHours = storeHoursResult.data;
    const preOrderDate = storeCheckoutData[checkout.keys.DELIVERY_SCHEDULE];
    const isAlreadyAsap = preOrderDate?.value === CheckoutHelper.ASAP.value;
    const isCanAsap = CheckoutHelper.isCanAsap(storeHours);
    const { ok, data } = CheckoutHelper.getDateFromStoreHours(
      dateValue,
      storeHours
    );
    if (ok) {
      // if date filter is found on store hours, it means it's still possible to proceed on date filter schedule
      _updateCheckoutDetails(checkout.keys.DELIVERY_SCHEDULE, data); // set schedule as date filter
    } else if (isCanAsap && !isAlreadyAsap) {
      // if can order asap AND not yet selected asap
      alert(
        'Order date updated',
        'Your previous pre-order date has been removed. Your order is now set for ASAP.'
      );
      // set schedule as asap
      _updateCheckoutDetails(
        checkout.keys.DELIVERY_SCHEDULE,
        CheckoutHelper.ASAP
      );
    } else if (!isCanAsap && !lodashIsEmpty(preOrderDate)) {
      // if cannot order asap AND still has pre-order date
      alert(
        'Order date updated',
        'We have removed your pre-order date as it was outdated. Please select new schedule.'
      );
      _updateCheckoutDetails(checkout.keys.DELIVERY_SCHEDULE); // remove schedule
    }
  };

  const _updateCheckoutDetails = (key, value) => {
    dispatch(
      updateCheckoutDetails({
        store_id: checkoutStoreInfo.store_id,
        keyToUpdate: key,
        keyValue: value,
      })
    );
  };

  return {
    ...result,
    additionalCharge: {
      breakdownFactor: additionalChargeByOrderType,
      data: additionalChargeData,
      enabled: hasAdditionalCharge,
      error: additionalChargeError,
      loading: additionalChargeLoading,
      total: hasAdditionalCharge
        ? additionalChargeData?.total_additional_charges
        : 0,
    },
    isStoreHoursLoading: storeHoursLoading,
    storeCartData,
    storeCheckoutData,
    storeHours: storeHoursResult?.ok ? storeHoursResult.data : [],
    storeHoursError:
      storeHoursResult?.ok === false ? storeHoursResult.data : '',
    whenFilter,
    isCanPlaceOrder: _isCanPlaceOrder,
    onRequestCheckout: _onRequestCheckout,
    refetchStoreHours,
  };
}

export default useNormalCheckout;
