/**
 * @format
 */
import 'react-native-gesture-handler';
import { AppRegistry } from 'react-native-web';
// Generate required css
import PoppinsRegular from '../App/Assets/fonts/Poppins-Regular.ttf';
import PoppinsSemiBold from '../App/Assets/fonts/Poppins-SemiBold.ttf';
import Feather from 'react-native-vector-icons/Fonts/Feather.ttf';
import FontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import FontAwesome5_Brands from 'react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf';
import FontAwesome5_Regular from 'react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf';
import FontAwesome5_Solid from 'react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf';
import MaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import Ionicons from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import Entypo from 'react-native-vector-icons/Fonts/Entypo.ttf';
import { name as appName } from '../app.json';
import App from '../App/index';

const PR = `@font-face {
  src: url(${PoppinsRegular});
  font-family: Poppins-Regular;
}`;
const PS = `@font-face {
  src: url(${PoppinsSemiBold});
  font-family: Poppins-SemiBold;
}`;

//importing of vector icons

const FE = `@font-face {
  src: url(${Feather});
  font-family: Feather;
}`;

const FA = `@font-face {
  src: url(${FontAwesome});
  font-family: FontAwesome;
}`;
const FA5_Brands = `@font-face {
  src: url(${FontAwesome5_Brands});
  font-family: FontAwesome5_Brands;
}`;
const FA5_Regular = `@font-face {
  src: url(${FontAwesome5_Regular});
  font-family: FontAwesome5_Regular;
}`;
const FA5_Solid = `@font-face {
  src: url(${FontAwesome5_Solid});
  font-family: FontAwesome5_Solid;
}`;
const MC = `@font-face {
  src: url(${MaterialCommunityIcons});
  font-family: MaterialCommunityIcons;
}`;
const Ion = `@font-face {
  src: url(${Ionicons});
  font-family: Ionicons;
}`;
const Ent = `@font-face {
  src: url(${Entypo});
  font-family: Entypo;
}`;

// //Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = FE;
  style.styleSheet.cssText = PR;
  style.styleSheet.cssText = PS;
  style.styleSheet.cssText = FA;
  style.styleSheet.cssText = FA5_Brands;
  style.styleSheet.cssText = FA5_Regular;
  style.styleSheet.cssText = FA5_Solid;
  style.styleSheet.cssText = MC;
  style.styleSheet.cssText = Ion;
  style.styleSheet.cssText = Ent;
} else {
  style.appendChild(document.createTextNode(FE));
  style.appendChild(document.createTextNode(PR));
  style.appendChild(document.createTextNode(PS));
  style.appendChild(document.createTextNode(FA));
  style.appendChild(document.createTextNode(FA5_Brands));
  style.appendChild(document.createTextNode(FA5_Regular));
  style.appendChild(document.createTextNode(FA5_Solid));
  style.appendChild(document.createTextNode(MC));
  style.appendChild(document.createTextNode(Ion));
  style.appendChild(document.createTextNode(Ent));
}

// // // // Inject stylesheet
document.head.appendChild(style);

if (module.hot) {
  module.hot.accept();
}

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: document.getElementById('root'),
});
