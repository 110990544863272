import React, { memo } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { SwipeListView } from 'react-native-swipe-list-view';
import { Divider, Icon, Text } from '@ui-kitten/components';

import Badge from '../../Components/Badge';
import Button from '../../Components/Button';
import StoreCart from '../../Components/StoreCart';
import StoreCartItem from '../../Components/StoreCart/CartItem';
import StoreCartSummary from '../../Components/StoreCart/CartSummary';
import MinimumOrderNote from '../../Components/StoreCart/MinimumOrderNote';
import messages from '../../Config/messages';
import { getCurrency } from '../../Helper/Routines';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import useController from './controller/useStoreCartService';

const hiddenItemWidth = 75;

function StoreCartService(props) {
  const { index, setScrollEnabled, onItemPress, onStoreNamePress } = props;
  const {
    availabilityError,
    buttonLoading,
    buttonTxt,
    canContinueToPayment,
    isLessThanMinimum,
    isPaymentPending,
    items,
    otherElementLoading,
    promotion,
    promotionMinimum,
    store_name,
    storeData,
    storeInformation,
    storePromo,
    swipeRowRef,
    total,
    unavailableItemIds,
    getItemLoadingStatus,
    isCanReschedule,
    onItemRemove,
    onPromoRemove,
    onQtyChange,
    proceedToOrder,
  } = useController(props);

  const _renderHiddenItem = ({ item }) => {
    const loadingStatus = getItemLoadingStatus(item.item_id);
    return (
      <View style={[ThemeStyle.flex1, ThemeStyle.alignItemsEnd]}>
        <Button
          style={[
            ThemeStyle.flex1,
            ThemeStyle.alignItemsCenter,
            ThemeStyle.justifyContentCenter,
            { width: hiddenItemWidth, backgroundColor: 'red' },
          ]}
          onPress={() => onItemRemove(item)}
          disabled={otherElementLoading || loadingStatus.either}
          plain
        >
          {loadingStatus.removing ? (
            <ActivityIndicator size={25} color={ThemeColor.white} />
          ) : (
            <Icon
              name="trash-o"
              pack="fa"
              fill={ThemeColor.white}
              style={{ width: 25, height: 25 }}
            />
          )}
        </Button>
      </View>
    );
  };

  const _renderVisibleItem = ({ item, index: itemIndex }) => {
    const isLast = items.length - 1 === itemIndex;
    const loadingStatus = getItemLoadingStatus(item.item_id);
    const canReschedule = isCanReschedule(item.item_id);
    return (
      <View style={[ThemeStyle.flex1, ThemeStyle.pageBackground]}>
        {/* Cart Item */}
        <StoreCartItem
          canReschedule={canReschedule}
          changingQty={loadingStatus.updating}
          index={itemIndex}
          loading={
            otherElementLoading || loadingStatus.either || isPaymentPending
          }
          sectionIndex={index}
          showQtyBadge={false}
          unavailable={unavailableItemIds.includes(item.item_id)}
          onItemPress={() => onItemPress(item)}
          onRemovePress={() => onItemRemove(item, true)}
          onQtyChange={(newQty) => onQtyChange(item, newQty)}
          {...item}
        />
        {/* Item Separator */}
        <Divider
          style={[
            ThemeStyle.spacingLeft,
            ThemeStyle.spacingRight,
            ThemeStyle.divider,
            isLast && ThemeStyle.pageBackground, // if last item, make the divider white background so behind element not show on the bottom
          ]}
        />
      </View>
    );
  };

  return (
    <View>
      {/* Store Name */}
      <StoreCart
        contentRight={
          isPaymentPending && (
            <Badge
              category={Badge.CATEGORIES.WARNING}
              type={Badge.TYPES.OUTLINE}
              size="c1"
            >
              {messages.PAYMENT_PENDING.title}
            </Badge>
          )
        }
        storeName={store_name || storeInformation?.name || ''}
        onStoreNamePress={() => onStoreNamePress(storeData)}
      />

      {/* Store Cart Data */}
      <View
        style={[
          ThemeStyle.flex1,
          ThemeStyle.spacingBottomMedium,
          { overflow: 'hidden' },
        ]}
      >
        <SwipeListView
          ref={swipeRowRef}
          data={items}
          keyExtractor={(_, itemIndex) => `${index}.${itemIndex}`}
          scrollEnabled={false} // no need to enable vertical scroll of this because we only needed it for swipe left
          rightOpenValue={-hiddenItemWidth}
          stopRightSwipe={-hiddenItemWidth}
          renderItem={_renderVisibleItem}
          renderHiddenItem={_renderHiddenItem}
          onRowDidClose={() => setScrollEnabled(true)}
          swipeGestureBegan={() => setScrollEnabled(false)}
          swipeGestureEnded={() => setScrollEnabled(true)}
          disableLeftSwipe={isPaymentPending} // disable swipe if payment pending
          closeOnRowBeginSwipe
          disableHiddenLayoutCalculation
          disableRightSwipe
        />
      </View>

      {/* Summary */}
      <View
        style={[ThemeStyle.pageHorizontalSpacing, ThemeStyle.spacingBottom]}
      >
        <Text>Summary</Text>

        <StoreCartSummary
          cart={items}
          isDelivery={false} // always false on cart page, since we only need to display the promo details here
          promotion={promotion}
          noHspacing
        />

        <MinimumOrderNote
          current={total}
          minimum={promotionMinimum}
          show={isLessThanMinimum}
          voucher={storePromo}
          onRemove={onPromoRemove}
        />

        <Button
          testID="goToCheckoutButton"
          status={availabilityError ? 'danger' : 'success'}
          style={[ThemeStyle.shadow, ThemeStyle.spacingTopMedium]}
          accessoryRight={() =>
            canContinueToPayment || availabilityError ? null : (
              <Text
                appearance="alternative"
                style={ThemeStyle.spacingRightSmall}
              >
                {getCurrency(total)}
              </Text>
            )
          }
          onPress={proceedToOrder}
          loadingColor={ThemeColor.white}
          loading={buttonLoading}
          disabled={isPaymentPending}
        >
          {() => (
            <Text
              appearance="alternative"
              style={[
                ThemeStyle.flex1,
                ThemeStyle.spacingLeftSmall,
                canContinueToPayment && ThemeStyle.textCenter,
              ]}
            >
              {buttonTxt}
            </Text>
          )}
        </Button>
      </View>
    </View>
  );
}

export default memo(StoreCartService);
