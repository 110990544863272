import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Radio, Text } from '@ui-kitten/components';

import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

function PaymentMethodItem({ accessoryRight, images, label, ...rest }) {
  return (
    <View style={styles.wrapper}>
      <Radio {...rest}>
        {() => (
          <View
            style={[
              ThemeStyle.flex1,
              ThemeStyle.flexDirectionRowCenterSpaceBetween,
              ThemeStyle.spacingLeftMedium,
            ]}
          >
            <Text category={'p2'} style={ThemeStyle.flex1}>
              {label}
            </Text>
            {images.map((svg, i) => (
              <View style={ThemeStyle.spacingLeftSmall} key={i}>
                <svg.image
                  width={svg.width}
                  height={svg.width / svg.aspectRatio}
                />
              </View>
            ))}
            {accessoryRight}
          </View>
        )}
      </Radio>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    borderWidth: 1,
    borderColor: ThemeColor.gray,
    borderRadius: 5,
    ...ThemeStyle.pageVerticalSpacingMedium,
    ...ThemeStyle.pageHorizontalSpacingSmall,
  },
});

PaymentMethodItem.defaultProps = {
  images: [],
};

PaymentMethodItem.propTypes = {
  label: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.elementType.isRequired,
      width: PropTypes.number.isRequired,
    })
  ),
  accessoryRight: PropTypes.element,
};

export default PaymentMethodItem;
