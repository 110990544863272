import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';
import lodashIsEqual from 'lodash/isEqual';
import lodashFilter from 'lodash/filter';

import Button from '../../Components/Button';
import constants from '../../Config/constants';
import { getCartTerm } from '../../Helper/RemoteConfig';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE_LIGHT } from '../../Theme/typographyProps';

const vehicleList = [
  { label: 'Car', value: 'car', icon: 'car' },
  { label: 'Motorcycle', value: 'motorcycle', icon: 'motorcycle' },
];

function VehicleOptions({ disabled, value, onChange, hasBulk }) {
  const filteredVehicleList = hasBulk
    ? lodashFilter(vehicleList, { value: 'car' })
    : vehicleList;

  const _onVehiclePressed = (v) => () => {
    if (v.value === value?.value) {
      // if already selected just return
      return;
    }
    // call callback
    onChange(v);
  };

  return (
    <Fragment>
      <Text
        style={ThemeStyle.spacingBottomMedium}
        {...(constants.isWeb ? { category: 's2' } : SECTION_TITLE_LIGHT)}
      >
        Delivery Vehicle Options
      </Text>

      <View style={ThemeStyle.flexDirectionRowCenter}>
        {filteredVehicleList.map((v, i) => (
          <Button
            key={i}
            onPress={_onVehiclePressed(v)}
            style={ThemeStyle.spacingRightMedium}
            disabled={disabled}
            plain
          >
            <View
              style={[
                styles.container,
                lodashIsEqual(v, value) && styles.containerActive,
              ]}
            >
              <Icon
                name={v.icon}
                pack="fa"
                fill={ThemeColor.white}
                style={styles.icon}
              />

              <Text category="p2" appearance="alternative">
                {v.label}
              </Text>
            </View>
          </Button>
        ))}
      </View>

      {hasBulk && (
        <Text
          category="p2"
          style={[ThemeStyle.noticeText, ThemeStyle.pageVerticalSpacingMedium]}
        >
          You currently have bulk items in your {getCartTerm()} that requires
          car delivery
        </Text>
      )}
    </Fragment>
  );
}

const styles = StyleSheet.create({
  container: {
    ...ThemeStyle.alignItemsCenter,
    ...ThemeStyle.justifyContentCenter,
    backgroundColor: ThemeColor.gray,
    borderRadius: 10,
    width: 100,
    height: 70,
  },
  containerActive: {
    backgroundColor: ThemeColor.green,
  },
  icon: {
    ...ThemeStyle.spacingBottomSmall,
    width: 20,
    height: 20,
  },
});

VehicleOptions.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  hasBulk: PropTypes.bool,
};

export { vehicleList };
export default VehicleOptions;
