import routeList from '../Routes/list';
import { globalSpacing } from '../Theme/styles';

export const getCurrency = (
  amount = 0,
  currency = 'PHP',
  removeCurrent = false,
  decimalCount = 2,
  decimal = '.',
  thousands = ','
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? '-' : '';
    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    if (removeCurrent) {
      return (
        negativeSign +
        (j ? i.substring(0, j) + thousands : '') +
        i.substring(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : '')
      );
    } else {
      return `${negativeSign}₱${
        (j ? i.substring(0, j) + thousands : '') +
        i.substring(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : '')
      }`;
    }
  } catch (e) {
    return amount;
  }
};
export const getPageName = (name, storeName, orderNumber) => {
  let PageName = `Marketplace | `;
  switch (name) {
    case routeList.ONBOARDING:
      return PageName + `Welcome`;
    case routeList.HOME:
      return PageName + `Home`;
    case routeList.STORE:
      return PageName + ` ${storeName || 'Store'}`;
    case routeList.CHECKOUT:
      return PageName + `Checkout | ` + storeName;
    case routeList.CHECKOUT_WEBVIEW:
      if (orderNumber) {
        return `Track Order #${orderNumber}`;
      }
      return PageName + `Checkout | ` + storeName;
    case routeList.AUTH:
      return PageName + `Authentication`;
    case routeList.BASKET_TAB:
      return PageName + `Orders`;
    case routeList.PROFILE:
      return PageName + `Account`;
    case routeList.SEE_ALL:
      return PageName + `Saved Stores`;
    case routeList.GET_HELP:
      return PageName + `Help`;
    default:
      return 'Marketplace';
  }
};

export const isScrollEndReached = ({
  layoutMeasurement,
  contentOffset,
  contentSize,
}) => {
  const paddingRight = globalSpacing;
  return (
    layoutMeasurement.width + contentOffset.x >=
    contentSize.width - paddingRight
  );
};
