import React, { Fragment } from 'react';
import { Text } from '@ui-kitten/components';
import lodashpick from 'lodash/pick';

import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE_LIGHT } from '../../Theme/typographyProps';

import ThirdPartyAndVehicleInputs from './ThirdPartyAndVehicleInputs';

const fields = [
  { key: 'make', label: 'Vehicle Make' },
  { key: 'color', label: 'Vehicle Color' },
  { key: 'plateNumber', label: 'Vehicle Plate Number' },
];

// for curbside form
function VehicleInformation(props) {
  const { values, onChange } = props;

  const _onChange = (key) => (fieldValue) => {
    onChange({ ...values, [key]: fieldValue });
  };

  return (
    <Fragment>
      <Text style={ThemeStyle.spacingBottomMedium} {...SECTION_TITLE_LIGHT}>
        Vehicle Information
      </Text>

      <ThirdPartyAndVehicleInputs
        fields={fields}
        onChange={_onChange}
        {...lodashpick(props, ['disabled', 'values'])}
      />
    </Fragment>
  );
}

export default VehicleInformation;
