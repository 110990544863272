import { StackActions, useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import lodashIsEmpty from 'lodash/isEmpty';
import constants from '../Config/constants';
import { isLoggedInSelector } from '../RTK/user/selectors';
import routeList from '../Routes/list';

/**
 * Description placeholder
 *
 * @param {{ backRoute?: string; backRouteParams?: object; rootRoute?: string;isDefaultGoBack?:boolean }} param0
 * @param {string} [param0.backRoute] - route to go back to
 * @param {object} [param0.backRouteParams] - url parameter of route to back to
 * @param {string} [param0.rootRoute] - root route
 * @param {boolean} [param0.isDefaultGoBack] - flag to use navigate go back some scenario you don't want to use the goBack function
 * @returns {() => void}
 */
function useGoBack({
  backRoute,
  backRouteParams,
  rootRoute,
  isDefaultGoBack = true,
} = {}) {
  const navigation = useNavigation();
  const isLoggedIn = useSelector(isLoggedInSelector);

  return () => {
    if (navigation.canGoBack() && isDefaultGoBack) {
      // if can go back, just go back
      navigation.goBack();
    } else if (backRoute) {
      // if has back route, navigate to it
      navigation.replace(backRoute, backRouteParams);
    } else if (constants.isWeb) {
      // if can't go back and no backRoute specified and if web platform, back to homepage
      navigation.dispatch(
        StackActions.replace(routeList.HOME, {
          screen: 'MainMenu',
          params: {
            screen: rootRoute || routeList.ROOT_TAB,
            params: backRouteParams,
          },
        })
      );
    } else if (isLoggedIn) {
      // if logged in, back to home tab
      navigation.replace(rootRoute || routeList.ROOT_TAB);
    } else {
      // if not logged in, back to login page
      navigation.replace(routeList.AUTH);
    }
  };
}

export default useGoBack;
