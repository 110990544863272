import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { Layout, Icon, Text } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';
import BreakPoints from '../../../Theme/styles/breakpoints';

import { SECTION_TITLE_LIGHT } from '../../../Theme/typographyProps';

import AddressHelper from '../../../Helper/Address';

import { Props } from './constants';
import useDropOffOptions from './controller/useDropOffOptions';
import LastUsedAddresses from './LastUsedAddresses';

const DropOffOptions = (props: Props) => {
  const {
    disabled,
    selectedAddress,
    onAddressPress,
    onLastUsedAddressPress,
    onSelectedAddressPress,
  } = props;
  const {
    displayAddress,
    hideAccordion,
    userLastUsedAddresses,
    getRowData,
    toggleAccordion,
  } = useDropOffOptions(selectedAddress);

  return (
    <Layout style={styles.mainWrapper} testID="deliveryContainer">
      <View
        style={[ThemeStyle.flexDirectionRowSpaceBetween, styles.contentWrapper]}
      >
        <Text {...SECTION_TITLE_LIGHT}>Address</Text>

        <TouchableOpacity
          onPress={onAddressPress}
          style={styles.addAddressButton}
        >
          <Text {...SECTION_TITLE_LIGHT} status="success">
            View Saved Address
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.contentWrapper}>
        {/* @ts-expect-error */}
        <View style={{ rowGap: 10 }}>
          <Layout level="2" style={styles.activeItemWraper}>
            <Icon name="pin" style={styles.iconStyle} fill={ThemeColor.green} />
            <View
              style={[
                ThemeStyle.flex3,
                ThemeStyle.alignItemsStart,
                ThemeStyle.pageHorizontalSpacingSmall,
              ]}
            >
              <Text category="p2">
                {AddressHelper.getDisplayTitle(displayAddress)}
              </Text>
              <Text category="p2" status="primary" numberOfLines={1}>
                {AddressHelper.getDisplaySubTitle(displayAddress)}
              </Text>
            </View>
            <TouchableOpacity
              onPress={() => onSelectedAddressPress(displayAddress)}
              //@ts-ignore:TS error in touchable opacity
              dataSet={{ media: ids.locateOnMapButton }}
              style={styles.locateOnMapButton}
            >
              <Icon
                name="navigation-2-outline"
                style={styles.iconStyle}
                fill={ThemeColor.green}
              />
              <Text
                dataSet={{ media: ids.locateOnMapText }}
                style={styles.locateOnMapText}
                category="p2"
                status="success"
              >
                Locate on Map
              </Text>
            </TouchableOpacity>
          </Layout>
        </View>
        <LastUsedAddresses
          disabled={disabled}
          list={userLastUsedAddresses}
          selectedId={selectedAddress?.id}
          show={!hideAccordion}
          getRow={getRowData}
          onSelect={onLastUsedAddressPress}
          toggle={toggleAccordion}
        />
      </View>
    </Layout>
  );
};
const { ids, styles } = StyleSheet.create({
  mainWrapper: {
    padding: 10,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: ThemeColor.lightGray,
  },
  contentWrapper: {
    paddingTop: 10,
    paddingHorizontal: 10,
  },
  activeItemWraper: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.flexDirectionRow,
    ...ThemeStyle.alignItemsCenter,
    borderColor: ThemeColor.lightGray,
    borderWidth: 1,
    padding: 10,
    borderRadius: 4,
  },
  iconStyle: {
    width: 25,
    height: 25,
  },
  locateOnMapButton: {
    ...ThemeStyle.flex1,
    [`@media ${BreakPoints.xs}`]: {
      flexDirection: 'column',
      ...ThemeStyle.alignItemsCenter,
      ...ThemeStyle.justifyContentCenter,
      textAlign: 'center',
      rowGap: 5,
    },
    [`@media ${BreakPoints.sm}`]: {
      flexDirection: 'column',
      ...ThemeStyle.alignItemsCenter,
      ...ThemeStyle.justifyContentCenter,
      textAlign: 'center',
      rowGap: 5,
    },
    [`@media ${BreakPoints.md}`]: {
      ...ThemeStyle.flexDirectionRow,
      ...ThemeStyle.alignItemsCenter,
      columnGap: 5,
    },
    [`@media ${BreakPoints.lg}`]: {
      ...ThemeStyle.flexDirectionRow,
      ...ThemeStyle.alignItemsCenter,
      columnGap: 5,
    },
    [`@media ${BreakPoints.xl}`]: {
      ...ThemeStyle.flexDirectionRow,
      ...ThemeStyle.alignItemsCenter,
      columnGap: 5,
    },
    [`@media ${BreakPoints.xxl}`]: {
      ...ThemeStyle.flexDirectionRow,
      ...ThemeStyle.alignItemsCenter,
      columnGap: 5,
    },
  },
  locateOnMapText: {
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'flex',
    },
  },
  addAddressButton: {
    ...ThemeStyle.flexDirectionRow,
    ...ThemeStyle.alignItemsCenter,
    columnGap: 5,
  },
});

export default DropOffOptions;
