import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import Button from '../Button';
import ThemeStyle from '../../Theme/styles';

function StoreCart({
  sectionIndex,
  storeName,
  onStoreNamePress,
  contentRight,
  ...rest
}) {
  return (
    <View
      style={[
        ThemeStyle.pageHorizontalSpacing,
        ThemeStyle.spacingTopMedium,
        ThemeStyle.spacingBottomMedium,
        ThemeStyle.flexDirectionRowCenterSpaceBetween,
      ]}
      {...rest}
    >
      <Button
        testID={sectionIndex ? `storeNameButton${sectionIndex}` : ''}
        onPress={onStoreNamePress}
        style={ThemeStyle.flex1}
        plain
      >
        <Text style={ThemeStyle.flex1}>{storeName}</Text>
      </Button>

      {!!contentRight && (
        <View style={ThemeStyle.spacingLeft}>{contentRight}</View>
      )}
    </View>
  );
}

StoreCart.propTypes = {
  sectionIndex: PropTypes.number,
  storeName: PropTypes.string.isRequired,
  onStoreNamePress: PropTypes.func.isRequired,
  contentRight: PropTypes.node,
};

export default StoreCart;
