import { View, StyleSheet as RNStyleSheet } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { Layout, Text } from '@ui-kitten/components';
import Image from '../../../Components/Image';

import ThemeStyle from '../../../Theme/styles';
import Breakpoints from '../../../Theme/styles/breakpoints';
import constants from '../../../Config/constants';
import messages from '../../../Config/messages';

const EventBanner = ({ uri, isSoldOut }) => {
  return (
    <Layout>
      {isSoldOut && (
        <View style={styles.containerSoldOutOverlay}>
          <View style={styles.contentSoldOutOverlay}>
            <Text appearance="alternative">{messages.TICKET_SOLD_OUT}</Text>
          </View>
        </View>
      )}
      {/* @ts-ignore - Size typescript warning, Image component is JS file size props is optional */}
      <Image
        source={{ uri }}
        dataSet={{ media: ids.img }}
        style={styles.img}
        resizeMode={!constants.isWeb && 'cover'}
      />
    </Layout>
  );
};

const { ids, styles } = StyleSheet.create({
  img: {
    borderRadius: 10,
    [`@media ${Breakpoints.xs}`]: {
      height: constants.isWeb ? 250 : 200,
    },
    [`@media ${Breakpoints.sm}`]: {
      height: 300,
    },
    [`@media ${Breakpoints.md}`]: {
      height: 350,
    },
    [`@media ${Breakpoints.lg}`]: {
      height: 474,
    },
  },
  containerSoldOutOverlay: {
    ...RNStyleSheet.absoluteFillObject,
    zIndex: 1,
    overflow: 'hidden',
    borderRadius: 5,
  },
  contentSoldOutOverlay: {
    ...ThemeStyle.flexDirectionRowCenterCenter,
    backgroundColor: `#00000066`, // 40% black
  },
});

export default EventBanner;
