import './ignoreWarnings';
import React, { useEffect, useState } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { MainContext } from './MainContext';
import Sentry from './Helper/Sentry';
import { initializeRemoteConfig } from './Helper/RemoteConfig';
import store from './RTK/store';
import { FontAwesome } from './IconPack';

const persistor = persistStore(store);
const queryClient = new QueryClient();

Sentry.init();

function Main({ children }) {
  const [isRemoteConfigFetching, setRemoteConfigFetching] = useState(true);

  useEffect(() => {
    initializeRemoteConfig().finally(() => {
      setRemoteConfigFetching(false);
    });
  }, []);

  return (
    <MainContext.Provider value={{ isRemoteConfigFetching }}>
      <SafeAreaProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <IconRegistry icons={[EvaIconsPack, FontAwesome]} />
            <QueryClientProvider client={queryClient}>
              <GestureHandlerRootView style={{ flex: 1 }}>
                {children}
              </GestureHandlerRootView>
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </SafeAreaProvider>
    </MainContext.Provider>
  );
}
export default Main;
