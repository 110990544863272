import { createSelector } from '@reduxjs/toolkit';
import lodashFilter from 'lodash/filter';

import constants from '../../Config/constants';

const cart = (state) => state.cart;

const getStoreCartData = (cartData, storeId) => {
  if (storeId) {
    return lodashFilter(cartData, { store_id: storeId });
  }

  return cartData;
};

const allCartSelector = createSelector(cart, (state) => state?.cartData);

const pendingCartSelector = createSelector(allCartSelector, (carts) =>
  lodashFilter(carts, (c) => c.status === constants.ORDER_STATUS.PENDING)
);

const selectCart = createSelector(
  [
    allCartSelector, // base selector
    (_, storeId) => storeId, // for 2nd param
  ],
  getStoreCartData
);

// return an array for same item with different extras
const selectCartItemVariants = createSelector(
  [
    selectCart, // base selector
    (_, __, itemId) => itemId, // for 2nd (from selectCart) & 3rd param
  ],
  (storeCart, itemId) => {
    const storeCartItems = storeCart?.[0]?.items || [];
    return lodashFilter(storeCartItems, { item_id: itemId }) || [];
  }
);

const selectCartItemTotalQty = createSelector(
  [selectCartItemVariants],
  (cartItems) => cartItems.reduce((acc, obj) => acc + obj.quantity, 0)
);

export {
  allCartSelector,
  pendingCartSelector,
  selectCart,
  selectCartItemVariants,
  selectCartItemTotalQty,
};
