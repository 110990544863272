import { ApplicationProvider } from '@ui-kitten/components';
import * as eva from '@eva-design/eva';

import { initializeRemoteConfig } from './Helper/RemoteConfig';
import { default as themeColors } from './Theme/mapping/colors.json';
import { default as customMapping } from './Theme/mapping';

import Main from './main';
import Routes from './Routes';

initializeRemoteConfig();

function App() {
  return (
    <Main>
      <ApplicationProvider
        {...eva}
        theme={{ ...eva.light, ...themeColors }}
        customMapping={customMapping}
      >
        <Routes />
      </ApplicationProvider>
    </Main>
  );
}

export default App;
