import React from 'react';
import { connect } from 'react-redux';
import { StatusBar, PermissionsAndroid } from 'react-native';
import geolocation from '@react-native-community/geolocation';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../Config/constants';
import Image from '../../Components/Image';
import Map, { Marker, Polyline } from '../../Components/Map';
import userApi from '../../Service/api/user';

import MapLoader from './MapLoader';
import MapOverlay from './MapOverlay';
class OrderTracking extends React.Component {
  state = {
    isLoading: true,
    currentLocation: null,
    region: {
      // this default region is map of the philippines
      latitude: 12.035822043699245,
      latitudeDelta: 14,
      longitude: 122.09027756005526,
      longitudeDelta: 9.739883467555032,
    },
    polyLines: [],
    orderDetails: null,
  };

  componentDidMount() {
    // TODO - do api request if this file is used again
  }

  _regionContainingPoints = (points) => {
    // source: https://stackoverflow.com/a/38061230
    let minLat, maxLat, minLng, maxLng;

    // init first point
    ((point) => {
      minLat = point.latitude;
      maxLat = point.latitude;
      minLng = point.longitude;
      maxLng = point.longitude;
    })(points[0]);

    // calculate rect
    points.forEach((point) => {
      minLat = Math.min(minLat, point.latitude);
      maxLat = Math.max(maxLat, point.latitude);
      minLng = Math.min(minLng, point.longitude);
      maxLng = Math.max(maxLng, point.longitude);
    });

    const midLat = (minLat + maxLat) / 2;
    const midLng = (minLng + maxLng) / 2;

    const deltaLat = (maxLat - minLat) * 2.75;
    const deltaLng = (maxLng - minLng) * 2.75;

    return {
      latitude: midLat - deltaLat * 0.15, // adjust, for showing to above of MapOverlay
      longitude: midLng,
      latitudeDelta: deltaLat,
      longitudeDelta: deltaLng,
    };
  };

  _onCurrentLocationPress = async (isReset) => {
    // if reset is pressed
    if (isReset) {
      this.setState(
        { isLoading: true, currentLocation: null },
        this._getOrderDetails
      );
      return;
    }

    // to request permission
    if (constants.isIOS) {
      await geolocation.requestAuthorization();
    } else {
      await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
      );
    }

    // get user current location
    geolocation.getCurrentPosition(
      ({ coords: { latitude, longitude } }) => {
        const currentLocation = { latitude, longitude };

        this.setState({
          currentLocation,
          region: {
            latitude: currentLocation.latitude - 0.005,
            longitude: currentLocation.longitude,
            latitudeDelta: 0.02,
            longitudeDelta: 0.02,
          },
        });
      },
      (error) => {
        console.log('error', error);
      },
      { timeout: 20000 }
    );
  };

  render() {
    const { isLoading, currentLocation, region, polyLines, orderDetails } =
      this.state;
    const userLocationCoords = polyLines[0];
    const riderLocationCoords = polyLines[polyLines.length - 1];

    return (
      <>
        <StatusBar translucent={true} backgroundColor="transparent" />

        {/* UI overlay such as close, help button etc */}
        {isLoading ? (
          <MapLoader />
        ) : (
          <MapOverlay
            orderDetails={orderDetails}
            currentLocation={currentLocation}
            onCurrentLocationPress={this._onCurrentLocationPress}
          />
        )}

        <Map region={region} liteMode>
          {/* User location pin */}
          {!lodashIsEmpty(userLocationCoords) && (
            <Marker coordinate={userLocationCoords}>
              <Image
                source={{ uri: constants.pinUrl.HOME }}
                style={{ width: 50, height: 50 }}
              />
            </Marker>
          )}

          {/* Rider location pin */}
          {!lodashIsEmpty(riderLocationCoords) && (
            <Marker coordinate={riderLocationCoords}>
              <Image
                source={{ uri: constants.pinUrl.RIDER }}
                style={{ width: 50, height: 50 }}
              />
            </Marker>
          )}

          {/* user current location pin */}
          {!lodashIsEmpty(currentLocation) && (
            <Marker coordinate={currentLocation}>
              <Image
                source={{ uri: constants.pinUrl.CURRENT_LOCATION }}
                style={{ width: 50, height: 50 }}
              />
            </Marker>
          )}

          {/* Polylines (Route / path) */}
          {!lodashIsEmpty(polyLines) && (
            <Polyline coordinates={polyLines} strokeWidth={2} />
          )}
        </Map>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(OrderTracking);
