import 'text-encoding'; // required on 'react-native-qrcode-svg'
import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import LottieView from 'lottie-react-native';
import { Icon, Layout, Text } from '@ui-kitten/components';
import Collapsible from 'react-native-collapsible';
import QRCode from 'react-native-qrcode-svg';
import dayjs from 'dayjs';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';
import lodashPick from 'lodash/pick';

import lottieLiveJson from '../../Assets/lottie/live.json';

import Button from '../../Components/Button';
import Image from '../../Components/Image';
import modals from '../../Components/Sheets/modals';
import OrderProgress from '../../Components/OrderProgress';
import StoreCartSummary from '../../Components/StoreCart/CartSummary';
import { ModalManager } from '../../Components/Web/Modal/hooks/useModalManager';
import constants from '../../Config/constants';
import messages from '../../Config/messages';
import ImageHelper from '../../Helper/Image';
import OrderHelper, { STATUS } from '../../Helper/Order';
import { sanitizeFilename } from '../../Helper/FileSystem';
import { getCurrency } from '../../Helper/Routines';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import { ids, styles } from './styles';

const OrderAction = ({ isActive, text, style, onPress, ...rest }) => {
  const _onPress = (e) => {
    e?.preventDefault?.();
    onPress();
  };
  return (
    <Button
      appearance={isActive ? 'outline' : 'filled'}
      size="small"
      status="success"
      style={[{ maxWidth: '100%' }, StyleSheet.flatten(style)]}
      onPress={_onPress}
      {...rest}
    >
      {() => (
        <Text
          appearance={isActive ? 'default' : 'alternative'}
          category="c1"
          numberOfLines={1}
          style={ThemeStyle.pageHorizontalSpacingSmall}
        >
          {text}
        </Text>
      )}
    </Button>
  );
};

const DisplayQRCode = ({ id, fileName }) => {
  const qrValue = `qrg://${id}`;
  const _onClick = (e) => {
    e?.preventDefault?.();
    const payload = {
      fileName,
      title:
        'Please present this ticket code to the event, to complete the check-in process.',
      value: qrValue,
    };
    if (constants.isWeb) {
      ModalManager.show(modals.QR_PREVIEW, { payload });
    } else {
      modals.show(modals.QR_PREVIEW, payload);
    }
  };
  return (
    <Button onPress={_onClick} plain>
      <View style={ThemeStyle.alignItemsCenter}>
        <QRCode value={qrValue} size={70} />
        <Text category="p2" style={ThemeStyle.spacingTopSmall}>
          Click Me
        </Text>
      </View>
    </Button>
  );
};

function OrderItemOnGoing({
  isActive,
  isArriving,
  showDetails,
  onShare,
  onIhaveArrivedPress,
  onStoreNamePress,
  onViewOrderPress,
  ...item
}) {
  const {
    additional_charges,
    charged_delivery_fee,
    curbside_pickup_details,
    delivery_paid_by_cash,
    delivery_tracking_link,
    id,
    items_total_amount,
    items,
    meal_plan,
    order_date,
    order_time,
    order_type,
    pickup_details,
    promo_discount,
    promo,
    status,
    store_details,
    total_amount,
    tracking_page,
    transaction_number,
  } = item;

  const isPending = status === constants.ORDER_STATUS.PENDING;
  const isDelivery = order_type === constants.ORDER_TYPES.DELIVERY;
  const isCurbside = order_type === constants.ORDER_TYPES.CURBSIDE;
  const isDigital = order_type === 'digital';
  const isAsapText = order_time === 'asap_order_time';
  const displayDate = dayjs(
    `${order_date}${order_time ? ` ${order_time}` : ''}`
  ).format(constants.DATE_DISPLAY_FORMAT);
  const asapText = isDigital
    ? dayjs(order_date).format(constants.DATE_ONLY_DISPLAY_FORMAT)
    : 'ASAP';
  const qrFileName = sanitizeFilename(`${items[0]?.name} ${order_date}.png`);
  const showTrackOrder = isDelivery && Boolean(delivery_tracking_link);
  const isCurbsidePreparing =
    !isArriving &&
    isCurbside &&
    status === STATUS.ACCEPTED &&
    curbside_pickup_details.has_arrived;
  const disableIhaveArrived =
    isCurbside &&
    (status === STATUS.NEW || curbside_pickup_details.has_arrived);
  const promotion = {};
  if (promo?.code) {
    // construct similar response shape of verify promo api since the shape is needed on summary
    promotion.description = promo.free_item_description;
    promotion.type = promo.type;
    promotion.promo = {
      applied: promo_discount,
      value: promo.amount_off || `${promo.percentage_off}%`,
    };
    promotion.delivery_discount = promo.subsidized_delivery_amount;
  }
  const image = ImageHelper.getOrderImage(store_details);
  const isDefaultPickupImage = ImageHelper.isPickupDefaultBanner(image);

  const _getSubTitle = () => {
    if (isPending) {
      return (
        <View style={styles.itemPendingContainer}>
          <View style={styles.itemVerfiyLottieMainContainer}>
            <View style={styles.itemVerfiyLottieContainer}>
              <LottieView
                source={lottieLiveJson}
                style={styles.itemOnGoingLottie}
                webStyle={styles.itemOnGoingLottie}
                colorFilters={[
                  {
                    keypath: 'Shape Layer 1',
                    color: ThemeColor.green,
                  },
                  {
                    keypath: 'Shape Layer 2',
                    color: ThemeColor.green,
                  },
                  {
                    keypath: 'Shape Layer 3',
                    color: ThemeColor.green,
                  },
                ]}
                autoPlay
                loop
              />
            </View>
            <Icon
              name="clipboard-outline"
              fill={ThemeColor.white}
              style={styles.clipBoardIcon}
            />
          </View>

          <View style={styles.itemVerifyContainer}>
            <Text category="c2" appearance="alternative">
              {`${messages.PAYMENT_PENDING.title}...`}
            </Text>
          </View>
        </View>
      );
    } else if (isDigital) {
      return <Text category="p2">Booking Confirmed</Text>;
    }
    return (
      <Text category="p2" style={ThemeStyle.bold}>
        {OrderHelper.getOrderStatusText(status, order_type)}
      </Text>
    );
  };

  const _getProgressOrQr = () => {
    if (isDigital) {
      return (
        <View
          dataSet={{ media: ids.itemQrWrapper }}
          style={styles.itemQrWrapper}
        >
          <DisplayQRCode id={id} fileName={qrFileName} />
        </View>
      );
    } else if (!isPending) {
      return (
        <View style={styles.itemOnGoingProgressContainer}>
          <OrderProgress value={status} orderType={order_type} />
        </View>
      );
    }
    return null;
  };

  const _onStoreNamePressed = (e) => {
    if (isDigital) {
      e.preventDefault();
    } else {
      onStoreNamePress();
    }
  };

  return (
    <Layout>
      <View
        dataSet={{ media: ids.itemOnGoingContainer }}
        style={[
          styles.itemOnGoingContainer,
          isActive && { backgroundColor: ThemeColor.lightGreen },
        ]}
      >
        <View style={styles.iconContainer}>
          <Icon
            name={showDetails ? 'angle-up' : 'angle-down'}
            pack="fa"
            fill={ThemeColor.green}
            style={styles.accordionIcon}
          />
        </View>

        <View style={[ThemeStyle.flex1, ThemeStyle.flexDirectionRow]}>
          <View
            style={[
              styles.imageStyle,
              isDefaultPickupImage && styles.noImageStyle,
            ]}
          >
            <Image
              source={{ uri: image }}
              style={{ width: 70, height: 70 }}
              resizeMode={isDefaultPickupImage ? 'contain' : 'cover'}
            />
          </View>
          <View
            style={[
              ThemeStyle.flex1,
              ThemeStyle.spacingLeft,
              ThemeStyle.spacingRight,
            ]}
          >
            <View style={ThemeStyle.flexDirectionRow}>
              <Button
                style={[ThemeStyle.flex1, ThemeStyle.spacingBottomXSmall]}
                onPress={_onStoreNamePressed}
                hitSlop={10}
                plain
              >
                <Text
                  category={isDigital ? 'p1' : 'p2'}
                  numberOfLines={1}
                  style={ThemeStyle.flex1}
                >
                  {isDigital ? items[0].name : store_details.name}
                </Text>
              </Button>
            </View>
            {_getSubTitle()}
            <View
              style={[
                ThemeStyle.flexWrap,
                ThemeStyle.flex1,
                ThemeStyle.flexDirectionRowCenter,
                ThemeStyle.spacingTopXSmall,
              ]}
            >
              {!isPending && (
                <Text
                  category="p2"
                  style={[ThemeStyle.dimColor, ThemeStyle.spacingRightMedium]}
                >
                  {isDigital ? 'Booking' : 'Order'} Date:{' '}
                  {isAsapText ? asapText : displayDate}
                </Text>
              )}
              {!isPending && transaction_number && (
                <Text
                  category="p2"
                  style={[ThemeStyle.dimColor, ThemeStyle.spacingRightMedium]}
                >
                  Order #{transaction_number}
                </Text>
              )}
              {!isPending && Boolean(tracking_page) && (
                <Button onPress={onShare} plain>
                  <View style={ThemeStyle.flexDirectionRowCenter}>
                    <Icon
                      name="share"
                      fill={ThemeColor.green}
                      style={{ width: 16, height: 16 }}
                    />
                    <View
                      dataSet={{ media: ids.itemShareText }}
                      style={styles.itemShareText}
                    >
                      <Text category="p2" style={ThemeStyle.dimColor}>
                        Share
                      </Text>
                    </View>
                  </View>
                </Button>
              )}
            </View>

            {!lodashIsEmpty(pickup_details) && (
              <Text category="p2" style={ThemeStyle.dimColor}>
                c/o: {pickup_details.first_name} {pickup_details.last_name}
              </Text>
            )}
          </View>
        </View>
        {(showTrackOrder || !isPending || isCurbside || isDigital) && (
          <View
            dataSet={{ media: ids.itemOnGoing2ndContainer }}
            style={[
              !isDigital && ThemeStyle.flex1,
              styles.itemOnGoing2ndContainer,
            ]}
          >
            {_getProgressOrQr()}
            {(showTrackOrder || isCurbside) && (
              <View
                dataSet={
                  isCurbsidePreparing && { media: ids.iHaveArrivedContainer }
                }
                style={[
                  ThemeStyle.flexDirectionRowEnd,
                  ThemeStyle.spacingTop,
                  isCurbsidePreparing && styles.iHaveArrivedContainer,
                ]}
              >
                {showTrackOrder && (
                  <OrderAction
                    disabled={isPending}
                    isActive={isActive}
                    text="Track Order"
                    onPress={onViewOrderPress}
                  />
                )}
                {isCurbside && (
                  <OrderAction
                    disabled={disableIhaveArrived}
                    isActive={isActive && !isArriving}
                    loading={isArriving}
                    style={[
                      ThemeStyle.spacingLeftSmall,
                      disableIhaveArrived && {
                        backgroundColor: ThemeColor.gray,
                      },
                    ]}
                    text={
                      curbside_pickup_details.has_arrived && !isArriving
                        ? status === STATUS.ACCEPTED
                          ? 'We are preparing your order please hang tight'
                          : 'Coming up'
                        : 'I have arrived'
                    }
                    onPress={onIhaveArrivedPress}
                  />
                )}
              </View>
            )}
          </View>
        )}
      </View>

      <Collapsible collapsed={!showDetails} renderChildrenCollapsed={false}>
        <View
          style={[
            ThemeStyle.flexDirectionRow,
            ThemeStyle.pageHorizontalSpacing,
          ]}
        >
          {/* QR display on mobile */}
          {isDigital && (
            <View
              dataSet={{ media: ids.itemQrWrapperMobileView }}
              style={styles.itemQrWrapperMobileView}
            >
              <View style={ThemeStyle.spacingRight}>
                <DisplayQRCode id={id} fileName={qrFileName} />
              </View>
            </View>
          )}
          {/* Order details */}
          <View style={ThemeStyle.flex1}>
            <Text category="p2" style={ThemeStyle.spacingBottomSmall}>
              {isDigital ? 'Booking' : 'Order'} Details
            </Text>
            {items.map((item, index) => (
              <View
                style={ThemeStyle.flexDirectionRowCenterSpaceBetween}
                key={index}
              >
                <Text
                  category={constants.isWeb ? 'c1' : 'p2'}
                  style={[ThemeStyle.flex1, ThemeStyle.spacingRightSmall]}
                >
                  {item.name} &times; {item.quantity}
                </Text>
                {!meal_plan && (
                  <Text category={constants.isWeb ? 'c1' : 'p2'}>
                    {getCurrency(Number(item.price) * Number(item.quantity))}
                  </Text>
                )}
              </View>
            ))}
            <StoreCartSummary
              additionalCharge={OrderHelper.getAdditionalChargesData(
                additional_charges
              )}
              isDelivery={order_type === constants.ORDER_TYPES.DELIVERY}
              cart={items}
              promotion={promotion}
              delivery={{
                fee: charged_delivery_fee
                  ? Number(charged_delivery_fee)
                  : undefined,
                mode: delivery_paid_by_cash ? 'cash' : 'non_cash',
              }}
              computed={{
                subTotal: items_total_amount,
                total: total_amount,
              }}
              noHspacing
            />
          </View>
        </View>
      </Collapsible>
    </Layout>
  );
}

const isPropsAreEqual = (prevProps, nextProps) => {
  // this component will only update or trigger rerender when:
  const rerenderKeys = [
    'curbside_pickup_details', // when curbside details updated (when user arrived)
    'id', // when new orders coming up
    'isActive', // when this item has active indicator
    'isArriving', // when user click the "I have arrived" button
    'order_type', // when order type changed (null/undefined to actual order type if status still pending the value of is not the actual value of the order yet)
    'showDetails', // when user expand/collapse the accordion
    'status', // when order status changed
  ];
  return lodashIsEqual(
    lodashPick(prevProps, rerenderKeys),
    lodashPick(nextProps, rerenderKeys)
  );
};

OrderItemOnGoing.propTypes = {
  onShare: PropTypes.func,
  onIhaveArrivedPress: PropTypes.func,
  onStoreNamePress: PropTypes.func.isRequired,
  onViewOrderPress: PropTypes.func.isRequired,
};

export default React.memo(OrderItemOnGoing, isPropsAreEqual);
