import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { ActivityIndicator, View } from 'react-native';
import { Icon, Radio } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFilter from 'lodash/filter';

import Button from '../../Components/Button';
import Header from '../../Components/Header';
import List from '../../Components/List';
import MapSnapshotPreview from '../../Components/MapSnapshotPreview';
import ScreenWrapper from '../../Components/ScreenWrapper';
import constants from '../../Config/constants';
import AddressHelper from '../../Helper/Address';
import withUseShouldCallApi from '../../Hooks/withUseShouldCallApi';
import { removeUserAddress } from '../../RTK/user';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import savedAddressController from './controller/savedAddresses';
import withController from './controller/withController';

import ListRow from './ListRow';
import SavedAddressesEmptyState from './SavedAddressesEmptyState';
import styles from './styles';
import { checkout } from '../../RTK/defaultValues';
import { storeCheckoutSelector } from '../../RTK/checkout/selectors';

class SavedAddresses extends React.Component {
  componentDidMount() {
    this.props.controller.handleMount();
  }

  componentWillUnmount() {
    this.props.controller.handleUnmount();
  }

  _renderHeader = () => {
    const { controller, storeCheckoutData } = this.props;
    const { canSelectDefault, userAddresses, isFromAddressCheckout, state } =
      controller;
    const { gettingUserLocation } = state;

    const activeAddress = isFromAddressCheckout
      ? storeCheckoutData?.[checkout.keys.DELIVERY_ADDRESS]
      : userAddresses[0];

    return (
      <Fragment>
        <View style={ThemeStyle.spacingBottomSmall}>
          <MapSnapshotPreview
            markers={[
              {
                iconUrl: constants.pinUrl.HOME,
                latitude: activeAddress?.geo_json_point?.coordinates[1],
                longitude: activeAddress?.geo_json_point?.coordinates[0],
              },
            ]}
          />
        </View>

        <View
          style={[!canSelectDefault && ThemeStyle.pageVerticalSpacingMedium]}
        >
          {this._renderRow(
            { index: 0, item: { ...activeAddress, active: true } },
            true
          )}
        </View>

        <View style={ThemeStyle.pageVerticalSpacingSmall}>
          <ListRow
            leftAccessory={
              gettingUserLocation ? (
                <View>
                  <ActivityIndicator color={ThemeColor.green} />
                </View>
              ) : (
                <Icon
                  name="navigation-2-outline"
                  fill={ThemeColor.black}
                  style={styles.icon}
                />
              )
            }
            title="Use my current location"
            onPress={() => controller.goToAddAddressWithCurrentLocation()}
            addClickableStyle
            disabled={state.settingDefault}
            plain
          />
        </View>
      </Fragment>
    );
  };

  _renderRow = ({ index, item }, isStandAlone) => {
    const { controller } = this.props;
    const { canSelectDefault, isFromAddressCheckout, state } = controller;

    if (index == 0 && !isStandAlone && !isFromAddressCheckout) {
      // don't render the first address in List, because we will call this function
      // stand alone outside List component
      return null;
    }

    const { deleting, settingDefault } = state;
    const isDeleting = deleting.includes(item.id);
    const title = AddressHelper.getDisplayTitle(item);
    const { icon, pack } = controller.getPrefixLabel(title, true);

    return (
      <Fragment>
        {isDeleting && this._renderLoader()}
        <ListRow
          testID={`savedAddress${index}`}
          leftAccessory={
            item.active && !isFromAddressCheckout ? (
              <Radio status="success" checked />
            ) : (
              <Icon
                fill={isStandAlone ? ThemeColor.green : ThemeColor.black}
                name={icon}
                pack={pack}
                style={styles.icon}
              />
            )
          }
          rightAccessory={
            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Button
                testID={`editButton${index}`}
                onPress={() => controller.goToAddAddressAsEdit(index, item)}
                disabled={isDeleting || settingDefault}
                hitSlop={10}
                plain
              >
                <Icon
                  name="edit-outline"
                  fill={item.active ? ThemeColor.green : ThemeColor.black}
                  style={styles.icon}
                />
              </Button>

              {!item.active && !isFromAddressCheckout && (
                <View
                  style={[
                    ThemeStyle.spacingLeftMedium,
                    ThemeStyle.spacingRightMedium,
                  ]}
                />
              )}

              {!item.active && !isFromAddressCheckout && (
                <Button
                  testID={`removeButton${index}`}
                  onPress={() => controller.removeAddress(item)}
                  disabled={isDeleting || settingDefault}
                  hitSlop={10}
                  plain
                >
                  <Icon
                    name="trash-outline"
                    fill={ThemeColor.red}
                    style={styles.icon}
                  />
                </Button>
              )}
            </View>
          }
          title={title}
          titleLines={1}
          subTitle={AddressHelper.getDisplaySubTitle(item)}
          onPress={() => controller.onSavedAddressPressed(item)}
          disabled={isDeleting || settingDefault}
          addClickableStyle={canSelectDefault || !item.active}
          plain
        />
      </Fragment>
    );
  };

  _renderLoader = (size = 'small') => (
    <View style={styles.loaderRowContainer}>
      <ActivityIndicator size={size} color={ThemeColor.green} />
    </View>
  );

  render() {
    const { controller, storeCheckoutData } = this.props;
    const { isFromAddressCheckout, userAddresses, state } = controller;
    const { deleting, settingDefault } = state;

    const addressList = isFromAddressCheckout
      ? lodashFilter(
          userAddresses,
          (item) =>
            item?.id !== storeCheckoutData?.[checkout.keys.DELIVERY_ADDRESS]?.id
        ).map((item) => ({ ...item, active: false }))
      : userAddresses;

    if (lodashIsEmpty(userAddresses)) {
      return (
        <SavedAddressesEmptyState
          onBackPress={controller.onBackOrClose}
          onAddNewAddress={() => controller.goToAddAddress()}
        />
      );
    }

    return (
      <ScreenWrapper
        header={
          <Header
            backType="close"
            title="Addresses"
            alignTitle="left"
            actionLeftPress={controller.onBackOrClose}
            withBorder
          />
        }
        scroll={false}
        statusBar={controller.statusBar}
        barStyle={constants.isIOS ? 'light-content' : 'dark-content'}
        modal
      >
        <View style={ThemeStyle.flex1}>
          <View style={ThemeStyle.flex1}>
            {settingDefault && this._renderLoader('large')}

            <List
              data={addressList}
              extraData={[deleting, settingDefault]}
              renderItem={this._renderRow}
              ListHeaderComponent={this._renderHeader}
              withSeparator
              plain
            />
          </View>

          <View
            style={[
              ThemeStyle.pageHorizontalSpacing,
              ThemeStyle.pageVerticalSpacingMedium,
            ]}
          >
            <Button
              onPress={() => controller.goToAddAddress()}
              status="success"
              accessoryLeft={() => (
                <Icon
                  name="plus-outline"
                  fill={ThemeColor.white}
                  style={styles.icon}
                />
              )}
            >
              Add a new address
            </Button>
          </View>
        </View>
      </ScreenWrapper>
    );
  }
}

SavedAddresses = withUseShouldCallApi(SavedAddresses);
SavedAddresses = withController(SavedAddresses, savedAddressController);

const mapStateToProps = (state) => ({
  storeCheckoutData: storeCheckoutSelector(
    state,
    state.checkout.checkoutFlag.checkoutStoreId
  ),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchRemoveUserAddress: (data) => dispatch(removeUserAddress(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedAddresses);
