import lodashFind from 'lodash/find';

import constants from '../../../Config/constants';
import Service from '../../../Service';

import { baseApi } from '../baseApi';
import { PaginatedResult } from '../types';

import { EventDetails } from './types';

export const storeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getEvents: builder.query<
      PaginatedResult<EventDetails>,
      {
        offset: number;
        limit: number;
        location?: string;
        event_date?: string;
        keyword?: string;
      }
    >({
      query: (params) => ({
        url: `/event`,
        method: 'GET',
        headers: { 'x-api-version': '1' },
        params,
      }),
      transformErrorResponse: (error: any) =>
        Service.handleErrorMessage(error?.data?.message),
    }),
    getEventDetails: builder.query<EventDetails, { eventId: string }>({
      query: ({ eventId }) => ({
        url: `/event/${eventId}`,
        method: 'GET',
        headers: { 'x-api-version': '1' },
      }),
    }),
    addToCartEvent: builder.mutation<any, any>({
      query: (body) => ({
        url: `/cart`,
        method: 'POST',
        body: body,
        headers: { 'x-api-version': '1' },
      }),
      transformErrorResponse: (result) => {
        const errData = result.data as any;
        return Service.handleErrorMessage(errData.message) as string;
      },
    }),
    checkoutEvent: builder.mutation<any, any>({
      query: (body) => ({
        url: `/checkout/event`,
        method: 'POST',
        body: body,
        headers: { 'x-api-version': '1' },
      }),
      transformErrorResponse: (result) => {
        const errData = result.data as any;
        if (Array.isArray(errData.message)) {
          const soldOut = Object.keys(constants.EVENT_SOLDOUT).map(
            (e) => constants.EVENT_SOLDOUT[e] // get key value
          ); // overall or selected date
          // loop through sold out keys and return the word that matched
          return lodashFind(
            soldOut,
            (word) => lodashFind(errData.message, (msg) => msg.includes(word)) // find the key on error messages
          );
        }
        return Service.handleErrorMessage(errData.message) as string;
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetEventsQuery,
  useLazyGetEventsQuery,
  useGetEventDetailsQuery,
  useLazyGetEventDetailsQuery,
  useAddToCartEventMutation,
  useCheckoutEventMutation,
} = storeApi;
