import { useState } from 'react';
import { View } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';
import lodashFilter from 'lodash/filter';
import dayjs from 'dayjs';

import constants from '../../../../Config/constants';
import { getCartTerm } from '../../../../Helper/RemoteConfig';
import { getCurrency } from '../../../../Helper/Routines';
import usePromoCart from '../../../../Hooks/usePromoCart';
import ThemeColor from '../../../../Theme/colors';
import ThemeStyle from '../../../../Theme/styles';

import Button from '../../../Button';

import { MODALPROMPT } from './config';
import useModalPrompt from './hooks/useModalPrompt';
import ModalPrompt from './ModalPrompt';

type PromotionPayload = {
  applies_to: Array<string>;
  amount_off: number;
  percentage_off: number;
  subsidized_delivery_amount: number;
  minimum_purchase: number;
  code: string;
  type: string;
  free_item_description: string;
  start_date: string;
  end_date: string;
  discount: string;
};
const Promotion = ({ modalId }) => {
  const { hideModalPrompt } = useModalPrompt();
  const [data, setData] = useState<PromotionPayload>();
  const store_theme = useTheme();
  const { addPromoToCart } = usePromoCart();

  const { discount, code, minimum_purchase, applies_to, start_date, end_date } =
    data || {};

  const onClose = () => {
    hideModalPrompt(MODALPROMPT.promotion, {});
  };
  const onAdd = () => {
    addPromoToCart(data);
    hideModalPrompt(MODALPROMPT.promotion, {});
  };

  return (
    <ModalPrompt
      id={modalId}
      title={'Promo Details'}
      baseWidth={400}
      hasHeader
      hasHeaderLine
      onCloseButtonClick={onClose}
      closeOnDocumentClick={false}
      scrollEnabled={false}
      onBeforeShow={setData}
    >
      <View>
        {/* Promo Name */}
        <Text category="p1">{discount}</Text>

        <View style={ThemeStyle.spacingBottom}>
          {/* Promo Code */}
          <View style={ThemeStyle.flexDirectionRowCenter}>
            <Text category="p2" style={ThemeStyle.flex1}>
              Code
            </Text>
            <Text category="p2" style={ThemeStyle.flex2}>
              : {code}
            </Text>
          </View>

          {/* Minimum spent */}
          <View style={ThemeStyle.flexDirectionRowCenter}>
            <Text category="p2" style={ThemeStyle.flex1}>
              For orders over
            </Text>
            <Text category="p2" style={ThemeStyle.flex2}>
              : {getCurrency(minimum_purchase)}
            </Text>
          </View>

          {/* Applies to */}
          {(() => {
            const appliesToType = lodashFilter(
              constants.ORDER_TYPE_DATA,
              (ot) => applies_to?.includes(ot.value)
            );
            const appliesTo = appliesToType
              ?.map((t: any) => t.label)
              .join(', ');
            if (!appliesTo) {
              return null;
            }
            return (
              <View
                style={{
                  ...ThemeStyle.flexDirectionRowCenter,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <Text category="p2" style={ThemeStyle.flex1}>
                  Applicable on
                </Text>
                <Text
                  category="p2"
                  style={ThemeStyle.flex2}
                  adjustsFontSizeToFit
                >
                  : {appliesTo}
                </Text>
              </View>
            );
          })()}

          {/* Promo started */}
          {!!start_date && (
            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Text category="p2" style={ThemeStyle.flex1}>
                Started on
              </Text>
              <Text category="p2" style={ThemeStyle.flex2}>
                : {dayjs(start_date).format(constants.DATE_DISPLAY_FORMAT2)}
              </Text>
            </View>
          )}

          {/* Promo end */}
          {!!end_date && (
            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Text category="p2" style={ThemeStyle.flex1}>
                Valid until
              </Text>
              <Text category="p2" style={ThemeStyle.flex2}>
                : {dayjs(end_date).format(constants.DATE_DISPLAY_FORMAT2)}
              </Text>
            </View>
          )}
        </View>

        <Button
          status="control"
          onPress={onAdd}
          style={{
            borderColor: store_theme['button_color'] || ThemeColor.green,
            backgroundColor: store_theme['button_color'] || ThemeColor.green,
          }}
        >
          {() => (
            <Text
              status="control"
              style={{
                color: store_theme['button_text_color'] || ThemeColor.white,
              }}
            >
              Add to {getCartTerm()}
            </Text>
          )}
        </Button>
      </View>
    </ModalPrompt>
  );
};

export default Promotion;
