import React, { useRef, useState } from 'react';
import { Platform, View } from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import {
  check,
  Permission,
  PERMISSIONS,
  PermissionStatus,
  request,
  RESULTS,
} from 'react-native-permissions';
import { Text } from '@ui-kitten/components';

import Button from '../../../Components/Button';
import constants from '../../../Config/constants';
import { saveToPhoto } from '../../../Helper/FileSystem';
import useToast from '../../../Hooks/useToast';
import ThemeStyle from '../../../Theme/styles';

import { ModalManager } from '../../Web/Modal/hooks/useModalManager';

import modals from '../modals';
import styles from '../styles';

import { RenderProps } from './types';

const qrSize = constants.DEVICE_WIDTH - 40; // 40 is spacing left and right

type ToDataUrl = { toDataURL: Function };
type AskPermission = () => Promise<{
  ok: boolean;
  message?: string;
}>;
const QRPreviewRender = ({ fileName, title, value }: RenderProps) => {
  const qrRef = useRef<ToDataUrl | undefined>();
  const toast = useToast();

  const [downloading, setDownloading] = useState<boolean>(false);

  const _onClose = () => {
    if (constants.isWeb) {
      ModalManager.hide(modals.QR_PREVIEW);
    } else {
      modals.hide(modals.QR_PREVIEW);
    }
  };

  const _askPermission: AskPermission = async () => {
    const noAccess: PermissionStatus[] = [
      RESULTS.UNAVAILABLE,
      RESULTS.BLOCKED,
      RESULTS.DENIED,
    ];
    const deviceVersion = Platform.Version as number;
    let permission: Permission;
    if (constants.isWeb || (constants.isAndroid && deviceVersion >= 29)) {
      // no special permission needed on web or android that is version 29+
      return { ok: true };
    } else if (constants.isIOS) {
      permission = PERMISSIONS.IOS.PHOTO_LIBRARY;
    } else {
      // android version <= 28
      permission = PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE;
    }
    const checkResult = await check(permission);
    if (noAccess.includes(checkResult)) {
      const prompt = await request(permission);
      const isOk = prompt === RESULTS.GRANTED;
      return { ok: isOk, message: isOk ? '' : 'Permission Denied.' };
    } else {
      return { ok: true };
    }
  };

  const _getBase64 = () => {
    if (constants.isWeb) {
      // getRef of QRCode does not work on web, so we need to manually get the base 64 of svg using the testID
      const svgElement = document.querySelector('[data-testid=QRPreviewSVG]');
      const serializer = new XMLSerializer();
      const svgString = serializer.serializeToString(svgElement);
      // Create a Blob for the SVG
      const blob = new Blob([svgString], {
        type: 'image/svg+xml;charset=utf-8',
      });
      const url = URL.createObjectURL(blob);
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          // Create a Canvas to draw the image
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          // Draw the SVG image onto the canvas
          ctx.drawImage(img, 0, 0);
          // Convert canvas to PNG Base64
          const pngBase64 = canvas.toDataURL('image/png');
          resolve(pngBase64);
          // Clean up
          URL.revokeObjectURL(url);
        };
        img.src = url;
      });
    } else {
      return new Promise((resolve) => {
        qrRef.current.toDataURL(resolve);
      });
    }
  };

  const _onDownload = async () => {
    _setDownloading(true);
    const { ok: isPermissionOk, message: permissionError } =
      await _askPermission();
    if (isPermissionOk) {
      const base64 = (await _getBase64()) as string;
      const { ok, message } = await saveToPhoto(fileName, base64);
      if (ok) {
        // no need to set downloading to false, since we will close the modal the state on the next open is gonna be reset to false
        _onClose();
        toast.show('QR Code saved successfully!');
      } else {
        toast.show(message, false);
        _setDownloading(false);
      }
    } else {
      toast.show(permissionError, false);
      _setDownloading(false);
    }
  };

  const _setDownloading = (val: boolean) => {
    // set state only on mobile, no loading needed on web
    if (!constants.isWeb) setDownloading(val);
  };

  return (
    <View style={styles.contentContainer}>
      {Boolean(title) && (
        <Text style={[ThemeStyle.textCenter, ThemeStyle.spacingBottom]}>
          {title}
        </Text>
      )}
      <View style={[ThemeStyle.spacingBottom, ThemeStyle.alignItemsCenter]}>
        <QRCode
          testID="QRPreviewSVG"
          value={value}
          size={qrSize > 200 ? 200 : qrSize}
          getRef={(r) => (qrRef.current = r)}
        />
      </View>
      <Button
        loading={downloading}
        status="success"
        style={ThemeStyle.spacingBottomMedium}
        onPress={_onDownload}
      >
        {() => (
          <Text appearance="alternative">
            Download{downloading ? 'ing...' : ''}
          </Text>
        )}
      </Button>
      <Button disabled={downloading} status="control" onPress={_onClose}>
        {() => <Text>Close</Text>}
      </Button>
    </View>
  );
};

export default QRPreviewRender;
