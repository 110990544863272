import React from 'react';
import { ActivityIndicator, View, StyleSheet } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';
import Modal from 'react-native-modal';

import ThemeColor from '../Theme/colors';
import ThemeStyle, { deviceHeight } from '../Theme/styles';

export default class extends React.PureComponent {
  render() {
    const { isConnected, isInternetReachable } = this.props;
    // lets just check if isConnected
    const shouldShow = isConnected === false; //  || isInternetReachable === false // do not make it !isConnected or !isInternetReachable

    return (
      <Modal
        isVisible={shouldShow}
        animationIn="fadeIn"
        animationOut="fadeOut"
        presentationStyle="overFullScreen"
        deviceHeight={deviceHeight}
        useNativeDriver
        statusBarTranslucent
      >
        <View style={styles.wrapper}>
          <View style={ThemeStyle.alignItemsCenter}>
            <Icon name="wifi-off" fill={ThemeColor.white} style={styles.icon} />

            <Text category="h5" appearance="alternative" style={styles.text}>
              Oops, No Internet Connection
            </Text>

            <Text appearance="alternative" style={styles.text}>
              Make sure Wi-Fi or cellular data is turned on and has internet
              connection.
            </Text>

            <ActivityIndicator size="large" color={ThemeColor.white} />
          </View>
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: [
    ThemeStyle.flex1,
    ThemeStyle.alignItemsCenter,
    ThemeStyle.justifyContentCenter,
  ],
  icon: {
    ...ThemeStyle.spacingBottom,
    width: 100,
    height: 100,
  },
  text: [ThemeStyle.spacingBottom, ThemeStyle.textCenter],
});
