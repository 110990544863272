import { createSelector } from '@reduxjs/toolkit';

const search = (state) => state?.search;

const recentlyStoreSearchedSelector = createSelector(
  search,
  (state) => state.recentlySearched
);
const recentlyEventSearchedSelector = createSelector(
  search,
  (state) => state?.recentlyEventSearched
);

export { recentlyStoreSearchedSelector, recentlyEventSearchedSelector };
