import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { find as lodashFind } from 'lodash';

///CONFIG
import constants from '../Config/constants';

//THEMES
import ThemeStyle from '../Theme/styles';

//COMPONENTS
import Header from '../Components/Header';
import RightDrawerContent from '../Components/RightDrawerContent';
import CheckoutRightContent from '../Components/Web/CheckoutRightContent';

//CONTROLLER
import useCheckoutWeb from '../Screens/Checkout/controller/useCheckoutWeb';

///SCREENS
import Checkout from '../Screens/Checkout';
import CheckoutMealPlan from '../Screens/Checkout/CheckoutMealPlan';
import PlacingOrderModal from '../Screens/Checkout/PlacingOrderModal';

///REDUX
import { checkoutStoreInfoSelector } from '../RTK/checkout/selectors';
import { checkout } from '../RTK/defaultValues';
import { shopSelector } from '../RTK/shop/selectors';
import routeList from './list';

import { CheckoutNavigatorContext } from './CheckoutNavigatorContext';

const Drawer = createDrawerNavigator();

const { ORDER_TYPES, ORDER_TYPE_DATA } = constants;
const pickupOrderType = lodashFind(ORDER_TYPE_DATA, {
  value: ORDER_TYPES.PICKUP,
});

const CheckoutNavigator = () => {
  const checkoutWebHooks = useCheckoutWeb();
  const checkoutStoreInfo = useSelector(checkoutStoreInfoSelector);
  const shopData = useSelector(shopSelector);
  const mealPlanData = useSelector((state: any) => state.mealPlan.data);

  const {
    cartItemUpdatingById,
    deliveryQuotation,
    placingOrder,
    state,
    storeCartData,
    storeCheckoutData,
    storeMealPlanDeliveryDiscount,
    storeMealPlanDiscount,
    onOrderTypeChange,
    onPaymentOptionChanged,
    onPlaceOrderError,
    onRequestCheckout,
    onUpdateQuantity,
    proceedCheckout,
    proceedToCheckoutMealPlan,
    onRequestCheckouMealPlan,
    toggleConfirmDeleteModal,
    togglePlaceOrder,
  } = checkoutWebHooks;

  const orderType = storeCheckoutData[checkout.keys.ORDER_TYPE]?.value;
  const isMealPlan = Boolean(mealPlanData.type);
  const isDelivery = isMealPlan || orderType === ORDER_TYPES.DELIVERY;
  const promotion = isMealPlan
    ? mealPlanData.checkoutData[checkout.keys.PROMO_CODE]
    : storeCheckoutData[checkout.keys.PROMO_CODE];

  const screenOptions = {
    drawerPosition: 'right',
    drawerStyle: {
      width: 400,
    },
    header: (props) => (
      <View>
        <Header plain {...props} store_id={checkoutStoreInfo.store_id} />
      </View>
    ),
    sceneContainerStyle: {
      backgroundColor: '#fff',
    },
  };

  const drawerContent = (drawerProps) => (
    <RightDrawerContent {...drawerProps}>
      <View style={[ThemeStyle.spacingTop, ThemeStyle.spacingRight]}>
        <CheckoutRightContent
          mealPlanData={isMealPlan ? mealPlanData.checkoutData : {}}
          isMealPlan={isMealPlan}
          checkoutState={{
            state,
            storeMealPlanDeliveryDiscount,
            storeMealPlanDiscount,
          }}
          checkoutData={
            isMealPlan ? mealPlanData.checkoutData : storeCheckoutData
          }
          checkoutItems={
            isMealPlan ? mealPlanData.items : storeCartData?.[0]?.items
          }
          promotion={promotion}
          incrementPress={onUpdateQuantity}
          decrementPress={onUpdateQuantity}
          isUpdatingItem={cartItemUpdatingById}
          hasPickupOrderType={
            checkoutStoreInfo.order_types[pickupOrderType.apiDataKey]
          }
          onOrderTypeChange={onOrderTypeChange}
          deliveryQuotation={deliveryQuotation}
          toggleConfirmModal={toggleConfirmDeleteModal}
          hasDeliveryFee={isDelivery}
          placeOrder={isMealPlan ? proceedToCheckoutMealPlan : proceedCheckout}
          paymentMethods={
            isMealPlan
              ? ['non_cash'] // this is the only available for meal plan checkout
              : checkoutStoreInfo.delivery_payment_methods
          }
          onPaymentOptionChanged={onPaymentOptionChanged}
          isPlacingOrder={placingOrder}
          error={{}}
        />
        <PlacingOrderModal
          data={
            isMealPlan
              ? {
                  storeId: shopData?.id,
                  storeName: shopData?.name,
                  cart: mealPlanData.items,
                  details: mealPlanData.checkoutData,
                }
              : {
                  storeId: checkoutStoreInfo.store_id,
                  storeName: storeCartData?.[0]?.store_name,
                  cart: storeCartData?.[0]?.items,
                  details: storeCheckoutData,
                }
          }
          visible={placingOrder}
          redirectUrlParams={{ isMealPlan }}
          onRequest={isMealPlan ? onRequestCheckouMealPlan : onRequestCheckout}
          togglePlaceOrder={togglePlaceOrder}
          onError={onPlaceOrderError}
        />
      </View>
    </RightDrawerContent>
  );
  return (
    <CheckoutNavigatorContext.Provider value={checkoutWebHooks}>
      <Drawer.Navigator
        id="RightDrawer"
        defaultStatus="closed"
        //@ts-ignore : JS code error
        screenOptions={screenOptions}
        useLegacyImplementation
        drawerContent={drawerContent}
        initialRouteName={
          isMealPlan ? routeList.CHECKOUT_MEAL_PLAN : routeList.CHECKOUT
        }
      >
        <Drawer.Screen
          name={routeList.CHECKOUT}
          component={Checkout}
          options={{ unmountOnBlur: true }}
        />
        <Drawer.Screen
          name={routeList.CHECKOUT_MEAL_PLAN}
          component={CheckoutMealPlan}
          options={{ unmountOnBlur: true }}
        />
      </Drawer.Navigator>
    </CheckoutNavigatorContext.Provider>
  );
};

export default CheckoutNavigator;
