/**
 * @file For file system methods for WEB
 * */

export * from './helper';

export const saveToPhoto = async (fileNameWithType: string, base64: string) => {
  const a = document.createElement('a');
  a.href = base64;
  a.download = fileNameWithType;
  // Safari workaround: open in new tab if download doesn't work
  if (
    navigator.userAgent.includes('Safari') &&
    !navigator.userAgent.includes('Chrome')
  ) {
    a.target = '_blank';
  }
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  return { ok: true };
};
