import { useState } from 'react';
import { Alert, BackHandler } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from 'react-native-toast-notifications';
import { CANCEL_ERROR } from 'apisauce';
import lodashGet from 'lodash/get';

import Toast from '../../../Components/Toast';
import constants from '../../../Config/constants';
import messages from '../../../Config/messages';
import Sentry from '../../../Helper/Sentry';
import useCancellableRequest from '../../../Hooks/useCancellableRequest';
import useShouldCallApi from '../../../Hooks/useShouldCallApi';
import { updateCheckoutDetails } from '../../../RTK/checkout';
import { checkout } from '../../../RTK/defaultValues';
import { checkoutFlagSelector } from '../../../RTK/checkout/selectors';
import { mealPlanCheckoutFlagSelector } from '../../../RTK/mealPlan/selectors';
import { setCheckoutData } from '../../../RTK/mealPlan';
import { removeUserAddress, setUserAddressAsFirst } from '../../../RTK/user';
import { addressSelector } from '../../../RTK/user/selectors';
import routeList from '../../../Routes/list';
import Service from '../../../Service';

import userApi from '../../../Service/api/user';

import useCommonHooks from './useCommonHooks';

function useController({ navigation, ...props }) {
  const dispatch = useDispatch();
  const { getPrefixLabel, getUserLocation } = useCommonHooks();
  const { createRequest } = useCancellableRequest();
  const shouldCallApi = useShouldCallApi();
  const toast = useToast();

  const userAddresses = useSelector(addressSelector);
  const checkoutFlag = useSelector(checkoutFlagSelector);
  const isFromAddressMealPlanCheckout = useSelector(
    mealPlanCheckoutFlagSelector
  );

  const [deleting, setDeleting] = useState([]);
  const [settingDefault, setSettingAsDefault] = useState(false);
  const [handlerListener, setHandlerListener] = useState(null);
  const [gettingUserLocation, setGettingUserLocation] = useState(false);

  const { addressSelection: isFromAddressCheckout, checkoutStoreId } =
    checkoutFlag;

  // for going to add address screen
  const _goToAddAddress = (params) => {
    navigation.navigate(routeList.ADDRESS_ADD_EDIT, params);
  };

  // for going to add address screen as edit address purpose
  const _goToAddAddressAsEdit = (index, data) => {
    _goToAddAddress({
      update: {
        index,
        id: data.id,
        active: data.active,
        formatted_address: data.formatted_address,
        label: data.label,
        latitude: data.geo_json_point.coordinates[1],
        longitude: data.geo_json_point.coordinates[0],
        place_id: data.place_id,
      },
    });
  };

  // for going to add address screen with current location
  const _goToAddAddressWithCurrentLocation = async () => {
    setGettingUserLocation(true); // set loading flag

    getUserLocation(
      (res) => {
        setGettingUserLocation(false); // remove loading flag on success
        _goToAddAddress(res); // navigate to add/edit address
      },
      () => {
        setGettingUserLocation(false); // remove loading flag on error
      }
    );
  };

  // _handleMount depends if web is same flow for mobile
  const _handleMount = () => {
    // for the purpose of the user should not be able to bypass the add address screen if no address yet
    if (!userAddresses.length) {
      navigation.setOptions({ gestureEnabled: !userAddresses.length <= 0 });
      const handler = BackHandler.addEventListener(
        'hardwareBackPress',
        _onBackOrClose
      );
      setHandlerListener(handler);
    }
  };

  // _handleUnmount depends if web is same flow for mobile
  const _handleUnmount = () => {
    // for the purpose of the user should not be able to bypass the add address screen if no address yet
    handlerListener?.remove?.();
  };

  // called when user press the saved address
  const _onSavedAddressPressed = async (data, callback) => {
    // control on route params to close or not after setting as default
    const dontClose = lodashGet(props, 'route.params.dontClose');
    if (isFromAddressMealPlanCheckout) {
      // if from meal plan checkout dispatch for displaying it on meal plan checkout page
      await dispatch(
        setCheckoutData({
          key: checkout.keys.DELIVERY_ADDRESS,
          data,
        })
      );
      if (constants.isWeb) {
        callback?.();
      } else {
        navigation.goBack();
      }
    } else if (isFromAddressCheckout) {
      // if from normal checkout dispatch for displaying it on checkout page
      await dispatch(
        updateCheckoutDetails({
          store_id: checkoutStoreId,
          keyToUpdate: checkout.keys.DELIVERY_ADDRESS,
          keyValue: data,
        })
      );
      if (constants.isWeb) {
        callback?.();
      } else {
        navigation.goBack();
      }
    } else if (!data.active) {
      // if not active (from saved address page)
      // make the selected address as active
      setSettingAsDefault(true);
      if (shouldCallApi) {
        // prettier-ignore
        const { ok, data: apiData, problem } = await createRequest(userApi.setAddressAsActive, data.id)
        // stop the code from going if request is cancelled
        if (problem === CANCEL_ERROR) {
          return;
        }
        if (ok) {
          await dispatch(setUserAddressAsFirst(data.id));
        } else {
          Sentry.reportError('Error setting address as default', apiData);
          Alert.alert(
            messages.COMMON_ERROR_TITLE,
            apiData.messages || messages.COMMON_ERROR_MESSAGE
          );
          return;
        }
      } else {
        console.log(
          'Guest/Social Account',
          'Set address as active from device'
        );
        await dispatch(setUserAddressAsFirst(data.id));
      }
      callback?.(); // if has callback function, then call it
      // after setting address as default
      if (dontClose) {
        // if dontClose route params is pass, just call the callback if has any
        setSettingAsDefault(false);
        // show toast after successfully setting address as default if from profile page
        Toast.show('Successfully setting address as default');
      } else {
        // if not from profile page, close it
        if (constants.isWeb) {
          return true;
        }
        navigation.goBack();
        return;
      }
    }
  };

  // if user press the back/close button
  const _onBackOrClose = () => {
    if (userAddresses.length <= 0) {
      // for the purpose of the user should not be able to bypass the add address screen if no address yet
      Toast.show('Adding your first address is required', Toast.TYPE_ERROR);
      return true; // prevent default (android hardware back button)
    } else {
      navigation.goBack();
      return false; // prevent default (android hardware back button)
    }
  };

  // when user deleting saved address
  const _removeAddress = async (item) => {
    if (constants.isWeb) {
      // add saved address id on deleting
      setDeleting([...deleting, item.id]);

      if (shouldCallApi) {
        // prettier-ignore
        const { ok, data, problem } = await createRequest(userApi.removeAddress, [item.id])
        // stop the code from going if request is cancelled
        if (problem === CANCEL_ERROR) {
          return;
        }
        if (ok) {
          await dispatch(removeUserAddress(item.id));
        } else {
          // prettier-ignore
          const msg = 'An error has occurred while trying to remove your address. Please try again'
          Alert.alert(
            'An error has occurred',
            Service.handleErrorMessage(data.message || msg)
          );
        }
      } else {
        console.log('Guest/Social Account', 'Delete from device');
        await dispatch(removeUserAddress(item.id));
      }

      // remove saved address id on deleting
      setDeleting(deleting.filter((d) => item.id !== d));
    } else {
      Alert.alert(
        messages.REMOVE_ADDRESS.title,
        messages.REMOVE_ADDRESS.message,
        [
          {
            text: 'Yes',
            onPress: async () => {
              // add saved address id on deleting
              setDeleting([...deleting, item.id]);

              if (shouldCallApi) {
                // prettier-ignore
                const { ok, data, problem } = await createRequest(userApi.removeAddress, [item.id])
                // stop the code from going if request is cancelled
                if (problem === CANCEL_ERROR) {
                  return;
                }
                if (ok) {
                  await dispatch(removeUserAddress(item.id));
                } else {
                  // prettier-ignore
                  const msg = 'An error has occurred while trying to remove your address. Please try again'
                  Sentry.reportError('Error removing saved address', data);
                  Alert.alert(
                    'An error has occurred',
                    Service.handleErrorMessage(data.message || msg)
                  );
                }
              } else {
                console.log('Guest/Social Account', 'Delete from device');
                await dispatch(removeUserAddress(item.id));
              }

              // remove saved address id on deleting
              setDeleting(deleting.filter((d) => item.id !== d));
            },
          },
          { text: 'No' },
        ]
      );
    }
  };

  return {
    state: {
      deleting,
      gettingUserLocation,
      settingDefault,
    },
    canSelectDefault: isFromAddressCheckout || isFromAddressMealPlanCheckout,
    isFromAddressCheckout: isFromAddressCheckout,
    statusBar: lodashGet(props, 'route.params.statusBar'),
    userAddresses,
    getPrefixLabel,
    goToAddAddress: _goToAddAddress,
    goToAddAddressAsEdit: _goToAddAddressAsEdit,
    goToAddAddressWithCurrentLocation: _goToAddAddressWithCurrentLocation,
    handleMount: _handleMount,
    handleUnmount: _handleUnmount,
    onBackOrClose: _onBackOrClose,
    onSavedAddressPressed: _onSavedAddressPressed,
    removeAddress: _removeAddress,
  };
}

export default useController;
