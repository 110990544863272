import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';

import { checkoutFlagSelector } from '../../../../RTK/checkout/selectors';
import { mealPlanCheckoutFlagSelector } from '../../../../RTK/mealPlan/selectors';
import {
  addressSelector,
  lastUsedAddressSelector,
} from '../../../../RTK/user/selectors';

function useDropOffOptions(selectedAddress) {
  const isMounted = useRef<boolean>();
  const isFromAddressCheckout = useSelector(
    (state) => checkoutFlagSelector(state).addressSelection
  );
  const isFromAddressMealPlanCheckout = useSelector(
    mealPlanCheckoutFlagSelector
  );
  const userAddresses = useSelector(addressSelector);
  const userLastUsedAddresses = useSelector(lastUsedAddressSelector);

  const [hideAccordion, setHideAccordion] = useState(true);

  const hasLastUsedAddresses = !lodashIsEmpty(userLastUsedAddresses);
  const isNotInLastUsedAddress = !userLastUsedAddresses.includes(
    selectedAddress?.id
  );
  const displayAddress = selectedAddress;

  // this hooks handle the auto closing of accordion, must not be execute onload
  useEffect(() => {
    if (isMounted.current) {
      // execute only if mounted
      if (isFromAddressCheckout || isFromAddressMealPlanCheckout) {
        // if user select address in saved address, from checkout
        if (userLastUsedAddresses.includes(selectedAddress?.id)) {
          // if selected address is in last used address
          setTimeout(() => {
            setHideAccordion(false); // open accordion
          }, 100); // added delay for the accordion animation propagate (not gonna work without delay)
        }
      }
    }
  }, [
    isFromAddressCheckout, // if user access the address selection on normal checkout
    isFromAddressMealPlanCheckout, // if user access the address selection on meal plan checkout
    selectedAddress?.id, // if selected address changed
    userLastUsedAddresses, // if last used address changed
  ]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const _getRowData = (addressId: string, index: number) => {
    const addressInfo = lodashFind(userAddresses, { id: addressId });
    return {
      address: addressInfo,
      isSelected: selectedAddress?.id === addressInfo?.id,
      isLast: userLastUsedAddresses.length - 1 === index,
    };
  };

  const _toggleAccordion = () => setHideAccordion((prev) => !prev);

  return {
    displayAddress,
    hasLastUsedAddresses,
    hideAccordion,
    isNotInLastUsedAddress,
    userLastUsedAddresses,
    getRowData: _getRowData,
    toggleAccordion: _toggleAccordion,
  };
}

export default useDropOffOptions;
