import React from 'react';
import { Alert, StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { useDispatch, useSelector } from 'react-redux';
import zxcvbn from 'zxcvbn';

import ForgotPasswordImage from '../../Assets/forgotpassword.png';
import Form from '../../Components/FormFields/Form';
import FormField from '../../Components/FormFields/FormField';
import SubmitButton from '../../Components/FormFields/SubmitButton';
import Image from '../../Components/Image';
import ScreenWrapper from '../../Components/ScreenWrapperWithTitleAnimation';
import constants from '../../Config/constants';
import validation from '../../Config/validationRules';
import Sentry from '../../Helper/Sentry';
import useGoBack from '../../Hooks/useGoBack';
import { logout } from '../../RTK/user';
import { isLoggedInSelector } from '../../RTK/user/selectors';
import routeList from '../../Routes/list';
import Service from '../../Service';
import userApi from '../../Service/api/user';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

const validationSchema = validation.createSchema({
  newPassword: validation.newPasswordSchema,
  confirmNewPassword: validation.confirmNewPasswordSchema,
});

const formInitialValues = {
  newPassword: '',
  confirmNewPassword: '',
};

function ResetPassword({ navigation, route }) {
  const dispatch = useDispatch();
  const goBack = useGoBack();
  const isLoggedIn = useSelector(isLoggedInSelector);

  const _handleSubmit = async (formData, { setSubmitting, setErrors }) => {
    const token = route.params?.token;
    const { score, feedback } = zxcvbn(formData.newPassword);

    if (!token) {
      return setErrors({
        api: {
          title: 'Missing or invalid token!',
          message:
            'Click the link on your email or request for new reset password link again.',
        },
      });
    }

    if (score < 2) {
      return setErrors({
        api: {
          title: 'Weak Password',
          message: Service.handleErrorMessage([
            feedback.warning,
            ...feedback.suggestions,
          ]),
        },
      });
    }

    const payload = {
      password: formData.newPassword,
      confirm_password: formData.confirmNewPassword,
    };
    const { ok, data } = await userApi.resetPassword(payload, token);

    if (ok && data.success) {
      if (isLoggedIn) {
        // if logged in, log it out first before redirect
        await dispatch(logout());
      }
      // redirect to auth page
      navigation.replace(routeList.AUTH);
      Alert.alert('Success', 'You successfully reset your password.');
    } else {
      Sentry.reportError('Error changing pasword from reset password screen', {
        payload,
        data,
      });
      Alert.alert('Please try again', Service.handleErrorMessage(data.message));
    }

    setSubmitting(false);
  };

  return (
    <ScreenWrapper
      title={constants.isWeb ? '' : 'Forgot Password?'}
      onBackPress={goBack}
      automaticallyAdjustKeyboardInsets
    >
      <View style={constants.isWeb ? styles.wrapper_web : styles.wrapper}>
        {constants.isWeb && (
          <Text style={styles.reset_password}>Forgot Password?</Text>
        )}
        <View
          style={[ThemeStyle.pageVerticalSpacing, ThemeStyle.alignItemsCenter]}
        >
          <Image
            source={ForgotPasswordImage}
            style={styles.image}
            resizeMode="contain"
          />
        </View>

        <View style={ThemeStyle.spacingTop}>
          <Form
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={_handleSubmit}
          >
            <View style={ThemeStyle.spacingBottomMedium}>
              <FormField
                direction="column"
                type="password"
                name="newPassword"
                label="New Password"
                placeholder="Enter your new password"
                withBackground
                withClear
              />
            </View>

            <FormField
              direction="column"
              type="password"
              name="confirmNewPassword"
              label="Confirm Password"
              placeholder="Enter your confirm password"
              withBackground
              withClear
            />

            <SubmitButton text="Reset Password" />
          </Form>
        </View>
      </View>
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  wrapper: [
    ThemeStyle.flex1,
    ThemeStyle.pageVerticalSpacingMedium,
    ThemeStyle.pageHorizontalSpacing,
  ],
  image: {
    width: 280,
    height: 230,
    maxWidth: '100%',
  },
  wrapper_web: {
    padding: 10,
    alignSelf: 'center',
    width: 450,
    height: 'auto',
    borderColor: ThemeColor.gray,
    borderWidth: 1,
  },
  reset_password: {
    ...ThemeStyle.bold,
    fontSize: 20,
  },
});

export default ResetPassword;
