import React, { Fragment, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ActivityIndicator, View } from 'react-native';
import { Icon, Radio } from '@ui-kitten/components';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';

import Button from '../../Components/Button';
import Header from '../../Components/Header';
import List from '../../Components/List';
import MapView, { Marker } from '../../Components/Map';
import ScreenWrapper from '../../Components/ScreenWrapper';
import constants from '../../Config/constants';
import AddressHelper from '../../Helper/Address';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';
import addEditAddressController from './controller/addEditAddress';
import savedAddressController from './controller/savedAddresses';

import ListRow from './ListRow';
import SavedAddressesEmptyState from './SavedAddressesEmptyState';
import styles from './styles';

const iconSize = 20;

const SaveAddress = ({ navigation }) => {
  const {
    isFromReservation,
    canSelectDefault,
    userAddresses,
    state,
    getPrefixLabel,
    goToAddAddress,
    goToAddAddressAsEdit,
    goToAddAddressWithCurrentLocation,
    removeAddress,
    onSavedAddressPressed,
  } = savedAddressController({ navigation });
  const state_2 = addEditAddressController({ navigation }).state;
  const { deleting, settingDefault } = state;
  const map = useRef(null);

  const _renderHeader = () => {
    const { gettingUserLocation } = state;

    return (
      <Fragment>
        <View style={ThemeStyle.spacingBottomSmall}>
          <View
            style={{
              borderRadius: 10,
              alignSelf: 'center',
              width: '100%',
              height: 200,
            }}
          >
            <MapView
              testID="mapView"
              ref={map}
              region={{
                lng: userAddresses[0]?.geo_json_point.coordinates[0],
                lat: userAddresses[0]?.geo_json_point.coordinates[1],
              }}
              zoom={7}
            >
              <Marker
                coordinate={{
                  lng: userAddresses[0]?.geo_json_point.coordinates[0],
                  lat: userAddresses[0]?.geo_json_point.coordinates[1],
                }}
                // coordinateChanged={(data) =>
                //     setSelectedEditSavedAddress({
                //         ...selectedEditSavedAddress,
                //         geo_json_point: {
                //             ...selectedEditSavedAddress.geo_json_point,
                //             coordinates: [Number(data.lng), Number(data.lat)]
                //         }
                //     })
                // }
              />
            </MapView>
          </View>
        </View>

        <View
          style={[!canSelectDefault && ThemeStyle.pageVerticalSpacingMedium]}
        >
          {_renderRow({ index: 0, item: userAddresses[0] }, true)}
        </View>

        <View style={ThemeStyle.pageVerticalSpacingSmall}>
          <ListRow
            leftAccessory={
              gettingUserLocation ? (
                <View style={styles.spacingRight}>
                  <ActivityIndicator color={ThemeColor.green} />
                </View>
              ) : (
                <Icon
                  name="navigation-2-outline"
                  fill={ThemeColor.black}
                  style={[
                    { width: iconSize, height: iconSize },
                    styles.spacingRight,
                  ]}
                />
              )
            }
            title="Use my current location"
            onPress={() => goToAddAddressWithCurrentLocation()}
            addClickableStyle
            disabled={state.settingDefault}
            plain
          />
        </View>
      </Fragment>
    );
  };

  const _renderRow = ({ index, item }, isStandAlone) => {
    if (index == 0 && !isStandAlone) {
      // don't render the first address in List, because we will call this function
      // stand alone outside List component
      return null;
    }

    const isDeleting = deleting.includes(item.id);
    const title = AddressHelper.getDisplayTitle(item);
    const { icon } = getPrefixLabel(title, true);

    return (
      <Fragment>
        {isDeleting && _renderLoader()}
        <ListRow
          testID={`savedAddress${index}`}
          status={item.active ? 'success' : 'basic'}
          leftAccessory={
            <Icon
              name={icon}
              fill={isStandAlone ? ThemeColor.green : ThemeColor.darkGray}
              style={[
                { width: iconSize, height: iconSize },
                ThemeStyle.spacingRight,
              ]}
            />
          }
          rightAccessory={
            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Button
                testID={`editButton${index}`}
                onPress={() => goToAddAddressAsEdit(index, item)}
                style={{ padding: globalSpacing / 4 }}
                disabled={isDeleting || settingDefault}
                plain
              >
                <Icon
                  name="pencil"
                  fill={item.active ? ThemeColor.green : ThemeColor.black}
                  pack="fa"
                  style={{ width: iconSize, height: iconSize }}
                />
              </Button>

              {!item.active && <View style={ThemeStyle.rowSeparator} />}

              {!item.active && (
                <Button
                  testID={`removeButton${index}`}
                  onPress={() => removeAddress(item)}
                  style={{ padding: globalSpacing / 4 }}
                  disabled={isDeleting || settingDefault}
                  plain
                >
                  <Icon
                    name="trash"
                    fill={item.active ? ThemeColor.red : ThemeColor.black}
                    pack="fa"
                    style={{ width: iconSize, height: iconSize }}
                  />
                </Button>
              )}
            </View>
          }
          title={title}
          titleLines={1}
          subTitle={AddressHelper.getDisplaySubTitle(item)}
          onPress={() => onSavedAddressPressed(item)}
          disabled={isDeleting || settingDefault}
          addClickableStyle={canSelectDefault || !isStandAlone}
          plain
        />
      </Fragment>
    );
  };

  const _renderLoader = (size = 'small') => (
    <View style={styles.loaderRowContainer}>
      <ActivityIndicator size={size} color={ThemeColor.green} />
    </View>
  );

  if (lodashIsEmpty(userAddresses)) {
    return (
      <Fragment>
        <Header plain hasBackButton={false} />
        <SavedAddressesEmptyState
          //onBackPress={controller.onBackOrClose}
          onAddNewAddress={goToAddAddress}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Header plain hasBackButton={false} />
      <ScreenWrapper scroll={true} style={styles.screenWrapper}>
        <View style={ThemeStyle.flex1}>
          <View style={ThemeStyle.flex1}>
            {settingDefault && _renderLoader('large')}

            <List
              data={userAddresses}
              extraData={[deleting, settingDefault]}
              renderItem={_renderRow}
              ListHeaderComponent={_renderHeader}
              withSeparator
              plain
            />
          </View>

          <View
            style={[
              ThemeStyle.pageHorizontalSpacing,
              ThemeStyle.pageVerticalSpacingMedium,
              { alignItems: 'center' },
            ]}
          >
            <Button
              onPress={goToAddAddress}
              status="success"
              accessoryLeft={() => (
                <Icon
                  name="plus-outline"
                  fill={ThemeColor.white}
                  style={{ width: iconSize, height: iconSize }}
                />
              )}
              style={{ width: 250 }}
            >
              Add a new address
            </Button>
          </View>
        </View>
      </ScreenWrapper>
    </Fragment>
  );
};

export default SaveAddress;
