import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Divider, Text } from '@ui-kitten/components';

import ThemeStyle from '../../Theme/styles';
import {
  SECTION_TITLE_LIGHT,
  SECTION_SUB_TITLE,
} from '../../Theme/typographyProps';
import Service from '../../Service';

function Notes({ title, notes, refund }) {
  const isRefund = !!refund;

  return (
    <Fragment>
      <View
        style={[
          ThemeStyle.pageVerticalSpacingMedium,
          ThemeStyle.pageHorizontalSpacing,
        ]}
      >
        <Text style={ThemeStyle.spacingBottomMedium} {...SECTION_TITLE_LIGHT}>
          {title}
        </Text>
        {isRefund ? (
          <View>
            <Text style={ThemeStyle.spacingBottomMedium} {...SECTION_SUB_TITLE}>
              * Your order has been cancelled and is subject for refund.
            </Text>
            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Text {...SECTION_SUB_TITLE}>Refund Amount: </Text>
              <Text style={ThemeStyle.noticeText} {...SECTION_SUB_TITLE}>
                {Service.commafyNumber(refund, true)}
              </Text>
            </View>
            <View style={[ThemeStyle.flexDirectionRow, ThemeStyle.flexWrap]}>
              <Text {...SECTION_SUB_TITLE}>Refund Reason: </Text>
              <Text style={ThemeStyle.flex1} {...SECTION_SUB_TITLE}>
                {notes}
              </Text>
            </View>
          </View>
        ) : (
          <Text>{notes}</Text>
        )}
      </View>

      <Divider style={ThemeStyle.sectionSeparator} />
    </Fragment>
  );
}

Notes.propTypes = {
  title: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  refund: PropTypes.number,
};

export default Notes;
