import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

export const noSessionSlice = createSlice({
  name: 'noSession',
  initialState: {
    deepLinkingData: undefined,
    countdown: undefined,
    lastTimeCodepushCheck: undefined,
    rememberEmail: undefined,
    showOnboarding: true,
  },
  reducers: {
    removeCountdown: (state) => {
      state.countdown = undefined;
    },
    setCountdown: (state) => {
      const countdownDuration = 60; // 1 min.
      state.countdown = dayjs().add(countdownDuration, 'second').valueOf();
    },
    setDeeplinkingData: (state, action) => {
      // payload shape is: { route: 'route name', params: 'route params' }
      state.deepLinkingData = action.payload;
    },
    setRememberEmail: (state, action) => {
      state.rememberEmail = action.payload;
    },
    setShowOnboarding: (state, action) => {
      state.showOnboarding = action.payload;
    },
    updateLastTimeCodepushCheck: (state) => {
      state.lastTimeCodepushCheck = dayjs().valueOf();
    },
  },
});

export const {
  removeCountdown,
  setCountdown,
  setDeeplinkingData,
  setRememberEmail,
  setShowOnboarding,
  updateLastTimeCodepushCheck,
} = noSessionSlice.actions;

export default noSessionSlice.reducer;
