import React, { useState } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { Text } from '@ui-kitten/components';
import { UAParser } from 'ua-parser-js';
import ModalPrompt from './ModalPrompt';

import useCheckoutEvent from '../../../../Hooks/useCheckoutEvent';
import { EventDetails } from '../../../../RTK/api/events/types';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import Button from '../../../Button';
import EventOrderSummaryContent from '../../../Sheets/EventOrderSummary/Content';

import { ModalManager } from '../hooks/useModalManager';

interface OrderSummaryProps {
  modalId?: string;
}

const EventOrderSummary = ({ modalId }: OrderSummaryProps) => {
  const { device } = UAParser();
  const [data, setData] = useState<EventDetails>();

  const {
    apiError,
    eventDate,
    eventTime,
    paymentMethod,
    isCantProceedToCheckout,
    ticketPrice,
    onlineFeeTotal,
    grandTotal,
    isRequestingCheckout,
    buttonText,
    onPaymentMethodChange,
    onInputChange,
    onProceed,
  } = useCheckoutEvent(data);

  const isMobileView = device.type === 'mobile';

  const _onClose = () => {
    if (!isRequestingCheckout) {
      ModalManager.hide(modalId);
    }
  };

  return (
    <ModalPrompt
      id={modalId}
      title={'Order Summary'}
      hasHeader={true}
      hasHeaderLine={true}
      headerStyle={{ flex: null }}
      mainContentWrapperStyle={isMobileView && styles.mobileMainWrapper}
      closeOnDocumentClick={false}
      onBeforeShow={setData}
      onCloseButtonClick={_onClose}
      footer={
        <View dataSet={{ media: ids.footer }} style={styles.footer}>
          <Button
            status="success"
            style={{ flex: 1 }}
            disabled={isCantProceedToCheckout}
            onPress={onProceed}
            loading={isRequestingCheckout}
          >
            {() => <Text appearance="alternative">{buttonText}</Text>}
          </Button>
        </View>
      }
    >
      <EventOrderSummaryContent
        error={apiError}
        eventDate={eventDate}
        eventTime={eventTime}
        grandTotal={grandTotal}
        name={data?.name}
        onlineFeeTotal={onlineFeeTotal}
        paymentMethod={paymentMethod}
        ticketPrice={ticketPrice}
        onInputChange={onInputChange}
        onPaymentMethodChange={onPaymentMethodChange}
      />
    </ModalPrompt>
  );
};

const { ids, styles } = StyleSheet.create({
  mobileMainWrapper: {
    width: '100vw',
    height: '100dvh',
    borderRadius: 10,
  },
  footer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    rowGap: 30,
    columnGap: 30,
    paddingBottom: 20,
    paddingHorizontal: 30,
    [`@media ${BreakPoints.xs}`]: {
      flexDirection: 'columns',
    },
    [`@media ${BreakPoints.sm}`]: {
      flexDirection: 'columns',
    },
    [`@media ${BreakPoints.md}`]: {
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.lg}`]: {
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xl}`]: {
      flexDirection: 'row',
    },
    [`@media ${BreakPoints.xxl}`]: {
      flexDirection: 'row',
    },
  },
});

export default EventOrderSummary;
