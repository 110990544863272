import React, { Fragment } from 'react';
import { ScrollView, View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import {
  StackActions,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { Divider } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

//ROUTES
import routeList from '../../Routes/list';

import Button from '../../Components/Button';
import Header from '../../Components/Header';
import StoreCart from '../../Components/StoreCart';
import StoreCartItem from '../../Components/StoreCart/CartItem';
import StoreCartSummary from '../../Components/StoreCart/CartSummary';

import constants from '../../Config/constants';

import OrderHelper from '../../Helper/Order';
import { promoTypes } from '../../Helper/Promo';

import ThemeStyle from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

import DateAndTimes from './DateAndTimes';
import DeliveryAddress from './DeliverAddress';
import Notes from './Notes';
import PageLoader from './PageLoader';
import PaymentMethodUsed from './PaymentMethodUsed';
import StatusBanner from './StatusBanner';
import ThirdPartyPickupDetails from './ThirdPartyPickupDetails';
import useController from './index.controller';
import useOrderController from '../OrderHistory/controller/OrderHistory.controller';
import Footer from '../../Components/Footer';
import EmptyData from '../../Components/EmptyData';

const { ORDER_TYPES } = constants;

const OrderDetails = () => {
  const navigation = useNavigation<any>();
  const route = useRoute<any>();
  const {
    errorFetchingOrderDetails,
    isLoading,
    orderDetails,
    onAddOrViewOrderRatingPressed,
    onCopyPressed,
    onItemPressed,
    onStoreNamePressed,
  } = useController({ navigation, route });

  const { toggleWebOrderAgain } = useOrderController();

  const {
    additional_charges,
    charged_delivery_fee,
    completed_time,
    delivery_address,
    delivery_paid_by_cash,
    items_total_amount,
    items,
    order_date,
    order_notes,
    order_time,
    order_type,
    payment_method,
    pickup_details,
    promo_discount,
    promo,
    rating_id,
    refunded_amount,
    status,
    store_details,
    store_notes,
    total_amount,
    transaction_number,
  } = orderDetails;

  const isAsapText = order_time === 'asap_order_time';
  const isCompleted = status === 'completed';
  const hasPromo = !lodashIsEmpty(promo);

  const _showOrderAgain = () => {
    toggleWebOrderAgain(orderDetails);
  };

  const _renderFooter = () => {
    if (!isCompleted) {
      return null;
    }

    return (
      <View
        style={[
          ThemeStyle.alignItemsCenter,
          ThemeStyle.pageVerticalSpacingMedium,
        ]}
      >
        <Button
          testID="buyAgainButton"
          onPress={_showOrderAgain}
          status="success"
          style={[ThemeStyle.shadow, { width: 250 }]}
        >
          Order Again
        </Button>
      </View>
    );
  };

  const _getPromoValue = () => {
    if (promo.type === promoTypes.PERCENT_OFF) {
      return `${promo.percentage_off}%`;
    } else if (promo.type === promoTypes.FREE_DELIVERY) {
      return promo.subsidized_delivery_amount;
    } else if (promo.type === promoTypes.AMOUNT_OFF) {
      return promo.amount_off;
    }
    return 0;
  };

  const goBack = () => {
    ///Check if the page has refreshed
    navigation.dispatch(
      StackActions.replace(routeList.HOME, {
        screen: 'MainMenu',
        params: {
          screen: routeList.BASKET_TAB,
          params: {
            title: 'Orders',
            activeTab: 1,
            refetch: false,
          },
        },
      })
    );
    return;
  };

  if (isLoading) {
    return (
      <Fragment>
        <Header plain backButtonText="Back" onPressBack={goBack} />
        <PageLoader />
      </Fragment>
    );
  }

  if (!lodashIsEmpty(errorFetchingOrderDetails.title)) {
    return (
      <Fragment>
        <Header plain backButtonText="Back" onPressBack={goBack} />
        <EmptyData
          type="filter"
          title={errorFetchingOrderDetails?.title}
          subtitle={errorFetchingOrderDetails?.message}
        />
      </Fragment>
    );
  }

  return (
    <View style={{ height: '100vh' }}>
      <Header plain backButtonText="Back" onPressBack={goBack} />
      <ScrollView
        dataSet={{ media: ids.mainWrapper }}
        style={styles.mainWrapper}
        scrollEventThrottle={16}
      >
        {isCompleted && (
          <StatusBanner
            ratingId={rating_id}
            onBtnPress={onAddOrViewOrderRatingPressed}
          />
        )}

        {!!delivery_address && <DeliveryAddress address={delivery_address} />}

        <View style={ThemeStyle.pageVerticalSpacingSmall}>
          <StoreCart
            sectionIndex={0}
            storeName={store_details?.name}
            onStoreNamePress={onStoreNamePressed}
            accessibilityRole="link"
            href={`/store?id=${store_details?.id}`}
            target="_blank"
          />

          {items?.map((item, index) => (
            <StoreCartItem
              key={index}
              index={index}
              sectionIndex={0}
              {...item}
              onItemPress={onItemPressed(item)}
            />
          ))}

          <StoreCartSummary
            additionalCharge={OrderHelper.getAdditionalChargesData(
              additional_charges
            )}
            isDelivery={order_type === ORDER_TYPES.DELIVERY}
            promotion={
              hasPromo
                ? {
                    type: promo?.type,
                    description: promo?.free_item_description,
                    delivery_discount: promo?.subsidized_delivery_amount,
                    promo: {
                      value: _getPromoValue(),
                      applied: promo_discount,
                    },
                  }
                : {}
            }
            refund={refunded_amount}
            cart={items}
            delivery={{
              fee: charged_delivery_fee
                ? Number(charged_delivery_fee)
                : undefined,
              mode: delivery_paid_by_cash ? 'cash' : 'non_cash',
            }}
            computed={{
              subTotal: items_total_amount,
              total: total_amount,
            }}
          />
        </View>

        <Divider style={ThemeStyle.sectionSeparator} />

        <PaymentMethodUsed value={payment_method} />

        {!lodashIsEmpty(pickup_details) && (
          <ThirdPartyPickupDetails details={pickup_details} />
        )}

        {!!order_notes && (
          <Notes title="Additional Information" notes={order_notes} />
        )}

        {!!store_notes && (
          <Notes title="Remarks" notes={store_notes} refund={refunded_amount} />
        )}

        <View style={ThemeStyle.pageHorizontalSpacing}>
          <DateAndTimes
            transactionNumber={transaction_number}
            isAsap={isAsapText}
            isComplete={isCompleted}
            orderDate={order_date}
            orderTime={order_time}
            completedTime={completed_time}
            onCopyPress={onCopyPressed}
          />
        </View>

        {_renderFooter()}

        <View style={styles.footerWrapper}>
          <Footer />
        </View>
      </ScrollView>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  mainWrapper: {
    ...ThemeStyle.pageBackground,
    [`@media ${BreakPoints.xs}`]: {
      paddingHorizontal: 0,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingHorizontal: 100,
    },
    [`@media ${BreakPoints.md}`]: {
      paddingHorizontal: 100,
    },
    [`@media ${BreakPoints.lg}`]: {
      paddingHorizontal: 100,
    },
    [`@media ${BreakPoints.xl}`]: {
      paddingHorizontal: 100,
    },
    [`@media ${BreakPoints.xxl}`]: {
      paddingHorizontal: 100,
    },
  },
  footerWrapper: {
    ...ThemeStyle.pageBackground,
  },
});
export default OrderDetails;
