import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Icon } from '@ui-kitten/components';
import lodashFind from 'lodash/find';
import lodashIsEqual from 'lodash/isEqual';
import lodashIsNil from 'lodash/isNil';

import Button from '../../Components/Button';
import Image from '../../Components/Image';
import ListItem from '../../Components/ListItem';
import { getCurrency } from '../../Helper/Routines';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import { RatingItemProps } from './types';

const RatingItem = ({
  items,
  reactions,
  disabled,
  onItemReaction,
}: RatingItemProps) => {
  const _renderAccessoryLeft = (item) => {
    return (
      <Image
        source={{ uri: item.image }}
        style={styles.image}
        resizeMode="cover"
      />
    );
  };

  const _renderAccessoryRight = (item, reaction, callback) => {
    const isLiked = reaction?.liked === true;
    const isNotLiked = reaction?.liked === false;
    return (
      <View style={ThemeStyle.flexDirectionRowCenter}>
        <Button onPress={callback(item, true)} disabled={disabled} plain>
          <View
            style={[ThemeStyle.buttonContentGray, isLiked && styles.bgGreen]}
          >
            <Icon
              name="thumbs-o-up"
              pack="fa"
              fill={isLiked ? ThemeColor.white : ThemeColor.black}
              style={styles.icon}
            />
          </View>
        </Button>

        <View style={ThemeStyle.spacingLeftSmall} />

        <Button onPress={callback(item, false)} disabled={disabled} plain>
          <View
            style={[ThemeStyle.buttonContentGray, isNotLiked && styles.bgRed]}
          >
            <Icon
              name="thumbs-o-down"
              pack="fa"
              fill={isNotLiked ? ThemeColor.white : ThemeColor.black}
              style={[styles.icon, ThemeStyle.flipHorizontal]}
            />
          </View>
        </Button>
      </View>
    );
  };

  return items.map((item, key) => {
    const itemExtraIds = item?.extras?.map((e) => e.id) || [];
    const reaction = lodashFind(reactions, (r: Reaction) => {
      return r.id === item.id && lodashIsEqual(r.extraIds, itemExtraIds);
    });

    return (
      <ListItem
        key={key}
        accessoryLeft={_renderAccessoryLeft(item)}
        title={item.name}
        subTitle={!lodashIsNil(item?.price) ? getCurrency(item.price) : ''}
        accessoryRight={_renderAccessoryRight(item, reaction, onItemReaction)}
      />
    );
  });
};

const styles = StyleSheet.create({
  image: {
    ...ThemeStyle.spacingRightMedium,
    width: 50,
    height: 40,
    borderRadius: 5,
  },
  icon: {
    width: 25,
    height: 25,
  },
  bgGreen: {
    backgroundColor: ThemeColor.green,
  },
  bgRed: {
    backgroundColor: ThemeColor.red,
  },
});

export default RatingItem;
