import React, { Fragment, useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { StackActions } from '@react-navigation/native';
import { Icon, Text } from '@ui-kitten/components';
import { CANCEL_ERROR } from 'apisauce';

import Button from '../../Components/Button';
import Header from '../../Components/Header';
import ScreenWrapper from '../../Components/ScreenWrapper';
import messages from '../../Config/messages';
import Sentry from '../../Helper/Sentry';
import useCancellableRequest from '../../Hooks/useCancellableRequest';
import Service from '../../Service';
import userApi from '../../Service/api/user';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import routeList from '../../Routes/list';

const SignupVerificationSuccess = ({ route, navigation }) => {
  const { createRequest } = useCancellableRequest();

  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState('');

  const token = route.params?.token;

  useEffect(() => {
    if (token) {
      _verifyEmail();
    } else {
      // Less likely to happen, unless user clicked a link that token on url parameter is missing
      _setErrorState(
        'Token is required. Token is missing on the link you visit from your email.'
      );
    }
  }, [token]);

  const _verifyEmail = async () => {
    setVerifying(true); // add loader flag
    setError(''); // remove error message
    const { ok, data, problem } = await createRequest(
      userApi.verifyEmail,
      token
    );
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      _setErrorState('API call is cancelled.');
      return;
    }
    // if api call is not cancel
    if (ok) {
      _setSuccessState();
    } else {
      const err = data?.message || messages.COMMON_ERROR_MESSAGE;
      Sentry.reportError('Error verifying email', data);
      _setErrorState(Service.handleErrorMessage(err));
    }
  };

  const _setSuccessState = () => {
    setVerifying(false); // remove loader flag
    setError(''); // remove error message
  };

  const _setErrorState = (err) => {
    setVerifying(false); // remove loader flag
    setError(err);
  };

  const RenderIcon = () => {
    if (verifying) {
      return (
        <ActivityIndicator color={ThemeColor.green} size={styles.icon.width} />
      );
    } else if (error) {
      return <Icon name="close" fill={ThemeColor.red} style={styles.icon} />;
    }
    return (
      <Icon
        name="checkmark-circle-2"
        fill={ThemeColor.green}
        style={styles.icon}
      />
    );
  };

  const RenderTitle = () => {
    let title = 'Verified!';
    if (verifying) {
      title = 'Verifying...';
    } else if (error) {
      title = 'Failed to verify';
    }
    return (
      <Text category="h5" style={ThemeStyle.spacingBottomMedium}>
        {title}
      </Text>
    );
  };

  const RenderSubtitle = () => {
    let subTitle = 'You have successfully verified your acount.';
    if (verifying) {
      subTitle = 'Please wait a moment...';
    } else if (error) {
      subTitle = error;
    }
    return (
      <Text style={[ThemeStyle.textCenter, ThemeStyle.spacingBottom]}>
        {subTitle}
      </Text>
    );
  };

  const RenderButton = () => {
    const btnProps = { status: 'success' };
    if (verifying) {
      btnProps.disabled = true;
    } else if (error) {
      btnProps.status = 'danger';
    }
    return (
      <Button
        style={ThemeStyle.fitWidthContainer}
        onPress={goBack}
        {...btnProps}
      >
        Ok
      </Button>
    );
  };

  const goBack = () => {
    ///Check if the page has refreshed
    navigation.dispatch(
      StackActions.replace(routeList.HOME, {
        screen: 'MainMenu',
        params: {
          screen: routeList.HOME_TAB,
        },
      })
    );
    return;
  };
  return (
    <Fragment>
      <Header plain backButtonText="Back" onPressBack={goBack} />
      <ScreenWrapper>
        <View
          style={[
            ThemeStyle.flex1,
            ThemeStyle.justifyContentCenter,
            ThemeStyle.pageHorizontalSpacing,
            { backgroundColor: ThemeColor.lightGreen },
          ]}
        >
          <View
            style={[
              ThemeStyle.pageVerticalSpacing,
              ThemeStyle.pageHorizontalSpacing,
              ThemeStyle.pageBackground,
              ThemeStyle.shadowThin,
              ThemeStyle.alignSelfCenter,
              { borderRadius: 10, width: 300, maxWidth: 400 },
            ]}
          >
            <View style={[ThemeStyle.flex1, ThemeStyle.alignItemsCenter]}>
              <RenderIcon />
              <RenderTitle />
              <RenderSubtitle />
              <RenderButton />
            </View>
          </View>
        </View>
      </ScreenWrapper>
    </Fragment>
  );
};

const styles = StyleSheet.create({
  icon: {
    ...ThemeStyle.spacingBottom,
    width: 100,
    height: 100,
  },
});

export default SignupVerificationSuccess;
