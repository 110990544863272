import React, { Fragment, useRef, useState } from 'react';
import { Alert, View } from 'react-native';
import { WebView } from 'react-native-webview';
import { useDispatch } from 'react-redux';
import { CommonActions, StackActions } from '@react-navigation/native';
import { Layout, Text } from '@ui-kitten/components';
import { useToast } from 'react-native-toast-notifications';
import lodashGet from 'lodash/get';

import Button from '../../Components/Button';
import Header from '../../Components/Header';
import ScreenWrapper from '../../Components/ScreenWrapper';
import PopupModal from '../../Components/Web/PopupModal';
import Toast from '../../Components/Toast';
import modals from '../../Components/Sheets/modals';
import { MODALPROMPT } from '../../Components/Web/Modal/ModalPrompt/config';
import useModalPrompt from '../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import config from '../../Config';
import constants from '../../Config/constants';
import messages from '../../Config/messages';
import { isValidJson } from '../../Helper';
import withCheckout from '../../Hooks/withCheckout';
import useSetSpecificUserData from '../../Hooks/useSetSpecificUserData';
import routeList from '../../Routes/list';
import { updateCheckoutDetails } from '../../RTK/checkout';
import { checkout } from '../../RTK/defaultValues';
import ThemeStyle from '../../Theme/styles';
import { getCheckoutRedirectionDelay } from '../../Helper/RemoteConfig';

// use this variable on webview like this injectedJavaScript={debug} to console the console log on webview
// to terminal, make sure to add onMessage bcs console on web will show there
const debug = `
  console = new Object();
  console.log = function(...args) {
    window.ReactNativeWebView.postMessage(args.join(', '))
  };
  console.debug = console.log;
  console.info = console.log;
  console.warn = console.log;
  console.error = console.log;
`;

function CheckoutWebview({
  navigation,
  route,
  clearCartAndCheckoutData,
  clearMealPlanAndCheckoutData,
}) {
  const dispatch = useDispatch();
  const webViewRef = useRef();
  const isOrderSuccess = useRef();
  const paySuccessDelayTimeout = useRef();
  const toast = useToast();
  const { showModalPrompt } = useModalPrompt();
  const { updateLastUsedAddress } = useSetSpecificUserData();
  const [isPaymentExpired, setIsPaymentExpired] = useState(false); // for web modal use

  const { isMealPlan, checkout_date, payment, statusBar, storeId, url } =
    lodashGet(route, 'params');
  const sbar = typeof statusBar === 'boolean' ? statusBar : true;

  const _goBack = () => {
    if (constants.isWeb) {
      setIsPaymentExpired(false); // close modal first before redirect
    }
    navigation.goBack();
  };

  const _successOrder = () => {
    clearTimeout(paySuccessDelayTimeout.current);
    paySuccessDelayTimeout.current = null;
    isOrderSuccess.current = true;
    // clearing of cart and updating of last used address
    if (isMealPlan) {
      clearMealPlanAndCheckoutData();
    } else {
      clearCartAndCheckoutData(); // clear cart if not meal plan
    }
    updateLastUsedAddress(); // update last used address
    // show toast and redirect to order page
    const commonUrlParams = { refreshOrders: true, highlightOrderId: 'first' };
    if (constants.isWeb) {
      toast.show(messages.ORDER_SUCCESS, {
        type: 'success',
        placement: 'top',
        duration: 2000,
      });
      window.history.replaceState('', '', routeList.HOME);
      navigation.dispatch(
        StackActions.replace(routeList.HOME, {
          screen: 'MainMenu',
          params: {
            screen: routeList.BASKET_TAB,
            params: commonUrlParams,
          },
        })
      );
    } else {
      Toast.show(messages.ORDER_SUCCESS);
      // clear all route on stack navigator
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: routeList.ROOT_TAB }],
        })
      );
      // navigate to basket with params
      navigation.navigate(routeList.BASKET, {
        ...commonUrlParams,
        activeTab: 1,
      });
    }
  };

  const _listenToMessage = async ({ nativeEvent: { data, origin } }) => {
    const eventName = isValidJson(data) ? JSON.parse(data).message : data;
    const checkOriginWeb = constants.isWeb ? origin === config.PAYMENT : true;
    if (
      eventName === 'payment-verify' &&
      !paySuccessDelayTimeout.current &&
      !isOrderSuccess.current
    ) {
      // payment success on pickup backend, wait 5s before redirect to order page
      // payment is verified, safe to go back to order page
      paySuccessDelayTimeout.current = setTimeout(
        _successOrder,
        getCheckoutRedirectionDelay() * 1000
      );
    } else if (eventName === 'send-attributes' && checkOriginWeb) {
      // card info is needed, pass the card information
      webViewRef?.current?.postMessage(
        JSON.stringify({
          message: 'payment-attributes',
          attributes: {
            card_details: payment.cardNumber.replace(/\s/g, ''),
            exp_month: payment.cardMonth,
            exp_year: payment.cardYear,
            cvc: payment.cardCvv,
          },
        }),
        constants.isWeb ? config.PAYMENT : undefined
      );
    } else if (eventName === 'payment-succeeded') {
      // payment success, go back to order page
      _successOrder();
    } else if (eventName === 'payment-expired') {
      // payment is expired, payment is taking too long
      if (constants.isWeb) {
        setIsPaymentExpired(true);
      } else {
        Alert.alert(
          messages.PAYMENT_EXPIRED.title,
          messages.PAYMENT_EXPIRED.message,
          [{ text: 'OK', onPress: _goBack }]
        );
      }
    } else if (eventName === 'payment-failed') {
      // remove continue to payment from cart, so user can select new payment method on checkout page
      dispatch(
        updateCheckoutDetails({
          store_id: storeId,
          keyToUpdate: checkout.keys.CONTINUE_TO_PAYMENT,
          keyValue: undefined,
        })
      );
      // show prompt
      const data = {
        title: 'Payment Failed',
        message:
          'The card has been declined by the issuing bank. Please contact them for more information.',
        buttonStatus: 'success',
        buttonText: 'Choose different payment method',
      };
      if (constants.isWeb) {
        showModalPrompt(MODALPROMPT.prompt, data);
      } else {
        modals.show(modals.PROMPT, data);
      }
      _goBack(); // go back to previous screen
    }
  };

  return (
    <Fragment>
      {constants.isWeb && (
        <Fragment>
          <Header plain={true} store_id={storeId} backButtonText="Back" />
          <PopupModal
            isOpen={isPaymentExpired}
            onClosed={_goBack}
            title={messages.PAYMENT_EXPIRED.title}
            hasHeaderLine
          >
            <Layout>
              <Text style={[ThemeStyle.textCenter, ThemeStyle.spacingBottom]}>
                {messages.PAYMENT_EXPIRED.message}
              </Text>
              <View style={ThemeStyle.flexDirectionRowCenterCenter}>
                <Button onPress={_goBack} status="success" size="small">
                  {() => (
                    <Text
                      appearance="alternative"
                      category="c1"
                      style={ThemeStyle.pageHorizontalSpacing}
                    >
                      OK
                    </Text>
                  )}
                </Button>
              </View>
            </Layout>
          </PopupModal>
        </Fragment>
      )}

      <ScreenWrapper
        header={
          !constants.isWeb && (
            <Header title="Checkout" alignTitle="left" withBorder />
          )
        }
        statusBar={sbar}
        scroll={false}
      >
        <WebView
          ref={webViewRef}
          source={{ uri: url }}
          bounces={false}
          onMessage={_listenToMessage}
          // comment for now the originWhitelist, because it open browser instead of loading it on webview
          // even tho the the origin and config.PAYMENT is same
          // originWhitelist={[config.PAYMENT]}
        />
      </ScreenWrapper>
    </Fragment>
  );
}

export default withCheckout(CheckoutWebview);
