// import { clientStatic } from './client';
// import endpoints from './endpoints';

// import pkg from '../../package.json';

async function isAppUpdated() {
  // const path = endpoints.VERSION
  // const { ok, data } = await clientStatic.get(path)

  // if (ok) {
  //   return pkg.version === data.version
  // } else {
  //   return true // or false ?
  // }
  // disable app update checker for now
  return true;
}

export default { isAppUpdated };
