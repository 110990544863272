import lodashFilter from 'lodash/filter';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../Config/constants';

import StoreHelper from './Store';

const STATUS = {
  NEW: 'new',
  ACCEPTED: 'accepted',
  READY: 'ready',
  OUT_FOR_DELIVERY: 'out_for_delivery',
  COMPLETED: 'completed',
};

const statuses = [
  {
    icon: 'shopping-cart-outline',
    percent: 0,
    text: 'We have received your order.',
    value: STATUS.NEW,
  },
  {
    icon: 'book-outline',
    deliveryPercent: 27.5,
    pickupPercent: 35,
    text: 'Your order is now being prepared.',
    value: STATUS.ACCEPTED,
  },
  {
    icon: 'cube',
    deliveryPercent: 50,
    pickupPercent: 65,
    text: 'Your order is now ready for delivery.',
    pickupText: 'Your order is now ready for pickup.',
    value: STATUS.READY,
  },
  {
    delivery: true,
    icon: 'car',
    iconPack: 'fa',
    deliveryPercent: 72.5,
    pickupPercent: 65,
    text: 'Order is now out for delivery!',
    value: STATUS.OUT_FOR_DELIVERY,
  },
  {
    icon: 'home',
    percent: 100,
    text: 'Food has been delivered!',
    value: STATUS.COMPLETED,
  },
];

/**
 * Filter data of ongoing order to not display the pending if not successful order
 * @param {object[]} data - array of object of result of api of new orders
 * @param {string[]} successfulOrders - array of store id
 */
const filterData = (data, successfulOrders) => {
  if (lodashIsEmpty(data)) return data;
  // filter the data to not show the abandon order (verifying payment + no successful order flag)
  return lodashFilter(data, (d, index) => {
    if (index === 0 && d.status === constants.ORDER_STATUS.PENDING) {
      // only apply the successful order check on first index that is pending payment
      return StoreHelper.isSuccessfulOrder({
        storeId: d.store_details.id,
        orderId: d.id,
        successfulOrder: successfulOrders,
      });
    } else if (d.status === constants.ORDER_STATUS.PENDING) {
      // other pending payment that is not first order, hide
      return false;
    }
    // other not pending payment, show
    return true;
  });
};

/**
 * Transform charges from order/history data to shape of props of CartSummary component
 * @param {[{
 *    name: string,
 *    type: "amount_charge" | "percent_charge",
 *    factor: number,
 *    amount: number
 * }]} charges - array of object
 */
const getAdditionalChargesData = (charges) => {
  if (lodashIsEmpty(charges)) {
    return null;
  }
  const total = charges.reduce((acc, data) => acc + data.amount, 0);
  return {
    breakdownFactor: {
      factor: charges[0].factor,
    },
    data: {
      charges_breakdown: charges.map((c) => ({
        amount: c.amount,
        name: c.name.replace('_fee', ''),
        type: c.type,
      })),
      total_additional_charges: total,
    },
    enabled: true,
    total: total,
  };
};

const getDefaultStar = (paramValue) => {
  const star = parseInt(paramValue);
  return isNaN(star) ? 0 : star;
};

const getOrderStatusDetails = (status) => {
  return lodashFind(statuses, { value: status });
};

const getOrderStatusText = (status, orderType) => {
  const result = getOrderStatusDetails(status);
  const isPickup = orderType?.includes?.(constants.ORDER_TYPES.PICKUP);
  const text = isPickup ? result?.pickupText || result?.text : result?.text;
  return text || 'Verifying Payment...';
};

export { STATUS };

export const CHECKPOINTS = {
  DELIVERY: statuses,
  NONDELIVERY: lodashFilter(statuses, (s) => !s.delivery),
};

export default {
  filterData,
  getAdditionalChargesData,
  getDefaultStar,
  getOrderStatusDetails,
  getOrderStatusText,
};
