import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Icon, Input, withStyles } from '@ui-kitten/components';
import debounce from 'lodash/debounce';

import customMapping from '../Theme/mapping';
import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

import Button from './Button';

const iconSize = 20;

class Search extends React.PureComponent {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.state = {
      inputValue: '',
    };
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.searchInput.focus, 100);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  _debounceCallback = debounce(() => {
    this.props.onSearch(this.state.inputValue);
  }, 1000);

  _clearSearch = () => {
    this.setState({ inputValue: '' }, () => this.props.onSearch(''));
  };

  _getValue = () => this.state.inputValue;

  _onInputChange = (text, immediate) => {
    if (immediate) {
      this.setState({ inputValue: text }, () =>
        this.props.onSearch(this.state.inputValue, immediate)
      );
    } else {
      this.setState({ inputValue: text }, this._debounceCallback);
    }
  };

  _renderAccessoryLeft = () => {
    const theme = this.props.eva.theme;
    return (
      <Icon
        name="search"
        fill={theme['icon_color'] || ThemeColor.green}
        style={styles.searchIcon}
      />
    );
  };

  _renderAccessoryRight = () => {
    const theme = this.props.eva.theme;
    const { inputValue } = this.state;

    if (inputValue) {
      return (
        <Button testID="searchClear" onPress={this._clearSearch} plain>
          <Icon
            name="close-circle-outline"
            fill={theme['icon_color'] || ThemeColor.green}
            style={styles.closeIcon}
          />
        </Button>
      );
    }

    return null;
  };

  render() {
    const { disabled, inputStyle, onPressIn } = this.props;
    // to not pass the autoFocus props on input, on android it does not open the keyboard
    const { autoFocus, ...inputRestProps } = this.props;
    const { inputValue } = this.state;

    return (
      <View testID="searchInputContainer" style={styles.container}>
        {disabled && (
          // make the onPressIn work even the input is disabled
          // without this block of code onPressIn not gonna work on android but it work on iOS
          <Button
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 1,
            }}
            onPress={onPressIn}
            plain
          />
        )}

        <Input
          ref={(r) => (this.searchInput = r)}
          testID="searchInput"
          value={inputValue}
          onChangeText={this._onInputChange}
          status="control"
          placeholderTextColor={ThemeColor.darkGray}
          style={[ThemeStyle.flex1, styles.input, inputStyle]}
          textStyle={styles.inputText}
          accessoryLeft={this._renderAccessoryLeft}
          accessoryRight={this._renderAccessoryRight}
          autoCorrect={false}
          {...inputRestProps}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchIcon: {
    width: iconSize,
    height: iconSize,
    paddingHorizontal: 5,
  },
  input: {
    backgroundColor: ThemeColor.lightGray,
    borderRadius: 50,
    borderWidth: 0,
  },
  inputText: {
    color: ThemeColor.black,
    fontFamily: customMapping.strict['text-font-family'],
    fontSize: customMapping.strict['text-paragraph-1-font-size'],
    fontWeight: customMapping.strict['text-paragraph-1-font-weight'],
  },
  closeIcon: {
    width: iconSize + 5,
    height: iconSize + 5,
  },
});

Search.propTypes = {
  onSearch: (props, propName, componentName) => {
    // required onSearch if not disabled
    if (!props.onSearch && !props.disabled) {
      return new Error(
        `The prop "onSearch" is marked as required in ${componentName}, but its value is "undefined".`
      );
    }
  },
};

export default withStyles(Search);
