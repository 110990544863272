import React, { useEffect, useRef } from 'react';
import { View, ScrollView } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { Layout, Text } from '@ui-kitten/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmpty as lodashIsEmpty,
  isEqual as lodashIsEqual,
  find as lodashFind,
} from 'lodash';

//CONFIG
import constants from '../../Config/constants';

//COMPONENTS
import DropOffOptions from './DropOffOptions';
import Footer from '../../Components/Footer';
import ProfileForm from '../../Components/ProfileForm';

//CONTEXT
import { useCheckoutNavigatorContext } from '../../Routes/CheckoutNavigatorContext';

//REDUX
import { checkout } from '../../RTK/defaultValues';
import { setAddressSelection, setCheckoutData } from '../../RTK/mealPlan';
import { addressSelector } from '../../RTK/user/selectors';

//?THEMES
import ThemeColor from '../../Theme/colors';
//@ts-ignore: importing module
import ThemeStyle, { webPaddingHorizontal } from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

///SUB COMPONENTS
import VehicleOptions from './VehicleOptions';
import CheckoutRightContent from '../../Components/Web/CheckoutRightContent';

// TODO - show user details form here

const CheckoutMealPlan = () => {
  const dispatch = useDispatch();
  const currentAddress = useRef();
  const mealPlanData = useSelector((state: any) => state.mealPlan.data);
  const addresses = useSelector(addressSelector);

  const {
    deliveryQuotation,
    hasBulk,
    noUserDetails,
    placingOrder,
    state,
    storeCheckoutData,
    storeMealPlanDeliveryDiscount,
    storeMealPlanDiscount,
    onUserDetailsChange,
    toggleViewAddressModal,
    toggleLocateAddressModal,
    getDeliveryQuotation,
    proceedToCheckoutMealPlan,
    onPaymentOptionChanged,
    onLastUsedAddressPress,
  } = useCheckoutNavigatorContext();

  const dAddress = mealPlanData.checkoutData[checkout.keys.DELIVERY_ADDRESS];
  const promotion = mealPlanData.checkoutData[checkout.keys.PROMO_CODE];
  const activeAddress = lodashFind(addresses, { active: true });

  useEffect(() => {
    currentAddress.current = activeAddress;
  }, []);

  useEffect(() => {
    if (!lodashIsEqual(activeAddress, currentAddress.current)) {
      //to update the delivery address for checkout.
      onLastUsedAddressPress(activeAddress);
    } else if (lodashIsEmpty(dAddress)) {
      //if empty
      onLastUsedAddressPress(activeAddress);
    }
  }, [activeAddress]);

  useEffect(() => {
    _setDefaultValues();
    return () => {
      // remove flag as from meal plan checkout for address selection
      dispatch(setAddressSelection(false));
    };
  }, []);

  const _setDefaultValues = async () => {
    // set default order type to delivery
    await _dispatchCheckoutData(
      checkout.keys.ORDER_TYPE,
      constants.ORDER_TYPE_DATA[0]
    );
    // get delivery quotation after setting the default value
    getDeliveryQuotation();
  };

  const _onFormChange = (key) => (value) => _dispatchCheckoutData(key, value);

  const _dispatchCheckoutData = (key, data) =>
    dispatch(setCheckoutData({ key, data }));

  return (
    <Layout style={ThemeStyle.flex1}>
      <ScrollView
        dataSet={{ media: ids.contentContainer }}
        contentContainerStyle={styles.contentContainer}
        showsVerticalScrollIndicator
        scrollEventThrottle={16}
      >
        <View style={[ThemeStyle.flex1, ThemeStyle.flexDirectionRow]}>
          <View style={styles.wrapperFirstColumn}>
            {noUserDetails && (
              <View style={styles.details_wrapper}>
                <Text category="s2">Customer Information</Text>
                <ProfileForm
                  values={
                    storeCheckoutData[checkout.keys.USER_DETAILS] as {
                      firstName: string;
                      lastName: string;
                      phone: string;
                      email: string;
                    }
                  }
                  onChange={onUserDetailsChange}
                />
              </View>
            )}
            {
              //SHIPPING ADDRESS
            }
            <DropOffOptions
              selectedAddress={dAddress}
              disabled={placingOrder}
              onAddressPress={toggleViewAddressModal}
              onSelectedAddressPress={toggleLocateAddressModal}
              onLastUsedAddressPress={onLastUsedAddressPress}
            />

            <View style={styles.details_wrapper}>
              <VehicleOptions
                disabled={false}
                hasBulk={hasBulk}
                value={mealPlanData.checkoutData[checkout.keys.VEHICLE_OPTIONS]}
                onChange={_onFormChange(checkout.keys.VEHICLE_OPTIONS)}
              />
            </View>
          </View>
          <View
            dataSet={{ media: ids.wrapperSecondColumn }}
            style={styles.wrapperSecondColumn}
          >
            <CheckoutRightContent
              mealPlanData={mealPlanData}
              isMealPlan={true}
              checkoutState={{
                state,
                storeMealPlanDeliveryDiscount,
                storeMealPlanDiscount,
              }}
              checkoutData={mealPlanData.checkoutData}
              checkoutItems={mealPlanData.items}
              promotion={promotion}
              incrementPress={() => {}}
              decrementPress={() => {}}
              hasPickupOrderType={{}}
              onOrderTypeChange={() => {}}
              deliveryQuotation={deliveryQuotation}
              toggleConfirmModal={() => {}}
              hasDeliveryFee={true}
              placeOrder={proceedToCheckoutMealPlan}
              paymentMethods={['non_cash']} // this is the only available for meal plan checkout
              onPaymentOptionChanged={onPaymentOptionChanged}
              isPlacingOrder={placingOrder}
              error={{}}
            />
          </View>
        </View>

        <Footer />
      </ScrollView>
    </Layout>
  );
};

const { ids, styles } = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    ...ThemeStyle.pageBackground,
    [`@media ${BreakPoints.xs}`]: {
      paddingHorizontal: webPaddingHorizontal - 70,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingHorizontal: webPaddingHorizontal - 60,
    },
    [`@media ${BreakPoints.md}`]: {
      paddingHorizontal: webPaddingHorizontal - 50,
    },
    [`@media ${BreakPoints.lg}`]: {
      paddingHorizontal: webPaddingHorizontal - 40,
    },
    [`@media ${BreakPoints.xl}`]: {
      paddingHorizontal: webPaddingHorizontal - 30,
    },
    [`@media ${BreakPoints.xxl}`]: {
      paddingHorizontal: webPaddingHorizontal,
    },
  },
  main_wrapper: {
    flex: 1,
    justifyContent: 'space-between',
    height: '100vh',
    borderColor: 'red',
    borderWidth: 1,
  },
  wrapperFirstColumn: {
    ...ThemeStyle.flex4,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingRight: webPaddingHorizontal - 40,
    paddingTop: 10,
    rowGap: 10,
  },
  wrapperSecondColumn: {
    ...ThemeStyle.flex2,
    paddingTop: 10,
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'flex',
    },
  },

  label: {
    ...ThemeStyle.medium,
    fontSize: 14,
    color: ThemeColor.gray,
  },
  store_title: {
    ...ThemeStyle.medium,
    fontSize: 14,
    color: ThemeColor.black,
  },
  store_container: {
    ...ThemeStyle.spacingTopSmall,
    ...ThemeStyle.medium,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },

  icon_size: { width: 20, height: 20 },

  details_wrapper: {
    ...ThemeStyle.spacingTopMedium,
    padding: '2%',
    minHeight: 50,
    borderWidth: 1,
    borderColor: ThemeColor.gray,
    borderRadius: 5,
    marginBottom: 20,
  },
  saving_address_container: {
    backgroundColor: ThemeColor.white,
  },
});

export default CheckoutMealPlan;
