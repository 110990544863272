import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Divider } from '@ui-kitten/components';

import ThemeStyle from '../../Theme/styles';

import { CircleLoader, RectangleLoader } from '../Loader';

import { styles as itemStyles } from './CartItem';

function Loader({ noSubmitButton, withSummaryText }) {
  return (
    <View
      style={[
        ThemeStyle.pageHorizontalSpacing,
        ThemeStyle.pageVerticalSpacingMedium,
      ]}
    >
      {/* Store name */}
      <View style={ThemeStyle.flexDirectionRowCenter}>
        <View style={ThemeStyle.spacingRightMedium}>
          <RectangleLoader width={20} height={20} />
        </View>

        <RectangleLoader width={120} height={20} />
      </View>

      {/* Cart Item */}
      <View
        style={[
          ThemeStyle.spacingTop,
          ThemeStyle.spacingBottom,
          ThemeStyle.flexDirectionRowCenter,
        ]}
      >
        <View style={itemStyles.itemImageAndQuantityContainer}>
          <View style={{ zIndex: 1, position: 'absolute' }}>
            {/* cart item quantity */}
            <CircleLoader size={30} />
          </View>
          {/* cart item image */}
          <RectangleLoader width={75} height={75} />
        </View>

        <View style={ThemeStyle.flex1}>
          <View style={ThemeStyle.flex1}>
            <View style={ThemeStyle.spacingBottomSmall}>
              {/* cart item title */}
              <RectangleLoader width="100%" height={21} />
            </View>

            <View style={ThemeStyle.spacingBottomSmall}>
              {/* cart item extra group */}
              <RectangleLoader width="100%" height={15} />
            </View>

            {/* cart item notes */}
            <RectangleLoader width="100%" height={15} />
          </View>
        </View>

        <View style={ThemeStyle.spacingLeftMedium}>
          <View style={[itemStyles.itemPriceAndEllipsisContainer]}>
            {/* cart item cart price */}
            <RectangleLoader width={65} height={21} />
          </View>
        </View>
      </View>

      {/* cart summary */}
      <View style={ThemeStyle.spacingBottomMedium}>
        {/* Summary text */}
        {withSummaryText && <RectangleLoader width={100} height={25} />}

        {/* Divider */}
        {withSummaryText && (
          <View
            style={[
              ThemeStyle.spacingTopMedium,
              ThemeStyle.spacingBottomMedium,
            ]}
          >
            <Divider style={ThemeStyle.divider} />
          </View>
        )}

        <View
          style={[
            ThemeStyle.flexDirectionRowCenterSpaceBetween,
            ThemeStyle.spacingBottomMedium,
          ]}
        >
          {/* cart sub total left and value right */}
          <RectangleLoader width={80} height={15} />
          <RectangleLoader width={80} height={15} />
        </View>

        <View
          style={[
            ThemeStyle.flexDirectionRowCenterSpaceBetween,
            ThemeStyle.spacingBottomMedium,
          ]}
        >
          {/* cart delivery fee left and value right */}
          <RectangleLoader width={120} height={15} />
          <RectangleLoader width={100} height={15} />
        </View>

        <View style={[ThemeStyle.flexDirectionRowCenterSpaceBetween]}>
          {/* cart fees left and value right */}
          <RectangleLoader width={70} height={15} />
          <RectangleLoader width={80} height={15} />
        </View>

        <View
          style={[ThemeStyle.spacingTopMedium, ThemeStyle.spacingBottomMedium]}
        >
          <Divider style={ThemeStyle.divider} />
        </View>

        <View style={[ThemeStyle.flexDirectionRowCenterSpaceBetween]}>
          {/* cart total left and value right */}
          <RectangleLoader width={60} height={18} />
          <RectangleLoader width={90} height={18} />
        </View>
      </View>

      {!noSubmitButton && <RectangleLoader height={47} />}
    </View>
  );
}

Loader.defaultProps = {
  withSummaryText: true,
};

Loader.propTypes = {
  withSummaryText: PropTypes.bool,
  withProceedToOrderButton: PropTypes.bool,
};

export default Loader;
