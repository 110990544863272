import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';

import Button from '../Button';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

const iconSize = 14;

function StoreCart({
  sectionIndex,
  storeName,
  onStoreNamePress,
  contentRight,
  ...rest
}) {
  return (
    <View
      style={[
        ThemeStyle.pageHorizontalSpacing,
        ThemeStyle.spacingTopMedium,
        ThemeStyle.spacingBottomMedium,
        ThemeStyle.flexDirectionRowCenterSpaceBetween,
      ]}
      {...rest}
    >
      <Button
        testID={sectionIndex ? `storeNameButton${sectionIndex}` : ''}
        onPress={onStoreNamePress}
        style={ThemeStyle.flex1}
        plain
      >
        <View style={[ThemeStyle.flex1, ThemeStyle.flexDirectionRowCenter]}>
          <Icon
            name="store"
            pack="fa5"
            fill={ThemeColor.green}
            style={styles.icon}
          />
          <Text style={ThemeStyle.flex1}>{storeName}</Text>
        </View>
      </Button>

      {!!contentRight && (
        <View style={ThemeStyle.spacingLeft}>{contentRight}</View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  icon: {
    ...ThemeStyle.spacingRightMedium,
    width: iconSize,
    height: iconSize,
  },
  viewStoreIcon: {
    width: iconSize,
    height: iconSize,
  },
});

StoreCart.propTypes = {
  sectionIndex: PropTypes.number,
  storeName: PropTypes.string.isRequired,
  onStoreNamePress: PropTypes.func.isRequired,
  contentRight: PropTypes.node,
};

export default StoreCart;
