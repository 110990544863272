import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { useSelector } from 'react-redux';

import Button from '../../Components/Button';
import constants from '../../Config/constants';
import CartHelper from '../../Helper/Cart';
import { getCurrency } from '../../Helper/Routines';
import {
  checkoutPromoSelector,
  checkoutOrderTypeSelector,
} from '../../RTK/checkout/selectors';
import { mealPlanCheckoutPromoSelector } from '../../RTK/mealPlan/selectors';
import ThemeStyle from '../../Theme/styles';
import { SECTION_SUB_TITLE } from '../../Theme/typographyProps';

const minimumOrder = 100;
function ContinueToCheckoutButton({
  additionalCharge,
  cartItems,
  deliveryQuotation,
  disabled,
  isMealPlan,
  mealPlanDiscount,
  placingOrder,
  storeId,
  onContinueToCheckoutPress,
}) {
  const storePromoSelector = isMealPlan
    ? mealPlanCheckoutPromoSelector
    : checkoutPromoSelector;
  const isPromoOk = useSelector((state) => state.checkout.isPromoOk);
  const orderType = useSelector((state) =>
    checkoutOrderTypeSelector(state, storeId)
  );
  const promotion = useSelector((state) => storePromoSelector(state, storeId));

  const isDelivery = orderType === constants.ORDER_TYPES.DELIVERY;
  const { total } = CartHelper.getBreakdown({
    cart: cartItems,
    delivery: deliveryQuotation,
    isDelivery,
    promotion,
    subTotalPromoPercent: mealPlanDiscount,
    totalAdditionalCharge: additionalCharge?.total,
  });

  const isLessThanMinimum = total < minimumOrder;

  const isQuotationNotOk = () => {
    if (isDelivery) {
      return (
        deliveryQuotation.loading || // if loading
        !!deliveryQuotation.error || // has error message
        typeof deliveryQuotation.fee !== 'number' // quotation fee is not a number
      );
    } else {
      return false;
    }
  };

  const isAdditionalChargeNotOk = () => {
    if (additionalCharge?.enabled) {
      return (
        additionalCharge.loading || typeof additionalCharge.total !== 'number'
      );
    } else {
      return false;
    }
  };

  return (
    <View
      style={[
        ThemeStyle.pageHorizontalSpacing,
        ThemeStyle.spacingTopMedium,
        ThemeStyle.spacingBottomMedium,
      ]}
    >
      {isLessThanMinimum && (
        <View style={ThemeStyle.spacingBottomSmall}>
          <Text
            style={[ThemeStyle.noticeText, ThemeStyle.textCenter]}
            {...SECTION_SUB_TITLE}
          >
            Minimum order is {getCurrency(minimumOrder)}
          </Text>
        </View>
      )}

      <Button
        testID="placeOrderButton"
        status="success"
        accessoryRight={() => (
          <Text appearance="alternative" style={ThemeStyle.spacingRightSmall}>
            {getCurrency(total)}
          </Text>
        )}
        style={ThemeStyle.shadow}
        onPress={onContinueToCheckoutPress}
        disabled={
          !isPromoOk ||
          disabled ||
          isAdditionalChargeNotOk() ||
          isLessThanMinimum ||
          isQuotationNotOk() ||
          placingOrder
        }
      >
        {() => (
          <Text
            appearance="alternative"
            style={[ThemeStyle.flex1, ThemeStyle.spacingLeftSmall]}
          >
            Place Order
          </Text>
        )}
      </Button>
    </View>
  );
}

export default ContinueToCheckoutButton;
