import { createContext, useContext } from 'react';

type MainContextProps = {
  isRemoteConfigFetching: boolean;
};

const MainContext = createContext<MainContextProps | undefined>(undefined);

const useMainContext = () => useContext(MainContext);

export { MainContext, useMainContext };
