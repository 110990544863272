import React, { Fragment, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { FlatList, View, StyleSheet, StyleProp, ViewStyle } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Icon } from '@ui-kitten/components';

import lodashIsEmpty from 'lodash/isEmpty';
import lodashGet from 'lodash/get';

import constants from '../../../Config/constants';

import routeList from '../../../Routes/list';

import userApi from '../../../Service/api/user';
import checkoutApi from '../../../Service/api/checkout';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';

import Badge from '../../Badge';
import Button from '../../Button';
import CartListService from './CartListService';
// @ts-ignore: Unable to find module error
import { RAZZLE_BUILD_MODE } from '@env';

import EmptyData from '../../EmptyData';
import { RectangleLoader } from '../../Loader';

import Sentry from '../../../Helper/Sentry';

import StoreHelper from '../../../Helper/Store';

import useCart from '../../../Hooks/useCart';

import { removeCart } from '../../../RTK/cart';
import {
  removeCheckoutDetails,
  updateCheckoutDetails,
} from '../../../RTK/checkout';

import { checkout } from '../../../RTK/defaultValues';
import { successfulOrderSelector } from '../../../RTK/utility/selectors';

import messages from '../../../Config/messages';
import OngoingOrderBanner from '../../OngoingOrderBanner';

import useModalPrompt from '../Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../Modal/ModalPrompt/config';
import useError from '../../../Hooks/useError';
import { whenFilterSelector } from '../../../RTK/filter/selectors';
import { getCartTerm } from '../../../Helper/RemoteConfig';
import useMealPlan from '../../../Hooks/useMealPlan';

import { shopSelector } from '../../../RTK/shop/selectors';
import { selectCart } from '../../../RTK/cart/selectors';

import MealPlanList from './MealPlanList';
import dayjs from 'dayjs';

const { ORDER_TYPES } = constants;

const Cart = (props) => {
  const { isMealPlan } = useMealPlan();

  if (isMealPlan) return <MealPlanList />;

  return <CartList {...props} />;
};

const CartList = ({
  isCartLoading = false,
  isClickableHeader = true,
  emptyCartTitle = '',
  emptyCartSubTitle = '',
  onItemPress,
  route,
  state: routeState,
}: any) => {
  const navigation = useNavigation<any>();
  const dispatch = useDispatch();
  const shopData = useSelector(shopSelector);
  const cartData = useSelector((state) => selectCart(state, shopData?.id));

  const whenFilter = useSelector(whenFilterSelector);
  const checkoutData = useSelector((state: any) => state.checkout.checkoutData);
  const successfulOrder = useSelector(successfulOrderSelector);
  const { ErrorCartOrCheckoutHandler } = useError(shopData?.id);
  const { addOrUpdateCart, removeThisProductOnCart } = useCart();
  const { showModalPrompt } = useModalPrompt();

  const [cartItemUpdatingById, setCartItemUpdatingById] = useState<string>('');

  // Branded loader state
  const [isPaymentLinkLoading, setPaymentLinkLoaderStatus] = useState(false);

  const cartTerm = getCartTerm();

  const goBackToStore = (storeId) => (e) => {
    e?.preventDefault();
    navigation.navigate(routeList.STORE, { id: storeId });
  };

  const _onUpdateQuantity = (item) => async () => {
    setCartItemUpdatingById(item.cart_details_id);
    addOrUpdateCart({
      from: 'Product Page',
      item: { ...item, id: item.item_id, quantity: item.quantity },
      orderType: item.order_type,
      store: { id: item.store_id, name: item.store_name },
      onCancel: () => setCartItemUpdatingById(''),
      onError: async (error) => {
        setCartItemUpdatingById('');
        ErrorCartOrCheckoutHandler({
          error: error,
          isAcceptingAdvanceOrder:
            shopData?.is_accepting_in_advanced_orders ||
            item?.is_accepting_in_advanced_orders,
          store_id: item.store_id,
        });
      },
      onSuccess: () => setCartItemUpdatingById(''),
    });
  };
  const _toggleConfirmDeleteModal = (item, type) => () => {
    const isSingleItem = type === 'single';
    const itemNameOrStoreName = isSingleItem
      ? item?.product_name
      : item?.store_name;

    showModalPrompt(MODALPROMPT.prompt, {
      title: 'Are you sure?',
      message: `Do you really want to remove ${itemNameOrStoreName} from your ${cartTerm}?`,
      buttons: [
        {
          size: 'small',
          status: 'danger',
          text: 'Yes',
          onPress: () =>
            isSingleItem ? _removeThisProductOnCart(item) : _removeAll(item),
        },
        { size: 'small', text: 'No' },
      ],
    });
  };
  const _removeThisProductOnCart = async (item: any) => {
    setCartItemUpdatingById(item.cart_details_id);
    removeThisProductOnCart({
      item: item,
      storeId: item.store_id,
      onSuccess: () => setCartItemUpdatingById(''),
      onError: () => () => setCartItemUpdatingById(''),
    });
  };
  const _removeAll = async (item: any) => {
    const handleRemoveAll = async () => {
      await dispatch(removeCart(item.store_id)); // clear store cart data
      await dispatch(removeCheckoutDetails(item.store_id)); // clear store checkout details
      setCartItemUpdatingById('');
    };

    if (RAZZLE_BUILD_MODE !== 'branded') {
      const { ok, data } = await userApi.removeStoreCart([item.store_id]);
      if (ok) {
        // _handleStorageChange()
        await handleRemoveAll();
      } else {
        setCartItemUpdatingById('');
        // show alert
        Sentry.reportError('Error removing store cart', data);
        // @ts-ignore: JS code error
        alert('Please try again', 'Failed to remove store cart');
      }
    } else {
      await handleRemoveAll();
    }
  };

  const _goToCheckout = async (storeInfo, isValid, storeCart) => {
    const storeCheckoutData = StoreHelper.getStoreCheckoutData(
      storeInfo.id,
      checkoutData
    );
    const orderType = storeCheckoutData[checkout.keys.ORDER_TYPE];

    if (isValid) {
      if (RAZZLE_BUILD_MODE === 'branded') {
        const paramsFromDrawerNav = routeState?.routes?.find(
          ({ name }) => name === 'menu'
        );

        const order_date = lodashGet(whenFilter, 'date');
        const order_time = lodashGet(whenFilter, 'time');
        const order_type =
          orderType?.value ||
          lodashGet(
            route,
            'params.order_type',
            lodashGet(
              paramsFromDrawerNav,
              'params.order_type',
              ORDER_TYPES['DELIVERY']
            )
          );

        setPaymentLinkLoaderStatus(true);

        const landing_referrer = localStorage.getItem(
          'branded_redirect_referrer'
        );

        const { ok, data } = await checkoutApi.placeOrder({
          items: storeCart.items.map(({ quantity, id, extras }) => ({
            quantity,
            id,
            extras: extras.map(({ id }) => id),
          })),
          store_id: storeInfo.id,
          promo_code: lodashGet(storeCheckoutData, 'promoStore.promo_code', ''),
          order_type,
          order_date,
          order_time,
          referrer: landing_referrer,
        });

        if (ok) {
          if (data?.success) {
            window?.localStorage?.setItem(
              'BRANDED_LVS',
              window?.location?.href
            );
            dispatch(
              updateCheckoutDetails({
                store_id: storeInfo.id,
                keyToUpdate: checkout.keys.CONTINUE_TO_PAYMENT,
                keyValue: {
                  checkout_date: dayjs().toISOString(),
                  storeId: storeInfo.id,
                  url: data?.redirect,
                },
              })
            );
            navigation.push('checkout', {
              storeId: storeInfo.id,
              url: data?.redirect,
            });
            setPaymentLinkLoaderStatus(false);
          } else {
            ErrorCartOrCheckoutHandler({
              error: { title: constants.ERROR_TYPE.UNAVAILABLE },
              isAcceptingAdvanceOrder:
                shopData?.is_accepting_in_advanced_orders,
            });
            setPaymentLinkLoaderStatus(false);
          }
        } else {
          let error;
          if (
            constants.STORE_CLOSING_OR_CLOSED_ERROR[0].includes(
              data?.message?.toLowerCase()
            )
          ) {
            error = {
              title: messages.STORE_CLOSED_MOBILE.title,
              message: messages.STORE_CLOSED_MOBILE.message,
            };
          } else if (
            constants.STORE_CLOSING_OR_CLOSED_ERROR[1].includes(
              data?.message?.toLowerCase()
            )
          ) {
            error = {
              title: messages.STORE_CLOSING.title,
              message: messages.STORE_CLOSING.message,
            };
          } else {
            error = {
              title: messages.COMMON_ERROR_TITLE,
              message: messages.COMMON_ERROR_MESSAGE,
            };
          }

          ErrorCartOrCheckoutHandler({
            error,
            isAcceptingAdvanceOrder: shopData?.is_accepting_in_advanced_orders,
          });
          setPaymentLinkLoaderStatus(false);
        }
      } else {
        navigation.push(routeList.CHECKOUT_NAVIGATOR);
      }
    } else {
      ErrorCartOrCheckoutHandler({
        error: { title: constants.ERROR_TYPE.UNAVAILABLE },
        isAcceptingAdvanceOrder: shopData?.is_accepting_in_advanced_orders,
      });
      setPaymentLinkLoaderStatus(false);
    }
  };

  const storeHeader = (item) =>
    !isCartLoading ? (
      <View style={ThemeStyle.spacingTopSmall}>
        <Text category="p1" status="primary">
          Your {cartTerm} from
        </Text>
        <View style={ThemeStyle.flexDirectionRowCenter}>
          {!isClickableHeader ? (
            <View
              style={[
                ThemeStyle.flex1,
                ThemeStyle.spacingRightMedium,
                styles.storeContainer,
              ]}
            >
              <Text category="p1" numberOfLines={2}>
                {item.store_name}
              </Text>
            </View>
          ) : (
            <Button
              onPress={goBackToStore(item.store_id)}
              style={ThemeStyle.flex1}
              accessibilityRole="link"
              href={`/store?id=${item.store_id}`}
              target="_blank"
              plain
            >
              <View
                style={[
                  ThemeStyle.flex1,
                  ThemeStyle.spacingRightMedium,
                  styles.storeContainer,
                ]}
              >
                <Text category="p1" numberOfLines={2}>
                  {item.store_name}
                </Text>

                <Icon
                  name="chevron-right"
                  fill={ThemeColor.black}
                  style={styles.iconSize}
                />
              </View>
            </Button>
          )}
          {StoreHelper.isPaymentPending({
            status: item.status,
            storeId: item.store_id,
            successfulOrder,
          }) ? (
            //@ts-ignore: Error redline due to JS File
            <Badge
              category={Badge.CATEGORIES.WARNING}
              type={Badge.TYPES.OUTLINE}
              size="c1"
            >
              {messages.PAYMENT_PENDING.title}
            </Badge>
          ) : (
            <Button
              onPress={_toggleConfirmDeleteModal(item, 'all')}
              disabled={Boolean(cartItemUpdatingById)}
              plain
            >
              <View style={styles.itemRemoveButton}>
                <Text category="c1" appearance="alternative">
                  Remove
                </Text>
              </View>
            </Button>
          )}
        </View>
      </View>
    ) : (
      <View>
        <View style={ThemeStyle.spacingTopMedium}>
          <RectangleLoader width={100} height={20} />
        </View>
        <View style={ThemeStyle.spacingTopMedium}>
          <RectangleLoader width={100} height={20} />
        </View>
      </View>
    );

  const _renderItem = ({ item, index }) => (
    <CartListService
      key={index}
      isCartLoading={isCartLoading}
      store_cart={item}
      goToCheckout={_goToCheckout}
      onUpdateQuantity={_onUpdateQuantity}
      storeHeader={storeHeader}
      toggleConfirmDeleteModal={_toggleConfirmDeleteModal}
      onItemPress={onItemPress}
      isUpdatingItem={cartItemUpdatingById}
      isPaymentLinkLoading={isPaymentLinkLoading}
    />
  );

  const contentContainerStyle: StyleProp<ViewStyle> = [{ paddingBottom: 100 }];

  if (lodashIsEmpty(cartData))
    contentContainerStyle.unshift(styles.cartContentCenter);

  return (
    <Fragment>
      <FlatList
        data={cartData}
        renderItem={_renderItem}
        ListEmptyComponent={
          <EmptyData
            type="cart"
            title={emptyCartTitle}
            subtitle={emptyCartSubTitle}
          />
        }
        ItemSeparatorComponent={() => <View style={styles.storeSeparator} />}
        ListHeaderComponent={
          //@ts-ignore: Error redline due to JS File
          RAZZLE_BUILD_MODE !== 'branded' ? <OngoingOrderBanner /> : null
        }
        ListHeaderComponentStyle={
          lodashIsEmpty(cartData) && { marginBottom: 40 }
        }
        contentContainerStyle={contentContainerStyle}
        style={ThemeStyle.spacingTopMedium}
      />
    </Fragment>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    ...ThemeStyle.spacingTopMedium,
    ...ThemeStyle.spacingBottomMedium,
  },
  selectionContainer: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.pageVerticalSpacing,
    backgroundColor: ThemeColor.gray,
    borderRadius: 10,
  },
  active: {
    backgroundColor: ThemeColor.green,
  },
  icon: {
    ...ThemeStyle.spacingRightMedium,
    width: 20,
    height: 20,
  },
  storeSeparator: { height: 10 },
  storeContainer: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },

  iconSize: { width: 20, height: 20 },
  itemRemoveButton: {
    ...ThemeStyle.pageVerticalSpacingSmall,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    backgroundColor: ThemeColor.red,
    borderRadius: 10,
  },
  box: {
    borderWidth: 1,
    borderColor: ThemeColor.gray,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    padding: 5,
  },
  cartContentCenter: {
    flexGrow: 1,
    justifyContent: 'center',
    marginBottom: 50,
  },
});

export default Cart;
