import React, { Fragment, memo, useMemo, useRef, useState } from 'react';
import { View, ScrollView } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Layout, Text, useTheme } from '@ui-kitten/components';
import { UAParser } from 'ua-parser-js';

import lodashIsEmpty from 'lodash/isEmpty';
import Tooltip from 'rn-tooltip';

import useError from '../../../../Hooks/useError';

import routeList from '../../../../Routes/list';

import MenuItemHelper from '../../../../Helper/MenuItem';

import TagIconList from '../../../../Service/TagIconList';

import Badge from '../../../../Components/Badge';
import Button from '../../../../Components/Button';
import Image from '../../../../Components/Image';

import ThemeStyle, { globalSpacing } from '../../../../Theme/styles';
import ThemeColors from '../../../../Theme/colors';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import ExtraGroup from '../../../../Screens/Product/ExtraGroup';
import ProductFooter from '../../../../Screens/Product/ProductFooter';

import useController from '../../../../Screens/Product/controller';

import constants from '../../../../Config/constants';
import messages from '../../../../Config/messages';

import useToast from '../../../../Hooks/useToast';

import {
  productLoadingSelector,
  productSelector,
} from '../../../../RTK/product/selectors';

import CloseButton from '../../CloseButton';
import ReadMore from '../../../Readmore';
import ModalPrompt from './ModalPrompt';
import { MODALPROMPT } from './config';
import { CartDetails } from '../../../../Types';
import useModalPrompt from './hooks/useModalPrompt';
import { shopSelector } from '../../../../RTK/shop/selectors';
import { isLoggedInSelector } from '../../../../RTK/user/selectors';
import ProductLoader from '../../../../Screens/Product/Loader';

//@ts-ignore: JS Code error
import { RAZZLE_BUILD_MODE } from '@env';

interface ItemProps {
  item: CartDetails;
  route?: any;
}
const Item = () => {
  const store_theme = useTheme();
  const { device } = UAParser();

  const { showModalPrompt, hideModalPrompt } = useModalPrompt();

  const toast = useToast();
  const bulkToolTip = useRef(null);
  const scrollList = useRef<ScrollView>();
  const shopData = useSelector(shopSelector);
  const isProductLoading = useSelector(productLoadingSelector);
  const productData = useSelector(productSelector);

  const isLogin = useSelector(isLoggedInSelector);
  const [data, setData] = useState<ItemProps>();

  const { item, route, navigation } = data || {
    item: {
      isUpdate: false,
      item_id: '',
      storeImage: '',
      storeName: '',
    },
  };
  const { isUpdate, item_id, storeImage, storeName } = item;
  const availableExtras = productData?.extra_group;
  // memoizing the params is important, or else the product controller will trigger an infinite trigger of the effect since every rerender the object is a new reference so it needs to be memoized
  const memoizedParams = useMemo(
    () => ({
      params: {
        id: item_id,
        is_exclusive: Boolean(productData?.is_exclusive),
        ...item,
        isUpdate,
      },
      scrollHandlers: scrollList,
    }),
    [item_id, isUpdate]
  );
  const { ErrorCartOrCheckoutHandler } = useError(shopData?.id);
  const {
    state,
    getItemTotalPrice,
    addOrRemoveExtras,
    toggleExtrasErrorPrompt,
    getSelectedExtra,
    onAddToCartPressed,
    errorMessage,
    setErrorMessage,
    getMealPlanProps,
  } = useController(memoizedParams);

  const noStock = !state.stocks.isLoading && state.stocks.stock === 0;

  const isMobileView = device.type === 'mobile';

  const _renderTagBelowItemName = () => {
    const showTagBelowName = productData?.is_bulk || noStock;

    if (!showTagBelowName) {
      return null;
    }

    return (
      <View style={[ThemeStyle.flexDirectionRow]}>
        {productData?.is_bulk && (
          <View
            style={[
              ThemeStyle.spacingBottomSmall,
              ThemeStyle.spacingRightMedium,
            ]}
          >
            {
              //@ts-ignore: JS code error
              <Tooltip
                ref={bulkToolTip}
                width={200}
                height={50}
                withOverlay={false}
                containerStyle={{
                  borderRadius: 5,
                  padding: 5,
                  backgroundColor: ThemeColors.white,
                  ...ThemeStyle.shadow,
                }}
                pointerColor={ThemeColors.gray}
                popover={
                  <Text>
                    This item is bulky and can only be delivered by cars
                  </Text>
                }
              >
                <Button
                  onPress={() => bulkToolTip.current.toggleTooltip()}
                  hitSlop={30}
                  plain
                >
                  <Icon
                    name={TagIconList.fontAwesomeIcon.bulk}
                    pack="fa5"
                    fill={ThemeColors.orange}
                    style={{ width: 18, height: 18 }}
                  />
                </Button>
              </Tooltip>
            }
          </View>
        )}
        {noStock && (
          <View
            style={[
              ThemeStyle.spacingBottomSmall,
              ThemeStyle.spacingRightSmall,
            ]}
          >
            {/* @ts-ignore: JS code error */}
            <Badge
              size={'c1'}
              type={Badge.TYPES.SOLID}
              category={Badge.CATEGORIES.DANGER}
            >
              Unavailable
            </Badge>
          </View>
        )}
      </View>
    );
  };

  const _renderTagBelow = () => {
    if (productData?.tags?.length === 0) {
      return null;
    }

    return (
      <View style={ThemeStyle.spacingLeftMedium}>
        <View
          style={[
            ThemeStyle.spacingLeftMedium,
            ThemeStyle.flexDirectionRow,
            { flexWrap: 'wrap', flexShrink: 1 },
          ]}
        >
          {productData?.tags?.map((t, i) => {
            const result: {
              id: string;
              type: string;
              category: string;
              icon: string;
              name: string;
            } = TagIconList.getTagIcon(t);
            return (
              <View
                style={[
                  ThemeStyle.spacingBottomSmall,
                  ThemeStyle.spacingRightSmall,
                ]}
                key={`${item_id}-${i}`}
              >
                {
                  //@ts-ignore: JS code error
                  <Badge
                    size={'c1'}
                    type={result.type}
                    category={result.category}
                  >
                    {`${result.icon} ${result.name}`}
                  </Badge>
                }
              </View>
            );
          })}
        </View>
      </View>
    );
  };

  const _getLimit = () => {
    //@ts-ignore: JS code error
    const currentStock = MenuItemHelper.getCategoryOrItemStock(item);
    if (currentStock && !noStock) {
      return (
        <Text category="p2" status="danger">
          {currentStock} items left!
        </Text>
      );
    }
    //@ts-ignore: JS code error
    return item.heading;
  };

  const _extras = (item, index) => {
    let defaultSelected = state.isUpdate ? getSelectedExtra(item) : null;
    const isRequired = item?.is_required;

    if (lodashIsEmpty(item)) {
      return null;
    }

    return (
      <Fragment key={index}>
        <ExtraGroup
          store_theme={store_theme}
          defaultSelected={defaultSelected}
          group={{ ...item, index }}
          onGroupSelection={addOrRemoveExtras}
          onError={toggleExtrasErrorPrompt}
          onLayout={(event) => {
            const layout = event.nativeEvent.layout;
            state.extrasCoords[index] = layout.y;
          }}
        />
        {errorMessage && isRequired && (
          <View style={styles.errorTags}>
            <Text category="p2" status="danger">
              {errorMessage}
            </Text>
          </View>
        )}
      </Fragment>
    );
  };

  const _onPressClosed = () => {
    setErrorMessage('');
    hideModalPrompt(MODALPROMPT.itemModal);
  };

  const onDone = (data) => {
    if (data.type === 'Success') {
      if (route?.name === routeList.SEARCH) {
        toast.show(messages.ADDED_CART_ITEM, true);
      }
      return hideModalPrompt(MODALPROMPT.itemModal);
    } else {
      return ErrorCartOrCheckoutHandler({
        error: data,
        isAcceptingAdvanceOrder: shopData?.is_accepting_in_advanced_orders,
        store_id: item.store_id,
      });
    }
  };

  const _renderListItem = (
    <Layout level="1">
      {/* storeName AND storeImage is pass on store page item result */}
      {!lodashIsEmpty(storeName) && (
        <View style={styles.storeHeader}>
          <Image
            source={{
              uri: storeImage,
            }}
            style={styles.storeImage}
          />
          <Text
            category="s1"
            numberOfLines={1}
            style={ThemeStyle.spacingRightSmall}
          >
            {storeName}
          </Text>

          <View style={styles.storeCloseContainer}>
            <CloseButton onPress={_onPressClosed} />
          </View>
        </View>
      )}

      <View style={styles.img_container}>
        {
          //@ts-ignore: JS code error
          <Image
            source={{ uri: productData?.image }}
            resizeMode={'cover'}
            style={[styles.img_size, isMobileView && styles.img_border]}
          />
        }
        {lodashIsEmpty(storeName) && (
          <View style={styles.closeButtonContainer}>
            <CloseButton onPress={_onPressClosed} />
          </View>
        )}
      </View>

      <View
        style={{
          ...ThemeStyle.flexDirectionRow,
          ...ThemeStyle.pageHorizontalSpacing,
          ...ThemeStyle.spacingTopSmall,
          columnGap: 12,
        }}
      >
        <Text category="s1" numberOfLines={1}>
          {productData?.name}
        </Text>
        {_renderTagBelowItemName()}
      </View>

      {_renderTagBelow()}

      <View style={styles.descriptionContainer}>
        <ReadMore
          numberOfLines={2}
          value={productData?.description}
          readmoreContainerStyle={{}}
        />
      </View>
      <View style={styles.description_tags}>{_getLimit()}</View>
    </Layout>
  );

  const _addToCartPress = () => {
    if (!isLogin && RAZZLE_BUILD_MODE !== 'branded') {
      hideModalPrompt(MODALPROMPT.itemModal);
      showModalPrompt(MODALPROMPT.authentication, {
        isLogin: true,
        navigation: navigation,
      });
      return;
    }

    onAddToCartPressed(scrollList.current, onDone);
  };

  return (
    <ModalPrompt
      id={MODALPROMPT.itemModal}
      title=""
      mainContentWrapperStyle={isMobileView && styles.mobileMainWrapper}
      childContentContainerStyle={styles.childContentContainerStyle}
      hasHeader={false}
      hasHeaderLine={false}
      closeOnDocumentClick={false}
      footer={
        <View
          style={{
            flex: 1,
          }}
        >
          <ProductFooter
            stocks={state.stocks}
            disabled={state.disableAddOrUpdateButton}
            isAvailable={!state.submitting && productData}
            totalPrice={getItemTotalPrice}
            productData={productData}
            addToCartPress={_addToCartPress}
            {...getMealPlanProps()}
          />
        </View>
      }
      footerStyle={{ marginBottom: 0 }}
      scrollEnabled={false}
      onBeforeShow={setData}
    >
      <ScrollView
        ref={scrollList}
        showsVerticalScrollIndicator={true}
        dataSet={{ media: ids.scrollWrapper }}
        contentContainerStyle={styles.scrollWrapper}
        scrollEventThrottle={constants.scrollThrottle}
        onContentSizeChange={() => {
          if (lodashIsEmpty(errorMessage)) {
            return scrollList.current?.scrollTo({ y: 0, animated: true });
          }
        }}
      >
        {isProductLoading ? (
          <Fragment>
            <ProductLoader />
            <View style={[styles.storeCloseContainer, { top: globalSpacing }]}>
              <CloseButton onPress={_onPressClosed} />
            </View>
          </Fragment>
        ) : (
          <Fragment>
            {_renderListItem}
            {availableExtras?.map(_extras)}
          </Fragment>
        )}
      </ScrollView>
    </ModalPrompt>
  );
};

const { ids, styles } = StyleSheet.create({
  mobileMainWrapper: {
    width: '100vw',
    height: '100dvh',
    borderRadius: 10,
  },
  contentWrapper: {
    height: 450,
  },
  headerStyle: {
    marginRight: 5,
  },
  listContainer: {
    height: 430,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    overflow: 'hidden',
  },
  listContainerLandscape: {
    height: 150,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    overflow: 'hidden',
  },
  scrollWrapper: {
    ...ThemeStyle.pageBottomSpace,
    ...ThemeStyle.pageBackground,
    [`@media ${BreakPoints.xs}`]: {
      width: '100%',
    },
    [`@media ${BreakPoints.sm}`]: {
      width: '100%',
    },
    [`@media ${BreakPoints.md}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 'auto',
    },
  },

  childContentContainerStyle: {
    paddingHorizontal: 0,
    flex: 2,
  },
  closeButtonContainer: {
    position: 'absolute',
    alignSelf: 'flex-end',
    ...ThemeStyle.spacingTopSmall,
    ...ThemeStyle.spacingRightSmall,
  },
  descriptionContainer: {
    flex: 1,
    ...ThemeStyle.spacingTopSmall,
    ...ThemeStyle.pageHorizontalSpacing,
  },
  descriptionTxt: { fontSize: 14 },
  description_tags: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.pageVerticalSpacingSmall,
  },
  errorTags: {
    maxWidth: 550,
    ...ThemeStyle.spacingLeft,
    ...ThemeStyle.spacingRight,
    ...ThemeStyle.pageVerticalSpacingSmall,
  },
  img_container: {
    width: '100%',
    height: 250,
    alignItems: 'center',
  },
  img_size: { width: '100%', height: '100%' },
  img_border: { borderRadius: 10 },
  controlContainer: {
    flexDirection: 'row',
  },
  addToCartButton: {
    height: 40,
    borderRadius: 120,
    borderColor: ThemeColors.green,
    borderWidth: 1,
    backgroundColor: ThemeColors.green,
    alignItems: 'center',
    justifyContent: 'center',
  },
  extrasContainer: {
    [`@media ${BreakPoints.xs}`]: {
      width: '100%',
    },
    [`@media ${BreakPoints.sm}`]: {
      width: '100%',
    },
    [`@media ${BreakPoints.md}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 'auto',
    },
  },
  storeHeader: {
    ...ThemeStyle.flexDirectionRowCenter,
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.spacingTopMedium,
    ...ThemeStyle.spacingBottomMedium,
    columnGap: 16,
  },
  storeImage: {
    width: 45,
    height: 45,
    borderRadius: 45,
  },
  storeCloseContainer: {
    position: 'absolute',
    right: 20,
  },
});

export default memo(Item);
