import { useEffect, useRef, useState } from 'react';
import { Alert, Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';

import { MODALPROMPT } from '../../Components/Web/Modal/ModalPrompt/config';
import useModalPrompt from '../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import constants from '../../Config/constants';
import { numberToArray } from '../../Helper';
import OrderHelper from '../../Helper/Order';
import useSetSpecificUserData from '../../Hooks/useSetSpecificUserData';
import useToast from '../../Hooks/useToast';
import routeList from '../../Routes/list';
import { useIHaveArrivedMutation } from '../../RTK/api/order';
import { pendingCartSelector } from '../../RTK/cart/selectors';
import { userSelector } from '../../RTK/user/selectors';
import { setHasOrderBanner } from '../../RTK/utility';
import { successfulOrderSelector } from '../../RTK/utility/selectors';
import Service from '../../Service';
import userApi from '../../Service/api/user';
import Share from '../../Service/Share';

let shouldFetchCart = false; // must be outside of life cycle
const loaderData = { result: numberToArray(5).map(() => ({ loading: true })) };
function useOrderController(enabled = true) {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const arrivedRef = useRef({});
  const route = useRoute();
  const toast = useToast();
  const { setCartData } = useSetSpecificUserData();
  const { showModalPrompt } = useModalPrompt();
  const [iHaveArrived, { isLoading: isArriving }] = useIHaveArrivedMutation();

  const hasOrderBanner = useSelector((state) => state.utility.hasOrderBanner);
  const successfulOrder = useSelector(successfulOrderSelector);
  const userId = useSelector((state) => userSelector(state)?.id);
  const pendingCart = useSelector(pendingCartSelector);
  const [highlightedOrderId, setHighlightedOrderId] = useState('');
  const [isRefreshing, setRefreshing] = useState(false);
  const [showDetailsOrderId, setShowDetailsOrderId] = useState('');
  const [isPollingEnabled, setPollingEnabled] = useState(true);

  const refreshOrdersParams = lodashGet(route, 'params.refreshOrders');
  const highlightOrderIdParams = lodashGet(route, 'params.highlightOrderId');

  const {
    data: orderData,
    isFetching: isOrderLoading,
    refetch: refreshOrders,
  } = useQuery({
    enabled: Boolean(userId) && isPollingEnabled && enabled,
    initialData: loaderData,
    queryFn: userApi.getNewOrders,
    queryKey: [userId, 'ongoing-orders'],
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const err = orderData?.message;
  const error = err ? Service.handleErrorMessage(err) : '';
  const data = lodashGet(orderData, 'result') || [];
  const filteredData = OrderHelper.filterData(data, successfulOrder);

  // for handling url parameter related to order page
  useEffect(() => {
    if (refreshOrdersParams == true) {
      // if has refresh order params, boolean or string (on web, all url parameter is consider as string)
      if (!isOrderLoading) {
        refreshOrders(); // if not yet loading, refresh the order
      }
      navigation.setParams({ refreshOrders: undefined }); // clear on url parameter
    }
    if (highlightOrderIdParams) {
      // if has highlight order id params
      setHighlightedOrderId(highlightOrderIdParams); // set the highlight order id of url parameter to local state
      navigation.setParams({ highlightOrderId: undefined }); // clear on url parameter
    }
  }, [refreshOrdersParams, highlightOrderIdParams]);

  // for after fetching orders
  useEffect(() => {
    if (!isOrderLoading) {
      // if order is not loading anymore
      if (lodashIsEmpty(filteredData)) {
        // if no filteredData, disable polling
        dispatch(setHasOrderBanner(false));
        setPollingEnabled(false);
      } else {
        // if has filteredData
        // #region condition for fetching cart if before the first order is pending and status gets updated
        if (filteredData?.[0]?.status === constants.ORDER_STATUS.PENDING) {
          // add flag that it should fetch cart after the first order status is not pending
          shouldFetchCart = true;
        } else if (shouldFetchCart && !lodashIsEmpty(pendingCart)) {
          // previous order is pending and now the status gets updated, and has pending cart
          shouldFetchCart = false; // reset to false
          setCartData(); // refetch cart
        }
        // #endregion
        if (!hasOrderBanner) {
          // if order banner is not showing yet, flag to show order banner
          if (enabled) {
            dispatch(setHasOrderBanner(true));
          }
          setPollingEnabled(true);
        }
      }
      if (isRefreshing) {
        // if currently refreshing, remove flag
        setRefreshing(false);
      }
    }
  }, [isOrderLoading]);

  const _toggleShowDetails = (data) => () => {
    const shouldRemove = showDetailsOrderId === data.id;
    setShowDetailsOrderId(shouldRemove ? '' : data.id);
  };

  const _onRefresh = () => {
    setHighlightedOrderId('');
    setRefreshing(true);
    refreshOrders();
  };

  const _onShare = (shareableLink) => (e) => {
    if (constants.isWeb) {
      e.preventDefault();
      toast.show(
        'Order copied! Spread the word and share it with your friends.'
      );
    }
    Share.shareLink({
      title: 'Share this with friends!',
      message:
        "Here's the link to track your order. Spread the word and share it with your friends.",
      linkToShare: shareableLink,
    });
  };

  const _onIhaveArrivedPress = (data) => (e) => {
    const title = 'Confirm Arrival for Pickup';
    const message =
      'This will alert our team that you have arrived for your curbside pickup. Continue?';
    const buttons = [
      { text: 'Abort' },
      {
        status: 'success',
        text: 'Confirm',
        onPress: async () => {
          arrivedRef.current[data.id] = true; // set as arrived when clicked confirm
          const result = await iHaveArrived({ payload: { id: data.id } });
          if (result.error) {
            arrivedRef.current[data.id] = false; // remove in case failed api call
            toast.show(
              'Failed to notify the pickup team, please try again later',
              false
            );
          } else {
            toast.show('You have alerted the merchant of your arrival');
          }
        },
      },
    ];
    if (constants.isWeb) {
      e.preventDefault();
      showModalPrompt(MODALPROMPT.prompt, {
        title,
        message,
        buttons,
      });
    } else {
      Alert.alert(title, message, buttons);
    }
  };

  const _onStoreNamePressed = (data) => () => {
    if (constants.isWeb) {
      navigation.navigate(routeList.HOME, {
        screen: 'MainMenu',
        params: {
          screen: routeList.STORE,
          params: { id: data.store_details.id },
        },
      });
    } else {
      navigation.navigate(routeList.STORE, { id: data.store_details.id });
    }
  };

  const _onViewOrderPressed = (data) => () => {
    Linking.openURL(data.delivery_tracking_link);
  };

  return {
    arrivedRef,
    data: filteredData,
    error,
    highlightedOrderId,
    isArriving,
    isRefreshing,
    showDetailsOrderId,
    onRefresh: _onRefresh,
    onShare: _onShare,
    onIhaveArrivedPress: _onIhaveArrivedPress,
    onStoreNamePressed: _onStoreNamePressed,
    onViewOrderPressed: _onViewOrderPressed,
    toggleShowDetails: _toggleShowDetails,
  };
}

export default useOrderController;
