import React from 'react';

import ModalPrompt from '../../Web/Modal/ModalPrompt/ModalPrompt';

import Render from './Render';
import { SheetProps } from './types';

type Props = SheetProps & {
  modalId: string;
};
const QRPreview = ({ modalId, payload }: Props) => {
  return (
    <ModalPrompt id={modalId} title="QR Preview" hasHeader hasHeaderLine>
      <Render {...payload} />
    </ModalPrompt>
  );
};

export default QRPreview;
