import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  StackActions,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useToast } from 'react-native-toast-notifications';
import lodashIsEmpty from 'lodash/isEmpty';
//CONFIG
import constants from '../../../Config/constants';
import messages from '../../../Config/messages';

import routeList from '../../../Routes/list';

import { isUserDetailsIncomplete } from '../../../Helper';
import CheckoutHelper from '../../../Helper/Checkout';
import { getCartTerm } from '../../../Helper/RemoteConfig';

import { checkout } from '../../../RTK/defaultValues';
import {
  setAddressSelection,
  updateCheckoutDetails,
} from '../../../RTK/checkout';
import {
  checkoutFlagSelector,
  checkoutStoreInfoSelector,
} from '../../../RTK/checkout/selectors';
import {
  setCheckoutData,
  setAddressSelection as setMealPlanAddressSelection,
} from '../../../RTK/mealPlan';
import { shopRawSelector } from '../../../RTK/shop/selectors';
import { isLoggedInSelector } from '../../../RTK/user/selectors';
import { updateInformation } from '../../../RTK/user';

import useAddress from '../../../Hooks/useAddress';
import useCart from '../../../Hooks/useCart';
import useNormalCheckout from './useNormalCheckout';
import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';

import { AddressItemDetails } from '../../../Types';
import { CartDetails } from '../../../Types';
import useCancellableRequest from '../../../Hooks/useCancellableRequest';

import checkoutApi from '../../../Service/api/checkout';
import userApi from '../../../Service/api/user';
import useError from '../../../Hooks/useError';

const { ASAP } = CheckoutHelper;

const { ORDER_TYPES } = constants;

const useCheckoutWeb = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const checkoutStoreInfo = useSelector(checkoutStoreInfoSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const shopRawData = useSelector(shopRawSelector);
  const mealPlanData = useSelector((state: any) => state.mealPlan.data);
  const isFromAddressCheckout = useSelector(
    (state) => checkoutFlagSelector(state).addressSelection
  );
  const route = useRoute();
  const toast = useToast();
  const { createRequest } = useCancellableRequest();

  const isMealPlan = Boolean(mealPlanData.type);

  const conditionalProps: any = isMealPlan
    ? {
        mealPlanType: mealPlanData.type,
        mealPlanItems: mealPlanData.items,
        mealPlanCheckoutData: mealPlanData.checkoutData,
        mealPlanReduxDispatch: ({ keyToUpdate, keyValue }) =>
          dispatch(
            setCheckoutData({
              key: keyToUpdate,
              data: keyValue,
            })
          ),
      }
    : {};
  const checkoutHooks = useNormalCheckout(conditionalProps);
  const { showModalPrompt } = useModalPrompt();

  ///PLACING ORDER STATE
  const [placingOrder, setPlacingOrder] = useState(false);

  const [cartItemUpdatingById, setCartItemUpdatingById] = useState<string>('');

  const { setActiveAddress } = useAddress();
  const { addOrUpdateCart, removeThisProductOnCart } = useCart();

  const { ErrorCartOrCheckoutHandler } = useError(checkoutStoreInfo.store_id);

  const deliverySchedule =
    checkoutHooks.storeCheckoutData[checkout.keys.DELIVERY_SCHEDULE];
  const orderType = checkoutHooks.storeCheckoutData[checkout.keys.ORDER_TYPE];
  const isScheduleAsap = deliverySchedule?.value === ASAP.value;
  const isAcceptingAdvanceOrder = checkoutHooks?.storeHours?.length > 1;

  //toggle schedule list modal
  const _toggleScheduleListModal = async (error: any) => {
    if (isAcceptingAdvanceOrder) {
      const result = await showModalPrompt(
        MODALPROMPT.changeDateAndTimeSchedule,
        {
          value: deliverySchedule,
          modalTitle: 'Later (Schedule Order Later)',
          error,
        }
      );
      if (!lodashIsEmpty(result?.label)) {
        _onProceedChangeSchedule(result);
      }
    } else {
      // open modal for changing time for today
      const isPickup = orderType?.value?.includes(ORDER_TYPES.PICKUP);
      const result = await showModalPrompt(MODALPROMPT.changeTimeSchedule, {
        title: `Select ${isPickup ? 'Pickup' : 'Dispatch'} Time`,
        modalTitle: 'Later (Schedule Order Later)',
        value: deliverySchedule,
        orderTimeSlot: checkoutHooks.storeHours,
        error,
      });
      if (!lodashIsEmpty(result?.label)) {
        _onProceedChangeSchedule(result);
      }
    }
  };

  const _toggleViewAddressModal = () => {
    if (isMealPlan) {
      dispatch(setMealPlanAddressSelection(true));
    } else {
      dispatch(setAddressSelection(checkoutStoreInfo.store_id));
    }
    showModalPrompt(MODALPROMPT.listAddress, {
      storeId: checkoutStoreInfo.store_id,
      navigation: navigation,
      route: route,
    });
  };

  const _toggleLocateAddressModal = async (item: AddressItemDetails | any) => {
    if (isMealPlan) {
      dispatch(setMealPlanAddressSelection(true));
    } else {
      dispatch(setAddressSelection(checkoutStoreInfo.store_id));
    }
    const result: any = await showModalPrompt(MODALPROMPT.addressDetails, {
      status: 'edit',
      selectedAddress: {
        ...item,
        index: item.id,
        latitude: isLoggedIn
          ? item?.geo_json_point.coordinates[1]
          : item?.latitude,
        longitude: isLoggedIn
          ? item?.geo_json_point.coordinates[0]
          : item?.longitude,
      },
      navigation: navigation,
      route: route,
    });
    /*This is for only checkout
    // if updating address details is active, should call the api to set it as active
    */
    if (result?.active) {
      setActiveAddress(result);
    }
  };

  const _toggleConfirmDeleteModal = (item, type) => () => {
    showModalPrompt(MODALPROMPT.prompt, {
      title: 'Are you sure?',
      message: `Do you really want to remove ${
        item?.product_name || item?.name
      } from your ${getCartTerm()}?`,
      buttons: [
        {
          size: 'small',
          status: 'danger',
          text: 'Yes',
          onPress: () => _removeThisProductOnCart(item),
        },
        {
          size: 'small',
          text: 'No',
        },
      ],
    });
  };
  const _removeThisProductOnCart = async (item: CartDetails) => {
    setCartItemUpdatingById(item.cart_details_id);
    removeThisProductOnCart({
      item: item,
      storeId: item?.store_id,
      onError: () => setCartItemUpdatingById(''),
      onSuccess: (isCartNowEmpty) => {
        setCartItemUpdatingById('');
        if (isCartNowEmpty) {
          navigation.dispatch(
            StackActions.replace(routeList.HOME, {
              screen: 'MainMenu',
              params: {
                screen: routeList.STORE,
                params: {
                  id: checkoutStoreInfo.store_id,
                  orderForLater: shopRawData?.orderForLater,
                },
              },
            })
          );
        }
      },
    });
  };

  const _backToHome = () => {
    //setError({ errorType: '', title: '', message: '', unavailableItems: [] });
    navigation.dispatch(
      StackActions.replace(routeList.HOME, {
        screen: 'MainMenu',
        params: {
          screen: routeList.HOME_TAB,
        },
      })
    );
  };
  const _onUpdateQuantity = (item) => async () => {
    setCartItemUpdatingById(item.cart_details_id);
    addOrUpdateCart({
      from: 'Checkout Page',
      item: { ...item, id: item.item_id, quantity: item.quantity },
      orderType: item.order_type,
      store: { id: item.store_id, name: item.store_name },
      preOrderDate: isScheduleAsap ? undefined : deliverySchedule, //it has value of asap, if delivery schedule set to asap
      onCancel: () => setCartItemUpdatingById(''),
      onError: (error) => {
        setCartItemUpdatingById('');
        ErrorCartOrCheckoutHandler({
          error: error,
          isAcceptingAdvanceOrder: true,
        });
      },
      onSuccess: () => setCartItemUpdatingById(''),
    });
  };

  const _onPressActiveAddress = (address) => setActiveAddress(address);

  const _onProceedChangeSchedule = (whenFilter) => {
    _onDeliveryScheduleChange(whenFilter);
    toast.show(
      `${messages.CHANGE_SCHEDULE} ${whenFilter.label || 'ASAP'}.`,
      constants.SUCCESS_CONFIG
    );
  };

  const _onThirdPartyPickupPress = (data) => {
    _updateCheckoutReduxState(checkout.keys.THIRD_PARTY_PICKUP, data);
  };
  //Set the last address used
  const _onLastUsedAddressPress = (address) => {
    _updateCheckoutReduxState(checkout.keys.DELIVERY_ADDRESS, address);
  };
  ///TO CHANGE THE DELIVERY SCHEDULE
  const _onDeliveryScheduleChange = (data) => {
    _updateCheckoutReduxState(checkout.keys.DELIVERY_SCHEDULE, data);
  };

  //To change payment mode
  const _onPaymentOptionChanged = (payment) => {
    if (isMealPlan) {
      _updateCheckoutReduxState(checkout.keys.PAYMENT_OPTIONS, payment);
    } else {
      if (
        checkoutHooks.storeCheckoutData[checkout.keys.VEHICLE_OPTIONS] ==
        undefined
      ) {
        alert('Please Select Vehicle Options');
        return;
      }
      _updateCheckoutReduxState(checkout.keys.PAYMENT_OPTIONS, payment);
    }
  };
  //To change the vehicle for delivery mode
  const _onVehicleOptionChanged = (vehicle) => {
    _updateCheckoutReduxState(checkout.keys.VEHICLE_OPTIONS, vehicle);
  };
  const _onVehicleInformationChange = (data) => {
    _updateCheckoutReduxState(checkout.keys.VEHICLE_INFORMATION, data);
  };
  //To change the order type
  const _onOrderTypeChange = (data) => {
    _updateCheckoutReduxState(checkout.keys.ORDER_TYPE, data);
  };

  const _updateCheckoutReduxState = (key, value) => {
    if (isMealPlan) {
      ///implemented the strict equality operator after the page has been reloaded the value of isMealPlan become string so it always return true
      const data = {
        key: key,
        data: value,
      };
      dispatch(setCheckoutData(data));
    } else {
      const data = {
        store_id: checkoutStoreInfo.store_id,
        keyToUpdate: key,
        keyValue: value,
      };

      dispatch(updateCheckoutDetails(data));
    }
  };

  //callback when placing order got error
  const _onPlaceOrderError = (err) => {
    if (err.errorType === constants.ERROR_TYPE.UNAVAILABLE) {
      _toggleScheduleListModal(err);
      return;
    } else {
      ErrorCartOrCheckoutHandler({ error: err });
    }
  };

  const _onUserDetailsChange = (key, value) => {
    _updateCheckoutReduxState(checkout.keys.USER_DETAILS, {
      ...checkoutHooks.storeCheckoutData[checkout.keys.USER_DETAILS],
      [key]: value,
    });
  };

  //onRequestCheckout MealPLan

  const _onRequestCheckouMealPlan = async () => {
    const {
      [checkout.keys.DELIVERY_ADDRESS]: selectedAddress,
      [checkout.keys.PAYMENT_METHOD]: paymentMethod,
      [checkout.keys.PAYMENT_OPTIONS]: poption,
      [checkout.keys.VEHICLE_OPTIONS]: voption,
      [checkout.keys.PROMO_CODE]: promotion,
      [checkout.keys.USER_DETAILS]: userDetails,
    } = mealPlanData.checkoutData;
    //@ts-ignore: JS code error
    if (isUserDetailsIncomplete(checkoutHooks.user)) {
      // user doesn't setup his user details yet, so on the meal plan checkout
      // we update user details on the api using it before making api call for placing the order
      const updateUserPayload = {
        first_name: userDetails.firstName,
        last_name: userDetails.lastName,
        phone_number: userDetails.phone,
      };
      await userApi.update(updateUserPayload);
      await dispatch(updateInformation(userDetails));
    }
    const payload = {
      address_id: selectedAddress.id,
      delivery_option: {
        delivery_payment_method: poption.value,
        delivery_vehicle: voption.value,
      },
      items: mealPlanData.items.map((item) => ({
        id: item.id,
        quantity: item.quantity,
        extras: item?.extras?.map((ie) => ie.id) || [],
      })),
      order_type: constants.ORDER_TYPES.DELIVERY,
      payment_method: paymentMethod?.method,
      promo_code: promotion?.code?.toUpperCase(),
      store_id: checkoutStoreInfo.store_id,
    };

    return createRequest(checkoutApi.placeOrderMealPlan, payload);
  };

  //toggle for place order modal
  const _togglePlaceOrder = () => setPlacingOrder(!placingOrder);

  ///proceed to Checkout mealplan

  const _procedToCheckoutMealPlan = async () => {
    const requiredData = [
      { key: 'DELIVERY_ADDRESS', label: 'Address' },
      { key: 'USER_DETAILS', label: 'User details' },
      {
        key: 'PAYMENT_OPTIONS',
        message: 'Please choose a delivery payment option.',
      },
      { key: 'VEHICLE_OPTIONS', label: 'Delivery Vehicle Option' },
      { key: 'PAYMENT_METHOD', message: messages.PAYMENT_METHOD.required },
    ];
    // Open modal if sucess validation
    const isOk = await checkoutHooks.validateForm(requiredData);
    if (isOk) {
      _togglePlaceOrder();
    }
  };

  ///Proceed to checkout button press
  const _proceedCheckout = async () => {
    const { isClosed, isClosing, ok } = await checkoutHooks.isCanPlaceOrder(
      checkoutStoreInfo
    );

    if (isClosed && isAcceptingAdvanceOrder) {
      showModalPrompt(MODALPROMPT.changeDateAndTimeSchedule, {
        errorType: constants.ERROR_TYPE.GENERIC,
        title: messages.STORE_CLOSED_MOBILE.title,
        message: `${messages.STORE_CLOSED_MOBILE.message} ${messages.STORE_CLOSED_MOBILE.messageExtension}`,
      });
    } else if (isClosing && isAcceptingAdvanceOrder) {
      showModalPrompt(MODALPROMPT.changeDateAndTimeSchedule, {
        errorType: constants.ERROR_TYPE.GENERIC,
        title: messages.STORE_CLOSING.title,
        message: `${messages.STORE_CLOSING.message} ${messages.STORE_CLOSING.messageExtension}`,
      });
    } else if (isClosed && !isAcceptingAdvanceOrder) {
      showModalPrompt(MODALPROMPT.errorModal, {
        title: messages.STORE_CLOSED_MOBILE.title,
        message: messages.STORE_CLOSED_MOBILE.message,
      });
    } else if (isClosing && !isAcceptingAdvanceOrder) {
      showModalPrompt(MODALPROMPT.errorModal, {
        title: messages.STORE_CLOSING.title,

        message: messages.STORE_CLOSING.message,
      });
    } else if (ok) {
      _togglePlaceOrder();
    }
  };

  return {
    ...checkoutHooks,
    ASAP,
    deliverySchedule,
    isAcceptingAdvanceOrder,
    isMealPlan,
    cartItemUpdatingById,
    placingOrder,
    backToHome: _backToHome,
    onDeliveryScheduleChange: _onDeliveryScheduleChange,
    onLastUsedAddressPress: _onLastUsedAddressPress,
    onOrderTypeChange: _onOrderTypeChange,
    onPaymentOptionChanged: _onPaymentOptionChanged,
    onPlaceOrderError: _onPlaceOrderError,
    onPressActiveAddress: _onPressActiveAddress,
    onRequestCheckouMealPlan: _onRequestCheckouMealPlan,
    onThirdPartyPickupPress: _onThirdPartyPickupPress,
    onUpdateQuantity: _onUpdateQuantity,
    onUserDetailsChange: _onUserDetailsChange,
    onVehicleInformationChange: _onVehicleInformationChange,
    onVehicleOptionChanged: _onVehicleOptionChanged,
    proceedCheckout: _proceedCheckout,
    proceedToCheckoutMealPlan: _procedToCheckoutMealPlan,
    toggleConfirmDeleteModal: _toggleConfirmDeleteModal,
    toggleLocateAddressModal: _toggleLocateAddressModal,
    togglePlaceOrder: _togglePlaceOrder,
    toggleScheduleListModal: _toggleScheduleListModal,
    toggleViewAddressModal: _toggleViewAddressModal,
  };
};

export default useCheckoutWeb;
