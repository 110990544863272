import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import constants from '../Config/constants';
import { mealPlan } from '../RTK/defaultValues';

// this function return array of date string of 3 or 5 depending of meal plan type
function generateSchedule(type) {
  dayjs.extend(weekday); // extend dayjs to have .weekday method
  const today = dayjs();
  const isTodaySunday = today.day() === 0;
  const nextMonday = isTodaySunday ? today.add(1, 'day') : dayjs().weekday(8); // Get next Monday (or tomorrow if today is Sunday)
  const dateFormat = `${constants.DATE_ONLY_DISPLAY_FORMAT}, YYYY`;
  if (type === mealPlan.type.threeDay) {
    return [
      nextMonday.format(dateFormat),
      nextMonday.add(2, 'day').format(dateFormat),
      nextMonday.add(4, 'day').format(dateFormat),
    ];
  } else if (type === mealPlan.type.fiveDay) {
    return [
      nextMonday.format(dateFormat),
      nextMonday.add(1, 'day').format(dateFormat),
      nextMonday.add(2, 'day').format(dateFormat),
      nextMonday.add(3, 'day').format(dateFormat),
      nextMonday.add(4, 'day').format(dateFormat),
    ];
  } else {
    return [];
  }
}

export default {
  generateSchedule,
};
