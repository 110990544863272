import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Radio, RadioGroup, Text } from '@ui-kitten/components';

import Button from '../../../Button';

import ThemeStyle from '../../../../Theme/styles';

import ModalPrompt from './ModalPrompt';

import { MODALPROMPT } from './config';
import { useDispatch, useSelector } from 'react-redux';
import { changeEventFilter } from '../../../../RTK/filter';
import { ModalManager } from '../hooks/useModalManager';
import { eventFilterSelector } from '../../../../RTK/filter/selectors';
import constants from '../../../../Config/constants';

const { eventFilterData } = constants;

const EventFilter = () => {
  const dispatch = useDispatch();
  const eventFilter = useSelector(eventFilterSelector);
  const [selectedIndex, setSelectedIndex] = useState(
    eventFilterData.findIndex((item) => item.label === eventFilter.label)
  );

  const _onProceed = () => {
    dispatch(changeEventFilter(eventFilterData[selectedIndex]));
    ModalManager.hide(MODALPROMPT.eventFilter);
  };
  return (
    <ModalPrompt
      id={MODALPROMPT.eventFilter}
      baseWidth={400}
      title={'Event Date'}
      hasHeader
    >
      <View style={styles.singleContentContainer}>
        <RadioGroup
          selectedIndex={selectedIndex}
          onChange={(index) => setSelectedIndex(index)}
        >
          {eventFilterData.map((item, index) => (
            <Radio key={index} status="success">
              {() => (
                <Text style={styles.itemTxt} category="p2">
                  {item.label}
                </Text>
              )}
            </Radio>
          ))}
        </RadioGroup>

        <Button status="success" style={styles.proceedBtn} onPress={_onProceed}>
          {() => (
            <Text category="p2" status="basic" appearance="alternative">
              Update Search
            </Text>
          )}
        </Button>
      </View>
    </ModalPrompt>
  );
};

const styles = StyleSheet.create({
  singleContentContainer: {
    flex: 1,
    paddingVertical: 10,
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  itemTxt: { marginLeft: 20 },
  proceedBtn: {
    ...ThemeStyle.spacingTopMedium,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default EventFilter;
