import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Divider, Icon, Text } from '@ui-kitten/components';
import { SafeAreaView } from 'react-native-safe-area-context';
import lodashIsEmpty from 'lodash/isEmpty';

import Form from '../../Components/FormFields/Form';
import Header from '../../Components/Header';
import ScreenWrapper from '../../Components/ScreenWrapper';
import SubmitButton from '../../Components/FormFields/SubmitButton';
import constants from '../../Config/constants';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

import addEditAddressController from './controller/addEditAddress';
import withController from './controller/withController';

import AddLabelForm from './AddLabelForm';
import GoogleAutoSuggestion from './GoogleAutoSuggestion';
import Map from './Map/Map';

let formInitialValues = {};
let validationSchema = {};
const formDefaultValues = { label: null };

class AddEditAddress extends React.Component {
  constructor(props) {
    super(props);
    if (props.controller.formInitialValues) {
      formInitialValues = props.controller.formInitialValues;
    } else {
      formInitialValues = formDefaultValues;
    }
    validationSchema = props.controller.validationSchema;
  }

  componentDidMount() {
    this.props.controller.askForUserLocation();
  }

  _renderButtonContent = () => {
    const { state } = this.props.controller;
    const { submitting } = state;

    if (submitting) {
      return <ActivityIndicator size="large" color={ThemeColor.white} />;
    } else {
      return (
        <Icon
          name="save-outline"
          fill={ThemeColor.white}
          style={styles.saveIcon}
        />
      );
    }
  };

  render() {
    const { controller } = this.props;
    const {
      autoCompleteRef,
      mapRef,
      pageTitle,
      state,
      onRegionChangeComplete,
      onSearchItemPressed,
      onSubmit,
    } = controller;
    const { selectedAddress, existingError } = state;

    return (
      <ScreenWrapper
        header={
          <Header
            backType="close"
            title={pageTitle}
            alignTitle="left"
            withBorder
          />
        }
        scroll={false}
        barStyle={constants.isIOS ? 'light-content' : 'dark-content'}
        modal
      >
        <Form
          innerRef={controller.formRef}
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={async (...args) => {
            const { center } = await mapRef.current._getMapCamera();
            onSubmit(center, ...args);
          }}
        >
          <Divider style={ThemeStyle.divider} />

          <View style={ThemeStyle.flex2}>
            {/* Search suggestion container */}
            <View style={styles.searchContainer}>
              <GoogleAutoSuggestion
                ref={autoCompleteRef}
                onDone={onSearchItemPressed}
                defaultValue={selectedAddress.address}
              />

              <Divider style={ThemeStyle.divider} />
            </View>

            {/* Map Container */}
            {lodashIsEmpty(selectedAddress) ? (
              <View
                style={[
                  ThemeStyle.flex1,
                  ThemeStyle.justifyContentCenter,
                  ThemeStyle.alignItemsCenter,
                ]}
              >
                <Text category="p2">Search address to show map.</Text>
              </View>
            ) : (
              <View style={ThemeStyle.flex1}>
                <Map
                  ref={mapRef}
                  latitude={selectedAddress.latitude}
                  longitude={selectedAddress.longitude}
                  onRegionChangeComplete={onRegionChangeComplete}
                />
                <View
                  style={{
                    position: 'absolute',
                    right: globalSpacing,
                    bottom: globalSpacing,
                  }}
                >
                  <SafeAreaView edges={['right', 'bottom']}>
                    <SubmitButton
                      size="giant"
                      style={[styles.saveIcon, ThemeStyle.shadow]}
                      customLoader
                    >
                      <View>{this._renderButtonContent()}</View>
                    </SubmitButton>
                  </SafeAreaView>
                </View>
              </View>
            )}
          </View>
        </Form>
      </ScreenWrapper>
    );
  }
}

const styles = StyleSheet.create({
  searchContainer: {
    flex: 1,
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  saveIcon: {
    width: 30,
    height: 30,
  },
});

AddEditAddress = withController(AddEditAddress, addEditAddressController);

export { formDefaultValues };
export default withController(AddEditAddress, addEditAddressController);
