import dayjs from 'dayjs';
import dayjsIsBetween from 'dayjs/plugin/isBetween';
import lodashFilter from 'lodash/filter';
import lodashFind from 'lodash/find';
import lodashFindIndex from 'lodash/findIndex';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../Config/constants';

import DateHelper from './Date';

dayjs.extend(dayjsIsBetween);

const {
  DBDATE_FORMAT,
  ORDER_STATUS,
  PREP_TIME_ALLOWANCE,
  TIME_ONLY_DISPLAY_CAPITAL_FORMAT,
} = constants;

const open = {
  NOW: 'now',
  TODAY: 'today',
};

/**
 * Checks if the payment for a cart is still pending.
 * This is used for checking on:
 * - Add, update or remove cart to not be able to modify the verifying payment cart status
 * - Cart page to notify that the cart is still verifying payment
 * @param {Object} params - The parameters object.
 * @param {string} params.status - The current status of cart.
 * @param {string} params.storeId - The ID of the store.
 * @param {Array} params.successfulOrder - this is the array of successfulOrder on utility redux
 * @returns {boolean} Returns `true` if the payment is pending and the order is flagged as successful, otherwise returns `false`.
 */
const isPaymentPending = ({ status, storeId, successfulOrder }) => {
  const isPendingStatus = status === ORDER_STATUS.PENDING;
  const isOrderSuccess = isSuccessfulOrder({
    storeId,
    successfulOrder,
  });
  return (
    isPendingStatus && // if cart status is pending AND
    isOrderSuccess // it was flag as successful order
  );
};

/**
 * Checks if store id OR/AND order id is on successfulOrder array.
 * - Hiding of abandoned checkout of user (previously proceed to checkout but decided to leave when payment screen appear / 3DS)
 * - Added / updated cart will remove the previously successful order flag of the store (should not proceed on this scenario when isPaymentPending return true)
 * @param {Object} params - The parameters object.
 * @param {string} params.storeId - The ID of the store.
 * @param {string} params.orderId - (Optional) The ID of the order.
 * @param {Array} params.successfulOrder - this is the array of successfulOrder on utility redux
 * @returns {boolean} Returns `true` if the store id OR/AND order id is in successfulOrder
 */
const isSuccessfulOrder = ({ storeId, orderId, successfulOrder }) => {
  const findOnArray = lodashFind(successfulOrder, (e) => {
    if (e.orderId && orderId) {
      return e.id === storeId && e.orderId === orderId;
    } else {
      return e.id === storeId;
    }
  });
  return !lodashIsEmpty(findOnArray);
};

const getStoreCartData = (storeId, cartData) => {
  // storeId must be string, cartData must be array of object. this will return object
  return lodashFind(cartData, { store_id: storeId }) || {};
};

const getStoreCheckoutData = (storeId, checkoutData) => {
  // storeId must be string, checkoutData must be array of object. this will return object
  return lodashFind(checkoutData, { store_id: storeId }) || {};
};

// used in getStoreAvailability sub functions
function isOpen(object, nowOrToday = open.NOW, dateAndTime) {
  // this function will return 11.5 for 11:30 etc, 11.75 for 11:45 and 20.13 for 21:08 etc.
  const sumTime = (accumulator, nextData) =>
    Number(accumulator) + Number(nextData) / 60;

  // if no opening or closing return store as close (return false)
  if (lodashIsEmpty(object.opening) || lodashIsEmpty(object.closing)) {
    return false;
  }

  let currentDayAndTime = dayjs(dateAndTime);
  // pass dateAndTime on today variable to start the date on the date filter ignoring the hours bcs of startOf function
  let today = dayjs(dateAndTime).startOf('day');

  let startHour = object.opening?.split(':').reduce(sumTime);
  let endHour = object.closing?.split(':').reduce(sumTime);

  let startTime = today.add(startHour, 'hour');
  let endTime = today.add(endHour, 'hour');

  if (nowOrToday === open.NOW) {
    // check if store open now
    return currentDayAndTime.isBetween(startTime, endTime);
  } else {
    // check if store open today
    return startTime.isBetween(currentDayAndTime, endTime);
  }
}

// this function is use in getStoreAvailability sub functions
function sortByOpeningTime(a, b) {
  const dateA = new Date(DateHelper.getDayjsAtTime(a.opening).toISOString());
  const dateB = new Date(DateHelper.getDayjsAtTime(b.opening).toISOString());
  return dateA - dateB;
}

// this function is use in getStoreAvailability sub functions
function getFormattedTime(time) {
  return time
    ? DateHelper.getDayjsAtTime(time).format(TIME_ONLY_DISPLAY_CAPITAL_FORMAT)
    : null;
}

// this function is use in getStoreAvailabilityObject
function formatTimeslots(timeslots) {
  return timeslots.map((ts) => ({
    ...ts,
    opening: getFormattedTime(ts.opening),
    closing: getFormattedTime(ts.closing),
  }));
}

// this function is use in getStoreAvailabilityObject
function lookForOtherDaySchedule(todayDayInNumber, dates, offDates) {
  const isNotOffDates = (day, startOn) => {
    day = Number(day);
    // below is handling prevention of going back to previous date/day by adding +7 if less than the current day so it look for next week
    const pastToFutureDay = day <= dayjs(startOn).day() ? day + 7 : day;
    const nextStoreOpenDate = dayjs(startOn).day(pastToFutureDay);
    return {
      ok: !offDates.includes(nextStoreOpenDate.format(DBDATE_FORMAT)),
      date: nextStoreOpenDate.format('MMM DD'),
    };
  };

  const checkOtherDay = (tomorrowToSaturday, newToday, specificDate) => {
    let result = {};

    const loopFunction = (loopIndex) => {
      const nextStoreOpen = lodashFilter(dates, {
        day: loopIndex.toString(),
        is_open: true,
      });
      if (nextStoreOpen.length) {
        const firstTimeslot = nextStoreOpen[0]; // we only need the first one
        const { ok, date } = isNotOffDates(firstTimeslot.day, specificDate);
        if (ok) {
          // if nextStoreOpen is not off dates lets choose this as next opening schedule
          result = JSON.parse(JSON.stringify(firstTimeslot));
          if (specificDate) {
            // if has specificDate defined, lets add the date here from next opening schedule
            result.reOpening = date;
          }
          return true; // stop the loop, we got the next open schedule
        }
      }
    };

    if (tomorrowToSaturday) {
      // look for tomorrow to saturday (0 = sunday, 6 = saturday)
      for (let i = Number(newToday || todayDayInNumber) + 1; i <= 6; i++) {
        const shouldBreak = loopFunction(i);
        if (shouldBreak) {
          break;
        }
      }
    } else {
      for (let i = 0; i <= Number(newToday || todayDayInNumber); i++) {
        const shouldBreak = loopFunction(i);
        if (shouldBreak) {
          break;
        }
      }
    }
    return result;
  };

  // the initial value handle the day for this week
  let otherDaySchedule = checkOtherDay(true);

  // if at this point still no otherDaySchedule
  if (lodashIsEmpty(otherDaySchedule)) {
    // and this handle the day for next week
    otherDaySchedule = checkOtherDay(false);
  }

  // if at this point still no otherDaySchedule
  if (lodashIsEmpty(otherDaySchedule)) {
    // this handle the day for next next week depending when the store is available
    // we loop on the off dates, and check the next day available base on the offDates
    // e.g Dec 1, 2, 3, 5 so 4 is the available date we check if the store hours is available on that day if not
    // we look on the other available day, we use for loop so we can break the loop
    for (let i = 0; i < offDates.length; i++) {
      const currentOffDate = offDates[i];
      const nextDate = dayjs(currentOffDate).add(1, 'day');
      if (!offDates.includes(nextDate.format(DBDATE_FORMAT))) {
        otherDaySchedule = checkOtherDay(
          true,
          nextDate.day(),
          nextDate.toISOString()
        );

        if (lodashIsEmpty(otherDaySchedule)) {
          otherDaySchedule = checkOtherDay(
            false,
            nextDate.day(),
            nextDate.toISOString()
          );
          if (!lodashIsEmpty(otherDaySchedule)) {
            break;
          }
        } else {
          break;
        }
      }
    }
  }
  // otherDaySchedule should not be empty at this point, if still empty there is wrong with the logic above ^
  otherDaySchedule.isOpen = false; // automatic false, since the schedule is from another day so it's not open today yet
  otherDaySchedule.opening = getFormattedTime(otherDaySchedule.opening);
  otherDaySchedule.closing = getFormattedTime(otherDaySchedule.closing);
  return otherDaySchedule;
}

// used in getStoreAvailability function
const getStoreAvailabilityArray = (dates, dateAndTime) => {
  const todayDayInNumber = dayjs(dateAndTime).day().toString();
  let result = JSON.parse(JSON.stringify(dates));

  const todayInDataIndex = lodashFindIndex(dates, { day: todayDayInNumber });
  result[todayInDataIndex].isToday = true;
  result[todayInDataIndex].isOpen = isOpen(
    result[todayInDataIndex],
    open.NOW,
    dateAndTime
  );
  // processing of grouping the multiple timeslot for the day
  const uniqueResult = [];
  for (let i = 0; i < 7; i++) {
    const storeHours = lodashFilter(result, { day: i.toString() });
    if (storeHours.length > 1) {
      const storeObj = { ...storeHours[0], opening: [], closing: [] };
      // sort first
      storeHours.sort(sortByOpeningTime).forEach((sh) => {
        if (sh.is_open) {
          storeObj.opening.push(sh.opening);
          storeObj.closing.push(sh.closing);
        }
      });
      uniqueResult.push(storeObj);
    } else if (storeHours.length === 1) {
      uniqueResult.push(storeHours[0]);
    } else {
      // if store is no schedule for specific day, let just add it as closed
      uniqueResult.push({ day: i.toString(), is_open: false });
    }
  }
  result = uniqueResult;
  // processing for formatting the date
  result = result.map((r) => {
    // format for opening
    if (r.opening) {
      if (Array.isArray(r.opening)) {
        r.opening = r.opening.map((op) => getFormattedTime(op));
      } else {
        r.opening = getFormattedTime(r.opening);
      }
    }
    // format for closing
    if (r.closing) {
      if (Array.isArray(r.closing)) {
        r.closing = r.closing.map((cl) => getFormattedTime(cl));
      } else {
        r.closing = getFormattedTime(r.closing);
      }
    }
    return r;
  });

  return result;
};

// used in getStoreAvailability function
const getStoreAvailabilityObject = (dates, offDates, dateAndTime) => {
  // this function return the available schedule for today and put other timeslots for today in .timeslots
  // and also this function return the next available schedule of the store if store is closed for today
  const todayDayInNumber = dayjs(dateAndTime).day().toString();
  let result = {};

  // get today open timeslots also sort it by opening
  // prettier-ignore
  const timeSlots = lodashFilter(dates, { day: todayDayInNumber, is_open: true }).sort(sortByOpeningTime)
  const tsLength = timeSlots.length;

  if (tsLength) {
    const timeSlotFirst = JSON.parse(JSON.stringify(timeSlots[0]));
    const timeSlotLast = JSON.parse(JSON.stringify(timeSlots[tsLength - 1]));

    // loop through timeslots
    for (let i = 0; i < tsLength; i++) {
      let current = JSON.parse(JSON.stringify(timeSlots[i]));
      current.isOpen = isOpen(current, open.NOW, dateAndTime);
      current.isOpenLater = isOpen(current, open.TODAY);
      current.rawOpening = current.opening; // must be declare before formatting
      current.opening = getFormattedTime(current.opening);
      current.rawClosing = current.closing; // must be declare before formatting
      current.closing = getFormattedTime(current.closing);
      current.timeslots = formatTimeslots(timeSlots);
      if (current.isOpen || current.isOpenLater) {
        // if current timeslot is open, lets make this as result value
        result = current;
        break; // stop loop when result has been set
      } else {
        // lets check if this store is already close for today
        const storeFirstOpenAndLastClose = {
          day: todayDayInNumber,
          opening: timeSlotFirst.opening,
          closing: timeSlotLast.closing,
        };
        const isStillOpenForToday = isOpen(
          storeFirstOpenAndLastClose,
          open.NOW,
          dateAndTime
        );

        if (isStillOpenForToday) {
          const now = dayjs();
          const nextTimeslot = JSON.parse(JSON.stringify(timeSlots[i + 1]));
          const isNotPastTime = !!now.isBefore(
            dayjs(nextTimeslot.opening, 'HH:mm')
          );

          if (isNotPastTime) {
            nextTimeslot.isOpen = isOpen(nextTimeslot, open.NOW, dateAndTime);
            nextTimeslot.rawOpening = nextTimeslot.opening; // must be declare before formatting
            nextTimeslot.opening = getFormattedTime(nextTimeslot.opening);
            nextTimeslot.rawClosing = nextTimeslot.closing; // must be declare before formatting
            nextTimeslot.closing = getFormattedTime(nextTimeslot.closing);
            nextTimeslot.timeslots = formatTimeslots(timeSlots);
            result = nextTimeslot;
            break; // stop loop when result has been set
          }
        } else {
          // closed, get next available day open schedule
          result = {
            ...current,
            nextSchedule: lookForOtherDaySchedule(
              todayDayInNumber,
              dates,
              offDates
            ),
          };
          break; // stop loop when result has been set
        }
      }
    }
  } else {
    // meaning, no open timeslot for today (store closed) so get today closed
    // prettier-ignore
    const todayClosedTimeslot = lodashFilter(dates, { day: todayDayInNumber, is_open: false })

    const todayClosedFirstTimeslot = JSON.parse(
      JSON.stringify(todayClosedTimeslot[0])
    );
    todayClosedFirstTimeslot.isOpen = false;
    todayClosedFirstTimeslot.rawOpening = todayClosedFirstTimeslot.opening; // must be declare before formatting
    todayClosedFirstTimeslot.opening = getFormattedTime(
      todayClosedFirstTimeslot.opening
    );
    todayClosedFirstTimeslot.rawClosing = todayClosedFirstTimeslot.closing; // must be declare before formatting
    todayClosedFirstTimeslot.closing = getFormattedTime(
      todayClosedFirstTimeslot.closing
    );
    todayClosedFirstTimeslot.timeslots = formatTimeslots(todayClosedTimeslot);
    result = {
      ...todayClosedFirstTimeslot,
      nextSchedule: lookForOtherDaySchedule(todayDayInNumber, dates, offDates),
    };
  }

  return result;
};

const getStoreAvailability = (
  dates = [],
  offDates = [],
  returnType = 'object',
  dateAndTime
) => {
  // validation
  if (lodashIsEmpty(dates)) {
    return; // do nothing if empty
  }
  if (!Array.isArray(dates)) {
    throw new Error('Store hours must be array of object');
  } else if (!Array.isArray(offDates)) {
    throw new Error('Store Off Dates must be array of object');
  }
  // functions
  if (returnType === 'object') {
    return getStoreAvailabilityObject(dates, offDates, dateAndTime);
  } else {
    return getStoreAvailabilityArray(dates, dateAndTime);
  }
};

const getStoreHourStatus = ({
  storeHours = [],
  offDates = [],
  prepTime = 0,
  isForAccordion = false,
  dateAndTime,
  isPreOrderDate = false /*
  ///This is only used for store page status
  ///When this is enable status of the store depends on current date and time
  */,
}) => {
  const selectedDateAndTime = !isPreOrderDate ? dateAndTime : undefined;

  const { isOpen, isOpenLater, opening, closing, rawClosing, nextSchedule } =
    getStoreAvailability(storeHours, offDates, 'object', selectedDateAndTime);
  const now = dayjs(selectedDateAndTime);
  const closingWord = 'Closing soon';
  const openWord = isForAccordion || !dayjs().isSame(now) ? 'Open' : 'Open now';
  const closeWord = isForAccordion || !dayjs().isSame(now) ? 'Close' : 'Closed';

  const [closingHour, closingMinute] = rawClosing.split(':');
  const acceptingOrdersUntil = now
    .set('hour', Number(closingHour))
    .set('minute', Number(closingMinute))
    .subtract(prepTime + PREP_TIME_ALLOWANCE, 'minutes');
  const lastOrder = acceptingOrdersUntil.format(
    TIME_ONLY_DISPLAY_CAPITAL_FORMAT
  );
  const isClosing = isOpen ? now.isSameOrAfter(acceptingOrdersUntil) : false;
  const status = isOpen ? (isClosing ? 'warning' : 'success') : 'danger'; // success, warning or danger
  const statusText = isOpen ? (isClosing ? closingWord : openWord) : closeWord; // open, closed or closing
  let statusDescription = ''; // description of status open at or closing at, or when will reopen
  if (
    !lodashIsEmpty(nextSchedule) && // if has nextSchedule AND
    (lodashIsEmpty(nextSchedule.opening) || lodashIsEmpty(nextSchedule.closing)) // it does not have opening or closing
  ) {
    // it means, the store store_hours doesn't contain open schedule for the whole week
    statusDescription = 'Will accept orders upon reopening';
  } else if ((isForAccordion || isClosing) && isOpen) {
    // if used in accordion or store is closing and store is open
    statusDescription = `Accepting orders until ${lastOrder}`;
  } else if (isForAccordion && !isOpen) {
    // if used in accordion and store is close
    const nextReopening = nextSchedule?.opening || opening;
    statusDescription = `Will accept orders at ${nextReopening}`;
  } else if (isOpen) {
    // if store is open
    statusDescription = `Closes at ${closing}`;
  } else if (!lodashIsEmpty(nextSchedule)) {
    // if store is closed today or any other day
    const todayDay = now.day();
    const tomorrowDay = now.add(1, 'day').day();
    const isTomorrow = tomorrowDay == nextSchedule.day; // no need strict comparison, since nextSchedule.day is also '0' - '6'
    const numberDayToWordDay = DateHelper.convertNumberToDay(nextSchedule.day);
    const addNext = todayDay == nextSchedule.day; // no need strict comparison, since nextSchedule.day is also '0' - '6'
    const whenOpen = isTomorrow
      ? 'tomorrow' // if tomorrow, just say it instead of date and time
      : `${addNext ? 'next ' : ''}${numberDayToWordDay}`; // meaning, if todayDay is Wednesday and nextSchedule.day is also Wednesday we gonna concat the word 'next' so it doesn't confuse user
    statusDescription = `Opens ${nextSchedule.reOpening || whenOpen} at ${
      nextSchedule.opening
    }`;
  } else if (!isOpen || isOpenLater) {
    if (isPreOrderDate) {
      const { opening: OpeningPreOrderDate } = getStoreAvailability(
        storeHours,
        offDates,
        'object',
        dateAndTime
      );

      statusDescription = `Opens at ${dayjs(dateAndTime).format(
        constants.DATE_DISPLAY_FORMAT2
      )} ${OpeningPreOrderDate}`;
    } else {
      // if store is close or open later today
      statusDescription = `Opens at ${opening}`;
    }
  }
  return {
    isClosing,
    lastOrder,
    status,
    statusDescription,
    statusText,
  };
};

export default {
  isPaymentPending,
  isSuccessfulOrder,
  getStoreCartData,
  getStoreCheckoutData,
  getStoreAvailability,
  getStoreHourStatus,
};
