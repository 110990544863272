import { Fragment } from 'react';
import { View } from 'react-native';
import { Spinner, Text } from '@ui-kitten/components';
import { useSelector } from 'react-redux';
import StyleSheet from 'react-native-media-query';
import lodashIsEmpty from 'lodash/isEmpty';
import ThemeStyle from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';
import EventCard from './components/EventCard';
import useEventViewModel from './useEventViewModel';
import EventListSection from './components/EventListSection';
import constants from '../../Config/constants';
import { SECTION_TITLE } from '../../Theme/typographyProps';
import EmptyData from '../../Components/EmptyData';
import ScreenWrapper from '../../Components/ScreenWrapper';
import useBuyTicket from './useEventViewModel/useBuyTicket';

import { eventFilterSelector } from '../../RTK/filter/selectors';

const Events = () => {
  const eventFilter = useSelector(eventFilterSelector);
  const { onBuyTickets } = useBuyTicket();
  const { eventsData, isEventDataFetching, isCloseToBottom } =
    useEventViewModel();

  const _renderEmpty = () => {
    return (
      <View style={[ThemeStyle.flex1, ThemeStyle.pageVerticalSpacing]}>
        <EmptyData type="filter" />
      </View>
    );
  };

  return (
    <ScreenWrapper
      scrollEventThrottle={1500}
      onScroll={({ nativeEvent }) => isCloseToBottom(nativeEvent)}
      persistentScrollbar
      showsVerticalScrollIndicator
    >
      <View style={ThemeStyle.flex1}>
        {eventFilter.label === 'All upcoming' && (
          <Fragment>
            <EventListSection
              title="Available this week"
              isDistance={false}
              onBuyTickets={onBuyTickets}
            />
            <EventListSection
              title="Events near you"
              isDistance={true}
              onBuyTickets={onBuyTickets}
            />
          </Fragment>
        )}

        <View style={ThemeStyle.flex1}>
          <View style={ThemeStyle.pageVerticalSpacingMedium}>
            <Text {...(constants.isWeb ? { category: 's1' } : SECTION_TITLE)}>
              All Events
            </Text>
          </View>
          {!lodashIsEmpty(eventsData) ? (
            <View
              dataSet={{ media: ids.listSectionContainer }}
              //@ts-ignore
              style={[styles.listSectionContainer, ThemeStyle.spacingBottom]}
            >
              {eventsData?.map((event, i) => (
                <EventCard
                  key={i}
                  {...event}
                  onBuyTickets={onBuyTickets}
                  fullWidthOnMobile
                />
              ))}
            </View>
          ) : (
            _renderEmpty()
          )}

          {isEventDataFetching && (
            <View
              style={[
                ThemeStyle.flexDirectionRowCenterCenter,
                ThemeStyle.spacingBottom,
              ]}
            >
              <Spinner size="giant" status="success" />
            </View>
          )}
        </View>
      </View>
    </ScreenWrapper>
  );
};

const { ids, styles } = StyleSheet.create({
  listSectionContainer: {
    //@ts-ignore
    display: 'grid',
    //@ts-ignore
    columnGap: 10,
    rowGap: 10,
    //@ts-ignore
    gridColumnGap: 10,
    [`@media ${BreakPoints.md}`]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [`@media ${BreakPoints.lg}`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [`@media ${BreakPoints.xxl}`]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [`@media ${BreakPoints.xxxl}`]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
  },
});

export default Events;
