import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { ActivityIndicator, ScrollView, View, StyleSheet } from 'react-native';
import { UAParser } from 'ua-parser-js';
import { Divider, Icon, Radio, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFilter from 'lodash/filter';

import Button from '../../Button';
import List from '../../List';
import { LoadingIndicator } from '../../Loader';
import MapSnapShotPreview from '../../MapSnapshotPreview';
import { MODALPROMPT } from '../Modal/ModalPrompt/config';
import useModalPrompt from '../Modal/ModalPrompt/hooks/useModalPrompt';

import AddressHelper from '../../../Helper/Address';
import StoreHelper from '../../../Helper/Store';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../../Theme/styles';

import savedAddressController from '../../../Screens/Address/controller/savedAddresses';

import ListRow from '../../../Screens/Address/ListRow';
import SavedAddressesEmptyState from '../../../Screens/Address/SavedAddressesEmptyState';

import { isLoggedInSelector } from '../../../RTK/user/selectors';

import { AddressItemDetails } from '../../../Types';

import styles from '../../../Screens/Address/styles';
import { checkout } from '../../../RTK/defaultValues';
import { checkoutFlagSelector } from '../../../RTK/checkout/selectors';
import { mealPlanCheckoutFlagSelector } from '../../../RTK/mealPlan/selectors';
import useMealPlan from '../../../Hooks/useMealPlan';

type AddressSelectorProps = {
  headerText?: string;
  navigation?: any;
  route?: any;
  storeId?: string;
  addNewAddress: () => void;
  onSelectedSavedAddress: (item: AddressItemDetails) => void;
  onSelectedSavedAddressAsEdit: (
    index: number,
    item: AddressItemDetails
  ) => void;
  showNewAddressButton?: boolean;
};
const AddressSelector = ({
  headerText = '',
  navigation,
  route,
  storeId,
  addNewAddress,
  onSelectedSavedAddress,
  onSelectedSavedAddressAsEdit,
  showNewAddressButton = true,
}: AddressSelectorProps) => {
  const { device } = UAParser();
  const { showModalPrompt } = useModalPrompt();
  const {
    canSelectDefault,
    userAddresses,
    state,
    getPrefixLabel,
    removeAddress,
  } = savedAddressController({ navigation, route, selectedAddress: '' });

  const [mapWidth, setMapWidth] = useState<number>();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const normalCheckoutFlag = useSelector(checkoutFlagSelector);
  const mealPlancheckoutFlag = useSelector(mealPlanCheckoutFlagSelector);
  const checkoutData = useSelector((state: any) => state.checkout.checkoutData);
  const mealPlanData = useSelector((state: any) => state.mealPlan.data);
  const { addressSelection: isFromAddressNormalCheckout } = normalCheckoutFlag;
  const isFromAddressMealPlanCheckout = mealPlancheckoutFlag;

  const { isMealPlan } = useMealPlan();

  const isCheckoutPage =
    isFromAddressNormalCheckout || isFromAddressMealPlanCheckout;

  const { deleting, settingDefault } = state;
  const { gettingUserLocation } = state;

  const storeCheckoutData = StoreHelper.getStoreCheckoutData(
    storeId,
    checkoutData
  );

  const location = {
    lat: userAddresses[0]?.geo_json_point.coordinates[1],
    lng: userAddresses[0]?.geo_json_point.coordinates[0],
  };

  const showAddAddressButton =
    (isLoggedIn && showNewAddressButton) ||
    (!isLoggedIn && userAddresses.length === 0);

  const activeAddress = isCheckoutPage
    ? isMealPlan
      ? mealPlanData.checkoutData?.[checkout.keys.DELIVERY_ADDRESS]
      : storeCheckoutData?.[checkout.keys.DELIVERY_ADDRESS]
    : userAddresses[0];

  const isMobileView = device.type === 'mobile';

  const addressList = isCheckoutPage
    ? isMealPlan
      ? lodashFilter(
          userAddresses,
          (item) =>
            item?.id !==
            mealPlanData.checkoutData?.[checkout.keys.DELIVERY_ADDRESS]?.id
        ).map((item) => ({ ...item, active: false }))
      : lodashFilter(
          userAddresses,
          (item) =>
            item?.id !== storeCheckoutData?.[checkout.keys.DELIVERY_ADDRESS]?.id
        ).map((item) => ({ ...item, active: false }))
    : userAddresses;

  const _renderHeader = () => {
    return (
      <View
        onLayout={(e) => {
          setMapWidth(e.nativeEvent.layout.width);
        }}
      >
        <View style={[ThemeStyle.spacingBottomSmall]}>
          <View style={addressSelectorStyle.mapViewStyle}>
            {headerText != '' && (
              <Text style={ThemeStyle.medium}>{headerText}</Text>
            )}
            <MapSnapShotPreview
              markers={[
                {
                  latitude: location.lat,
                  longitude: location.lng,
                },
              ]}
              height={200}
              width={mapWidth}
              zoom={15}
              withRadius={!isMobileView}
            />
          </View>
        </View>

        <View
          style={[!canSelectDefault && ThemeStyle.pageVerticalSpacingMedium]}
        >
          {_renderRow(
            {
              index: 0,
              item: { ...activeAddress, active: true },
            },
            true
          )}
          {isCheckoutPage && (
            <Divider
              style={[
                ThemeStyle.divider,
                ThemeStyle.spacingLeft,
                ThemeStyle.spacingRight,
              ]}
            />
          )}
        </View>
      </View>
    );
  };

  const _renderRow = ({ index, item }, isStandAlone) => {
    if (index == 0 && !isStandAlone && !isCheckoutPage) {
      // don't render the first address in List, because we will call this function
      // stand alone outside List component
      return null;
    }
    const isDeleting = deleting.includes(item.id);

    const title = isLoggedIn ? AddressHelper.getDisplayTitle(item) : item.label;

    const subtitle = isLoggedIn
      ? AddressHelper.getDisplaySubTitle(item)
      : item.formatted_address || item.address;

    const { icon, pack } = getPrefixLabel(title, true);

    const isActive = item.active;
    const showDelete = !item.active && !isCheckoutPage;

    return (
      <Fragment>
        {isDeleting && _renderLoader()}
        <ListRow
          testID={`savedAddress${index}`}
          status={item.active ? 'success' : 'basic'}
          leftAccessory={
            item.active && !isCheckoutPage ? (
              <Radio
                checked={item.active}
                status="success"
                style={[styles.icon, !isMobileView && ThemeStyle.spacingRight]}
              />
            ) : (
              <Icon
                fill={isStandAlone ? ThemeColor.green : ThemeColor.darkGray}
                name={icon}
                pack={pack}
                style={[styles.icon, !isMobileView && ThemeStyle.spacingRight]}
              />
            )
          }
          rightAccessory={
            <View style={addressSelectorStyle.rightAccessoryStyle}>
              <Button
                testID={`editButton${index}`}
                onPress={() => onSelectedSavedAddressAsEdit(index, item)}
                style={{ padding: globalSpacing / 4 }}
                disabled={isDeleting || settingDefault}
                plain
              >
                <Icon
                  name="edit-outline"
                  fill={isActive ? ThemeColor.green : ThemeColor.darkGray}
                  style={styles.icon}
                />
              </Button>

              {showDelete && (
                <Button
                  testID={`removeButton${index}`}
                  onPress={() => {
                    showModalPrompt(MODALPROMPT.prompt, {
                      title: 'Are you sure?',
                      message:
                        'You are trying to remove your saved address do you want to continue?',
                      buttons: [
                        {
                          size: 'small',
                          status: 'danger',
                          text: 'Yes',
                          onPress: () => removeAddress(item),
                        },
                        { size: 'small', text: 'No' },
                      ],
                    });
                  }}
                  style={{ padding: globalSpacing / 4 }}
                  disabled={isDeleting || settingDefault}
                  plain
                >
                  <Icon
                    name="trash-outline"
                    fill={ThemeColor.red}
                    style={styles.icon}
                  />
                </Button>
              )}
            </View>
          }
          title={title}
          titleLines={1}
          subTitle={subtitle}
          onPress={() => onSelectedSavedAddress(item)}
          disabled={isDeleting || settingDefault}
          addClickableStyle={
            isCheckoutPage || canSelectDefault || !isStandAlone
          }
          plain
        />
      </Fragment>
    );
  };

  const _renderLoader = (size = 'small') => (
    <View style={styles.loaderRowContainer}>
      <ActivityIndicator size={size as any} color={ThemeColor.green} />
    </View>
  );

  if (lodashIsEmpty(userAddresses)) {
    return (
      <Fragment>
        <SavedAddressesEmptyState onAddNewAddress={addNewAddress} />
      </Fragment>
    );
  }

  return (
    <ScrollView style={{}}>
      <View style={[ThemeStyle.flex1]}>
        {settingDefault && _renderLoader('large')}

        <List
          data={addressList}
          extraData={[deleting, settingDefault]}
          renderItem={_renderRow}
          ListHeaderComponent={_renderHeader}
          style={{ maxHeight: 500 }}
          withSeparator
          plain
        />

        {showAddAddressButton && (
          <View style={addressSelectorStyle.addAddressButtonContainer}>
            <Button
              onPress={addNewAddress}
              status="success"
              size="small"
              accessoryLeft={() => (
                <Icon
                  name="plus-outline"
                  fill={ThemeColor.white}
                  style={styles.icon}
                />
              )}
            >
              Add a new address
            </Button>
          </View>
        )}
      </View>
      <LoadingIndicator isOpen={gettingUserLocation} />
    </ScrollView>
  );
};

const addressSelectorStyle = StyleSheet.create({
  mapViewStyle: {
    height: 200,
  },
  icon: {
    width: 20,
    height: 20,
  },
  rightAccessoryStyle: {
    ...ThemeStyle.flexDirectionRowCenterCenter,
    columnGap: 10,
  },
  addAddressButtonContainer: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.pageVerticalSpacingMedium,
    ...ThemeStyle.alignItemsCenter,
  },
});
export default React.memo(AddressSelector);
