import React, { Fragment, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useRoute } from '@react-navigation/native';
import { Text, Icon } from '@ui-kitten/components';
import dayjs from 'dayjs';

import lodashIsUndefined from 'lodash/isUndefined';
import lodashIsEmpty from 'lodash/isEmpty';

//REDUX
import { populatesResultSearched } from '../../RTK/search';

//CONFIG
import constants from '../../Config/constants';

//HELPER
import { capitalize } from '../../Helper';
import { getCartTerm, getIsEnabledEvents } from '../../Helper/RemoteConfig';

//SERVICE
import searchApi from '../../Service/api/search';

//ROUTES
import routeList from '../list';

//HOOKS
import useCancellableRequest from '../../Hooks/useCancellableRequest';
import useSetUserData from '../../Hooks/useSetUserData';

//COMPONENTS
import Button from '../../Components/Button';
import ComingSoon from '../../Components/ComingSoon';
import Header from '../../Components/Header';
import LeftDrawerContent from '../../Components/LeftDrawerContent';

//THEMES
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

//STYLES
import { ids, styles } from './';

//SCREENS
import { Profile } from '../../Screens/Account';
import Basket from '../../Screens/Basket';
import FeedDescription from '../../Screens/Feed/view/Description';
import { myFavorites } from '../../Screens/Home/sections';
import HomeNavigator from '../HomeNavigator';
import SeeAll from '../../Screens/SeeAll';
import Store from '../../Screens/Store';

import { isLoggedInSelector, userSelector } from '../../RTK/user/selectors';

import useModalPrompt from '../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../Components/Web/Modal/ModalPrompt/config';
import { setAddressSelection as setAddressNormalSelection } from '../../RTK/checkout';
import { setAddressSelection as setAddressMealPlanSelection } from '../../RTK/mealPlan';
import { mealPlanCheckoutFlagSelector } from '../../RTK/mealPlan/selectors';
import { checkoutFlagSelector } from '../../RTK/checkout/selectors';
import {
  eventFilterSelector,
  whenFilterSelector,
} from '../../RTK/filter/selectors';

import { logout } from '../../RTK/user';
import TabBarHome from '../../Components/SVG/TabBarHome';
import TabBarBasket from '../../Components/SVG/TabBarBasket';
import TabBarProfile from '../../Components/SVG/TabBarProfile';
import Search from '../../Components/Web/Search';
import SearchEvents from '../../Components/Web/SearchEvents';
import SchedulePicker from '../../Components/SchedulePicker';
import { ModalManager } from '../../Components/Web/Modal/hooks/useModalManager';
import { useMainContext } from '../../MainContext';
import { shopSelector } from '../../RTK/shop/selectors';
import EventNavigator from '../EventNavigator';
import TabBarFeed from '../../Components/SVG/TabBarFeed';
import useMealPlan from '../../Hooks/useMealPlan';

const LeftDrawer = createDrawerNavigator();
const iconSize = 20;
const svgIconSize = 35;
const svgStrokeWidth = 45;
const LeftDrawerScreen = () => {
  const dispatch = useDispatch<any>();
  const route = useRoute<any>();
  const { createRequest } = useCancellableRequest();
  const { showModalPrompt } = useModalPrompt();
  const isSetUserData = useSetUserData();
  const { isRemoteConfigFetching } = useMainContext();
  const shopData = useSelector(shopSelector);
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userDetails = useSelector(userSelector);
  const normalAddressSelection = useSelector(checkoutFlagSelector);
  const mealPlanAddressSelection = useSelector(mealPlanCheckoutFlagSelector);
  const eventFilter = useSelector(eventFilterSelector);
  const whenFilter = useSelector(whenFilterSelector);
  const userFavorites = useSelector(
    (state: any) => state.favourite?.favouriteData
  );
  const deepLinkingData = useSelector(
    // @ts-ignore - must create selector for this
    (state) => state.noSession.deepLinkingData
  );

  const { isMealPlan } = useMealPlan();
  const [dateVisible, setDateVisible] = useState(false);
  const [pageName, setPageName] = useState('');
  const isEventTab = pageName === routeList.EVENT_TAB;
  const activeAddress = userDetails?.addresses.filter((item) => {
    return item.active == true;
  });

  useEffect(() => {
    if (
      !isLoggedIn &&
      lodashIsEmpty(userDetails?.addresses) &&
      lodashIsEmpty(deepLinkingData)
    ) {
      setTimeout(() => ModalManager.show(MODALPROMPT.deliveryAddress), 200);
    }
    if (normalAddressSelection || mealPlanAddressSelection) {
      dispatch(setAddressNormalSelection(false));
      dispatch(setAddressMealPlanSelection(false));
    }
  }, []);

  const searchStoreName = async (text) => {
    const { ok, data } = await createRequest(
      searchApi.getSearchResults,
      text,
      '',
      false
    );
    if (ok) {
      dispatch(populatesResultSearched(data.result));
    }
  };

  const _toggleAddressList = () => {
    ModalManager.show(MODALPROMPT.listAddress);
  };

  const screenOptions = {
    drawerPosition: 'left',
    header: (props) => (
      <View>
        <Header
          {...props}
          isSetUserDone={isSetUserData}
          onTextChanged={searchStoreName}
        />

        <View
          dataSet={{ media: ids.topLayoutWithOutCredentials }}
          style={styles.topLayoutWithOutCredentials}
        >
          {pageName !== routeList.STORE && (
            <View
              dataSet={{ media: ids.searchContainer }}
              style={styles.searchContainer}
            >
              {isEventTab ? <SearchEvents /> : <Search />}
            </View>
          )}

          <View style={styles.addressAndDateFilterContainer}>
            {!isMealPlan && (
              <Button
                onPress={() =>
                  isEventTab
                    ? ModalManager.show(MODALPROMPT.eventFilter)
                    : setDateVisible(true)
                }
                plain
              >
                <View style={[ThemeStyle.flexDirectionRowCenterCenter]}>
                  <Text category="p1" numberOfLines={1}>
                    {isEventTab
                      ? eventFilter.label
                      : lodashIsUndefined(whenFilter)
                      ? 'ASAP'
                      : dayjs(whenFilter?.value).format(
                          constants.DATE_TIME_FILTER_DISPLAY
                        )}
                  </Text>
                  <Icon
                    name="chevron-down"
                    fill={ThemeColor.black}
                    style={[
                      styles.rightIcon,
                      { width: iconSize, height: iconSize },
                    ]}
                  />
                </View>
              </Button>
            )}
            <Button onPress={_toggleAddressList} plain>
              <View
                dataSet={{ media: ids.popContentTriggerButton }}
                style={styles.popContentTriggerButton}
              >
                <Text category="p1" numberOfLines={1}>
                  {!lodashIsEmpty(activeAddress)
                    ? activeAddress[0].formatted_address
                    : 'Address'}
                </Text>
                <Icon
                  name="chevron-down"
                  fill={ThemeColor.black}
                  style={[
                    styles.rightIcon,
                    { width: iconSize, height: iconSize },
                  ]}
                />
              </View>
            </Button>
          </View>
        </View>
      </View>
    ),
    sceneContainerStyle: {
      backgroundColor: '#fff',
    },
  };

  const toggle = () =>
    showModalPrompt(MODALPROMPT.prompt, {
      title: 'Confirm Logout',
      message: `Are you sure you want to logout?`,
      buttons: [
        {
          size: 'small',
          status: 'danger',
          text: 'Yes',
          onPress: confirmLogout,
        },
        { size: 'small', text: 'No' },
      ],
    });

  const confirmLogout = async () => {
    toggle();
    dispatch(logout());
  };

  const leftDrawerContent = [
    {
      text: 'Home',
      value: routeList.HOME_TAB,
      SideBarIcon: ({ color }) => (
        <TabBarHome
          width={svgIconSize}
          height={svgIconSize}
          strokeWidth={svgStrokeWidth}
          color={color}
        />
      ),
    },
    {
      text: 'Orders',
      value: routeList.BASKET_TAB,
      SideBarIcon: ({ color }) => (
        <TabBarBasket
          width={svgIconSize}
          height={svgIconSize}
          strokeWidth={svgStrokeWidth}
          color={color}
        />
      ),
    },
    {
      text: 'Account',
      value: routeList.PROFILE,
      SideBarIcon: ({ color }) => (
        <TabBarProfile
          width={svgIconSize}
          height={svgIconSize}
          strokeWidth={svgStrokeWidth}
          color={color}
        />
      ),
    },
    ...(!lodashIsEmpty(userFavorites)
      ? [
          {
            text: 'Saved Stores',
            value: routeList.SEE_ALL,
            SideBarIcon: ({ color }) => (
              <Icon
                name="heart-outline"
                style={styles.drawerIcon}
                fill={color}
              />
            ),
            params: {
              title: 'Saved Stores',
              ...myFavorites.tag,
            },
          },
        ]
      : []),
    {
      text: 'Events',
      value: routeList.EVENT_TAB,
      SideBarIcon: ({ color }) => (
        <TabBarFeed
          width={svgIconSize}
          height={svgIconSize}
          strokeWidth={svgStrokeWidth}
          color={color}
        />
      ),
    },
    {
      text: 'Sign Out',
      value: routeList.ACCOUNT_TAB,
      SideBarIcon: ({ color }) => (
        <Icon name="log-out-outline" style={styles.drawerIcon} fill={color} />
      ),
      onPressItem: toggle,
    },
  ];

  return (
    <Fragment>
      <LeftDrawer.Navigator
        id="LeftDrawer"
        initialRouteName={routeList.HOME_TAB}
        useLegacyImplementation
        screenListeners={({ route: page }) => setPageName(page.name)}
        screenOptions={screenOptions}
        drawerContent={(props) => (
          <LeftDrawerContent
            data={
              isLoggedIn
                ? leftDrawerContent
                : [leftDrawerContent.find((item) => item.text === 'Home')]
            }
            {...props}
          />
        )}
      >
        <LeftDrawer.Screen
          name={routeList.HOME_TAB}
          component={HomeNavigator}
          options={{
            title: 'Home',
            iconName: 'home',
            unmountOnBlur: true,
          }}
        />
        <LeftDrawer.Screen
          name={routeList.BASKET_TAB}
          component={Basket}
          options={{
            title: capitalize(getCartTerm()),
            iconName: 'shopping-bag',
            unmountOnBlur: true,
          }}
        />

        <LeftDrawer.Screen
          name={routeList.PROFILE}
          component={Profile}
          options={{
            title: 'Account',
            iconName: 'home',
            unmountOnBlur: true,
          }}
        />

        <LeftDrawer.Screen
          name={routeList.FEED_DESCRIPTION}
          component={FeedDescription}
          options={{
            unmountOnBlur: true,
          }}
        />
        <LeftDrawer.Screen
          name={routeList.SEE_ALL}
          options={{ unmountOnBlur: true }}
          component={SeeAll}
        />

        <LeftDrawer.Screen
          name={routeList.STORE}
          options={{
            unmountOnBlur: true,
          }}
          component={Store}
        />
        <LeftDrawer.Screen
          name={routeList.EVENT_TAB}
          options={{
            unmountOnBlur: true,
          }}
          component={
            !isRemoteConfigFetching && getIsEnabledEvents()
              ? EventNavigator
              : ComingSoon
          }
        />
      </LeftDrawer.Navigator>

      {
        //To render again when opening the modal
        //So it will prevent to retain the current state
        //It will depends on the current whenfilter selector
        dateVisible && (
          <SchedulePicker
            storeId={shopData?.id}
            show={dateVisible}
            onCancel={() => setDateVisible(false)}
            onConfirm={(date) => {
              route.params?.onConfirm(date);
              setDateVisible(false);
            }}
          />
        )
      }
    </Fragment>
  );
};

export default LeftDrawerScreen;
