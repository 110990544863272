import { NativeModules, Dimensions, Platform } from 'react-native';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek'; // Plugin to start the week on Monday

dayjs.extend(isoWeek);

const today = dayjs();
// Get the last day of the week (Sunday)
const endOfWeek = today.endOf('isoWeek'); // Gets Sunday as the end of ISO week
// Calculate remaining days
const remainingWeekDays = endOfWeek.diff(today, 'day');

// Get total days in the current month
const currentMonthDays = dayjs().daysInMonth();
// Get total days in the next month
const nextMonthDays = dayjs().add(1, 'month').daysInMonth();

const { StatusBarManager } = NativeModules;
const DEVICE_WIDTH = Dimensions.get('window').width;
const DEVICE_HEIGHT = Dimensions.get('window').height;
const patchRequired = false; // change this if codepush is required the user to update or not
const isIOS = Platform.OS === 'ios';
const isAndroid = Platform.OS === 'android';
const isWeb = Platform.OS === 'web';
const statusBarHeight = StatusBarManager?.HEIGHT || 0; // or just StatusBar.currentHeight for android
const scrollThrottle = 16;
const minuteInterval = 30; // this use for order_time of store and also for schedule time list
const currencySign = '₱';
const DATE_DISPLAY_FORMAT = 'dddd, MMM DD, hh:mm A';
const DATE_DISPLAY_FORMAT2 = 'ddd, MMM. DD, YYYY';
const DATE_ONLY_DISPLAY_FORMAT = 'dddd, MMM DD';
const DATE_TIME_FILTER_DISPLAY = 'MMM D, hh:mm A';
const DBDATE_FORMAT = 'YYYY-MM-DD';
const DBTIME_FORMAT = 'HH:mm';
const TIME_ONLY_DISPLAY_FORMAT = 'hh:mm a';
const TIME_ONLY_DISPLAY_CAPITAL_FORMAT = 'hh:mm A';
const DEFAULT_DISTANCE = 10;
const DISTANCE_MAX = 50;
const DISTANCE_UNIT = 'km';
const listTypes = {
  STORE: 'store',
  ITEM: 'item',
};
const DELIVERY_PAYMENT_OPTION = {
  CASH: 'cash',
  NON_CASH: 'non_cash',
};
const PRODUCT_TYPES = [
  {
    label: 'Food',
    value: 'food',
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-25.png',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-24.png',
  },
  {
    label: 'Non Food',
    value: 'non-food',
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-39.png',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-38.png',
  },
];
const serviceFee = null; // if want to use the service fee change the value to { MIN: number, MAX: number }
const pinUrl = {
  HOME: 'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/pin-home.png',
  RIDER: 'https://cdn-icons-png.flaticon.com/512/2060/2060329.png',
  STORE:
    'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/pin-store.png',
  CURRENT_LOCATION:
    'https://cdn4.iconfinder.com/data/icons/location-and-map-gray-set-1/100/Untitled-1-16-512.png',
};
const PICKUP_DEFAULT_TRANSPARENT_STORE =
  'https://pickupph.s3.ap-southeast-1.amazonaws.com/assets/pickup-default.png?cb=2';

const PICKUP_DEFAULT_GREEN_STORE =
  'https://pickupph.s3.ap-southeast-1.amazonaws.com/assets/pickup-default-green.jpg?cb=3';

const ORDER_STATUS = {
  CONVERTED: 'converted',
  FAILED: 'payment_failed',
  PENDING: 'payment_pending',
  EXPIRED: 'expired',
};
const ORDER_TYPES = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
  THIRD_PARTY_PICKUP: 'third_party_pickup',
  CURBSIDE: 'curbside_pickup',
  MEAL_PLAN: 'meal_plan',
};
const ORDER_TYPE_DATA = [
  {
    testID: 'deliveryType',
    value: ORDER_TYPES.DELIVERY,
    label: 'Delivery',
    apiDataKey: 'is_delivery_active',
  },
  {
    testID: 'pickupType',
    value: ORDER_TYPES.PICKUP,
    label: 'Pickup',
    apiDataKey: 'is_pickup_active',
  },
  {
    testID: 'curbsideType',
    value: ORDER_TYPES.CURBSIDE,
    label: 'Curbside',
    apiDataKey: 'is_curbside_active',
  },
  {
    testID: 'mealPlanType',
    value: ORDER_TYPES.MEAL_PLAN,
    label: 'Meal Plan',
    apiDataKey: 'is_meal_plan_active',
  },
];
const ORDER_TYPE_API_KEYS = ORDER_TYPE_DATA.map((e) => e.apiDataKey);

const LABEL_TYPE = [
  { label: 'Home', icon: 'home' },
  { label: 'Work', icon: 'briefcase' },
  { label: 'Partner', icon: 'heart' },
  { label: 'Other', icon: 'plus', showInput: true },
];

const CHANNEL_IDS = {
  GENERAL: 'pickup_superapp_general_channel',
  ORDERS: 'pickup_superapp_orders_channel',
  REVIEWS: 'pickup_superapp_reviews_channel',
  PAYMENT: 'pickup_superapp_payments_channel',
};

// Feature flags
const USE_PARENT_CATEGORY = false; // to be removed when parent category is completely implemented

const STORE_CLOSING_OR_CLOSED_ERROR = [
  'order_date - store is closed',
  'order_time - store is closed',
];
const ERROR_CONFIG = {
  type: 'danger',
  duration: 2000,
};
const SUCCESS_CONFIG = {
  type: 'success',
  duration: 2000,
};
const ERROR_TYPE = {
  GENERIC: 'GENERIC',
  UNAVAILABLE: 'UNAVAILABLE',
};

const ImageSize = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

const EVENT_SOLDOUT = {
  ALL: 'event_capacity',
  DAY: 'event_day_capacity',
};

const eventFilterData = [
  {
    label: 'All upcoming',
    value: currentMonthDays + nextMonthDays,
  },
  {
    label: 'Today',
    value: undefined,
  },
  {
    label: 'This Week',
    value: remainingWeekDays,
  },
  {
    label: 'This month',
    value: currentMonthDays,
  },
];
// limits and duration
const CODEPUSH_CHECK_FREQUENCY = 5; // in minutes
const PREP_TIME_ALLOWANCE = 5; // minutes
const STORE_INFO_CACHE_DURATION = 8; // in hours
const STORE_MENU_CACHE_DURATION = 5; // in minutes

const META_TITLE = 'Pickup Marketplace';
const META_DESCRIPTION =
  'Welcome to Pickup, your ultimate ordering companion! Experience the ease of browsing local restaurants, creating meal plans, and effortlessly placing and tracking your orders – all with just a few taps.';

const EVENT_RANGE_DATE = dayjs().add(60, 'day').format(DBDATE_FORMAT);
const ACCEPTED_PAYMENT_CARDS = ['mastercard', 'visa'];

export default {
  patchRequired,
  isIOS,
  isAndroid,
  isWeb,
  statusBarHeight,
  scrollThrottle,
  minuteInterval,
  currencySign,
  ImageSize,
  eventFilterData,
  DATE_DISPLAY_FORMAT,
  DATE_DISPLAY_FORMAT2,
  DATE_ONLY_DISPLAY_FORMAT,
  DATE_TIME_FILTER_DISPLAY,
  DBDATE_FORMAT,
  DBTIME_FORMAT,
  TIME_ONLY_DISPLAY_FORMAT,
  TIME_ONLY_DISPLAY_CAPITAL_FORMAT,
  PREP_TIME_ALLOWANCE,
  DELIVERY_PAYMENT_OPTION,
  PRODUCT_TYPES,
  listTypes,
  serviceFee,
  pinUrl,
  remainingWeekDays,
  DEVICE_WIDTH,
  DEVICE_HEIGHT,
  PICKUP_DEFAULT_TRANSPARENT_STORE,
  PICKUP_DEFAULT_GREEN_STORE,
  ORDER_STATUS,
  ORDER_TYPES,
  ORDER_TYPE_DATA,
  ORDER_TYPE_API_KEYS,
  DEFAULT_DISTANCE,
  DISTANCE_MAX,
  DISTANCE_UNIT,
  LABEL_TYPE,
  USE_PARENT_CATEGORY,
  CHANNEL_IDS,
  ERROR_TYPE,
  ERROR_CONFIG,
  SUCCESS_CONFIG,
  CODEPUSH_CHECK_FREQUENCY,
  STORE_INFO_CACHE_DURATION,
  STORE_MENU_CACHE_DURATION,
  STORE_CLOSING_OR_CLOSED_ERROR,
  META_TITLE,
  META_DESCRIPTION,
  EVENT_RANGE_DATE,
  ACCEPTED_PAYMENT_CARDS,
  EVENT_SOLDOUT,
};
