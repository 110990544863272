import React from 'react';
import { Linking } from 'react-native';
import { connect } from 'react-redux';
import { NavigationContainer } from '@react-navigation/native';
import NetInfo from '@react-native-community/netinfo';
import PushNotification from 'react-native-push-notification';
import { SheetProvider } from 'react-native-actions-sheet';

import AppUpdateModal from '../Components/AppUpdateModal';
import ConnectionIssueModal from '../Components/ConnectionIssueModal';
import DatePicker from '../Components/DatePicker';
import Toast from '../Components/Toast';
import SchedulePicker from '../Components/SchedulePicker';
import useSchedulePicker from '../Components/SchedulePicker/index.controller';
import '../Components/Sheets';
import config from '../Config';
import constants from '../Config/constants';
import { getPageName } from '../Helper/Routines';
import withDatePicker from '../Hooks/withDatePicker';
import withController from '../Hooks/withController';
import useDeepLinking from '../Hooks/useDeepLinking';
import routeList from '../Routes/list';
import { isLoggedInSelector } from '../RTK/user/selectors';
import utilsApi from '../Service/api/utils';

import RootNavigator from './RootNavigator';

const webDeeplink = {
  [routeList.HOME]: {
    screens: {
      MainMenu: {
        screens: {
          [routeList.HOME_TAB]: routeList.HOME_TAB,
          [routeList.STORE]: routeList.STORE,
          [routeList.BASKET_TAB]: routeList.BASKET_TAB,
          [routeList.SEE_ALL]: routeList.SEE_ALL,
          [routeList.EVENT_TAB]: {
            screens: {
              [routeList.EVENTS]: routeList.EVENT_TAB,
              [routeList.EVENT_DETAILS]: routeList.EVENT_DETAILS,
            },
          },
          [routeList.PROFILE]: routeList.PROFILE,
        },
      },
    },
  },
  [routeList.CHECKOUT_NAVIGATOR]: {
    screens: {
      [routeList.CHECKOUT]: routeList.CHECKOUT,
      [routeList.CHECKOUT_WEBVIEW]: routeList.CHECKOUT_WEBVIEW,
    },
  },
  [routeList.STORE_REVIEWS]: routeList.STORE_REVIEWS,
  [routeList.ORDER_DETAILS]: routeList.ORDER_DETAILS,
  [routeList.ORDER_RATING]: routeList.ORDER_RATING,
  [routeList.LEGAL_BINDING_CONTRACT]: routeList.LEGAL_BINDING_CONTRACT,
  [routeList.SIGNUP_VERIFICATION_SUCCESS]: 'verify-email',
};

const mobileDeeplink = {
  [routeList.ROOT_TAB]: {
    // root navigator
    screens: {
      [routeList.HOME_TAB]: {
        // home tab because navigator of store is in home navigator
        screens: {
          [routeList.STORE]: routeList.STORE,
        },
      },
      [routeList.BASKET_TAB]: {
        // basket tab because navigator of rating is in basket navigator
        screens: {
          [routeList.ORDER_RATING]: routeList.ORDER_RATING,
        },
      },
      [routeList.EVENT_TAB]: {
        // event tab because navigator
        screens: {
          [routeList.EVENTS]: routeList.EVENT_TAB,
          [routeList.EVENT_DETAILS]: routeList.EVENT_DETAILS,
        },
      },
    },
  },
  // route that can directly access on deeplinking
  [routeList.RESET_PASSWORD]: 'reset',
  [routeList.SIGNUP_VERIFICATION_SUCCESS]: 'verify-email',
};

class Routes extends React.Component {
  state = {
    isConnected: true,
    isInternetReachable: true,
    showAppUpdate: false,
  };

  componentDidMount() {
    this.shouldSetState = true;
    this.netInfoSubscription();
    this.updateChecker();
    if (constants.isWeb && !this.props.isLoggedIn) {
      // on web, we prepare deep linking on page mount
      this.props.deepLink.prepareDeepLinking(window.location.href);
    }
  }

  netInfoSubscription() {
    this.netInfoUnsubscribe?.();
    this.netInfoUnsubscribe = NetInfo.addEventListener(
      ({ isConnected, isInternetReachable }) => {
        this.setState({ isConnected, isInternetReachable });
      }
    );
  }

  async updateChecker() {
    const isUpdated = await utilsApi.isAppUpdated();

    if (this.shouldSetState && !isUpdated) {
      this.toggleAppUpdate();
    }
  }

  toggleAppUpdate = () => {
    this.setState({ showAppUpdate: !this.state.showAppUpdate });
  };

  componentWillUnmount() {
    this.shouldSetState = false;
    this.netInfoUnsubscribe?.();
  }

  _getDeepLinks = () => {
    if (this.props.isLoggedIn || constants.isWeb) {
      if (constants.isWeb) {
        return webDeeplink;
      } else {
        return mobileDeeplink;
      }
    } else {
      return { [routeList.AUTH]: routeList.AUTH };
    }
  };

  _linking = {
    prefixes: ['pickupcustomerapp://', config.WEB],
    config: {
      screens: this._getDeepLinks(),
    },
    getInitialURL: async () => {
      PushNotification.popInitialNotification((notification) => {
        if (!notification) return;
        const { link = null } = notification?.data || {};
        link && Linking.openURL(link);
      });
      return Linking.getInitialURL().then((url) => {
        if (!constants.isWeb && !this.props.isLoggedIn) {
          // on mobile, we prepare deep linking here (this is not getting called on web)
          this.props.deepLink.prepareDeepLinking(url);
          return null; // Prevent default deep link processing
        } else {
          return url; // Use default deep link process
        }
      });
    },
  };

  render() {
    const {
      onConfirm,
      orderNumber,
      schedulePicker,
      shopData,
      showDatePicker,
      toggleDateTimePicker,
      whenFilter,
    } = this.props; // for date picker and redux shop data
    const { isConnected, isInternetReachable, showAppUpdate } = this.state;

    return (
      <NavigationContainer
        linking={this._linking} // deep linking for user when they logged in
        documentTitle={{
          formatter: (options, route) =>
            getPageName(route?.name, shopData?.name, orderNumber),
        }}
      >
        <SheetProvider>
          <Toast />

          <ConnectionIssueModal
            isConnected={isConnected}
            isInternetReachable={isInternetReachable}
          />

          {!constants.isWeb && (
            <AppUpdateModal
              show={showAppUpdate}
              onClose={this.toggleAppUpdate}
            />
          )}

          <RootNavigator />

          {/* This should be the only DatePicker for mobile (if intent is open the date picker to change order date), because if not it will show multiple date picker */}
          {/* Just call toggleDateTimePicker on withDatePicker hooks/hoc to open DatePicker */}
          {!constants.isWeb && (
            <DatePicker
              open={showDatePicker}
              value={whenFilter?.value}
              onCancel={() => toggleDateTimePicker('cancel')}
              onConfirm={onConfirm}
            />
          )}

          {/* Used when rescheduling */}
          {schedulePicker.showReschedule && !constants.isWeb && (
            <SchedulePicker
              scheduleTitle="Select Reschedule"
              storeId={schedulePicker.rescheduleStoreId}
              show={schedulePicker.showReschedule}
              onConfirm={schedulePicker.rescheduleOnStorePage}
            />
          )}
        </SheetProvider>
      </NavigationContainer>
    );
  }
}

Routes = withDatePicker(Routes);
Routes = withController(Routes, useSchedulePicker, 'schedulePicker');
Routes = withController(Routes, useDeepLinking, 'deepLink');

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedInSelector(state),
  shopData: state.shop.data,
  orderNumber: state.checkout.trackOrder,
});

export default connect(mapStateToProps)(Routes);
