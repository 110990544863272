export const sanitizeFilename = (
  fileName: string,
  replacement: string = '-'
) => {
  return fileName
    .replace(/[\/\\:*?"<>|]/g, replacement) // Remove invalid characters
    .replace(/[’']/g, replacement) // Replace apostrophes
    .replace(/\s+/g, replacement) // Replace spaces
    .trim();
};
