import React, { Fragment } from 'react';
import { View } from 'react-native';
import { Divider, Text } from '@ui-kitten/components';

import ThemeStyle from '../../Theme/styles';
import {
  SECTION_TITLE_LIGHT,
  SECTION_SUB_TITLE,
} from '../../Theme/typographyProps';

function ThirdPartyPickupDetails({ details }) {
  return (
    <Fragment>
      <View
        style={[
          ThemeStyle.pageVerticalSpacingMedium,
          ThemeStyle.pageHorizontalSpacing,
        ]}
      >
        <Text {...SECTION_TITLE_LIGHT}>Third Party Pickup Information</Text>
        <Text {...SECTION_SUB_TITLE}>
          Name: {details?.first_name} {details?.last_name}
        </Text>
        <Text {...SECTION_SUB_TITLE}>Phone: +63{details?.contact_number}</Text>
      </View>

      <Divider style={ThemeStyle.sectionSeparator} />
    </Fragment>
  );
}

export default ThirdPartyPickupDetails;
