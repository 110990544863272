import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Divider, Icon, Text } from '@ui-kitten/components';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import {
  SECTION_TITLE_LIGHT,
  SECTION_SUB_TITLE,
} from '../../Theme/typographyProps';

import styles from './styles';

function PaymentMethodUsed({ value }) {
  return (
    <Fragment>
      <View
        style={[
          ThemeStyle.pageHorizontalSpacing,
          ThemeStyle.pageVerticalSpacingMedium,
          ThemeStyle.flexDirectionRow,
        ]}
      >
        <Icon
          name="credit-card-outline"
          fill={ThemeColor.green}
          style={styles.icon}
        />

        <View style={ThemeStyle.flex1}>
          <Text style={ThemeStyle.spacingBottomMedium} {...SECTION_TITLE_LIGHT}>
            Payment Method
          </Text>

          <Text style={ThemeStyle.capitalize} {...SECTION_SUB_TITLE}>
            {value?.replace(/_/g, ' ')}
          </Text>
        </View>
      </View>

      <Divider style={ThemeStyle.sectionSeparator} />
    </Fragment>
  );
}

PaymentMethodUsed.propTypes = {
  value: PropTypes.string.isRequired,
};

export default PaymentMethodUsed;
