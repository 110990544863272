import React, { useRef } from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import ActionSheet, {
  ActionSheetRef,
  useScrollHandlers,
} from 'react-native-actions-sheet';
import { ScrollView } from 'react-native-gesture-handler';

import useCheckoutEvent from '../../../Hooks/useCheckoutEvent';
import ThemeStyle from '../../../Theme/styles';

import Button from '../../Button';

import commonProps from '../commonProps';

import EventOrderSummaryContent from './Content';
import { Props } from './types';

const EventOrderSummary: React.FC<Props> = ({ sheetId, payload }) => {
  const sheetRef = useRef<ActionSheetRef>();
  const scrollHandlers = useScrollHandlers('eventOrderSummary', sheetRef);
  const {
    apiError,
    eventDate,
    eventTime,
    paymentMethod,
    isCantProceedToCheckout,
    ticketPrice,
    onlineFeeTotal,
    grandTotal,
    isRequestingCheckout,
    buttonText,
    onPaymentMethodChange,
    onInputChange,
    onProceed,
  } = useCheckoutEvent(payload);

  return (
    <ActionSheet id={sheetId} {...commonProps}>
      <View style={{ maxHeight: 650 }}>
        {/* @ts-ignore: ScrollView is throwing an ts error */}
        <ScrollView showsVerticalScrollIndicator={false} {...scrollHandlers}>
          <EventOrderSummaryContent
            error={apiError}
            eventDate={eventDate}
            eventTime={eventTime}
            grandTotal={grandTotal}
            name={payload?.name}
            onlineFeeTotal={onlineFeeTotal}
            paymentMethod={paymentMethod}
            ticketPrice={ticketPrice}
            onInputChange={onInputChange}
            onPaymentMethodChange={onPaymentMethodChange}
          />
        </ScrollView>

        <View style={ThemeStyle.pageHorizontalSpacing}>
          <Button
            status="success"
            loading={isRequestingCheckout}
            disabled={isCantProceedToCheckout}
            onPress={onProceed}
          >
            {() => <Text appearance="alternative">{buttonText}</Text>}
          </Button>
        </View>
      </View>
    </ActionSheet>
  );
};

export default EventOrderSummary;
