import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '@ui-kitten/components';

import Button from '../../Components/Button';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE, SECTION_SUB_TITLE } from '../../Theme/typographyProps';

function StatusBanner({ isDigital, ratingId, onBtnPress }) {
  const actionText = ratingId ? 'Edit' : 'Add';

  return (
    <View style={styles.statusBanner}>
      <View style={[ThemeStyle.flex1, ThemeStyle.spacingRight]}>
        <Text
          appearance="alternative"
          style={ThemeStyle.spacingBottomMedium}
          {...SECTION_TITLE}
        >
          {isDigital ? 'Event' : 'Order'} Completed
        </Text>

        <View style={ThemeStyle.flexDirectionRow}>
          <Text appearance="alternative" {...SECTION_SUB_TITLE}>
            Thank you for {isDigital ? 'book' : 'order'}ing with us.
          </Text>
        </View>
      </View>

      {!isDigital && (
        <Button
          testID="viewOrderRatingButton"
          onPress={onBtnPress}
          status="success"
          size="small"
        >
          {actionText} Rating
        </Button>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  statusBanner: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.pageVerticalSpacing,
    ...ThemeStyle.flexDirectionRowCenter,
    backgroundColor: ThemeColor.darkGreen,
  },
  icon: {
    width: 40,
    height: 40,
  },
});

export default StatusBanner;
