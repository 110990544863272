import React, { Fragment, useState } from 'react';
import { View } from 'react-native';
import { Divider, Icon, Text } from '@ui-kitten/components';

import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

import Button from './Button';
import PopupModal from './Web/PopupModal';

// This currently used in
// order history page
function StatusFilter({ status, type, onChange, ...rest }) {
  const { options } = rest;
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  const onPress = (item) => () => {
    onChange(item);
    toggleModal;
  };

  return (
    <Fragment>
      <View
        style={[
          ThemeStyle.pageVerticalSpacingMedium,
          ThemeStyle.pageHorizontalSpacing,
          ThemeStyle.pageBackground,
        ]}
      >
        <Button
          onPress={toggleModal}
          plain
          style={ThemeStyle.flexDirectionRowCenter}
        >
          <Text
            category="h6"
            style={[ThemeStyle.capitalize, ThemeStyle.spacingRightMedium]}
          >
            {status} {type}
          </Text>

          <Icon
            name="arrow-ios-downward-outline"
            fill={ThemeColor.black}
            style={{ width: 20, height: 20 }}
          />
        </Button>
      </View>

      <PopupModal
        isOpen={modal}
        hasHeaderLine
        title="Order Status"
        onClosed={toggleModal}
      >
        <View style={ThemeStyle.pageHorizontalSpacing}>
          {options.map((item, index) => (
            <Fragment key={index}>
              <Button
                onPress={onPress(item)}
                plain
                style={[
                  ThemeStyle.alignItemsCenter,
                  ThemeStyle.pageVerticalSpacing,
                  ThemeStyle.pageHorizontalSpacing,
                ]}
              >
                <Text category="p1">{item?.label}</Text>
              </Button>
              {options.length - 1 !== index && (
                <Divider style={ThemeStyle.divider} />
              )}
            </Fragment>
          ))}
        </View>
      </PopupModal>
    </Fragment>
  );
}

export default StatusFilter;
