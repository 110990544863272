import React from 'react';
import { Animated, View } from 'react-native';
import PropTypes from 'prop-types';
import { FlatList, RefreshControl } from 'react-native';
import { Divider, List as UIKittenList } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../Config/constants';
import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

class List extends React.PureComponent {
  // purpose of this is to have only 1 List importing from all over so if we need to optimize the list
  // it can easily change here instead of every file importing FlatList of react-native and List of @ui-kitten/components

  _scrollToIndex(options) {
    this.list?.scrollToIndex?.(options);
  }

  _scrollToOffset(options) {
    this.list?.scrollToOffset?.(options);
  }

  _renderItem = ({ item, index }) => {
    const { data, renderItem } = this.props;
    const isFirstOfList = index === 0;
    const isLastOfList = index === data.length - 1;

    return renderItem({ isFirstOfList, isLastOfList, item, index });
  };

  _renderItemSeparator = () => {
    if (constants.isWeb) {
      return (
        <View>
          <View style={ThemeStyle.webPageWrapper}>
            <Divider
              style={[
                ThemeStyle.divider,
                ThemeStyle.spacingLeft,
                ThemeStyle.spacingRight,
                ThemeStyle.webPageContainer,
              ]}
            />
          </View>
        </View>
      );
    } else {
      return (
        <Divider
          style={[
            ThemeStyle.divider,
            ThemeStyle.spacingLeft,
            ThemeStyle.spacingRight,
          ]}
        />
      );
    }
  };

  _keyExtractor = (item, index) => index.toString();

  render() {
    const {
      animated,
      data,
      renderItem,
      withSeparator,
      plain,
      pullToRefreshProps,
      ...rest
    } = this.props;
    const conditionalProps = {};

    if (!lodashIsEmpty(pullToRefreshProps)) {
      const { onRefresh, ...ptrRest } = pullToRefreshProps;
      conditionalProps.bounces = true;
      conditionalProps.refreshControl = (
        <RefreshControl
          tintColor={ThemeColor.green}
          colors={[ThemeColor.green]}
          style={ThemeStyle.zIndex1}
          onRefresh={() => {
            // don't call onRefresh if already refreshing
            if (!pullToRefreshProps.refreshing) {
              onRefresh();
            } else {
              // already refreshing, do nothing
            }
          }}
          {...ptrRest}
        />
      );
    }

    if (plain) {
      const Container = animated ? Animated.FlatList : FlatList;
      return (
        <Container
          ref={(r) => (this.list = r)}
          data={data}
          renderItem={this._renderItem}
          keyExtractor={this._keyExtractor}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          ItemSeparatorComponent={
            withSeparator ? this._renderItemSeparator : null
          }
          {...rest}
          {...conditionalProps}
        />
      );
    }

    return (
      <UIKittenList
        ref={(r) => (this.list = r)}
        data={data}
        renderItem={this._renderItem}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        ItemSeparatorComponent={
          withSeparator ? this._renderItemSeparator : null
        }
        style={ThemeStyle.pageBackground}
        {...rest}
        {...conditionalProps}
      />
    );
  }
}

List.defaultProps = {
  animated: false,
  plain: false,
};

List.propsTypes = {
  animated: PropTypes.bool,
  plain: PropTypes.bool,
  data: PropTypes.array.isRequired,
  renderItem: PropTypes.node.isRequired,
  withSeparator: PropTypes.bool,
  pullToRefreshProps: PropTypes.object, // props for RefreshControl component of pull to refresh
};

export default List;
