import { createSlice } from '@reduxjs/toolkit';
import indexOf from 'lodash/indexOf';

const initialState = {
  resultSearched: [],
  recentlySearched: [],
  recentlyEventSearched: [],
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    populatesResultSearched: (state, action) => {
      state.resultSearched = [...action.payload];
    },
    pushRecentlySearch: (state, action) => {
      const isStoreSearch = action.payload.isStoreSearch;
      let recentlySearched = isStoreSearch
        ? state.recentlySearched
        : state.recentlyEventSearched;

      const searchValue = action.payload.value.toLowerCase();
      const resultIndex = indexOf(recentlySearched, searchValue);
      const isExist = resultIndex !== -1;
      if (isExist) {
        // remove it from the array and below code will push the searched to the first item
        recentlySearched.splice(resultIndex, 1);
      }
      recentlySearched = [searchValue, ...recentlySearched];
      if (isStoreSearch) {
        state.recentlySearched = recentlySearched;
      } else {
        state.recentlyEventSearched = recentlySearched;
      }

      if (recentlySearched.length >= 4) {
        // after adding the searched to redux and it has more than 4
        if (isStoreSearch) {
          state.recentlySearched.length = 4; // limit the search length to 4 only
        } else {
          state.recentlyEventSearched.length = 4; // limit the search length to 4 only
        }
      }
    },
    removeRecentlySearch: (state, action) => {
      const isStoreSearch = action.payload.isStoreSearch;
      let recentlySearched = isStoreSearch
        ? state.recentlySearched
        : state.recentlyEventSearched;

      if (action.payload.value) {
        // if has payload, remove individual
        const searchIndex = indexOf(recentlySearched, action.payload);
        if (isStoreSearch) {
          state.recentlySearched.splice(searchIndex, 1);
        } else {
          state.recentlyEventSearched.splice(searchIndex, 1);
        }
      } else {
        // if no payload, remove all
        if (isStoreSearch) {
          state.recentlySearched = initialState.recentlySearched;
        } else {
          state.recentlyEventSearched = initialState.recentlySearched;
        }
      }
    },
    clearResultSearched: (state) => {
      state.resultSearched = [];
    },
  },
});

export const {
  populatesResultSearched,
  pushRecentlySearch,
  removeRecentlySearch,
} = searchSlice.actions;
export default searchSlice.reducer;
