import React, { Fragment, useEffect, useState } from 'react';
import {
  KeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  View,
} from 'react-native';
import { SheetProvider } from 'react-native-actions-sheet';
import Modal from 'react-native-modal';
import { useSelector } from 'react-redux';
import { Divider, Icon, useTheme } from '@ui-kitten/components';
import _ from 'lodash';

import Button from '../../Components/Button';
import EmptyData from '../../Components/EmptyData';
import ItemCardLarge from '../../Components/Card/ItemLarge';
import List from '../../Components/List';
import Search from '../../Components/Search';
import { CONTEXT } from '../../Components/Sheets/modals';
import constants from '../../Config/constants';
import useMealPlan from '../../Hooks/useMealPlan';
import { shopSelector } from '../../RTK/shop/selectors';
import ThemeStyle, { deviceHeight } from '../../Theme/styles';
import { ItemLarge as ItemLargeTypes } from '../../types/item';
import PopupModal from '../../Components/Web/PopupModal';

type Props = {
  show?: boolean; // for web responsiveness
  onHide?: Function; // for web callback
  onPress: Function;
};
const MenuSearch: React.FC = ({ show, onHide, onPress }: Props) => {
  const theme = useTheme();
  const { isMealPlan } = useMealPlan();

  const shopData = useSelector(shopSelector);

  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const showResult = Boolean(showSearch && searchValue); // if search modal shows AND has search value
  const searchResults = showResult
    ? _(shopData?.menu_list) // lodash chain
        ?.flatMap?.('data') // flatten the menu list to array of items not included the category info
        ?.filter?.((item) =>
          item?.name?.toLowerCase?.().includes(searchValue?.toLowerCase?.())
        ) // filter the array to match the item name to search input value
        ?.value?.() // Convert chain to array
    : [];
  const ListContainer: React.ElementType = constants.isWeb
    ? Fragment
    : KeyboardAvoidingView; // on web its not scrolling if the list is wrapped on KeyboardAvoidingView
  const listContainerProps: KeyboardAvoidingViewProps | {} = constants.isWeb
    ? {}
    : {
        enabled: searchResults.length === 0, // enable only if no result found
        behavior: 'padding',
        style: { flexGrow: 1 },
      };

  // web effect for modal control via props
  if (constants.isWeb) {
    useEffect(() => {
      if (show) setShowSearch(show);
    }, [show]);
  }

  const _onClose = () => {
    setSearchValue('');
    setShowSearch(false);
    onHide?.();
  };

  const _onShow = () => setShowSearch(true);

  const _renderItem = ({ item }: { item: ItemLargeTypes }) => (
    //@ts-ignore ItemCardLarge is not yet typescript file
    <ItemCardLarge
      {...item}
      containerStyle={[
        ThemeStyle.pageHorizontalSpacingSmall,
        { borderWidth: 0 },
      ]}
      isMealPlan={isMealPlan}
      onPress={() => {
        onPress(item);
      }}
    />
  );

  const _renderNoResult = () =>
    showResult ? (
      <View
        style={[
          ThemeStyle.flex1,
          ThemeStyle.alignItemCenter,
          ThemeStyle.justifyContentCenter,
        ]}
      >
        <EmptyData type="filter" />
      </View>
    ) : null;

  const _renderSeparator = () => (
    <Divider
      style={[
        ThemeStyle.divider,
        ThemeStyle.spacingLeftSmall,
        ThemeStyle.spacingRightSmall,
      ]}
    />
  );

  const SearchContainerModal = constants.isWeb ? PopupModal : Modal;
  const conditionalProps = constants.isWeb
    ? {
        isOpen: showSearch,
        title: '',
        hasHeader: false,
        coverOverlayScreen: true,
      }
    : {
        animationIn: 'fadeIn',
        animationOut: 'fadeOut',
        animationInTiming: 1,
        animationOutTiming: 1,
        deviceHeight: deviceHeight,
        isVisible: showSearch,
        style: { justifyContent: 'flex-start', margin: 0 },
        onBackdropPress: _onClose,
        hideModalContentWhileAnimating: true,
        statusBarTranslucent: true,
        useNativeDriver: true,
        useNativeDriverForBackdrop: true,
      };
  return (
    <Fragment>
      {/* Trigger (Used only on mobile) */}
      {!constants.isWeb && (
        <View style={[ThemeStyle.pageHorizontalSpacing, ThemeStyle.spacingTop]}>
          <Search placeholder="Search in menu" onPressIn={_onShow} disabled />
        </View>
      )}
      {/* Search modal */}
      <SearchContainerModal {...conditionalProps}>
        <ListContainer {...listContainerProps}>
          <View
            style={[
              showResult &&
                (constants.isWeb ? { height: '100dvh' } : ThemeStyle.flex1),
              ThemeStyle.pageBackground,
              ThemeStyle.pageHorizontalSpacingSmall,
              { paddingTop: constants.statusBarHeight },
            ]}
          >
            {/* Back & Search */}
            <View
              style={[
                ThemeStyle.spacingBottomMedium,
                ThemeStyle.flexDirectionRowCenter,
                showResult && ThemeStyle.spacingBottomMedium,
                { paddingTop: 8, paddingHorizontal: 5 }, // custom style to match the store page header and back button
              ]}
            >
              <Button onPress={_onClose} plain>
                <Icon
                  name="arrow-back"
                  fill={theme['icon_color'] || theme['color-success-500']}
                  style={{ width: 25, height: 25 }}
                />
              </Button>
              <View style={[ThemeStyle.flex1, ThemeStyle.spacingLeftMedium]}>
                <Search
                  accessoryLeft={null}
                  inputStyle={{ borderRadius: 10 }}
                  placeholder="Search in menu"
                  onSearch={setSearchValue}
                  autoFocus
                />
              </View>
            </View>
            {/* Results */}
            <List
              contentContainerStyle={{ flexGrow: 1 }}
              data={searchResults}
              ItemSeparatorComponent={_renderSeparator}
              keyboardShouldPersistTaps="always"
              ListEmptyComponent={_renderNoResult}
              renderItem={_renderItem}
            />
          </View>
          {/* For showing the item action sheet above modal */}
        </ListContainer>

        {/* Sheet Provider to show the action sheet above search modal (Used only on mobile) */}
        {!constants.isWeb && <SheetProvider context={CONTEXT.SEARCH_PRODUCT} />}
      </SearchContainerModal>
    </Fragment>
  );
};

export default MenuSearch;
