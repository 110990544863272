import { GOOGLE_MAPS_API_KEY } from '@env';

export default {
  fetchDetails: true,
  enablePoweredByContainer: false,
  query: {
    key: GOOGLE_MAPS_API_KEY,
    language: 'en',
    components: 'country:ph',
  },
};
