import React, { Fragment, useState, memo } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Icon, Layout, Text } from '@ui-kitten/components';
import Collapsible from 'react-native-collapsible';
import { useNavigation } from '@react-navigation/native';
import { find as lodashFind } from 'lodash';

///CONFIG
import constants from '../../../Config/constants';

//HELPER
import MealPlanHelper from '../../../Helper/MealPlan';
import PromoHelper, { promoTypes } from '../../../Helper/Promo';
import { getCartTerm } from '../../../Helper/RemoteConfig';

//REDUX
import { updateCheckoutDetails } from '../../../RTK/checkout';
import { checkoutStoreInfoSelector } from '../../../RTK/checkout/selectors';
import { removeItem, setCheckoutData } from '../../../RTK/mealPlan';
import { checkout } from '../../../RTK/defaultValues';

//THEMES
import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';

//COMPONENTS
import Button from '../../Button';
import CartItem from '../Cart/CartItem';
import PopupModal from '../PopupModal';
import List from '../../List';
import MealPlanItem from '../../StoreCart/MealPlanItem';
import PaymentMethods from '../../PaymentMethods';
import PromoCode from '../../../Screens/Checkout/PromoCode';
import StoreCartSummary from '../../StoreCart/CartSummary';
import ContinueToCheckoutButton from '../../../Screens/Checkout/ContinueToCheckoutButton';

//ROUTES
import routeList from '../../../Routes/list';
import { useCheckoutNavigatorContext } from '../../../Routes/CheckoutNavigatorContext';

import PaymentOptions from '../../../Screens/Checkout/PaymentOptions';

export const StoreHeader = ({ onPress, store_name }) => (
  <View
    style={{ ...ThemeStyle.spacingTopSmall, ...ThemeStyle.spacingBottomMedium }}
  >
    <Text category="p1" status="primary">{`Your ${getCartTerm()} from`}</Text>
    <Button onPress={onPress} style={styles.storeContainer} plain>
      <Text category="p1" numberOfLines={2}>
        {store_name}
      </Text>

      <Icon
        name="chevron-right"
        fill={ThemeColor.green}
        style={styles.controlIcon}
      />
    </Button>
  </View>
);

const { ORDER_TYPES, ORDER_TYPE_DATA } = constants;

const pickupOrderType = lodashFind(ORDER_TYPE_DATA, {
  value: ORDER_TYPES.PICKUP,
});

const CheckoutRightContent = ({
  mealPlanData,
  isMealPlan,
  checkoutState, /// state hooks for meal plan and normal checkout
  checkoutData, //checkout data from redux
  checkoutItems, ///cart items from redux
  promotion,
  hasDeliveryFee,
  incrementPress,
  decrementPress,
  isUpdatingItem,
  toggleConfirmModal,
  deliveryQuotation,
  hasPickupOrderType,
  onOrderTypeChange,
  paymentMethods,
  onPaymentOptionChanged = (value) => {},
  placeOrder = () => {},
  isPlacingOrder,
  error,
}) => {
  const { additionalCharge } = useCheckoutNavigatorContext();
  const navigation = useNavigation();
  const checkoutStoreInfo = useSelector(checkoutStoreInfoSelector);
  const isPromoOk = useSelector((state) => state.checkout.isPromoOk);

  const dispatch = useDispatch();

  const isNormalCheckout = !isMealPlan;

  ///OTHER FUNCTIONALITY STATE
  const [expand, setExpand] = useState(false);
  const [deliveryFeeExpand, setDeliveryFeeExpand] = useState(false);

  const [showDeliveryFeeModal, setShowDeliveryFeeModal] = useState(false);
  const [showPriorityFeeModal, setShowPriorityFeeModal] = useState(false);

  const accordionRightIcon = expand ? 'chevron-down' : 'chevron-right';
  const accordionDeliveryRightIcon = deliveryFeeExpand
    ? 'chevron-down'
    : 'chevron-right';

  //DELIVERY QUOTATION
  const isNonCash =
    isMealPlan ||
    deliveryQuotation?.mode === constants.DELIVERY_PAYMENT_OPTION.NON_CASH;

  const formattedPromoValue = PromoHelper.getFormatedPromoValue(
    promotion || { delivery_discount: 0, description: '', promo: '', type: '' }
  );

  //MEAL PLAN SCHEDULE
  const mealPlanSchedule = MealPlanHelper.generateSchedule(mealPlanData?.type);

  const isDelivery =
    isMealPlan || checkoutData?.orderType?.value === ORDER_TYPES.DELIVERY;

  const totalItems = isMealPlan
    ? checkoutItems?.length
    : checkoutItems?.reduce(
        (previousValue, currentValue) =>
          parseInt(previousValue) + parseInt(currentValue.quantity),
        0
      );

  const showDeliveryFee = isNormalCheckout
    ? isDelivery && isNonCash
    : isDelivery;

  const itemTerm = totalItems <= 1 ? 'item' : 'items';

  //#region FOR DISABLING THE PLACE ORDER BUTTON
  const _onDisabled = () => {
    if (Boolean(isUpdatingItem)) {
      return true;
    } else if (
      checkoutData[checkout.keys.ORDER_TYPE]?.value === 'pickup' ||
      checkoutData[checkout.keys.ORDER_TYPE]?.value === 'curbside_pickup'
    ) {
      return false;
    } else if (isMealPlan && mealPlanData?.type === '3_day') {
      return checkoutItems?.length < 3;
    } else if (isMealPlan && mealPlanData?.type === '5_day') {
      return checkoutItems?.length < 5;
    } else if (showDeliveryFee && isMealPlan && !deliveryQuotation?.fee) {
      return true;
    } else if (showDeliveryFee && !isMealPlan && !deliveryQuotation?.fee) {
      return true;
    } else {
      return !checkoutData[checkout.keys.PAYMENT_OPTIONS] || !isPromoOk;
    }
  };
  //#endregion

  const _updateCheckoutReduxState = (key, value) => {
    if (isMealPlan) {
      const data = {
        key: key,
        data: value,
      };
      dispatch(setCheckoutData(data));
    } else {
      const data = {
        store_id: checkoutStoreInfo.store_id,
        keyToUpdate: key,
        keyValue: value,
      };
      dispatch(updateCheckoutDetails(data));
    }
  };

  const _onPaymentCardChange = (name) => (value) => {
    const existingData = JSON.parse(
      JSON.stringify(checkoutData?.[checkout.keys.PAYMENT_METHOD])
    );
    existingData[name] = value;
    _updateCheckoutReduxState(checkout.keys.PAYMENT_METHOD, existingData);
  };

  const _onPaymentMethodChange = (value) => {
    _updateCheckoutReduxState(checkout.keys.PAYMENT_METHOD, {
      method: value,
    });
  };

  const _onRemove = (index) => () => dispatch(removeItem(index));

  const _renderNonMealPlanItem = ({ item, index }) => {
    return (
      <CartItem
        item={item}
        isUnavailable={error?.unavailableItems?.includes(item.cart_details_id)}
        decrementPress={
          item.quantity > 1
            ? decrementPress({
                ...item,
                store_id: checkoutStoreInfo.store_id,
                quantity: item.quantity - 1,
                order_type: checkoutData?.orderType,
              })
            : toggleConfirmModal(
                {
                  ...item,
                  store_id: checkoutStoreInfo.store_id,
                  order_type: checkoutData?.orderType,
                },
                'single'
              )
        }
        incrementPress={incrementPress({
          ...item,
          store_id: checkoutStoreInfo.store_id,
          quantity: item.quantity + 1,
          order_type: checkoutData?.orderType,
        })}
        isUpdatingItem={isUpdatingItem === item.cart_details_id}
        disabled
      />
    );
  };

  const _renderMealPlanItem = ({ item, index }) => {
    // exclusive item use end of mealPlanSchedule date (friday)
    const dateIndex = item.is_exclusive ? mealPlanSchedule.length - 1 : index;
    const date = mealPlanSchedule[dateIndex];
    return <MealPlanItem {...item} date={date} onRemove={_onRemove(index)} />;
  };

  const toggleDeliveryModal = () =>
    setShowDeliveryFeeModal(!showDeliveryFeeModal);

  const togglePriorityModal = () =>
    setShowPriorityFeeModal(!showPriorityFeeModal);

  const onChangeOrderTypePriorityModal = () => {
    if (hasPickupOrderType) {
      onOrderTypeChange?.(pickupOrderType);
    }
    setShowPriorityFeeModal(!showPriorityFeeModal);
  };

  const DeliveryFeeModal = () => (
    <PopupModal
      isOpen={showDeliveryFeeModal}
      hasHeaderLine
      title="Delivery Fee"
      onClosed={toggleDeliveryModal}
    >
      <View style={styles.disclaimerContentContainer}>
        <Text
          category="c1"
          style={[ThemeStyle.textCenter, ThemeStyle.spacingTopSmall]}
        >
          The delivery fee varies for each restaurant based on your location,
          courier price surges, and other factors.
        </Text>
        <View style={styles.controlContainer}>
          <Button
            testID="continueButton"
            onPress={() => setShowDeliveryFeeModal(!showDeliveryFeeModal)}
            plain
            style={styles.disclaimerButton}
            status="success"
          >
            <Text category="c1" status="control">{`Continue`}</Text>
          </Button>
        </View>
      </View>
    </PopupModal>
  );
  const PriorityFeeModal = () => (
    <PopupModal
      isOpen={showPriorityFeeModal}
      hasHeaderLine
      title="Priority Fee"
      onClosed={togglePriorityModal}
    >
      <View style={styles.disclaimerContentContainer}>
        {/* <Text category="s1" style={ThemeStyle.textCenter}>
          Priority Fee
        </Text> */}
        {!hasPickupOrderType ? (
          <Text
            category="c1"
            style={[ThemeStyle.textCenter, ThemeStyle.spacingTopSmall]}
          >
            {'This priority fee will assist in hailing riders for your order.'}
          </Text>
        ) : (
          <Text
            category="c1"
            style={[ThemeStyle.textCenter, ThemeStyle.spacingTopSmall]}
          >
            If you prefer to opt out of the priority fee, please change this
            order to "
            <Text category="c1" status="success">
              {pickupOrderType.label}
            </Text>
            ", please note that with {pickupOrderType.label} orders you will
            need to hail your own rider and self-manage the delivery.
          </Text>
        )}
        <View style={styles.controlContainer}>
          <Button
            testID="continueButton"
            onPress={onChangeOrderTypePriorityModal}
            plain
            style={styles.disclaimerButton}
            status="success"
          >
            <Text category="c1" status="control">
              {hasPickupOrderType
                ? `Change the order type to "${pickupOrderType.label}"`
                : 'Continue'}
            </Text>
          </Button>
        </View>
      </View>
    </PopupModal>
  );

  const goBackToStore = () => {
    navigation.navigate(routeList.HOME, {
      screen: 'MainMenu',
      params: {
        screen: routeList.STORE,
        params: { id: checkoutStoreInfo.store_id },
      },
    });
  };

  return (
    <Layout level="2" style={styles.mainWrapper}>
      <ScrollView>
        <Fragment>
          <View style={styles.rowContent}>
            <StoreHeader
              onPress={goBackToStore}
              store_name={checkoutStoreInfo.store_name}
            />

            {error?.errorType === constants.ERROR_TYPE.UNAVAILABLE && (
              <Text style={ThemeStyle.noticeText}>{error?.message}</Text>
            )}

            <Button
              onPress={() => setExpand(!expand)}
              style={styles.optionButton}
              plain
            >
              <Icon
                name="shopping-cart-outline"
                fill={ThemeColor.green}
                style={styles.controlIcon}
              />

              <View
                style={[ThemeStyle.flex1, ThemeStyle.pageHorizontalSpacing]}
              >
                <Text category="p2">{`Order Summary (${
                  totalItems || 0
                } ${itemTerm})`}</Text>
              </View>
              <Icon
                name={accordionRightIcon}
                fill={ThemeColor.green}
                style={styles.controlIcon}
              />
            </Button>

            <Collapsible collapsed={!expand}>
              <View
                testID="storeCartData"
                style={ThemeStyle.pageVerticalSpacingMedium}
              >
                <List
                  data={checkoutItems}
                  renderItem={
                    isNormalCheckout
                      ? _renderNonMealPlanItem
                      : _renderMealPlanItem
                  }
                  ItemSeparatorComponent={() => (
                    <View style={styles.itemSeparator} />
                  )}
                  plain
                />
              </View>
            </Collapsible>
          </View>
          <Divider style={ThemeStyle.spacingTopSmall} />
          <View
            testID="storeCartData"
            style={[styles.rowContent, ThemeStyle.pageVerticalSpacingMedium]}
          >
            <PromoCode
              disabled={
                deliveryQuotation?.loading || // delivery quotation is loading
                !!deliveryQuotation?.error || // has error message
                isPlacingOrder // clicked the place order / continue to checkout button
              }
              isQuotationNotReady={
                Boolean(deliveryQuotation?.error) || deliveryQuotation?.loading
              }
            />
          </View>
          <Divider style={ThemeStyle.spacingTopSmall} />
          {hasDeliveryFee && (
            <View style={styles.rowContent}>
              <Button
                onPress={() => setDeliveryFeeExpand(!deliveryFeeExpand)}
                style={styles.optionButton}
                plain
              >
                <Icon
                  name="pricetags-outline"
                  fill={ThemeColor.green}
                  style={styles.controlIcon}
                />

                <View
                  style={[ThemeStyle.flex1, ThemeStyle.pageHorizontalSpacing]}
                >
                  <Text category="p2">Delivery Payment Options</Text>
                </View>
                <Icon
                  name={accordionDeliveryRightIcon}
                  fill={ThemeColor.green}
                  style={styles.controlIcon}
                />
              </Button>

              <Collapsible collapsed={!deliveryFeeExpand}>
                <View
                  testID="storeCartData"
                  style={[
                    ThemeStyle.pageHorizontalSpacing,
                    ThemeStyle.pageVerticalSpacingMedium,
                    ThemeStyle.spacingBottomMedium,
                  ]}
                >
                  <PaymentOptions
                    disabled={deliveryQuotation.loading}
                    value={checkoutData[checkout.keys.PAYMENT_OPTIONS]}
                    onChange={onPaymentOptionChanged}
                    paymentMethods={paymentMethods}
                  />
                </View>
              </Collapsible>
            </View>
          )}

          <Divider style={ThemeStyle.spacingTopSmall} />

          <View style={styles.rowContent}>
            <StoreCartSummary
              additionalCharge={additionalCharge}
              cart={checkoutItems}
              delivery={deliveryQuotation}
              hasMealPlanDeliveryDiscount={
                isMealPlan
                  ? Boolean(checkoutState?.storeMealPlanDeliveryDiscount)
                  : undefined
              }
              isDelivery={isDelivery}
              promotion={promotion}
              subTotalPromoPercent={
                isMealPlan ? checkoutState?.storeMealPlanDiscount : undefined
              }
              onDeliveryFeePress={toggleDeliveryModal}
              onPriorityFeePress={togglePriorityModal}
              noHspacing
            />
          </View>
          <Divider style={ThemeStyle.spacingTopSmall} />
          <View
            style={[
              ThemeStyle.spacingTopSmall,
              ThemeStyle.spacingBottomMedium,
              styles.rowContent,
            ]}
          >
            <PaymentMethods
              value={checkoutData[checkout.keys.PAYMENT_METHOD]}
              onInputChange={_onPaymentCardChange}
              onMethodChange={_onPaymentMethodChange}
            />
          </View>
        </Fragment>
        <ContinueToCheckoutButton
          additionalCharge={additionalCharge}
          cartItems={checkoutItems}
          deliveryQuotation={deliveryQuotation}
          placingOrder={isPlacingOrder}
          storeId={checkoutData.store_id}
          disabled={_onDisabled()}
          onContinueToCheckoutPress={placeOrder}
        />
      </ScrollView>

      <DeliveryFeeModal />
      <PriorityFeeModal />
    </Layout>
  );
};

const styles = StyleSheet.create({
  mainWrapper: {
    padding: 15,
    borderRadius: 24,
  },
  rowContent: {
    paddingHorizontal: '5%',
  },

  itemSeparator: {
    height: 1,
    alignSelf: 'stretch',
    backgroundColor: ThemeColor.gray,
  },
  label: {
    ...ThemeStyle.medium,
    fontSize: 14,
    color: ThemeColor.gray,
  },
  storeTitle: {
    ...ThemeStyle.medium,
    fontSize: 14,
    color: ThemeColor.black,
  },
  storeContainer: {
    ...ThemeStyle.spacingTopSmall,
    ...ThemeStyle.medium,
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
  placeOrderButton: {
    ...ThemeStyle.spacingTopMedium,
  },

  placeOrderTxt: {
    ...ThemeStyle.medium,
    fontSize: 14,
    color: ThemeColor.white,
  },

  controlIcon: { width: 20, height: 20 },
  optionButton: {
    ...ThemeStyle.spacingTopSmall,
    height: 35,
    flexDirection: 'row',
    alignItems: 'center',
  },

  totalContainer: {
    height: 30,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  disclaimerContentContainer: {
    alignItems: 'center',
  },
  disclaimerContentBold: {
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  disclaimerContentRegular: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 10,
  },
  controlContainer: {
    marginTop: 20,
    width: '100%',
  },

  disclaimerButton: {
    backgroundColor: ThemeColor.green,
    minWidth: 100,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: ThemeColor.green,
    borderWidth: 1,
    ...ThemeStyle.fullRoundCorder,
  },
  disclaimerButtonTxt: { color: ThemeColor.white },
});

export default memo(CheckoutRightContent);
