import React from 'react';
import { FlatList, StyleSheet, View } from 'react-native';

import constants from '../../Config/constants';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

import Footer from '../Footer';

const PAGE_MAX_WIDTH = 1330; // Just base on current UI, adjust as needed

type ScreenWrapperWebProps = {
  children: React.ReactNode;
  fullWidth?: boolean;
  header: React.ComponentType<any>;
  withFooter?: boolean;
  [key: string]: any;
};

const ScreenWrapperWeb: React.FC<ScreenWrapperWebProps> = ({
  children,
  fullWidth,
  header,
  withFooter = true,
  ...rest
}) => {
  const widthStyle = fullWidth
    ? { width: '100%', paddingHorizontal: '5%' }
    : { maxWidth: PAGE_MAX_WIDTH };
  const containerStyle = { ...styles.contentWrapper, ...widthStyle };
  const ListCellRenderer = ({ children, style }) => (
    <View style={[style, ThemeStyle.flex1]} children={children} />
  );
  const ListContent = () => (
    <View
      style={[ThemeStyle.flex1, containerStyle, ThemeStyle.flexDirectionRow]}
    >
      {children}
    </View>
  );
  const ListFooter = (
    <View style={containerStyle}>
      <Footer />
    </View>
  );
  return (
    <FlatList
      {...rest}
      contentContainerStyle={styles.flatListContainer}
      data={[0]}
      keyExtractor={(e) => `pageContainer${e}`}
      style={[
        ThemeStyle.pageBackground,
        constants.isWeb && { height: '100vh' }, // handle on web, bcs some routes is not wrapped inside a navigator so flex: 1 doesn't work
      ]}
      ListHeaderComponent={header}
      ListFooterComponent={withFooter && ListFooter}
      CellRendererComponent={ListCellRenderer}
      renderItem={ListContent}
    />
  );
};

const styles = StyleSheet.create({
  flatListContainer: {
    flexGrow: 1,
  },
  contentWrapper: {
    ...ThemeStyle.fitWidthContainer,
    alignSelf: 'center',
    paddingHorizontal: globalSpacing,
  },
});

export default ScreenWrapperWeb;
