import React from 'react';

import useShouldCallApi from './useShouldCallApi';

export default function (Component) {
  return (props) => {
    const shouldCallApi = useShouldCallApi();

    return <Component shouldCallApi={shouldCallApi} {...props} />;
  };
}
