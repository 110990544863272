import { useMemo, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import lodashIsEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

import modals from '../Components/Sheets/modals';
import { ModalManager } from '../Components/Web/Modal/hooks/useModalManager';
import { MODALPROMPT } from '../Components/Web/Modal/ModalPrompt/config';
import constants from '../Config/constants';
import CheckoutHelper from '../Helper/Checkout';
import routeList from '../Routes/list';
import {
  useAddToCartEventMutation,
  useCheckoutEventMutation,
} from '../RTK/api/events';
import { updateCheckoutDetails } from '../RTK/checkout';
import { storeCheckoutSelector } from '../RTK/checkout/selectors';
import { checkout } from '../RTK/defaultValues';
import { addressSelector } from '../RTK/user/selectors';
import { removeSuccessfulOrder } from '../RTK/utility';

const modal = constants.isWeb ? ModalManager : modals;

type PromptResult = { buttonClicked?: boolean };

const useCheckoutEvent = (data) => {
  const dispatch = useDispatch();
  const navigation = useNavigation<any>();
  const [paymentMethod, setPaymentMethod] = useState<{
    cardCvv?: string;
    cardMonth?: string;
    cardNumber?: string;
    cardYear?: string;
    method: string;
  }>({
    method: '',
  });
  const [addToCartEvent, { isLoading: isAddToCartLoading }] =
    useAddToCartEventMutation();
  const [checkoutEvent, { isLoading: isCheckoutLoading }] =
    useCheckoutEventMutation();
  const userAddresses = useSelector(addressSelector);

  const [apiError, setApiError] = useState('');

  const { id, isDetails, item, selectedDate, start_date, store } = data || {
    id: '',
    isDetails: false,
    item: {},
    selectedDate: '',
    start_date: '',
    store: {},
  };
  const storeId = `${store?.id}_event`;
  const storeCheckoutEventData = useSelector((state) =>
    storeCheckoutSelector(state, storeId)
  );

  const isCardPayment = paymentMethod.method === 'card';
  const continueToPaymentData =
    storeCheckoutEventData[checkout.keys.CONTINUE_TO_PAYMENT];
  const canContinueToPayment =
    !lodashIsEmpty(continueToPaymentData) &&
    continueToPaymentData?.itemId === item?.id;

  const cardResult = useMemo(() => {
    if (!isCardPayment) return { isValid: true };
    return CheckoutHelper.validateCard(
      paymentMethod?.cardNumber,
      paymentMethod?.cardMonth,
      paymentMethod?.cardYear,
      paymentMethod?.cardCvv
    );
  }, [
    isCardPayment,
    paymentMethod?.cardNumber,
    paymentMethod?.cardMonth,
    paymentMethod?.cardYear,
    paymentMethod?.cardCvv,
  ]);
  const hasPayment = isCardPayment
    ? cardResult.isValid
    : Boolean(paymentMethod.method);
  const eventSchedule = dayjs(selectedDate || start_date); // priority the selectedDate, because event is multiple date and user selected a event date

  const _onUpdateCheckoutEventDetails = ({ keyValue }) => {
    dispatch(
      updateCheckoutDetails({
        store_id: storeId,
        keyToUpdate: checkout.keys.CONTINUE_TO_PAYMENT,
        keyValue,
      })
    );
  };

  const _onProceed = () => {
    const cart = {
      extras: [],
      instructions: {},
      item_id: item.id,
      order_type: 'pickup',
      quantity: 1,
      store_id: store.id,
    };
    setApiError('');
    addToCartEvent(cart).then((res) => {
      if ('error' in res) {
        const errMsg = res.error as string;
        setApiError(errMsg);
      } else {
        const checkout = {
          address_id: userAddresses[0].id,
          order_date: dayjs(eventSchedule).format(constants.DBDATE_FORMAT),
          order_type: 'pickup',
          payment_method: paymentMethod?.method,
          store_id: store.id,
        };
        dispatch(removeSuccessfulOrder(checkout.store_id));
        checkoutEvent(checkout).then((result) => {
          if ('error' in result) {
            const errMsg = result.error as string;
            if (
              Object.keys(constants.EVENT_SOLDOUT)
                .map(
                  (e) => constants.EVENT_SOLDOUT[e] // get key value
                )
                .includes(errMsg)
            ) {
              // if sold out
              _soldOutPrompt(errMsg);
            } else {
              setApiError(errMsg);
            }
          } else {
            const checkoutParam = {
              storeId: storeId,
              url: result.data.redirect,
              payment: paymentMethod,
              ...result.data,
              itemId: item?.id,
            };
            if (!isDetails) {
              // if checkout on event homepage, pass this so when go back it go back to event details screen
              checkoutParam.backRoute = routeList.EVENT_DETAILS;
              checkoutParam.backParams = { eventId: id };
            }
            _onUpdateCheckoutEventDetails({
              keyValue: {
                checkout_date: dayjs().toISOString(),
                ...checkoutParam,
              },
            });
            modal.hide(modals.eventOrderSummary);
            navigation.navigate(routeList.CHECKOUT_WEBVIEW, checkoutParam);
          }
        });
      }
    });
  };

  const _soldOutPrompt = async (soldOutMsg: string) => {
    const isOverallSoldOut = soldOutMsg === constants.EVENT_SOLDOUT.ALL;
    const promptPayload = {
      title: isOverallSoldOut ? 'Sold Out' : 'Please try another date.',
      message: isOverallSoldOut
        ? "We're sorry, but all the slots for this event just sold out."
        : "We're sorry, but all slots for this date have been filled.",
      buttonText: isOverallSoldOut ? 'Ok' : 'Select different schedule',
      buttonStatus: 'success',
    };
    if (constants.isWeb) {
      const result: PromptResult = await ModalManager.show(MODALPROMPT.prompt, {
        payload: promptPayload,
      });
      ModalManager.hide(MODALPROMPT.eventOrderSummary, {
        payload: {
          reschedule: !isOverallSoldOut && result?.buttonClicked,
          soldout: isOverallSoldOut,
        },
      });
    } else {
      const result: PromptResult = await modals.show(
        modals.PROMPT,
        promptPayload
      );
      modals.hide(modals.eventOrderSummary, {
        reschedule: !isOverallSoldOut && result?.buttonClicked,
        soldout: isOverallSoldOut,
      });
    }
  };

  const _onContinuePayment = () => {
    modal.hide(modals.eventOrderSummary);
    const checkoutParam = {
      storeId: continueToPaymentData.id,
      url: continueToPaymentData.url,
      payment: paymentMethod.method || continueToPaymentData.payment,
    };
    navigation.navigate(routeList.CHECKOUT_WEBVIEW, checkoutParam);
  };
  const _onInputChange = (input: string) => (value: string) => {
    if (!lodashIsEmpty(continueToPaymentData?.payment)) {
      // if has previous data of payment, remove it
      _onUpdateCheckoutEventDetails({ keyValue: undefined });
    }
    setPaymentMethod({ ...paymentMethod, [input]: value });
  };
  const _onPaymentMethodChange = (value) => {
    if (!lodashIsEmpty(continueToPaymentData?.payment)) {
      // if has previous data of payment, remove it
      _onUpdateCheckoutEventDetails({ keyValue: undefined });
    }
    setPaymentMethod({ method: value });
  };

  const eventDate = eventSchedule.format(constants.DATE_ONLY_DISPLAY_FORMAT);
  const eventTime = eventSchedule.format(
    constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT
  );

  const ticketPrice = item?.price;
  const onlineFeeTotal = 200;

  const grandTotal = ticketPrice + onlineFeeTotal;

  return {
    apiError,
    eventDate,
    eventTime,
    grandTotal,
    paymentMethod:
      canContinueToPayment && !hasPayment
        ? continueToPaymentData?.payment
        : paymentMethod,
    ticketPrice,
    onlineFeeTotal,
    isCantProceedToCheckout: !canContinueToPayment && !hasPayment,
    isRequestingCheckout: isCheckoutLoading || isAddToCartLoading,
    canContinueToPayment,
    buttonText: canContinueToPayment ? 'Continue to payment' : 'Place order',
    onInputChange: _onInputChange,
    onPaymentMethodChange: _onPaymentMethodChange,
    onProceed: canContinueToPayment ? _onContinuePayment : _onProceed,
  };
};
export default useCheckoutEvent;
