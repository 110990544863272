import React, { Fragment } from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import Collapsible from 'react-native-collapsible';
import lodashpick from 'lodash/pick';

import Button from '../../Components/Button';
import Switch from '../../Components/Switch';
import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE_LIGHT } from '../../Theme/typographyProps';

import ThirdPartyAndVehicleInputs from './ThirdPartyAndVehicleInputs';

const fields = [
  { key: 'firstName', label: 'First Name' },
  { key: 'lastName', label: 'Last Name' },
  { key: 'contactNumber', label: 'Contact Number', type: 'phone' },
];

function ThirdPartyPickup(props) {
  const { disabled, values, onChange } = props;
  const isChecked = values?.isChecked || false;

  const _onChange = (key) => (fieldValue) => {
    onChange({ ...values, [key]: fieldValue });
  };

  return (
    <Fragment>
      <Button
        onPress={() => _onChange('isChecked')(!isChecked)}
        style={[
          ThemeStyle.flex1,
          ThemeStyle.flexDirectionRowCenterSpaceBetween,
        ]}
        plain
      >
        <Text {...(constants.isWeb ? { category: 's2' } : SECTION_TITLE_LIGHT)}>
          This order is for someone else
        </Text>

        <Switch
          value={isChecked}
          onChange={_onChange('isChecked')}
          disabled={disabled}
        />
      </Button>

      <Collapsible collapsed={!isChecked}>
        <View style={ThemeStyle.spacingTopMedium}>
          <ThirdPartyAndVehicleInputs
            fields={fields}
            onChange={_onChange}
            {...lodashpick(props, ['disabled', 'values'])}
          />
        </View>
      </Collapsible>
    </Fragment>
  );
}

export default ThirdPartyPickup;
