import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useRef,
} from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { List, ListItem, Input, Icon, Layout } from '@ui-kitten/components';
import Popup from 'reactjs-popup';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import ThemeColor from '../../Theme/colors';

type MatchedSubstrings = {
  length: number;
  offset: number;
};
type Terms = {
  offset: number;
  value: string;
};

type ResultItem = {
  description: string;
  matched_substrings: Array<MatchedSubstrings>;
  place_id: string;
  reference: string;
  structured_formatting: {
    main_text: string;
    main_text_matched_substrings: Array<MatchedSubstrings>;
    secondary_text: string;
  };
  terms: Array<Terms>;
  types: Array<string>;
};

type GooglePlacesAutocompleteProps = {
  testID: string;
  placeholder?: string;
  initialValue: string;
  onSelected: (item: ResultItem, result: any) => void;
  style?: StyleProp<ViewStyle>;
};

const GooglePlaces_Autocomplete = forwardRef(
  (props: GooglePlacesAutocompleteProps, ref) => {
    const {
      testID,
      placeholder = 'Address',
      initialValue,
      onSelected,
      style,
    } = props;

    const inputRef = useRef();
    const { placePredictions, getPlacePredictions, placesService } =
      usePlacesAutocompleteService({
        options: {
          componentRestrictions: { country: 'PH' },
          types: ['geocode', 'establishment'],
        },
      });

    const [value, setValue] = useState(initialValue ? initialValue : '');
    const [showPrediction, setShowPrediction] = useState(false);
    const [width, setWidth] = useState(0);
    useImperativeHandle(ref, () => ({
      _setDefaultSearchValue(value) {
        setTimeout(() => setValue(value), 100);
      },
    }));

    const _renderItem = ({ item }: { item: ResultItem }) => (
      <ListItem title={`${item.description}`} onPress={() => _onPress(item)} />
    );
    const _onPress = (item: ResultItem) => {
      setShowPrediction(!showPrediction);
      setValue(item?.description);
      placesService?.getDetails(
        {
          placeId: item.place_id,
        },
        (placeDetails) => {
          let convertToString = JSON.stringify(placeDetails); // need to convert first to string so we can get the raw value for lat and lng. if not it will read as constructor
          let result = JSON.parse(convertToString); // revert it to JSON again...
          onSelected(item, result);
        }
      );
    };

    const _onClose = () => {
      setShowPrediction(false);
      getPlacePredictions({ input: '' });
    };
    const _onChangeText = (text: string) => {
      setShowPrediction(true);
      getPlacePredictions({ input: text });
      setValue(text);
    };
    return (
      <Layout
        testID={testID}
        style={[{ flex: 1 }, style]}
        onLayout={({ nativeEvent }) => setWidth(nativeEvent.layout.width)}
      >
        <Popup
          open={showPrediction}
          trigger={() => (
            <button style={styles.btn} disabled>
              <Input
                ref={inputRef}
                style={styles.search_input}
                value={value}
                accessoryLeft={
                  <Icon
                    name="search"
                    fill={ThemeColor.green}
                    style={{ width: 14, height: 21 }}
                  />
                }
                placeholder={placeholder}
                onChangeText={_onChangeText}
              />
            </button>
          )}
          onClose={_onClose}
          position="bottom center"
          closeOnDocumentClick
          arrow={false}
          contentStyle={{ width: width }}
        >
          <List
            data={placePredictions}
            renderItem={_renderItem}
            style={styles.listContainer}
          />
        </Popup>
      </Layout>
    );
  }
);

const styles = StyleSheet.create({
  search_input: {
    borderColor: ThemeColor.darkGray,
    borderWidth: 1,
  },
  listContainer: {
    maxHeight: 180,
  },
  btn: {
    borderWidth: 0,
    padding: 0,
  },
});
export default GooglePlaces_Autocomplete;
