import { StyleSheet } from 'react-native';

import colors from '../colors';
import { fontWeights } from '../mapping';

import commonStyles from './common';

export * from './common';

export const webPaddingHorizontal = 80;

export default StyleSheet.create({
  ...commonStyles,
  bold: {
    fontFamily: 'Poppins-SemiBold',
  },
  medium: {
    fontFamily: 'Poppins-Regular',
  },
  regular: {
    fontFamily: 'Poppins-Regular',
    fontWeight: fontWeights.NORMAL,
  },
  textCenter: {
    textAlign: 'center',
    fontFamily: 'Poppins-Regular',
    fontWeight: fontWeights.NORMAL,
  },
  noticeText: {
    color: colors.red,
  },
  noticeTextLight: {
    color: colors.lightRed,
    fontFamily: 'Poppins-Regular',
    fontWeight: fontWeights.NORMAL,
  },
  pageBackground: {
    backgroundColor: colors.white,
    fontFamily: 'Poppins-Regular',
    fontWeight: fontWeights.NORMAL,
  },
  buttonHovered1: {
    backgroundColor: colors.green100,
    borderRadius: 5,
  },
});
