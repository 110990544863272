import React from 'react';
import { connect } from 'react-redux';
import { View } from 'react-native';
import lodashGet from 'lodash/get';

import Form from '../../Components/FormFields/Form';
import SubmitButton from '../../Components/FormFields/SubmitButton';
import ScreenWrapper from '../../Components/ScreenWrapperWithTitleAnimation';
import ProfileForm from '../../Components/ProfileForm';
import validation from '../../Config/validationRules';
import useFormField from '../../Hooks/useFormField';
import withController from '../../Hooks/withController';
import { storeCheckoutSelector } from '../../RTK/checkout/selectors';
import { updateCheckoutDetails } from '../../RTK/checkout';
import { setCheckoutData } from '../../RTK/mealPlan';
import { checkout } from '../../RTK/defaultValues';
import ThemeStyle from '../../Theme/styles';

const validationSchema = validation.createSchema({
  firstName: validation.firstNameSchema,
  lastName: validation.lastNameSchema,
  email: validation.emailSchema,
  phone: validation.phoneSchema,
});

const formInitialValues = {
  storeId: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
};

class UserDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    const userDetails = props.checkoutData[checkout.keys.USER_DETAILS];
    formInitialValues.storeId = props.checkoutData.store_id; // required
    formInitialValues.firstName = userDetails?.firstName || '';
    formInitialValues.lastName = userDetails?.lastName || '';
    formInitialValues.phone = userDetails?.phone?.replace?.('+63', '') || '';
    formInitialValues.email = userDetails?.email || '';
    this.formRef = React.createRef();
  }

  _handleSubmit = async (formData, { setSubmitting }) => {
    const {
      navigation,
      route,
      dispatchSetCheckoutData,
      dispatchUpdateCheckoutDetails,
    } = this.props;
    const { storeId, ...details } = formData;
    if (lodashGet(route, 'params.isFromMealPlan')) {
      // if meal plan checkout
      await dispatchSetCheckoutData({
        key: checkout.keys.USER_DETAILS,
        data: details,
      });
    } else {
      await dispatchUpdateCheckoutDetails({
        store_id: storeId,
        keyToUpdate: checkout.keys.USER_DETAILS,
        keyValue: details,
      });
    }
    setSubmitting(false);
    navigation.goBack();
  };

  _renderFooter = () => {
    return (
      <View
        style={[
          ThemeStyle.pageHorizontalSpacing,
          ThemeStyle.spacingBottomMedium,
        ]}
      >
        <SubmitButton
          onRef={this.formRef.setSubmitButtonRef}
          text="Save"
          withShadow
        />
      </View>
    );
  };

  render() {
    return (
      <Form
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={this._handleSubmit}
      >
        <ScreenWrapper
          title="Checkout User Details"
          renderFooter={this._renderFooter}
        >
          <View
            style={[
              ThemeStyle.pageVerticalSpacing,
              ThemeStyle.pageHorizontalSpacing,
            ]}
          >
            <ProfileForm ref={(r) => (this.formRef = r)} useFormikContext />
          </View>
        </ScreenWrapper>
      </Form>
    );
  }
}

UserDetails = withController(UserDetails, useFormField);

const mapStateToProps = (state) => ({
  checkoutData: storeCheckoutSelector(state, state.checkout.storeInfo.store_id),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetCheckoutData: (data) => dispatch(setCheckoutData(data)),
  dispatchUpdateCheckoutDetails: (data) =>
    dispatch(updateCheckoutDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
