import React, { Fragment } from 'react';

import { View, StyleSheet } from 'react-native';
import { Divider, Text, Radio, Icon } from '@ui-kitten/components';
import Collapsible from 'react-native-collapsible';
import lodashIsEmpty from 'lodash/isEmpty';

import ListItem from '../../../Components/ListItem';
import constants from '../../../Config/constants';
import AddressHelper from '../../../Helper/Address';
import ThemeColor from '../../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../../Theme/styles';

type Props = {
  disabled: boolean;
  list: string[];
  selectedId?: string;
  show: boolean;
  getRow: (
    addressId: string,
    index: number
  ) => { address: any; isSelected: boolean; isLast: boolean };
  onSelect: (param: any) => void;
  toggle: () => void;
};

const LastUsedAddresses = ({
  disabled,
  list,
  show,
  getRow,
  onSelect,
  toggle,
}: Props) => {
  const hasLastUsedAddresses = !lodashIsEmpty(list);
  return (
    <Fragment>
      {hasLastUsedAddresses && (
        <View style={styles.container}>
          <ListItem
            leftIcon="history"
            leftIconColor={ThemeColor.green}
            leftIconPack="material"
            renderContent={() => (
              <View
                style={[
                  !constants.isWeb && ThemeStyle.flex1,
                  ThemeStyle.flexDirectionRowCenter,
                  styles.contentContainer,
                ]}
              >
                <Text style={!constants.isWeb && ThemeStyle.flex1}>{`${
                  !show ? 'Show' : 'Hide'
                } last used addresses`}</Text>
                <Icon
                  name={!show ? 'arrow-ios-downward' : 'arrow-ios-upward'}
                  fill={ThemeColor.green}
                  style={styles.rightIcon}
                />
              </View>
            )}
            onPress={toggle}
          />
        </View>
      )}
      {hasLastUsedAddresses && show && <Divider style={ThemeStyle.divider} />}
      {/* Last used address accordion */}
      {hasLastUsedAddresses && (
        <Collapsible collapsed={!show} renderChildrenCollapsed={false}>
          {list.map((addressId: string, index: number) => {
            const { address, isSelected, isLast } = getRow(addressId, index);
            return (
              <View
                style={{ marginLeft: globalSpacing * 2 }}
                key={address?.id || index}
              >
                <ListItem
                  accessoryLeft={
                    <Radio
                      checked={isSelected}
                      status={isSelected ? 'success' : 'basic'}
                      style={ThemeStyle.spacingRight}
                      onChange={() => onSelect(address)}
                    />
                  }
                  title={AddressHelper.getDisplayTitle(address)}
                  subTitle={AddressHelper.getDisplaySubTitle(address)}
                  onPress={() => onSelect(address)}
                  disabled={disabled}
                />
                {!isLast && <Divider style={ThemeStyle.divider} />}
              </View>
            );
          })}
        </Collapsible>
      )}
    </Fragment>
  );
};
const styles = StyleSheet.create({
  container: { marginLeft: constants.isWeb ? 10 : 0 },
  contentContainer: { marginLeft: constants.isWeb ? -5 : 0, columnGap: 10 },
  rightIcon: { width: 15, height: 15 },
});
export default LastUsedAddresses;
