import lodashIsEmpty from 'lodash/isEmpty';
import constants from '../Config/constants';
const { PICKUP_DEFAULT_TRANSPARENT_STORE, PICKUP_DEFAULT_GREEN_STORE } =
  constants;

const getResizedImage = (uri = '', size = constants.ImageSize.MEDIUM) => {
  const whiteList = ['.amazonaws.com/items/'];

  const status = whiteList.find((item) => uri.includes(item));

  if (!lodashIsEmpty(status)) {
    const indexPosition = uri.lastIndexOf('.') || 0;
    const newString =
      uri.substring(0, indexPosition) +
      '_' +
      size +
      uri.substring(indexPosition);

    return newString;
  }

  return uri;
};

const getStoreBannerImage = (data, isGreenBG) => {
  const placeHolder = isGreenBG
    ? PICKUP_DEFAULT_GREEN_STORE
    : PICKUP_DEFAULT_TRANSPARENT_STORE;

  return data?.partner_banner || data?.banner || placeHolder;
};

const getOrderImage = (data) => {
  return (
    data?.partner_logo ||
    data?.store_logo ||
    data?.banner ||
    PICKUP_DEFAULT_GREEN_STORE
  );
};

const getStoreLogoImage = (data) => data?.partner_logo;

const isPickupDefaultBanner = (banner) => banner?.includes('pickup-default');

export default {
  getResizedImage,
  getStoreBannerImage,
  getStoreLogoImage,
  getOrderImage,
  isPickupDefaultBanner,
};
