import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Animated, View } from 'react-native';
import { Text, withStyles } from '@ui-kitten/components';
import lodashFindIndex from 'lodash/findIndex';
import lodashIsEqual from 'lodash/isEqual';
import lodashPick from 'lodash/pick';

import ThemeStyle from '../Theme/styles';
import ThemeColor from '../Theme/colors';
import { globalSpacing } from '../Theme/styles';

import Button from './Button';

const SIZES = ['small', 'medium'];
const defaultBorderRadius = 50;
class SwitchTab extends React.PureComponent {
  constructor(props) {
    super(props);
    this.shouldUpdateState = React.createRef();
    this.state = {
      activeIndex: 0,
      whiteTextIndex: 0,
      leftPosition: new Animated.Value(0),
    };
  }

  componentDidMount() {
    this.shouldUpdateState.current = true;
    this._updateActiveIndex();
  }

  componentDidUpdate(prevProps) {
    const keys = ['animationEnabled', 'data', 'value'];
    const prevData = lodashPick(prevProps, keys);
    const currentData = lodashPick(this.props, keys);
    if (!lodashIsEqual(prevData, currentData)) {
      // if value on keys is not same on current props, update active index
      this._updateActiveIndex();
    }
  }

  componentWillUnmount() {
    this.shouldUpdateState.current = false;
  }

  _updateActiveIndex = () => {
    // this also update the selected highlight
    const { value, data } = this.props;
    const updatedActiveIndex = lodashFindIndex(data, value);
    if (this.shouldUpdateState.current) {
      this.setState({ activeIndex: updatedActiveIndex });
      this.animateOverlay(updatedActiveIndex);
    }
  };

  animateOverlay = (index) => {
    const { animationEnabled } = this.props;
    const animationDuration = animationEnabled ? 200 : 0;
    // set timeout for half animation duration
    setTimeout(() => {
      if (this.shouldUpdateState.current) {
        this.setState({ whiteTextIndex: index });
      }
    }, animationDuration / 2);
    // animation of overlay
    Animated.timing(this.state.leftPosition, {
      toValue: index,
      duration: animationDuration,
      useNativeDriver: false,
    }).start();
  };

  _renderItem = (d, i) => {
    const { size, onChange, renderItem } = this.props;
    const { activeIndex, whiteTextIndex } = this.state;
    const isSmall = size === SIZES[0];
    const isButtonActive = activeIndex === i;
    const isTextActive = whiteTextIndex === i;
    if (typeof renderItem === 'function') {
      return renderItem(d, i, {
        isButtonActive,
        isTextActive,
      });
    } else {
      return (
        <Button
          key={i}
          onPress={() => !isButtonActive && onChange(d)}
          style={isSmall ? styles.button : styles.buttonMedium}
          testID={d.testID || `switchButton${i}`}
          plain
        >
          <Text
            category="p2"
            numberOfLines={1}
            style={[
              styles.text,
              {
                color: isTextActive ? ThemeColor.white : ThemeColor.black,
              },
            ]}
            adjustsFontSizeToFit
          >
            {d.label}
          </Text>
        </Button>
      );
    }
  };

  render() {
    const { activeColor, containerStyle, data, eva } = this.props;

    const widthPercent = `${100 / data.length}%`;
    const leftPosition = this.state.leftPosition.interpolate({
      inputRange: [0, data.length],
      outputRange: ['0%', '100%'],
    });
    const overlayRadius =
      typeof containerStyle?.borderRadius !== 'undefined'
        ? containerStyle.borderRadius
        : defaultBorderRadius;

    return (
      <View style={{ overflow: 'hidden' }}>
        <Animated.View
          style={[
            styles.overlay,
            {
              borderRadius: overlayRadius,
              width: widthPercent,
              left: leftPosition,
            },
            activeColor && { backgroundColor: activeColor },
          ]}
        />
        <View
          style={[
            eva.style.backgroundOverlay,
            { borderRadius: overlayRadius },
            activeColor && { backgroundColor: `${activeColor}1A` },
          ]}
        />
        <View style={[styles.container, containerStyle]}>
          {data.map(this._renderItem)}
        </View>
      </View>
    );
  }
}
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderColor: ThemeColor.gray400,
    borderRadius: defaultBorderRadius,
    borderWidth: 1,
    zIndex: 1,
  },
  overlay: [
    ThemeStyle.justifyContentCenter,
    {
      position: 'absolute',
      height: '100%',
      borderColor: ThemeColor.green,
      backgroundColor: ThemeColor.green,
      borderRadius: 50,
      zIndex: 1,
    },
  ],
  button: {
    flex: 1,
    padding: 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonMedium: {
    flex: 1,
    padding: globalSpacing / 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
    textTransform: 'capitalize',
  },
});

SwitchTab = withStyles(SwitchTab, (theme) => ({
  backgroundOverlay: {
    backgroundColor: theme['color-success-inactive'],
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
}));

const dataShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

SwitchTab.defaultProps = {
  animationEnabled: true,
  size: SIZES[0],
};

SwitchTab.propTypes = {
  activeColor: PropTypes.string,
  animationEnabled: PropTypes.bool,
  containerStyle: PropTypes.object,
  data: PropTypes.arrayOf(dataShape).isRequired,
  size: PropTypes.oneOf(SIZES),
  size: PropTypes.oneOf(SIZES),
  value: dataShape,
  onChange: PropTypes.func.isRequired,
  renderItem: PropTypes.func,
};

export { SIZES };
export default SwitchTab;
