import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';

import { getCartTerm } from '../../Helper/RemoteConfig';
import { getCurrency } from '../../Helper/Routines';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

class ProductCart extends React.PureComponent {
  render() {
    const { isUpdate, comparePrice, price, submitting } = this.props;
    // used shouldShrink for changing category type instead of adjustsFontSizeToFit props on text, because we have multiple Text component on a row
    // const shouldShrink = submitting && (comparePrice > 999 || price > 999);
    const textCategory = 'p1'; // no need shouldShrink since text is now smaller than before

    return (
      <View style={styles.wrapper}>
        <View
          style={[
            ThemeStyle.flex1,
            ThemeStyle.flexDirectionRowCenter,
            ThemeStyle.spacingRightMedium,
          ]}
        >
          {submitting && (
            <View style={ThemeStyle.spacingRightMedium}>
              <ActivityIndicator color={ThemeColor.white} />
            </View>
          )}

          <Text
            category={textCategory}
            appearance="alternative"
            style={ThemeStyle.flex1}
            numberOfLines={1}
            adjustsFontSizeToFit
          >
            {isUpdate ? 'Update' : 'Add to'} {getCartTerm()}
          </Text>
        </View>

        <View style={ThemeStyle.flexDirectionRowCenter}>
          {!!comparePrice && (
            <Fragment>
              <Text
                category={textCategory}
                appearance="alternative"
                style={[ThemeStyle.strikethrough, ThemeStyle.dimColor]}
              >
                {getCurrency(comparePrice)}
              </Text>

              <View style={ThemeStyle.rowSeparatorSmall} />
            </Fragment>
          )}

          <Text
            category={textCategory}
            appearance="alternative"
            style={[!!comparePrice && ThemeStyle.noticeTextLight]}
          >
            {getCurrency(price)}
          </Text>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.pageVerticalSpacingMedium,
    ...ThemeStyle.flexDirectionRowCenterSpaceBetween,
    ...ThemeStyle.shadow,
    backgroundColor: ThemeColor.green,
    borderRadius: 50,
  },
});

ProductCart.propTypes = {
  comparePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isUpdate: PropTypes.bool,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  submitting: PropTypes.bool,
};

export default ProductCart;
