import { StyleSheet } from 'react-native';

import ThemeStyle from '../../Theme/styles';

// common style for every component in OrderDetails
export default StyleSheet.create({
  icon: {
    ...ThemeStyle.spacingRightMedium,
    width: 20,
    height: 20,
  },
});
