import React, { Fragment, useEffect, useRef } from 'react';
import { View, TouchableOpacity, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import StyleSheet from 'react-native-media-query';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty as lodashIsEmpty, find as lodashFind } from 'lodash';
import { Card, Icon, Text, useTheme } from '@ui-kitten/components';

import constants from '../../../Config/constants';

import { updateCheckoutDetails } from '../../../RTK/checkout';
import { checkout } from '../../../RTK/defaultValues';

import Service from '../../../Service';

import useFavorite from '../../../Hooks/useFavorite';
import useMealPlan from '../../../Hooks/useMealPlan';

import ThemeStyle from '../../../Theme/styles';
import ThemeColor from '../../../Theme/colors';
import BreakPoints from '../../../Theme/styles/breakpoints';

import Button from '../../../Components/Button';
import { RectangleLoader } from '../../../Components/Loader';

import useDetailsController from '../controller/details';

import routeList from '../../../Routes/list';

import { formatDistance, formatPrepTime } from '../../../Helper';

import { checkoutOrderTypeSelector } from '../../../RTK/checkout/selectors';
import { shopSelector } from '../../../RTK/shop/selectors';
import OrderTypeNote from '../OrderTypeNote';

import RatingText from '../../../Components/RatingText';

import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';

import { RAZZLE_BUILD_MODE } from '@env';
import messages from '../../../Config/messages';

import StoreOverview from './Overview';

const isBranded = RAZZLE_BUILD_MODE === 'branded';
const StoreDescription = () => {
  const store_theme = useTheme();
  const { isSavedToFavourite, toggleFavourite } = useFavorite();

  const dispatch = useDispatch();
  const navigation = useNavigation();

  const orderTypeHasBeenSet = useRef();

  const shopData = useSelector(shopSelector);

  const { showStoreNotAcceptingAdvancedOrder, hasRating, getStoreStatus } =
    useDetailsController({
      additionalCondition: true,
      isModal: false,
    });

  const { showModalPrompt } = useModalPrompt();
  const { itemRequired } = useMealPlan();

  const selectedOrderType = useSelector((state) =>
    checkoutOrderTypeSelector(state, shopData?.id, true)
  );

  let paramOrderType = '';
  const store_id = shopData?.id;
  const distance = shopData?.distance;
  // Check if running in web
  if (constants.isWeb) {
    if (isBranded) {
      const params = new URLSearchParams(document?.location?.search); // check url params for order_type
      paramOrderType = params.get('order_type') || paramOrderType; // default order type as string
    }
  }

  const searchTagsFilter = useSelector((state) => state.filter.search.tags);
  const homeTagsFilter = useSelector((state) => state.filter.home.tags);
  const homeOrderTypeFilter = lodashFind(homeTagsFilter, {
    paramName: 'order_type',
  });

  useEffect(() => {
    if (!lodashIsEmpty(shopData) && !orderTypeHasBeenSet.current) {
      // check for previous screen only for search
      // because aside from search, other screen to access store page must use the homepage tag filter
      const routes = navigation.getState().routes; // get routes
      const prevRoutes = routes[routes.length - 2]; // get previous routes
      const isPreviousRouteSearch =
        prevRoutes?.name === routeList.SEARCH ? true : false; // is previous page is search ?
      const homeOrderTypeFilter = lodashFind(homeTagsFilter, {
        paramName: 'order_type',
      }); // get tag filter on homepage
      const searchOrderTypeFilter = lodashFind(searchTagsFilter, {
        paramName: 'order_type',
      }); // get tag filter on search page
      const tagFilter = isPreviousRouteSearch
        ? searchOrderTypeFilter
        : homeOrderTypeFilter; // if previous route is search: use the search tag filter, else home tag filter
      if (!lodashIsEmpty(tagFilter)) {
        _changeOrderTypeToSelectedTagFilter(tagFilter);
      }
      orderTypeHasBeenSet.current = true; // for flag, for this not to be called every change of menu (meal plan to non meal plan vice versa)
    }
    if (!selectedOrderType?.value) {
      _changeOrderTypeToSelectedTagFilter(homeOrderTypeFilter);
    }
  }, [shopData]);

  const _changeOrderTypeToSelectedTagFilter = (orderTypeFilter) => {
    const orderType = orderTypeFilter?.values[0].value || paramOrderType;
    const type = lodashFind(constants.ORDER_TYPE_DATA, { value: orderType });
    if (type) {
      dispatch(
        updateCheckoutDetails({
          store_id: store_id,
          keyToUpdate: checkout.keys.ORDER_TYPE,
          keyValue: type,
        })
      );
    }
  };

  const _moreInfoPress = () =>
    showModalPrompt(MODALPROMPT.storeInformation, { shopName: shopData?.name });

  const _onButtonPressed = () => {
    const url = Service.getStoreMapUrl(
      shopData.location,
      shopData?.geocode_location?.lat,
      shopData?.geocode_location?.lng
    );
    Linking.openURL(url);
  };

  if (lodashIsEmpty(shopData)) {
    return (
      <View
        dataSet={{ media: ids.loadingSkeletonContainer }}
        style={styles.loadingSkeletonContainer}
      >
        <View style={ThemeStyle.spacingTopMedium}>
          <RectangleLoader width={250} height={30} />
        </View>
        <View style={ThemeStyle.flexDirectionRowSpaceBetween}>
          <View style={ThemeStyle.spacingTopMedium}>
            <RectangleLoader width={150} height={20} />
          </View>
          <View style={ThemeStyle.spacingTopMedium}>
            <RectangleLoader width={100} height={20} />
          </View>
        </View>

        <View style={ThemeStyle.flexDirectionRowSpaceBetween}>
          <View>
            <View style={ThemeStyle.spacingTopMedium}>
              <RectangleLoader width={150} height={20} />
            </View>
            <View style={ThemeStyle.spacingTopMedium}>
              <RectangleLoader width={150} height={20} />
            </View>
          </View>
          <View style={styles.loading_column_2_container}>
            <View style={ThemeStyle.spacingTopMedium}>
              <RectangleLoader width={130} height={25} />
            </View>
            <View style={ThemeStyle.spacingTopMedium}>
              <RectangleLoader width={130} height={25} />
            </View>
          </View>
        </View>
      </View>
    );
  }
  return (
    <View style={styles.wrapper}>
      {/*
          store name section
          */}
      <View
        dataSet={{ media: ids.storeDescription }}
        style={styles.storeDescription}
      >
        {showStoreNotAcceptingAdvancedOrder && (
          <Card
            status="danger"
            style={styles.notAcceptingAdvanceOrderContainer}
          >
            <Text category="s2" style={styles.notAcceptingOrderTxt}>
              {messages.STORE_NOT_ACCEPTING_ADVANCE_ORDER_MSG}
            </Text>
          </Card>
        )}

        <Text category="h5">{shopData?.name}</Text>
        <View
          dataSet={{ media: ids.storeDescriptionRow }}
          style={styles.storeDescriptionRow}
        >
          <View
            style={[
              ThemeStyle.flex1,
              ThemeStyle.flexWrap,
              ThemeStyle.spacingRightMedium,
            ]}
          >
            <StoreOverview />
          </View>
          <View
            dataSet={{ media: ids.storeButtonContainer }}
            style={styles.storeButtonContainer}
          >
            {!isBranded && (
              <Button
                testID="save_btn"
                style={styles.more_info_button}
                onPress={toggleFavourite({ id: store_id })}
                plain
              >
                <Icon
                  name={isSavedToFavourite(store_id) ? 'heart' : 'heart-o'}
                  fill={ThemeColor.green}
                  pack="fa"
                  style={styles.control_icon}
                />
                <View
                  dataSet={{ media: ids.storeLabel }}
                  style={styles.storeLabel}
                >
                  <Text category="c1">
                    {isSavedToFavourite(store_id)
                      ? 'Remove from My Favorites'
                      : 'Add to My Favorites'}
                  </Text>
                </View>
              </Button>
            )}

            <Button
              testID="more_info_btn"
              onPress={_moreInfoPress}
              style={styles.more_info_button}
              plain
            >
              <Icon
                name="info"
                fill={store_theme['icon_color'] || ThemeColor.green}
                style={styles.control_icon}
              />

              <View
                dataSet={{ media: ids.storeLabel }}
                style={styles.storeLabel}
              >
                <Text category="c1">Store Info</Text>
              </View>
            </Button>
          </View>
        </View>

        <OrderTypeNote
          mealPlanRequiredItem={itemRequired}
          storeAddress={shopData.location}
          type={selectedOrderType?.value || paramOrderType}
          goToLocation={_onButtonPressed}
        />
      </View>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  storeDescription: {
    [`@media ${BreakPoints.xs}`]: {
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingLeft: 20,
      marginHorizontal: 50,
    },
  },
  storeDescriptionRow: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.flexDirectionRowEnd,
    [`@media ${BreakPoints.xs}`]: {
      marginRight: -20, // remove margin right on mobile due to whole container of 1st column on web
    },
  },
  storeLabel: {
    alignItems: 'flex-start',
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'flex',
    },
  },
  storeButtonContainer: {
    columnGap: 10,
    rowGap: 10,
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  more_info_button: {
    display: 'flex',
    borderRadius: 20,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: ThemeColor.lightGray,
    borderWidth: 1,
    borderColor: ThemeColor.lightGray,
    padding: 10,
    columnGap: 5,
  },
  control_icon: { width: 21, height: 21 },
  loadingSkeletonContainer: {
    ...ThemeStyle.spacingTopLarge,
    [`@media ${BreakPoints.xs}`]: {
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingLeft: 20,
      marginHorizontal: 50,
    },
  },
  loading_column_2_container: {
    ...ThemeStyle.flexDirectionRowSpaceBetween,
    width: '30%',
  },
  notAcceptingAdvanceOrderContainer: { backgroundColor: ThemeColor.red100 },
  notAcceptingOrderTxt: { textAlign: 'center' },
});

export default StoreDescription;
