import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text } from '@ui-kitten/components';
import Modal from 'react-native-modal';

import constants from '../Config/constants';
import messages from '../Config/messages';
import ThemeColor from '../Theme/colors';
import ThemeStyle, { deviceHeight } from '../Theme/styles';

import Button from './Button';

export default class extends React.PureComponent {
  render() {
    const { show, onClose } = this.props;

    return (
      <Modal
        isVisible={show}
        animationIn="fadeIn"
        animationOut="fadeOut"
        presentationStyle="overFullScreen"
        deviceHeight={deviceHeight}
        useNativeDriver
        statusBarTranslucent
      >
        <View style={styles.wrapper}>
          <View
            style={[
              ThemeStyle.pageVerticalSpacing,
              ThemeStyle.pageHorizontalSpacing,
            ]}
          >
            <Text category="h5" style={ThemeStyle.spacingBottomMedium}>
              {messages.APP_UPDATE_TITLE}
            </Text>
            <Text style={ThemeStyle.spacingBottomMedium}>
              {messages.APP_UPDATE_MESSAGE}
            </Text>

            {!constants.patchRequired && (
              <Button
                onPress={onClose}
                style={ThemeStyle.alignSelfEnd}
                hitSlop={20}
                plain
              >
                <Text style={styles.text}>Ok, I'll update later</Text>
              </Button>
            )}
          </View>
        </View>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    ...ThemeStyle.alignSelfCenter,
    ...ThemeStyle.justifyContentCenter,
    width: '95%',
    backgroundColor: ThemeColor.white,
    borderRadius: 5,
  },
  text: {
    color: ThemeColor.darkGreen,
  },
});
