import React, { useMemo, useState } from 'react';
import { View } from 'react-native';
import Map, { Marker, Polyline } from '../../../Components/Map';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';

const MapWeb = ({ route, navigation }) => {
  const map = React.useRef(null);
  const [region, setRegion] = useState({
    latitude: 0,
    longitude: 0,
  });
  useMemo(() => {
    geocodeByPlaceId(route?.params.place_id)
      .then((results) => {
        console.log(JSON.stringify(results));
        setRegion({
          longitude: results[0].geometry.location.lng(),
          latitude: results[0].geometry.location.lat(),
        });
      })
      .catch((error) => console.error(error));
  }, []);

  const userLoc = { latitude: 14.205389, longitude: 121.348766 };
  const riderLoc = {
    latitude: 14.218146791242958,
    longitude: 121.34773766621947,
  };
  const polylines = [
    { latitude: 14.205389, longitude: 121.348766 },
    { latitude: 14.20546, longitude: 121.34889 },
    { latitude: 14.205699, longitude: 121.34903 },
    { latitude: 14.206, longitude: 121.349308 },
    { latitude: 14.207958, longitude: 121.350097 },
    { latitude: 14.208782, longitude: 121.350866 },
    { latitude: 14.209945, longitude: 121.350102 },
    { latitude: 14.210454, longitude: 121.348979 },
    { latitude: 14.21204, longitude: 121.344626 },
    { latitude: 14.212321, longitude: 121.343179 },
    { latitude: 14.213002, longitude: 121.343906 },
    { latitude: 14.213158, longitude: 121.344689 },
    { latitude: 14.214095, longitude: 121.346351 },
    { latitude: 14.215206, longitude: 121.345395 },
    { latitude: 14.215653, longitude: 121.345663 },
    { latitude: 14.216639, longitude: 121.345159 },
    { latitude: 14.217325, longitude: 121.346596 },
    { latitude: 14.217481, longitude: 121.346536 },
    { latitude: 14.218146791242958, longitude: 121.34773766621947 },
  ];

  const _onPolyLineLoad = () => {
    map.current._addressForCoordinate(userLoc);
    map.current._fitBounds([userLoc, riderLoc]);
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <Map ref={map} region={region} zoom={20} panTo={region}>
          <Marker
            coordinate={region}
            icon="https://cdn-icons-png.flaticon.com/512/2060/2060329.png"
          />
        </Map>
      </View>
    </View>
  );
};

export default MapWeb;
