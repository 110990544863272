import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, SafeAreaView, Linking } from 'react-native';
import { Divider, Icon, Text } from '@ui-kitten/components';
import Tooltip from 'rn-tooltip';
import lodashIsEmpty from 'lodash/isEmpty';

import CurrentLocation from '../../Assets/currentLocation.png';
import Button from '../../Components/Button';
import Image from '../../Components/Image';
import ListItem from '../../Components/ListItem';
import OrderProgress from '../../Components/OrderProgress';
import constants from '../../Config/constants';
import withNavigation from '../../Hooks/withNavigation';
import routeList from '../../Routes/list';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

class MapOverlay extends React.PureComponent {
  componentDidMount() {
    setTimeout(this.smsTooltip.toggleTooltip, 1500);
  }

  _onClosePressed = () => {
    this.props.navigation.goBack();
    this.props.navigation.navigate(routeList.BASKET);
  };

  _onHelpPressed = () => {
    this.props.navigation.navigate(routeList.GET_HELP, { statusBar: false });
  };

  _onCallPressed = () => {
    const { rider_details } = this.props.orderDetails;
    Linking.openURL(`tel:${rider_details.phone}`);
  };

  _onSMSPressed = () => {
    const { rider_details } = this.props.orderDetails;
    const sampleComposedMessage =
      'Lorem ipsum dolor sit amet, consectetuer adipiscing elit';
    Linking.openURL(`sms:${rider_details.phone}?body=${sampleComposedMessage}`);
  };

  _onViewAllDetailsPressed = () => {
    this.props.navigation.navigate(routeList.ORDER_DETAILS, {
      fromOrderTracking: true,
      ...this.props.orderDetails,
    });
  };

  _renderTooltip = () => {
    return (
      <View style={ThemeStyle.flexDirectionRow}>
        <Icon
          name="cards-diamond"
          pack="material"
          fill={ThemeColor.white}
          style={[styles.iconSmall, ThemeStyle.spacingRightSmall]}
        />
        <Text
          category="label"
          appearance="alternative"
          style={[ThemeStyle.flex1, ThemeStyle.spacingRightSmall]}
        >
          You can now message your rider directly within the app!
        </Text>
        <Icon name="close" fill={ThemeColor.white} style={styles.iconSmall} />
      </View>
    );
  };

  _renderAccessoryRight = () => {
    return (
      <>
        <Button
          testID="callRider"
          style={ThemeStyle.buttonContentGraySmallCircle}
          onPress={this._onCallPressed}
          plain
        >
          <Icon name="phone" fill={ThemeColor.black} style={styles.iconSmall} />
        </Button>

        <View style={ThemeStyle.rowSeparator} />

        <Tooltip
          ref={(r) => (this.smsTooltip = r)}
          actionType="none"
          width={305}
          height={50}
          withOverlay={false}
          containerStyle={{
            borderRadius: 5,
            padding: 5,
            backgroundColor: ThemeColor.darkGreen,
          }}
          pointerColor={ThemeColor.darkGreen}
          popover={this._renderTooltip()}
        >
          <Button
            testID="messageRider"
            style={ThemeStyle.buttonContentGraySmallCircle}
            onPress={this._onSMSPressed}
            plain
          >
            <Icon
              name="message-text"
              pack="material"
              fill={ThemeColor.black}
              style={[styles.iconSmall, ThemeStyle.flipHorizontal]}
            />
          </Button>
        </Tooltip>
      </>
    );
  };

  render() {
    const { currentLocation, orderDetails, onCurrentLocationPress } =
      this.props;
    const { estimate_arrival, rider_details, store_details } = orderDetails;

    return (
      <View testID="mapOverlay" style={styles.wrapper}>
        <View style={styles.container}>
          <View
            style={[
              ThemeStyle.flex1,
              ThemeStyle.flexDirectionColumnSpaceBetween,
            ]}
          >
            {/* close button, help and current location button */}
            <View style={ThemeStyle.flexDirectionRowSpaceBetween}>
              {/* close button */}
              <View
                style={[
                  ThemeStyle.alignItemsStart,
                  ThemeStyle.spacingLeft,
                  ThemeStyle.spacingTopMedium,
                ]}
              >
                {/* Close button */}
                <Button
                  testID="closeButton"
                  onPress={this._onClosePressed}
                  plain
                >
                  <View style={[styles.button, ThemeStyle.spacingBottom]}>
                    <Icon
                      name="close"
                      fill={ThemeColor.black}
                      style={styles.iconSmall}
                    />
                  </View>
                </Button>

                {/* Reset button (will show when press current location) */}
                {!lodashIsEmpty(currentLocation) && (
                  <Button
                    testID="resetLocationButton"
                    onPress={() => onCurrentLocationPress(true)}
                    plain
                  >
                    <View style={styles.button}>
                      <Text style={ThemeStyle.bold}>Reset</Text>
                    </View>
                  </Button>
                )}
              </View>

              <View
                style={[
                  ThemeStyle.alignItemsEnd,
                  ThemeStyle.spacingRight,
                  ThemeStyle.spacingTopMedium,
                ]}
              >
                {/* Help button */}
                <Button testID="helpButton" onPress={this._onHelpPressed} plain>
                  <View
                    style={[
                      styles.button,
                      styles.buttonHelp,
                      ThemeStyle.spacingBottom,
                    ]}
                  >
                    <Text style={ThemeStyle.bold}>Help</Text>
                  </View>
                </Button>

                {/* current location button */}
                <Button
                  testID="currentLocationButton"
                  onPress={() => onCurrentLocationPress(false)}
                  plain
                >
                  <View style={styles.button}>
                    <Image source={CurrentLocation} style={styles.iconSmall} />
                  </View>
                </Button>
              </View>
            </View>

            {/* order status / details */}
            <SafeAreaView>
              <View
                style={[
                  ThemeStyle.pageVerticalSpacing,
                  ThemeStyle.pageHorizontalSpacing,
                ]}
              >
                <View style={styles.orderDetailsContainer}>
                  <Text category="h6" style={ThemeStyle.spacingBottomSmall}>
                    Picking up your order
                  </Text>

                  <View style={ThemeStyle.flexDirectionRowCenter}>
                    <Text>Arrives between: </Text>
                    <Text style={ThemeStyle.bold}>{estimate_arrival}</Text>
                  </View>

                  <View style={ThemeStyle.pageVerticalSpacing}>
                    <OrderProgress progress={45} />
                  </View>

                  <Text style={ThemeStyle.dimColor}>
                    {store_details.name} is preparing your order. Your delivery
                    rider is on the way to you.
                  </Text>

                  <Divider
                    style={[
                      ThemeStyle.spacingTopMedium,
                      ThemeStyle.spacingBottomMedium,
                      ThemeStyle.divider,
                    ]}
                  />

                  <ListItem
                    title="Your Delivery Rider"
                    subTitle={rider_details.name}
                    accessoryRight={this._renderAccessoryRight()}
                  />

                  <Divider
                    style={[
                      ThemeStyle.spacingTopMedium,
                      ThemeStyle.spacingBottomMedium,
                      ThemeStyle.divider,
                    ]}
                  />

                  <Button
                    testID="viewDetails"
                    onPress={this._onViewAllDetailsPressed}
                    plain
                  >
                    <View
                      style={[
                        ThemeStyle.flexDirectionRowCenter,
                        ThemeStyle.alignSelfCenter,
                        ThemeStyle.pageVerticalSpacingSmall,
                      ]}
                    >
                      <Text
                        category="label"
                        style={ThemeStyle.spacingRightMedium}
                      >
                        View all details
                      </Text>
                      <Icon
                        name="arrow-ios-downward-outline"
                        fill={ThemeColor.black}
                        style={styles.iconSmall}
                      />
                    </View>
                  </Button>
                </View>
              </View>
            </SafeAreaView>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  wrapper: {
    ...StyleSheet.absoluteFillObject,
    ...ThemeStyle.fitWidthContainer,
    position: 'absolute',
    zIndex: 1,
  },
  container: {
    ...ThemeStyle.flex1,
    paddingTop: constants.statusBarHeight,
  },
  button: {
    ...ThemeStyle.shadowThin,
    ...ThemeStyle.fullRoundCorder,
    ...ThemeStyle.pageBackground,
    padding: globalSpacing / 3,
  },
  buttonHelp: {
    paddingVertical: globalSpacing / 3,
    paddingHorizontal: globalSpacing / 2,
  },
  iconSmall: {
    width: 20,
    height: 20,
  },
  orderDetailsContainer: {
    ...ThemeStyle.shadow,
    ...ThemeStyle.pageBackground,
    ...ThemeStyle.pageVerticalSpacingMedium,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    borderRadius: 10,
  },
});

MapOverlay = withNavigation(MapOverlay);

MapOverlay.propTypes = {
  currentLocation: PropTypes.any,
  orderDetails: PropTypes.any,
  onCurrentLocationPress: PropTypes.func.isRequired,
};

export default MapOverlay;
