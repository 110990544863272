import React, { Fragment } from 'react';
import { View, Linking, ViewStyle } from 'react-native';
import { Text, Icon, Divider, Layout } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';

import AppStore from '../../Assets/AppStore.svg';
import GooglePlayStore from '../../Assets/GooglePlayStore.svg';
import useToast from '../../Hooks/useToast';
import links from '../../Config/externalLinks';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';
import breakPoints from '../../Theme/styles/breakpoints';

import Button from '../Button';

const COMPANY = [
  { title: 'Blog', link: links.BLOG },
  { title: 'About', link: links.ABOUT },
  { title: 'Contact', link: links.CONTACT },
];

const PARTNERSHIPS = [
  { title: 'Pickup for enterprise', link: links.ENTERPRISE },
  { title: 'Charities', link: links.CHARITIES },
  { title: 'Pickup ambassador', link: links.AMBASSADOR },
];

const SUPPORT = [
  { title: 'FAQs', link: links.FAQ },
  { title: 'Report an issue' },
];

const LEGAL = [
  { title: 'Terms of service', link: links.TERMS_OF_SERVICES },
  { title: 'Privacy policy', link: links.PRIVACY_POLICY },
  { title: 'Trust centre', link: links.TRUST_CENTRE },
];

const LINKS = [
  { title: 'Company', data: COMPANY },
  { title: 'Partnerships', data: PARTNERSHIPS },
  { title: 'Support', data: SUPPORT },
  { title: 'Legal', data: LEGAL },
];

const PLATFORMS = [
  { image: <AppStore />, link: links.APP_STORE },
  { image: <GooglePlayStore />, link: links.PLAY_STORE },
];

const SOCIALS = [
  { name: 'facebook', link: links.FACEBOOK },
  { name: 'instagram', link: links.INSTRAGRAM },
  { name: 'youtube', link: links.YOUTUBE },
];

const Footer = () => {
  const toast = useToast();

  const _onLinkPress = (link?: string) => {
    if (link) Linking.openURL(link);
    else toast.show('No link available', false);
  };

  return (
    <Fragment>
      <Divider style={ThemeStyle.divider} />
      <Layout style={styles.wrapper as ViewStyle}>
        {/* 1st Row */}
        <View
          dataSet={{ media: ids.firstRowContainer }}
          style={styles.firstRowContainer as ViewStyle}
        >
          {/* Links */}
          <View
            dataSet={{ media: ids.linksWrapper }}
            style={styles.linksWrapper as ViewStyle}
          >
            {LINKS.map((group, i) => (
              <View
                dataSet={{ media: ids.linksContainer }}
                style={styles.linksContainer as ViewStyle}
                key={i}
              >
                <Text category="c1">{group.title}</Text>
                {group.data.map((links, k) => (
                  <Button
                    key={`${i}-${k}`}
                    onPress={() => _onLinkPress(links.link)}
                    plain
                  >
                    <Text category="c1" status="primary">
                      {links.title}
                    </Text>
                  </Button>
                ))}
              </View>
            ))}
          </View>
          {/* Platforms */}
          <View
            dataSet={{ media: ids.platformContainer }}
            style={styles.platformContainer as ViewStyle}
          >
            {PLATFORMS.map((platform, i) => (
              <Button
                key={i}
                dataSet={{ media: ids.platformButton }}
                style={styles.platformButton}
                onPress={() => _onLinkPress(platform.link)}
                plain
              >
                {platform.image}
              </Button>
            ))}
          </View>
        </View>

        {/* 2nd Row */}
        <View
          dataSet={{ media: ids.secondRowContainer }}
          style={styles.secondRowContainer as ViewStyle}
        >
          <Text category="c1" status="primary">
            Copyright © 2024 Pickup.ph. This site was made with ❤️ in the
            Philippines.
          </Text>
          <View style={styles.socialContainer as ViewStyle}>
            {SOCIALS.map((social, i) => (
              <Button key={i} onPress={() => _onLinkPress(social.link)} plain>
                <Icon
                  name={social.name}
                  fill={ThemeColor.darkGray}
                  pack="fa"
                  style={styles.icon}
                />
              </Button>
            ))}
          </View>
        </View>
      </Layout>
    </Fragment>
  );
};

const { ids, styles } = StyleSheet.create({
  wrapper: {
    ...ThemeStyle.pageVerticalSpacingMedium,
    // @ts-ignore: gap/columnGap/rowGap is not recognizing as style but it works on web
    columnGap: globalSpacing / 2,
    // @ts-ignore: gap/columnGap/rowGap is not recognizing as style but it works on web
    rowGap: globalSpacing,
  },
  firstRowContainer: {
    ...ThemeStyle.flexDirectionRowSpaceBetween,
    // @ts-ignore: gap/columnGap/rowGap is not recognizing as style but it works on web
    rowGap: globalSpacing,
    flexDirection: 'column-reverse',
    [`@media ${breakPoints.md}`]: {
      flexDirection: 'row',
    },
  },
  linksWrapper: {
    ...ThemeStyle.flexDirectionRowSpaceBetween,
    ...ThemeStyle.flexWrap,
    // @ts-ignore: gap/columnGap/rowGap is not recognizing as style but it works on web
    columnGap: globalSpacing * 2,
    // @ts-ignore: gap/columnGap/rowGap is not recognizing as style but it works on web
    rowGap: globalSpacing * 2,
    [`@media ${breakPoints.xs}`]: {
      columnGap: globalSpacing,
      rowGap: globalSpacing,
    },
  },
  linksContainer: {
    // @ts-ignore: gap/columnGap/rowGap is not recognizing as style but it works on web
    rowGap: globalSpacing / 2,
    [`@media (max-width: 260px)`]: {
      flexBasis: '100%',
      alignItems: 'center',
    },
    [`@media (max-width: 374px)`]: {
      flexBasis: '45%',
    },
  },
  platformContainer: {
    // @ts-ignore: gap/columnGap/rowGap is not recognizing as style but it works on web
    gap: globalSpacing / 2,
    ...ThemeStyle.flexDirectionRow,
    [`@media (max-width: 350px)`]: {
      ...ThemeStyle.flexDirectionCol,
    },
    [`@media ${breakPoints.md}`]: {
      ...ThemeStyle.flexDirectionCol,
    },
  },
  platformButton: {
    backgroundColor: ThemeColor.darkGray,
    borderRadius: 5,
    alignItems: 'center',
    flex: undefined, // remove flex on md and above
    [`@media ${breakPoints.xs}`]: {
      ...ThemeStyle.flex1,
    },
    [`@media ${breakPoints.sm}`]: {
      ...ThemeStyle.flex1,
    },
  },
  secondRowContainer: {
    ...ThemeStyle.flexDirectionRowSpaceBetween,
    [`@media ${breakPoints.xs}`]: {
      alignItems: 'center',
      flexDirection: 'column-reverse',
      // @ts-ignore: gap/columnGap/rowGap is not recognizing as style but it works on web
      rowGap: globalSpacing / 2,
      textAlign: 'center',
    },
  },
  socialContainer: {
    ...ThemeStyle.flexDirectionRowCenter,
    // @ts-ignore: gap/columnGap/rowGap is not recognizing as style but it works on web
    columnGap: globalSpacing / 2,
  },
  icon: { width: 20, height: 20 },
});

export default Footer;
