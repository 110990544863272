import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Alert, StyleSheet, View } from 'react-native';
import { Divider, Text } from '@ui-kitten/components';
import dayjs from 'dayjs';
import lodashFindIndex from 'lodash/findIndex';
import lodashIsEmpty from 'lodash/isEmpty';

import DatePicker from '../../Components/DatePicker';
import EmptyData from '../../Components/EmptyData';
import List from '../../Components/List';
import modals from '../../Components/Sheets/modals';
import StoreCartLoader from '../../Components/StoreCart/Loader';
import Toast from '../../Components/Toast';
import messages from '../../Config/messages';
import AnalyticsHelper from '../../Helper/Analytics';
import CheckoutHelper from '../../Helper/Checkout';
import useSetSpecificUserData from '../../Hooks/useSetSpecificUserData';
import withCart from '../../Hooks/withCart';
import withController from '../../Hooks/withController';
import { allCartSelector } from '../../RTK/cart/selectors';
import { updateCheckoutDetails } from '../../RTK/checkout';
import { checkout } from '../../RTK/defaultValues';
import { addressSelector } from '../../RTK/user/selectors';
import { setScrollCartToStoreId } from '../../RTK/utility';
import routeList from '../../Routes/list';
import Service from '../../Service';
import ThemeStyle from '../../Theme/styles';

import StoreCartService from './StoreCartService';

const rescheduleDefaultState = {
  scrollEnabled: true,
  cartStoreId: '',
  rescheduleDateOptions: [],
  rescheduleMaxDate: undefined,
  rescheduleMinDate: undefined,
  rescheduleValue: dayjs().toISOString(),
  showRescheduleSelection: false,
};

class CartList extends React.Component {
  state = rescheduleDefaultState;

  // as of now the code on componentDidUpdate is only for scrolling to store cart / to top
  componentDidUpdate() {
    // prettier-ignore
    const { cartData, fetchingCartData, scrollCartToStoreId, dispatchSetScrollCartToStoreId } = this.props

    // for scrolling the cart to store id, this will get trigger if user press the floating cart in store page
    // only proceed if has scrollCartToStoreId, page is not loading
    if (scrollCartToStoreId && !fetchingCartData.loading && cartData.length) {
      // get list index in cart redux base on scrollCartToStoreId
      const listIndex = lodashFindIndex(cartData, {
        store_id: scrollCartToStoreId,
      });
      // added delay before scrolling because when user is not yet visited the cart page it will have loading state first before scrolling
      // and aside from that, the loading time of sectionlist to display all the data
      setTimeout(() => {
        this.cartList?._scrollToIndex?.({
          index: listIndex !== -1 ? listIndex : 0,
          viewOffset: 8,
        });
      }, 500);
      dispatchSetScrollCartToStoreId(); // clear scrollCartToStoreId in redux
    }
  }

  /* ==================== BELOW BEHAVIOUR FUNCTIONS ==================== */
  _onItemPressed = (itemData) => {
    AnalyticsHelper.itemSelection({
      ...itemData,
      fromSection: 'Store Cart Item',
    });
    modals.show(modals.PRODUCT, {
      isUpdate: true,
      id: itemData.item_id,
      ...itemData,
    });
  };

  _getDaysInAdvanceOrder = ({
    is_accepting_in_advanced_orders,
    days_accepting_in_advanced_orders,
  }) =>
    is_accepting_in_advanced_orders ? days_accepting_in_advanced_orders : 0;

  _onProceedToOrderPressed = () => {
    const { navigation, userAddresses } = this.props;
    if (userAddresses.length <= 0) {
      Toast.show(
        'Please add your address before proceeding to checkout',
        Toast.TYPE_ERROR
      );
    } else {
      navigation.navigate(routeList.CHECKOUT);
    }
  };

  _onStoreNamePressed = (store) => {
    AnalyticsHelper.storeSelection({ ...store, fromSection: 'Store Cart' });
    this.props.navigation.navigate(routeList.STORE, { id: store.store_id });
  };

  _onRescheduleClose = () => this.setState(rescheduleDefaultState);

  _onReschedule = (storeInfo, storeCheckoutData) => {
    const getDate = ({ date, time }) =>
      new Date(dayjs(`${date} ${time}`).valueOf());
    const stateToUpdate = {
      cartStoreId: storeInfo.id,
      showRescheduleSelection: true,
    };
    // generate date option
    const dateOptions = Service.generateDateOptions({
      storeHours: storeInfo.store_hours,
      offDates: storeInfo.off_dates,
      daysInAdvance: this._getDaysInAdvanceOrder(storeInfo),
      prepTime: storeInfo.pre_order_to_order_queue_timer,
    });
    // store date option first and last for min and max date
    stateToUpdate.rescheduleDateOptions = dateOptions;
    stateToUpdate.rescheduleMinDate = getDate(dateOptions[0]);
    stateToUpdate.rescheduleMaxDate = getDate(
      dateOptions[dateOptions.length - 1]
    );
    // conditional for reschedule default value
    if (
      !lodashIsEmpty(storeCheckoutData) && // store cart has checkout data
      storeCheckoutData?.[checkout.keys.DELIVERY_SCHEDULE]?.date // and delivery schedule is not asap
    ) {
      stateToUpdate.rescheduleValue =
        storeCheckoutData[checkout.keys.DELIVERY_SCHEDULE].value;
    }
    this.setState(stateToUpdate); // update state
  };

  _onScheduleSelection = async (date) => {
    const { cartStoreId, rescheduleDateOptions } = this.state;
    this._onRescheduleClose(); // close date picker and to reset the state
    const result = CheckoutHelper.getSelectedSchedule(
      date,
      rescheduleDateOptions
    );
    if (result?.error) {
      return Alert.alert(
        messages.STORE_CLOSED.title,
        messages.STORE_CLOSED.message
      );
    }
    this.props.dispatchUpdateCheckoutDetails({
      store_id: cartStoreId,
      keyToUpdate: checkout.keys.DELIVERY_SCHEDULE,
      keyValue: result,
    });
  };

  _changeScrollEnabled = (value) => {
    this.setState({ scrollEnabled: value });
  };

  /* ==================== BELOW RENDER FUNCTIONS ==================== */
  _renderEmptyList = () => (
    <View style={styles.emptyListContaner}>
      <EmptyData type="cart" />
    </View>
  );

  _renderItem = ({ item, index }) => {
    if (item.loading) {
      return <StoreCartLoader withProceedToOrderButton />;
    }
    return (
      <StoreCartService
        // key props in this is very important because this component is not getting rerender if you add/update cart
        // so the memory or local state of this component retain on where this is located on the list (index)
        // meaning, if this component is in index 0 and you add/update cart and index 0 will change and the local state of previous index 0
        // will carry over on the new index 0 cart
        key={item.store_id}
        index={index}
        storeData={item}
        setScrollEnabled={this._changeScrollEnabled}
        onItemPress={this._onItemPressed}
        onProceedToOrderPress={this._onProceedToOrderPressed}
        onReschedule={this._onReschedule}
        onStoreNamePress={this._onStoreNamePressed}
      />
    );
  };

  _keyExtractor = (item, index) => item?.store_id || index.toString();

  _renderSectionItemSeparator = () => (
    <Divider style={ThemeStyle.sectionSeparator} />
  );

  render() {
    const { controller, cartData, fetchingCartData } = this.props;
    const {
      rescheduleMaxDate,
      rescheduleMinDate,
      rescheduleValue,
      scrollEnabled,
      showRescheduleSelection,
    } = this.state;

    const listData = fetchingCartData.loading
      ? [{ loading: true }, { loading: true }]
      : cartData;

    return (
      <Fragment>
        <List
          ref={(r) => (this.cartList = r)}
          contentContainerStyle={{ flexGrow: 1 }}
          data={listData}
          ItemSeparatorComponent={this._renderSectionItemSeparator}
          keyExtractor={this._keyExtractor}
          ListEmptyComponent={this._renderEmptyList}
          renderItem={this._renderItem}
          pullToRefreshProps={{
            refreshing: listData.filter((e) => e.loading).length !== 0,
            onRefresh: () => controller.setCartData(true),
          }}
          scrollEnabled={scrollEnabled}
        />
        {/* Reschedule date selection */}
        <DatePicker
          open={showRescheduleSelection}
          value={rescheduleValue}
          onCancel={this._onRescheduleClose}
          onConfirm={this._onScheduleSelection}
          minimumDate={rescheduleMinDate}
          maximumDate={rescheduleMaxDate}
          title="Select another time slot"
          cancelText="Cancel"
        />
      </Fragment>
    );
  }
}

CartList = withCart(CartList);
CartList = withController(CartList, useSetSpecificUserData);

const styles = StyleSheet.create({
  emptyListContaner: [
    ThemeStyle.flex1,
    ThemeStyle.pageVerticalSpacing,
    ThemeStyle.pageHorizontalSpacing,
    ThemeStyle.alignItemsCenter,
  ],
});

const mapStateToProps = (state) => ({
  cartData: allCartSelector(state),
  fetchingCartData: state.utility.gettingUserData.cart,
  scrollCartToStoreId: state.utility.scrollCartToStoreId,
  userAddresses: addressSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchSetScrollCartToStoreId: (data) =>
    dispatch(setScrollCartToStoreId(data)),
  dispatchUpdateCheckoutDetails: (data) =>
    dispatch(updateCheckoutDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartList);
