import React, { Fragment } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';

import TextInput from '../../Components/TextInputDebounce';
import ThemeStyle from '../../Theme/styles';
import breakpoints from '../../Theme/styles/breakpoints';

function ThirdPartyAndVehicleInputs({ disabled, fields, values, onChange }) {
  return (
    <View dataSet={{ media: ids.wrapper }} style={styles.wrapper}>
      {fields.map((field, i) => (
        <Fragment key={i}>
          <View
            dataSet={{ media: ids.container }}
            style={[
              styles.container,
              i !== fields.length - 1
                ? ThemeStyle.spacingBottomMedium
                : ThemeStyle.spacingBottomSmall,
            ]}
          >
            <TextInput
              direction="column"
              disabled={disabled}
              label={field.label}
              required
              labelProps={{ numberOfLines: 1 }}
              type={field.type}
              value={values?.[field.key] || ''}
              onChange={onChange(field.key)}
              withBackground
            />
          </View>
          {i !== fields.length - 1 && (
            <View dataSet={{ media: ids.separator }} style={styles.separator} />
          )}
        </Fragment>
      ))}
    </View>
  );
}

const { ids, styles } = StyleSheet.create({
  wrapper: {
    [`@media ${breakpoints.sm}`]: ThemeStyle.flexDirectionRowCenterSpaceBetween,
  },
  container: {
    [`@media ${breakpoints.sm}`]: ThemeStyle.flex1,
  },
  separator: {
    [`@media ${breakpoints.sm}`]: ThemeStyle.rowSeparator,
  },
});

export default ThirdPartyAndVehicleInputs;
