import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Alert } from 'react-native';
import dayjs from 'dayjs';
import lodashOmit from 'lodash/omit';
import lodashIsEmpty from 'lodash/isEmpty';

import Service from '../Service';
import userApi from '../Service/api/user';

import modals from '../Components/Sheets/modals';
import constants from '../Config/constants';
import useSession from '../Hooks/useSession';
import useModalPrompt from '../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';

import { errorState, setErrorMessage } from '../RTK/error';
import {
  setRememberEmail as setRememberEmailAction,
  removeCountdown,
} from '../RTK/noSession';
import { clearGuestData, saveUserAddress, setAsLoggedIn } from '../RTK/user';
import { addressSelector } from '../RTK/user/selectors';
import { MODALPROMPT } from '../Components/Web/Modal/ModalPrompt/config';
import useCancellableRequest from '../Hooks/useCancellableRequest';
import { CANCEL_ERROR } from 'apisauce';
import Sentry from '../Helper/Sentry';
import useDeepLinking from './useDeepLinking';

type FormData = {
  email?: string;
  remember?: boolean;
  session_expired?: boolean;
};
type Actions = {
  resetForm: () => void;
  validateForm: (data) => void;
  validateField: (data) => void;
  setErrors: (error: any) => void;
  setFieldError: (data) => void;
  setSubmitting: (data: boolean) => void;
};
type OTPError = {
  title: string;
  message: string;
};
type SendOtpError = {
  api: OTPError;
};
export const FORM_TYPE = {
  SIGNIN: 'signin',
  SIGNUP: 'signup',
  OTP: 'otp',
};
const useAuth = () => {
  const routeParams = !constants.isWeb
    ? useRoute<any>()
    : { session_expired: false };
  // from route params, needed on hooks (e.g: useState)
  const isFromSessionExpired = routeParams?.session_expired;
  const paramsData = lodashOmit(routeParams, ['session_expired']);
  const currentForm = useRef<string>(FORM_TYPE.SIGNIN);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { redirectDeepLinking } = useDeepLinking();

  const deepLinkingData = useSelector(
    (state: any) => state.noSession.deepLinkingData
  );

  const { createRequest } = useCancellableRequest();
  const { sendOtp, verifyOtp } = useSession();
  const { showModalPrompt, hideModalPrompt } = useModalPrompt();

  const { countdown, rememberEmail } = useSelector(
    (state: any) => state.noSession
  );
  const userAddress = useSelector(addressSelector);
  const [payload, setPayload] = useState(paramsData);
  const [formType, setFormType] = useState(FORM_TYPE.SIGNIN);
  const [isOTPResending, setOTPResending] = useState(false);
  const [isOTPSubmitting, setOTPSubmitting] = useState(false);

  const timer = dayjs(countdown).diff(dayjs(), 'second');

  useEffect(() => {
    if (isFromSessionExpired) {
      _onSigninSubmit(paramsData, {
        resetForm: () => {},
        validateForm: (data) => {},
        validateField: (data) => {},
        setErrors: (error: any) => {},
        setFieldError: (data) => {},
        setSubmitting: (data: boolean) => {},
      });
    }
  }, []);

  const _goToSignUpForm = () => {
    currentForm.current = FORM_TYPE.SIGNUP;
    setFormType(FORM_TYPE.SIGNUP);
  };

  const _onBackSignup = () => {
    currentForm.current = FORM_TYPE.SIGNIN;
    setFormType(FORM_TYPE.SIGNIN);
  };
  const _onBack = () => {
    if (currentForm.current === FORM_TYPE.SIGNUP) {
      setFormType(FORM_TYPE.SIGNUP);
    } else {
      setFormType(FORM_TYPE.SIGNIN);
    }
  };

  const _onCountdownEnd = () => dispatch(removeCountdown());

  const _onSigninSubmit = async (formData: FormData, actions: Actions) => {
    // it will be called manually on useEffect if user is from session expired
    const setSubmitting = actions?.setSubmitting;
    const setErrors = actions?.setErrors;
    await sendOtp({
      formData,
      onSuccess: () => {
        setPayload(formData);
        setFormType(FORM_TYPE.OTP);
      },
      onError: (error: SendOtpError) => {
        if (constants.isWeb) {
          const errorTitle = !lodashIsEmpty(error.api.title)
            ? error.api.title
            : 'Ooops!';
          const errorMessage = !lodashIsEmpty(error)
            ? error.api.message
            : error;
          dispatch(
            setErrorMessage({
              errorTitle: errorTitle,
              errorMessage: errorMessage,
            })
          );
          dispatch(errorState({ isError: true }));
        } else {
          setErrors?.(error);
        }
      },
    });
    setSubmitting?.(false);
  };

  const _onOTPSubmit = async (otp) => {
    const { email, remember } = payload;
    setOTPSubmitting(true);
    await verifyOtp({
      formData: { email, otp },
      onSuccess: () => {
        const hasNoDeepLink = lodashIsEmpty(deepLinkingData);
        dispatch(setRememberEmailAction(remember ? email : undefined));
        if (constants.isWeb) {
          if (!lodashIsEmpty(userAddress)) {
            dispatch(clearGuestData()); //to reset the temporary data by guest user
          }

          hideModalPrompt(MODALPROMPT.authentication, {});

          if (hasNoDeepLink) {
            setTimeout(() => window.location.reload(), 200);
          }
        }
        if (!hasNoDeepLink) {
          redirectDeepLinking(navigation);
        }
      },
      onError: async (error: OTPError) => {
        if (constants.isWeb) {
          dispatch(
            setErrorMessage({
              errorTitle: 'Ooops!',
              errorMessage: error.message,
            })
          );
          dispatch(errorState({ isError: true }));
        } else {
          modals.show(modals.PROMPT, {
            ...error,
            buttonStatus: 'success',
            buttonText: 'Ok',
          });
        }
      },
    });
    setOTPSubmitting(false);
  };

  const _onOTPSubmitSignUp = async (otp) => {
    const { email } = payload;
    setOTPSubmitting(true);

    await verifyOtp({
      formData: { email, otp },
      shouldLogin: false,
      onSuccess: async () => {
        const signupPayload = {
          first_name: payload?.first_name,
          last_name: payload?.last_name,
          email: payload?.email,
          phone_number: payload.phone_number,
        };
        const { ok, data, status, problem } = await createRequest(
          userApi.update,
          signupPayload
        );

        // stop the code from going if request is cancelled
        if (problem === CANCEL_ERROR) {
          setOTPSubmitting(false);
          return;
        }

        if (ok) {
          setOTPSubmitting(false);
          dispatch(setAsLoggedIn());
          if (constants.isWeb) {
            if (!lodashIsEmpty(userAddress)) {
              await _saveAddressForNewUser();
              dispatch(clearGuestData()); //to reset the temporary data by guest user
            }
            hideModalPrompt(MODALPROMPT.authentication, {});
            setTimeout(() => {
              window.location.reload();
            }, 200);
            return;
          }
        } else {
          if (constants.isWeb) {
            showModalPrompt(MODALPROMPT.errorModal, {
              title: 'Error',
              message: Service.handleFormError(data?.message),
            });
          } else {
            Alert.alert('Error', Service.handleFormError(data?.message));
          }
        }
      },

      onError: (error) => {
        if (constants.isWeb) {
          dispatch(
            setErrorMessage({
              errorTitle: 'Ooops!',
              errorMessage: error.message,
            })
          );
          dispatch(errorState({ isError: true }));
        } else {
          modals.show(modals.PROMPT, {
            title: error.title,
            message: error.message,
            buttonStatus: 'success',
            buttonText: 'Ok',
          });
        }
      },
    });
    setOTPSubmitting(false);
  };

  const _onOTPResend = async () => {
    setOTPResending(true);
    await sendOtp({
      formData: payload,
      onSuccess: () => {},
      onError: (error) => {
        if (constants.isWeb) {
          dispatch(
            setErrorMessage({
              errorTitle: 'Ooops!',
              errorMessage: error.message,
            })
          );
          dispatch(errorState({ isError: true }));
        } else {
          const title = error.title || error.api.title;
          const message = error.message || error.api.message;
          modals.show(modals.PROMPT, {
            title,
            message,
            buttonStatus: 'success',
            buttonText: 'Ok',
          });
        }
      },
    });
    setOTPResending(false);
  };

  const _saveAddressForNewUser = async () => {
    const payload = {
      place_id: userAddress[0].place_id,
      label: userAddress[0].label,
      address: userAddress[0].formatted_address,
    };
    let addressObject = {};
    const { ok, data, status, problem } = await createRequest(
      userApi.saveAddress,
      payload
    );

    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    if (ok) {
      addressObject = {
        ...data,
        active: true,
      };
    } else {
      Sentry.reportError(`Error adding user address`, data);
      if (status === 400) {
        showModalPrompt(MODALPROMPT.errorModal, {
          title: 'Validation Error',
          message: Service.handleFormError(data?.message),
        });
      } else {
        showModalPrompt(MODALPROMPT.errorModal, {
          title: `Cannot save address`,
          message: Service.handleFormError(data?.message),
        });
      }
      return;
    }
    await dispatch(saveUserAddress(addressObject));
  };

  return {
    isOTPResending,
    isOTPSubmitting,
    isSigninForm: formType === FORM_TYPE.SIGNIN,
    isSignupForm: formType === FORM_TYPE.SIGNUP,
    payload,
    rememberEmail,
    timer,
    currentForm: currentForm.current,
    goToSignUpForm: _goToSignUpForm,
    onBack: _onBack,
    onBackSignup: _onBackSignup,
    onCountdownEnd: _onCountdownEnd,
    onOTPResend: _onOTPResend,
    onOTPSubmit: _onOTPSubmit,
    onOTPSubmitSignUp: _onOTPSubmitSignUp,
    onSigninSubmit: _onSigninSubmit,
  };
};

export default useAuth;
