import { RAZZLE_SENTRY_DSN, SENTRY_DSN } from '@env';
import lodashIsEmpty from 'lodash/isEmpty';

import config from '../../Config';
import constants from '../../Config/constants';

let Sentry: any; // this need to be initialize on load of file of mobile and web (setLibrary called on index.ts and index.web.ts)

const setLibrary = <T>(libraryToUse: T) => {
  Sentry = libraryToUse as T;
};

const init = () => {
  if (!Sentry) {
    throw new Error('Sentry has not been initialized. Call setLibrary first.');
  }
  const DSN = SENTRY_DSN || RAZZLE_SENTRY_DSN;
  if (DSN) {
    const options = {
      dsn: DSN,
      enabled: true,
      environment: config.SENTRY_ENV,
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
    };
    if (constants.isWeb && 'browserTracingIntegration' in Sentry) {
      Object.assign(options, {
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled,
        tracePropagationTargets: ['localhost', /^https:\/\/.*/],
        // Session Replay
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
    Sentry.init(options);
  }
};

const setUser = (payload: any) => Sentry.setUser(payload);

/**
 * Reports an error to a centralized error tracking system, such as Sentry,
 * with optional additional contextual information.
 *
 * @param {Error|string} error - The error object or error message to report.
 * @param {Object} extras - Optional additional contextual information to attach to the error.
 */
const reportError = (error: string, extras: any) => {
  if (!lodashIsEmpty(extras)) {
    Sentry.withScope((scope) => {
      scope.setExtras({ data: JSON.stringify(extras, null, 2) });
      Sentry.captureException(new Error(error));
    });
  } else {
    Sentry.captureException(new Error(error));
  }
};

export default {
  setLibrary,
  init,
  reportError,
  setUser,
};
