import * as React from 'react';
import Svg, { Defs, G, Path, Rect } from 'react-native-svg';

import ThemeColor from '../../Theme/colors';

function TabBarFeed(props) {
  return (
    <Svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1080 1080"
      stroke={props?.color || ThemeColor.darkGray}
      strokeWidth={props?.strokeWidth || 40}
      {...props}
    >
      <Defs></Defs>
      <G id="newspaper">
        <G id="Group_1050" data-name="Group 1050">
          <Path
            id="Path_687"
            data-name="Path 687"
            d="M230.85 804.42h563.7c62.54-2.43 111.32-54.99 109.09-117.53V334.35c1.11-31.26-23.26-57.53-54.51-58.77H340c-31.28 1.2-55.68 27.49-54.57 58.77v381.93c0 48.69-9.36 88.15-54.57 88.15z"
            fill="none"
          />
          <Path
            id="Path_688"
            data-name="Path 688"
            d="M176.28 504.26c0-30.14 24.43-54.57 54.57-54.57h54.57V736.2c0 37.55-16.93 68.22-54.57 68.22s-54.57-30.57-54.57-68.22V504.26z"
            fill="none"
          />
          <Path id="Path_689" data-name="Path 689" d="M666.57 613.41h163.72" />
          <Path id="Path_694" data-name="Path 694" d="M666.57 694.4h163.72" />
          <Path id="Path_691" data-name="Path 691" d="M666.57 454.7h163.72" />
          <Path id="Path_692" data-name="Path 692" d="M666.57 532.43h163.72" />
          <Path id="Path_690" data-name="Path 690" d="M387.91 613.41h155.56" />
          <Path id="Path_693" data-name="Path 693" d="M387.91 694.4h155.56" />
          <Rect
            x={379.68}
            y={381.97}
            width={187.76}
            height={125.17}
            rx={15.65}
            ry={15.65}
            id="Rectangle_537"
            data-name="Rectangle 537"
            fill="none"
          />
        </G>
      </G>
    </Svg>
  );
}

export default TabBarFeed;
