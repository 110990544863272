import { createSlice } from '@reduxjs/toolkit';
import {
  filter as lodashFilter,
  find as lodashFind,
  findIndex as lodashFindIndex,
  isEmpty as lodashIsEmpty,
} from 'lodash';

import CartHelper from '../../Helper/Cart';
import { getCartLimit } from '../../Helper/RemoteConfig';
import { specialInstructionDefaultValues } from '../../Screens/Product/SpecialInstruction';

function getNewArrayForSortingCart(index, data) {
  const dataAsFirst = data[index]; // object
  const otherData = lodashFilter(data, (s) => s.id !== dataAsFirst.id); // array
  return [dataAsFirst, ...otherData];
}

const initialState = {
  cartData: [],
  isAddingOrUpdatingCart: false,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    syncCart: (state, action) => {
      const { data, removeToBeSync } = action.payload;
      const { store_id, prev_cart_details_id, to_be_synced } = data;
      const shopDataIndex = lodashFindIndex(
        state.cartData,
        (d) => d.store_id === store_id
      );
      if (shopDataIndex === -1) {
        // first add to cart to store but failed api call
        state.isAddingOrUpdatingCart = false;
        return;
      }
      const shopItems = state.cartData[shopDataIndex].items;
      const condition = CartHelper.getConditionForItemVariant(data);
      const itemIndexId = lodashFindIndex(shopItems, {
        cart_details_id: prev_cart_details_id,
      });
      const itemIndexByVariant = lodashFindIndex(shopItems, condition);
      const itemCartIndex =
        itemIndexId === -1 ? itemIndexByVariant : itemIndexId;
      if (itemCartIndex !== -1) {
        if (removeToBeSync) {
          delete state.cartData[shopDataIndex].items[itemCartIndex]
            .to_be_synced; // remove to_be_synced object
        } else {
          // if api call not success, and not itemCartIndex is found
          const cartItem = shopItems[itemCartIndex];
          const { newlyAddedQuantity, ...rest } = to_be_synced;
          // what "reverseNewQuantity" do is if user click the reduce quantity we will add the newlyAddedQuantity to redux (vise versa)
          const reverseNewQuantity = 0 - newlyAddedQuantity; // this convert the positive number to negative number (vise versa)
          const revertedQuantity = cartItem.quantity + reverseNewQuantity;
          if (revertedQuantity <= 0) {
            // if quantity to be reverted is less than 0, remove it from cart state
            state.cartData[shopDataIndex].items.splice(itemCartIndex, 1);
            state.isAddingOrUpdatingCart = false;
          } else {
            state.cartData[shopDataIndex].items[itemCartIndex] = {
              ...cartItem, // add everything on the cart item object
              ...rest, // add everything on rest of to_be_synced object
              quantity: revertedQuantity, // reduce the newlyAddedQuantity to the existing quantity
            };
            delete state.cartData[shopDataIndex].items[itemCartIndex]
              .to_be_synced; // remove to_be_synced object
          }
        }
        if (!state.cartData[shopDataIndex].items.length) {
          // if shop cart has no items, remove it
          state.cartData.splice(shopDataIndex, 1);
        }
      } else {
        // if no itemCartIndex, do nothing
      }
      state.isAddingOrUpdatingCart = false; // once sync, already removed the flag
    },
    setCart: (state, action) => {
      state.cartData = action.payload.map((cart) => ({
        ...cart,
        date_updated: new Date().getTime(),
      }));
    },
    setAddingOrUpdatingCart: (state, action) => {
      // for adding loading flag to prevent multiple add to cart, flag will be removed on addToCart/updateCart method
      const hasPayload = typeof action.payload === 'boolean';
      state.isAddingOrUpdatingCart = hasPayload ? action.payload : true;
    },
    addToCart: (state, action) => {
      // cart_id is just to removed it on action.payload
      const { cart_id, store_id, store_name, ...itemDetails } = action.payload;
      const shopDataIndex = lodashFindIndex(
        state.cartData,
        (d) => d.store_id === store_id
      );
      if (shopDataIndex === -1) {
        // save first cart on the store
        state.cartData = [
          // insert new cart as first of list
          {
            store_id,
            store_name,
            date_updated: new Date().getTime(),
            items: [itemDetails],
          },
          ...state.cartData.slice(0, getCartLimit() - 1),
        ];
      } else {
        // search on shop cart data with the same variant / extra group of the item
        const condition = CartHelper.getConditionForItemVariant(itemDetails);
        const itemOnCartIndex = lodashFindIndex(
          state.cartData[shopDataIndex].items,
          condition
        );
        if (itemOnCartIndex === -1) {
          // no existing item on shop cart, push it in shop cart
          state.cartData[shopDataIndex].items = [
            itemDetails,
            ...state.cartData[shopDataIndex].items,
          ];
        } else {
          // has existing item and same variant on the cart, just update the quantity
          state.cartData[shopDataIndex].items[itemOnCartIndex].quantity +=
            itemDetails.quantity;
        }
        // after adding the item to store or updating it's quantity
        // if the shopDataIndex is not zero lets move it to index 0
        if (shopDataIndex !== 0) {
          state.cartData = getNewArrayForSortingCart(
            shopDataIndex,
            state.cartData
          );
        }
      }
      state.isAddingOrUpdatingCart = false; // remove loading flag
    },
    updateCart: (state, action) => {
      // first, find shop index on cart state using the store_id on the payload
      const { prev_cart_details_id, store_id, store_name, ...itemDetails } =
        action.payload;
      const shopDataIndex = lodashFindIndex(
        state.cartData,
        (d) => d.store_id === store_id
      );
      // find itemCartIndex
      const shopCart = state.cartData[shopDataIndex].items;
      const itemCartIndex = lodashFindIndex(shopCart, {
        cart_details_id: prev_cart_details_id,
      });
      // search on shop cart data with the same variant / extra group of the item
      const condition = CartHelper.getConditionForItemVariant(itemDetails);
      const sameItemOnCart = lodashFind(shopCart, condition);
      const hasSameItemOnCart = !lodashIsEmpty(sameItemOnCart);
      if (
        hasSameItemOnCart &&
        sameItemOnCart.cart_details_id !== prev_cart_details_id
      ) {
        // if has same variant on cart but not same cart_details_id, get the quantity of this item and delete it and merge the quantity to the same variant
        const sameVariantIndex = lodashFindIndex(shopCart, {
          cart_details_id: sameItemOnCart.cart_details_id,
        });
        state.cartData[shopDataIndex].items[sameVariantIndex].quantity =
          itemDetails.quantity;
      } else {
        // finally, update the cart data using the shopDataIndex and itemCartIndex to find the exact object on the cart
        state.cartData[shopDataIndex].items[itemCartIndex] = itemDetails;
      }
      // after adding the item to store or updating it's quantity
      // if the shopDataIndex is not zero lets move it to index 0
      if (shopDataIndex !== 0) {
        state.cartData = getNewArrayForSortingCart(
          shopDataIndex,
          state.cartData
        );
      }
      state.isAddingOrUpdatingCart = false; // remove loading flag
    },
    removeCartItem: (state, action) => {
      const { store_id, cart_details_id } = action.payload;
      const shopIndex = lodashFindIndex(state.cartData, { store_id });
      const shopCartItemIndex = lodashFindIndex(
        state.cartData[shopIndex].items,
        {
          cart_details_id,
        }
      );
      // remove shop cart item
      state.cartData[shopIndex].items.splice(shopCartItemIndex, 1);
      if (state.cartData[shopIndex].items.length === 0) {
        // if shop cart has no items, remove it
        state.cartData.splice(shopIndex, 1);
      }
    },
    removeCart: (state, action) => {
      const shopIndex = lodashFindIndex(state.cartData, {
        store_id: action.payload,
      });
      state.cartData.splice(shopIndex, 1);
    },
    reorderCart: (state, action) => {
      const { storeId, storeName, items } = action.payload;
      let shopIndex = lodashFindIndex(state.cartData, { store_id: storeId });
      if (shopIndex === -1) {
        // if no store cart yet, initialize it's cart as first data of cart
        state.cartData = [
          {
            store_id: storeId,
            store_name: storeName,
            date_updated: new Date().getTime(),
            items: [],
          },
          ...state.cartData.slice(0, getCartLimit() - 1),
        ];
        shopIndex = 0;
      }
      // loop through reorder items
      for (let i = 0; i < items.length; i++) {
        // removed item_limit_per_time_slot on item data
        const { item_limit_per_time_slot, ...item } = items[i];
        const itemData = {
          ...item,
          cart_details_id: CartHelper.getCartDetailsId(item),
          instructions: specialInstructionDefaultValues,
          item_id: item.id,
        };
        const cartItemIndex = lodashFindIndex(state.cartData[shopIndex].items, {
          cart_details_id: itemData.cart_details_id,
        });
        if (cartItemIndex === -1) {
          // if no existing item on the store cart, just push it
          state.cartData[shopIndex].items.push(itemData);
        } else {
          // if has existing item on the store cart, replace it with reorder data
          state.cartData[shopIndex].items[cartItemIndex] = itemData;
        }
      }
    },
    updateLastValidateTime: (state, action) => {
      const shopIndex = lodashFindIndex(state.cartData, {
        store_id: action.payload,
      });
      state.cartData[shopIndex].date_updated = new Date().getTime();
    },
  },
});

export const {
  setCart,
  setAddingOrUpdatingCart,
  addToCart,
  updateCart,
  removeCartItem,
  removeCart,
  reorderCart,
  syncCart,
  updateLastValidateTime,
} = cartSlice.actions;
export default cartSlice.reducer;
