import AddressDetails from './AddressDetails';
import Authentication from './Authentication';
import ChangeTimeSchedule from './ChangeTimeSchedule';
import ChangeDateTimeSchedule from './ChangeDateTimeSchedule';
import CompleteInformation from './CompleteInformation';
import DeliveryAddress from './DeliveryAddress';
import DistanceSelector from './DistanceSelector';
import EventOrderSummary from './EventOrderSummary';
import ListAddress from './ListAddress';
import Item from './Item';
import MealPlanTypeSelector from './MealPlanTypeSelector';
import OrderAgain from './OrderAgain';
import OrderType from './OrderType';
import Reviews from './Reviews';
import StoreRatings from './StoreRatings';

import StoreInformation from './StoreInformation';
import EventDatePicker from './EventDatePicker';
import Error from './Error';
import Prompt from './Prompt';
import Promotion from './Promotion';
import PublicReviewsNotice from './PublicReviewsNotice';
import RatingSelector from './RatingSelector';

import { registerModal } from '../hooks/provider';

import { MODALPROMPT } from './config';

import modals from '../../../Sheets/modals';
import QRPreview from '../../../Sheets/QRPreview';
import EventFilter from './EventFilter';

registerModal(MODALPROMPT.addressDetails, AddressDetails);
registerModal(MODALPROMPT.authentication, Authentication);
registerModal(MODALPROMPT.changeTimeSchedule, ChangeTimeSchedule);
registerModal(MODALPROMPT.changeDateAndTimeSchedule, ChangeDateTimeSchedule);
registerModal(MODALPROMPT.completeInformation, CompleteInformation);
registerModal(MODALPROMPT.eventDatePicker, EventDatePicker);
registerModal(MODALPROMPT.deliveryAddress, DeliveryAddress);
registerModal(MODALPROMPT.distanceSelector, DistanceSelector);
registerModal(MODALPROMPT.eventOrderSummary, EventOrderSummary);
registerModal(MODALPROMPT.eventFilter, EventFilter);
registerModal(MODALPROMPT.listAddress, ListAddress);
registerModal(MODALPROMPT.itemModal, Item);
registerModal(MODALPROMPT.mealPlanTypeSelector, MealPlanTypeSelector);
registerModal(MODALPROMPT.orderAgain, OrderAgain);
registerModal(MODALPROMPT.orderType, OrderType);
registerModal(MODALPROMPT.storeRatingsModal, StoreRatings);
registerModal(MODALPROMPT.ratingsSelector, RatingSelector);
registerModal(MODALPROMPT.reviewsModal, Reviews);
registerModal(MODALPROMPT.storeInformation, StoreInformation);
registerModal(MODALPROMPT.errorModal, Error);
registerModal(MODALPROMPT.prompt, Prompt);
registerModal(MODALPROMPT.promotion, Promotion);
registerModal(MODALPROMPT.publicReviewsNotice, PublicReviewsNotice);
registerModal(modals.QR_PREVIEW, QRPreview);
