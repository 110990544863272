import { useEffect, useState } from 'react';
import { useToast } from 'react-native-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import { CANCEL_ERROR } from 'apisauce';

import modals from '../../Components/Sheets/modals';
import Toast from '../../Components/Toast';
import constants from '../../Config/constants';
import { capitalize } from '../../Helper';
import AnalyticsHelper from '../../Helper/Analytics';
import SentryHelper from '../../Helper/Sentry';
import useCancellableRequest from '../../Hooks/useCancellableRequest';
import useGoBack from '../../Hooks/useGoBack';
import { setOrderDetails } from '../../RTK/utility';
import { orderDetailsSelector } from '../../RTK/utility/selectors';
import routeList from '../../Routes/list';
import Service from '../../Service';
import userApi from '../../Service/api/user';
import useModalPrompt from '../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../Components/Web/Modal/ModalPrompt/config';
import { isLoggedInSelector } from '../../RTK/user/selectors';
import messages from '../../Config/messages';

const { ERROR_ORDER_DETAILS } = messages;

function useOrderDetails({ navigation, route }) {
  const { createRequest } = useCancellableRequest();
  const { showModalPrompt, hideModalPrompt } = useModalPrompt();
  const dispatch = useDispatch();
  const goBack = useGoBack();
  const toast = useToast();

  const isLogin = useSelector(isLoggedInSelector);
  const orderDetails = useSelector(orderDetailsSelector);
  const [isLoading, setIsLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [product, setProduct] = useState({});
  const [errorFetchingOrderDetails, setErrorFetchingOrderDetails] = useState({
    title: '',
    message: '',
  });

  const isFromOrderTracking = lodashGet(route, 'params.fromOrderTracking');
  const ratingParams = lodashGet(route, 'params.rating');
  const transactionNumberParams = lodashGet(route, 'params.id');
  const statusBar = !isFromOrderTracking; // if from order tracking screen screwrapper statusbar props to false

  useEffect(() => {
    if (isLogin) {
      if (constants.isWeb && transactionNumberParams && ratingParams) {
        setTimeout(() => {
          showModalPrompt(MODALPROMPT.storeRatingsModal, {
            id: transactionNumberParams,
            defaultStar: ratingParams,
          });
        }, 500); // added delay because on web modal is not showing if no delay
      }
      if (!lodashIsEmpty(orderDetails)) {
        // already has data, just remove the loading
        setIsLoading(false);
      } else if (transactionNumberParams) {
        // user is in order rating page but no order details on redux, fetch it using transaction number on url params
        _getOrderHistoryData();
      } else {
        // if user is in order details page and has no order details on redux and no id on url parameter
        goBack();
      }
    } else {
      goBack();
    }
  }, []);

  const _getOrderHistoryData = async () => {
    const { ok, data, problem } = await createRequest(
      userApi.getOrder,
      transactionNumberParams
    );
    if (problem === CANCEL_ERROR) {
      // stop the code from going if request is cancelled
      return;
    }
    if (ok) {
      dispatch(setOrderDetails(data));
    } else {
      SentryHelper.reportError('Error getting order details', data);
      const ErroMessage = Service.handleErrorMessage(data?.message);

      if (ErroMessage === ERROR_ORDER_DETAILS.title) {
        setErrorFetchingOrderDetails({
          title: ErroMessage,
          message: ' ',
        });
      } else {
        setErrorFetchingOrderDetails({
          title: ErroMessage,
          message: 'Unable to load order details, Please try again.',
        });
      }
    }
    setIsLoading(false);
  };

  const _onAddOrViewOrderRatingPressed = () => {
    if (constants.isWeb) {
      showModalPrompt(MODALPROMPT.storeRatingsModal, {
        id: orderDetails?.transaction_number,
      });
    } else {
      navigation.navigate(routeList.ORDER_RATING, {
        id: orderDetails?.transaction_number,
      });
    }
  };

  const _onStoreNamePressed = () => {
    AnalyticsHelper.storeSelection({
      ...orderDetails.store_details,
      fromSection: 'Order Details',
    });
    navigation.navigate(routeList.STORE, { id: orderDetails.store_details.id });
  };

  const _onItemPressed = (item) => () => {
    AnalyticsHelper.itemSelection({ ...item, fromSection: 'Order Details' });
    if (constants.isWeb) {
      setModal(true);
      setProduct(item);
      return;
    }
    modals.show(modals.PRODUCT, { id: item.id });
  };

  const _onClosed = () => setModal(false);

  const _onCopyPressed = (dataToCopy, context) => {
    const msg = `${capitalize(context)} copied successfully.`;
    Service.copyToClipboard(dataToCopy);
    if (constants.isWeb) {
      toast.show(msg, {
        type: 'success',
        placement: 'top',
        duration: 2000,
      });
    } else {
      Toast.show(msg);
    }
  };

  const onClosedModalRatings = () =>
    hideModalPrompt(MODALPROMPT.storeRatingsModal, {});

  return {
    errorFetchingOrderDetails,
    isLoading,
    modalRatings: modal,
    orderDetails,
    product,
    statusBar,
    onAddOrViewOrderRatingPressed: _onAddOrViewOrderRatingPressed,
    onClosed: _onClosed,
    onCopyPressed: _onCopyPressed,
    onItemPressed: _onItemPressed,
    onStoreNamePressed: _onStoreNamePressed,
    setModal,
    setProduct,
    onClosedModalRatings,
  };
}

export default useOrderDetails;
