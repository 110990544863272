import { StyleSheet, View } from 'react-native';
import { Divider, Icon, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import Button from '../../Components/Button';
import MobileDatePicker from '../../Components/DatePicker';
import EventBanner from './components/EventBanner';
import Header from '../../Components/Header';
import { LoadingIndicator } from '../../Components/Loader';
import ScreenWrapper from '../../Components/ScreenWrapper';
import constants from '../../Config/constants';
import DateHelper from '../../Helper/Date';
import { getCurrency } from '../../Helper/Routines';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import useEventDetailsViewModel from './useEventDetailsViewModel';

const EventDetails = () => {
  const {
    buyEventTicketData,
    data,
    isLoading,
    onBuyTickets,
    onScheduleSelected,
    onScheduleSelectionCancelled,
  } = useEventDetailsViewModel();
  const {
    image,
    item,
    name,
    description,
    location_address,
    start_date,
    end_date,
    available,
  } = data;
  const isSoldOut = available === 0;
  const price = item?.price;
  const backText = 'Back to events';

  const conditionalProps = !constants.isWeb && {
    header: <Header alignTitle="left" title={backText} />,
  };
  if (isLoading) {
    return <LoadingIndicator isOpen={isLoading} />;
  }

  return (
    <ScreenWrapper
      {...conditionalProps}
      persistentScrollbar
      showsVerticalScrollIndicator
    >
      <View
        style={[
          ThemeStyle.flex1,
          constants.isWeb
            ? ThemeStyle.pageVerticalSpacing
            : ThemeStyle.pageHorizontalSpacing,
        ]}
      >
        <EventBanner uri={image} isSoldOut={isSoldOut} />
        <View style={[ThemeStyle.flex1, ThemeStyle.pageVerticalSpacing]}>
          {/* Store Name */}
          <Text
            category="h5"
            numberOfLines={3}
            style={ThemeStyle.spacingBottomMedium}
            adjustsFontSizeToFit
          >
            {name}
          </Text>
          <View style={ThemeStyle.spacingBottomMedium}>
            {/* Address */}
            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Icon
                name="pin-outline"
                fill={ThemeColor.green}
                style={styles.icon}
              />
              <Text category="p2" status="primary">
                {location_address}
              </Text>
            </View>
            {/* Date and Time*/}
            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Icon
                name={'calendar-outline'}
                fill={ThemeColor.green}
                style={styles.icon}
              />
              <Text
                category="p2"
                status="primary"
              >{`${DateHelper.formatDateOnlyRange(
                start_date,
                end_date
              )}`}</Text>
            </View>

            <View style={ThemeStyle.flexDirectionRowCenter}>
              <Icon
                name={'clock-outline'}
                fill={ThemeColor.green}
                style={styles.icon}
              />
              <Text
                category="p2"
                status="primary"
              >{`${DateHelper.formatTimeRange(start_date, end_date)}`}</Text>
            </View>
          </View>
          {/* Buy Ticket */}
          <View
            style={[
              ThemeStyle.flexDirectionRow,
              ThemeStyle.spacingBottomMedium,
            ]}
          >
            <Button
              status="success"
              onPress={onBuyTickets(data)}
              disabled={isSoldOut}
            >
              {() => (
                <Text
                  status={isSoldOut ? 'basic' : 'control'}
                  style={ThemeStyle.pageHorizontalSpacingSmall}
                >
                  Buy Ticket {getCurrency(price)}
                </Text>
              )}
            </Button>
          </View>
          {/* Description */}
          <View style={ThemeStyle.spacingBottomMedium}>
            <Text category="s1">About</Text>
            <Divider style={ThemeStyle.divider} />
          </View>
          <Text category="p2" style={{ lineHeight: 22 }}>
            {description}
          </Text>
        </View>
      </View>

      {!constants.isWeb && (
        <MobileDatePicker
          cancelText="Cancel"
          maximumDate={buyEventTicketData?.datePicker?.maxDate}
          minimumDate={buyEventTicketData?.datePicker?.minDate}
          mode="date"
          open={!lodashIsEmpty(buyEventTicketData)}
          title={buyEventTicketData?.datePicker?.title}
          value={buyEventTicketData?.datePicker?.minDate}
          onCancel={onScheduleSelectionCancelled}
          onConfirm={onScheduleSelected}
        />
      )}
    </ScreenWrapper>
  );
};

const styles = StyleSheet.create({
  icon: {
    width: 21,
    height: 21,
    marginRight: 8,
  },
});

export default EventDetails;
