import { createContext, useContext } from 'react';

import { AddressItemDetails } from '../Types';

type Asap = {
  value: 'asap';
  label: 'ASAP';
  date: '';
  time: '';
  canAsap: true;
};

type Validate = {
  key: string;
  label?: string;
  message?: string;
  validationSchema?: any;
  getValidationPayload?: (data: object) => object;
};

type CanPlaceOrder = {
  ok: boolean;
  isClosed: boolean;
  isClosing: boolean;
};

type WebSetError = {
  errorType: string;
  title: string;
  message: string;
  unavailableItems: Array<any>;
};

type WhenFilter = { date: string; label: string; time: string; value: string };

interface CheckoutProps {
  // useCheckout hooks
  // TODO - add other return of useCheckout hooks
  cartItemUpdatingById: string;
  deliveryQuotation?: {
    fee: any;
    priority_fee: number;
    loading: boolean;
    error: string;
    mode: string;
    codFreeDel: boolean;
  };
  hasBulk?: boolean;
  noUserDetails?: any;
  state?: any;
  storeMealPlanDeliveryDiscount?: any;
  storeMealPlanDiscount?: any;
  toBeApply?: any;
  getDeliveryQuotation?: (onLoad?: boolean) => void;
  validateForm?: (value: Validate) => void;
  // useNormalCheckout hooks
  isStoreHoursLoading?: boolean;
  storeCartData?: Array<any>;
  storeCheckoutData?: object;
  storeHours?: Array<any>;
  storeHoursError?: any;
  whenFilter?: WhenFilter;
  isCanPlaceOrder?: (storeInfo: any) => Promise<CanPlaceOrder>;
  onRequestCheckout?: () => Promise<void>;
  refetchStoreHours?: () => void;
  // useCheckoutWeb hooks
  ASAP?: Asap;
  scheduleList?: Array<any>;
  itemToDelete?: any;
  showConfirmSingleModal?: boolean;
  changeDateTimeSchedule?: boolean;
  changeTimeSchedule?: { isShow: boolean; title: string };
  placingOrder?: boolean;
  error?: WebSetError;
  deliverySchedule?: any;
  isClosed?: boolean;
  isClosing?: boolean;
  isAcceptingAdvanceOrder?: boolean;
  backToHome?: () => void;
  onDeleteCancel?: () => void;
  onDeliveryScheduleChange?: (value: any) => void;
  onLastUsedAddressPress?: (value: any) => void;
  onOrderTypeChange?: (value: any) => void;
  onPaymentOptionChanged?: (value: any) => void;
  onPromoCodeChange?: (value: string) => void;
  onPlaceOrderError?: (value: any) => void;
  onPressActiveAddress?: (item: AddressItemDetails) => void;
  onProceedChangeSchedule?: (value: WhenFilter) => void;
  onThirdPartyPickupPress?: (value: any) => void;
  onUpdateQuantity?: (value: any) => () => Promise<void>;
  onUserDetailsChange?: (key: string, value: string) => void;
  onVehicleInformationChange?: (value: any) => void;
  onVehicleOptionChanged?: (value: any) => void;
  onRequestCheckouMealPlan?: () => Promise<void>;
  proceedToCheckoutMealPlan?: () => Promise<void>;
  proceedCheckout?: () => Promise<void>;
  removeThisProductOnCart?: () => Promise<void>;
  reschedule?: () => void;
  setChangeDateTimeSchedule?: (value: boolean) => void;
  setChangeTimeSchedule?: (value: { isShow: boolean; title: string }) => void;
  setError?: (value: WebSetError) => void;
  setShowConfirmSingleModal?: (value: boolean) => void;
  toggleConfirmDeleteModal?: (item: any, type: any) => () => void;
  toggleLocateAddressModal?: (item: AddressItemDetails) => void;
  togglePlaceOrder?: () => void;
  toggleScheduleListModal?: () => void;
  toggleViewAddressModal?: () => void;
}

export const CheckoutNavigatorContext = createContext({});
export const useCheckoutNavigatorContext = () =>
  useContext<CheckoutProps>(CheckoutNavigatorContext);
