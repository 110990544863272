import React from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';

import pkg from '../../../app.json';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import constants from '../../Config/constants';

function PublicReviewNotice() {
  return (
    <ScrollView bounces={false}>
      {!constants.isWeb && (
        <View
          style={[
            ThemeStyle.flexWrap,
            ThemeStyle.flexDirectionRowCenter,
            ThemeStyle.spacingBottom,
          ]}
        >
          <Text category="h6">Public reviews on </Text>
          <Text category="h6" style={ThemeStyle.capitalize}>
            {pkg.displayName}
          </Text>
        </View>
      )}

      <View style={ThemeStyle.spacingBottom}>
        <View
          style={[ThemeStyle.spacingBottom, ThemeStyle.flexDirectionRowCenter]}
        >
          <View style={ThemeStyle.spacingRight}>
            <Icon name="star" fill={ThemeColor.green} style={styles.icon} />
          </View>
          <Text category="p2" style={ThemeStyle.flex1}>
            Customer reviews are about their relevant experiences with a
            merchant and the items the've tried.
          </Text>
        </View>

        <View
          style={[ThemeStyle.spacingBottom, ThemeStyle.flexDirectionRowCenter]}
        >
          <View style={ThemeStyle.spacingRight}>
            <Icon name="people" fill={ThemeColor.green} style={styles.icon} />
          </View>
          <Text category="p2" style={ThemeStyle.flex1}>
            Reviews are publicly available to customer and shared with
            merchants.
          </Text>
        </View>

        <View style={ThemeStyle.flexDirectionRowCenter}>
          <View style={ThemeStyle.spacingRight}>
            <Icon
              name="checkmark-circle"
              fill={ThemeColor.green}
              style={styles.icon}
            />
          </View>
          <Text category="p2" style={ThemeStyle.flex1}>
            All reviews are checked to ensure they meet our Review Guidelines.
          </Text>
        </View>
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  icon: {
    width: 25,
    height: 25,
  },
});

export default PublicReviewNotice;
