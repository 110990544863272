import React, { useState } from 'react';
import { UAParser } from 'ua-parser-js';

import useAddress from '../../../../Hooks/useAddress';

import ModalPrompt from './ModalPrompt';
import { MODALPROMPT } from './config';
import useModalPrompt from './hooks/useModalPrompt';
import { AddressSelector } from '../../Address';

import { AddressItemDetails } from '../../../../Types';
import { StyleSheet, View } from 'react-native';
import { Icon } from '@ui-kitten/components';
import Button from '../../../Button';

const ListAddress = () => {
  const { device } = UAParser();
  const { setActiveAddress } = useAddress();
  const { showModalPrompt, hideModalPrompt } = useModalPrompt();
  const [data, setData] = useState<{
    storeId: string;
    navigation: any;
    route: any;
  }>();
  const { storeId, navigation, route } = data || {};

  const isMobileView = device.type === 'mobile';

  const onPressActiveAddress = (address: AddressItemDetails) =>
    setActiveAddress({ ...address, storeId: storeId || '' });

  const onSelectedSavedAddressAsEdit = (
    index: number,
    item: AddressItemDetails
  ) => {
    hideModalPrompt(MODALPROMPT.listAddress, {});
    showModalPrompt(MODALPROMPT.addressDetails, {
      status: 'edit',
      selectedAddress: {
        ...item,
        index: item.id,
        latitude: item?.geo_json_point.coordinates[1],

        longitude: item?.geo_json_point.coordinates[0],

        storeId: storeId,
      },
      navigation: navigation,
      route: route,
    });
  };
  const onAddNewAddress = () => {
    hideModalPrompt(MODALPROMPT.listAddress, {});
    showModalPrompt(MODALPROMPT.addressDetails, {
      status: 'new',
      selectedAddress: {},
      navigation: navigation,
      route: route,
    });
  };
  return (
    <ModalPrompt
      id={MODALPROMPT.listAddress}
      title={'Saved Addresses'}
      mainContentWrapperStyle={isMobileView && styles.mobileMainWrapper}
      childContentContainerStyle={isMobileView && styles.mobileChildWrapper}
      hasHeader={true}
      hasHeaderLine={true}
      headerStyle={isMobileView && styles.mobileHeaderStyle}
      closeOnDocumentClick={false}
      onBeforeShow={setData}
      footer={
        isMobileView && (
          <View style={styles.footerStyle}>
            <Button
              onPress={onAddNewAddress}
              status="success"
              size="small"
              accessoryLeft={() => (
                <Icon name="plus-outline" fill={'white'} style={styles.icon} />
              )}
            >
              Add a new address
            </Button>
          </View>
        )
      }
    >
      <AddressSelector
        storeId={storeId}
        onSelectedSavedAddress={onPressActiveAddress}
        onSelectedSavedAddressAsEdit={onSelectedSavedAddressAsEdit}
        addNewAddress={onAddNewAddress}
        showNewAddressButton={!isMobileView}
      />
    </ModalPrompt>
  );
};

const styles = StyleSheet.create({
  mobileMainWrapper: {
    width: '100vw',
    height: '100dvh',
    borderRadius: 10,
    padding: 0,
  },
  mobileChildWrapper: { paddingHorizontal: 0 },
  mobileHeaderStyle: { flex: null, height: 30, marginBottom: 0 },
  icon: { width: 25, height: 25 },
  footerStyle: { paddingHorizontal: 10, marginBottom: 10 },
});

export default ListAddress;
