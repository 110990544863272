import React, { Fragment, useMemo } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { useQueryClient } from '@tanstack/react-query';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';
import { RAZZLE_BUILD_MODE } from '@env';

import Button from '../../../Components/Button';
import RatingText from '../../../Components/RatingText';
import { ModalManager } from '../../../Components/Web/Modal/hooks/useModalManager';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';
import constants from '../../../Config/constants';
import { formatDistance, formatPrepTime } from '../../../Helper';
import StoreHelper from '../../../Helper/Store';
import routeList from '../../../Routes/list';
import { shopSelector } from '../../../RTK/shop/selectors';
import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';
import { whenFilterSelector } from '../../../RTK/filter/selectors';

const isBranded = RAZZLE_BUILD_MODE === 'branded';

const Overview: React.FC = () => {
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const shopData = useSelector(shopSelector);
  const whenFilter = useSelector(whenFilterSelector);

  const query = queryClient.getQueryData([shopData?.id, 'total-order-rating']);
  const { isClosing, lastOrder, status, statusDescription, statusText } =
    useMemo(() => {
      if (lodashIsEmpty(shopData)) return {} as StoreHoursStatus;
      return StoreHelper.getStoreHourStatus({
        storeHours: shopData?.store_hours,
        offDates: shopData?.off_dates,
        prepTime: shopData?.pre_order_to_order_queue_timer,
        dateAndTime: whenFilter?.value, // to adjust on the date filter not just current date and time
        isPreOrderDate: !lodashIsEmpty(whenFilter?.value),
      }) as StoreHoursStatus;
    }, [
      shopData?.store_hours,
      shopData?.off_dates,
      shopData?.pre_order_to_order_queue_timer,
      whenFilter?.value,
    ]);
  const hasDistance = Boolean(shopData.distance);
  const hasRating = !lodashIsEmpty((query as any)?.data);

  const _onSeeReviews = () => {
    if (constants.isWeb) {
      ModalManager.show(MODALPROMPT.reviewsModal);
    } else {
      // @ts-ignore
      navigation.navigate(routeList.STORE_REVIEWS, {
        id: shopData?.id,
        name: shopData?.name,
      });
    }
  };

  return (
    <View style={ThemeStyle.flex1}>
      {/* Tags */}
      {!lodashIsEmpty(shopData?.store_tags) && (
        <View
          style={[
            ThemeStyle.flex1,
            ThemeStyle.flexDirectionRowCenter,
            ThemeStyle.flexWrap,
            ThemeStyle.spacingBottomSmall,
          ]}
        >
          {shopData.store_tags.map((tag: string, i: number) => (
            <View style={ThemeStyle.flexDirectionRowCenter} key={i}>
              <Text
                category="p2"
                status="primary"
                style={ThemeStyle.capitalize}
              >
                {tag}
              </Text>
              {i !== shopData.store_tags.length - 1 && rowSeparator}
            </View>
          ))}
        </View>
      )}
      {/* Ratings, Distance, See Reviews */}
      {!isBranded && (hasRating || hasDistance) && (
        <View
          style={[
            ThemeStyle.flexDirectionRowCenter,
            ThemeStyle.flexWrap,
            ThemeStyle.spacingBottomSmall,
          ]}
        >
          {hasDistance && (
            <Fragment>
              <Text category="p2" status="primary">
                {formatDistance(shopData.distance)} away
              </Text>
              {rowSeparator}
            </Fragment>
          )}
          <RatingText
            storeId={shopData?.id}
            type={2}
            category="p2"
            status="primary"
          />
          {hasRating && (
            <Fragment>
              {rowSeparator}
              <Button
                hitSlop={10}
                style={ThemeStyle.flexDirectionRowCenter}
                onPress={_onSeeReviews}
                plain
              >
                <Text category="p2" status="success">
                  See reviews
                </Text>
              </Button>
            </Fragment>
          )}
        </View>
      )}
      {/* Hours Status */}
      <View
        style={[
          ThemeStyle.flexDirectionRowCenter,
          ThemeStyle.flexWrap,
          ThemeStyle.spacingBottomSmall,
        ]}
      >
        <Icon
          name="clock-outline"
          fill={theme['icon_color'] || ThemeColor.green}
          style={[styles.clock, ThemeStyle.spacingRightSmall]}
        />
        <View
          style={[
            ThemeStyle.flex1,
            ThemeStyle.flexDirectionRowCenter,
            ThemeStyle.flexWrap,
          ]}
        >
          <Text
            category="p2"
            status={status}
            style={!constants.isWeb && ThemeStyle.bold}
          >
            {statusText}
          </Text>
          {!isClosing && Boolean(statusDescription) && (
            <Fragment>
              {rowSeparator}
              <Text category="p2" status="primary">
                {statusDescription}
              </Text>
            </Fragment>
          )}
        </View>
      </View>
      {/* Prep Time & Last Order note */}
      <View
        style={[
          ThemeStyle.flexDirectionRowCenter,
          ThemeStyle.flexWrap,
          ThemeStyle.spacingBottomSmall,
        ]}
      >
        <View
          style={[
            styles.clock, // this container to match the clock icon Icon pack "fa" calculate different size compare to eva
            ThemeStyle.spacingRightSmall,
            ThemeStyle.alignItemsCenter,
          ]}
        >
          <Icon
            name="hourglass-half"
            pack="fa"
            fill={theme['icon_color'] || ThemeColor.green}
            style={styles.hourglass}
          />
        </View>
        <View
          dataSet={{ media: ids.prepTimeContainer }}
          style={[
            ThemeStyle.flex1,
            ThemeStyle.flexDirectionRowCenter,
            ThemeStyle.flexWrap,
            styles.prepTimeContainer,
          ]}
        >
          <Text category="p2" status="primary">
            Prep time is{' '}
            {formatPrepTime(shopData?.pre_order_to_order_queue_timer)}
          </Text>
          <View
            dataSet={{ media: ids.prepTimeRowSeparator }}
            style={styles.prepTimeRowSeparator}
          >
            {rowSeparator}
          </View>
          <Text category="p2" status="primary">
            Accepting orders until {lastOrder}
          </Text>
        </View>
      </View>
    </View>
  );
};

type StoreHoursStatus = {
  isClosing: boolean;
  lastOrder: string;
  status: string;
  statusText: string;
  statusDescription: string;
};

const clockSize = 21;
const { ids, styles } = StyleSheet.create({
  clock: {
    width: clockSize,
    height: clockSize,
  },
  hourglass: {
    height: clockSize - 4,
  },
  rowSeparator: {
    ...ThemeStyle.spacingLeftSmall,
    ...ThemeStyle.spacingRightSmall,
    backgroundColor: ThemeColor.darkGray,
    borderRadius: 4,
    height: constants.isWeb ? 4 : 3,
    width: constants.isWeb ? 4 : 3,
  },
  prepTimeContainer: {
    // breakpoint must match prepTimeRowSeparator
    [`@media (max-width: 520px)`]: {
      ...ThemeStyle.flexDirectionColStart,
    },
    [`@media (min-width: 577px) and (max-width: 600px)`]: {
      ...ThemeStyle.flexDirectionColStart,
    },
    [`@media (min-width: 768px) and (max-width: 824px)`]: {
      ...ThemeStyle.flexDirectionColStart,
    },
    [`@media (min-width: 1200px) and (max-width: 1224px)`]: {
      ...ThemeStyle.flexDirectionColStart,
    },
  },
  prepTimeRowSeparator: {
    // breakpoint must match prepTimeContainer
    [`@media (max-width: 520px)`]: {
      display: 'none',
    },
    [`@media (min-width: 577px) and (max-width: 600px)`]: {
      display: 'none',
    },
    [`@media (min-width: 768px) and (max-width: 824px)`]: {
      display: 'none',
    },
    [`@media (min-width: 1200px) and (max-width: 1224px)`]: {
      display: 'none',
    },
  },
});

const rowSeparator = <View style={styles.rowSeparator} />;

export default Overview;
