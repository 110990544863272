import { Image as ImageView } from 'react-native';

const Image = ({ resizeMode, size, style, source, ...props }) => {
  return (
    <ImageView
      resizeMode={resizeMode}
      style={style}
      source={{
        uri: source?.uri,
      }}
      {...props}
    />
  );
};

export default Image;
