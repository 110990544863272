import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui-kitten/components';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';

import constants from '../../Config/constants';
import Button from '../../Components/Button';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import autoCompleteCommonProps from './autoCompleteCommonProps';
import ListRow from './ListRow';
import styles from './styles';

if (!constants.isWeb) {
  navigator.geolocation = require('@react-native-community/geolocation');
}

const iconSize = 20;

class GoogleAutoSuggestion extends React.PureComponent {
  constructor(props) {
    super(props);
    this.firstRender = true; // to prevent calling onChangeText onload
    this.state = { searchValue: '' };
  }

  componentDidMount() {
    // setting default search value when mount
    const { defaultValue } = this.props;
    if (defaultValue) {
      // this should execute 1 time only
      this._setDefaultSearchValue(defaultValue);
    }
  }

  componentDidUpdate(prevProps) {
    // setting default search value when update
    const { defaultValue: prevDefaultValue } = prevProps;
    const { defaultValue: currDefaultValue } = this.props;
    if (!prevDefaultValue && currDefaultValue) {
      // this should execute 1 time only
      this._setDefaultSearchValue(currDefaultValue);
    }
  }

  _setDefaultSearchValue(value) {
    // need delay in order for it to work on class component
    setTimeout(() => this.autoComplete.setAddressText(value), 100);
  }

  _renderSearchResultRow = (rowData) => {
    const title =
      rowData.structured_formatting?.main_text || rowData.description;
    const subTitle = rowData.structured_formatting?.secondary_text || '';

    return (
      <ListRow
        leftAccessory={
          <Icon
            name="pin"
            fill={ThemeColor.green}
            pack="eva"
            style={styles.icon}
          />
        }
        title={title}
        subTitle={subTitle}
      />
    );
  };

  _renderLeftButton = () => {
    return (
      <Icon
        name="search"
        fill={ThemeColor.green}
        style={[styles.icon, ThemeStyle.spacingRightMedium]}
      />
    );
  };

  _renderRightButton = () => {
    if (constants.isIOS) return;

    if (!this.state.searchValue) return;

    return (
      <Button onPress={() => this.autoComplete.clear()} plain>
        <Icon
          name="close-circle"
          fill={ThemeColor.gray}
          pack="eva"
          style={{ width: iconSize, height: iconSize, marginLeft: 10 }}
        />
      </Button>
    );
  };

  _setSearchedValue = (text) => {
    if (!this.firstRender) {
      // to prevent calling onChangeText onload
      this.setState({ searchValue: text });
    }
  };

  _onRowPressed = (data, details) => {
    this.props.onDone(data, details);
  };

  render() {
    return (
      <GooglePlacesAutocomplete
        ref={(r) => (this.autoComplete = r)}
        placeholder="Search for address"
        renderLeftButton={this._renderLeftButton}
        renderRightButton={this._renderRightButton}
        renderRow={this._renderSearchResultRow}
        onPress={this._onRowPressed}
        textInputProps={{
          autoCorrect: false,
          returnKeyType: 'done',
          placeholderTextColor: ThemeColor.darkGray,
          onChangeText: this._setSearchedValue,
          onPressIn: () => (this.firstRender = false), // to prevent calling onChangeText onload
          enablesReturnKeyAutomatically: true, // to prevent pressing done if input is empty (iOS)
        }}
        styles={styles.autoSuggestionStyle}
        debounce={1000}
        bounces={false}
        keepResultsAfterBlur // show result even keyboard is closed
        {...autoCompleteCommonProps}
      />
    );
  }
}

GoogleAutoSuggestion.propTypes = {
  onDone: PropTypes.func.isRequired,
};

export default GoogleAutoSuggestion;
