import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Radio, Text } from '@ui-kitten/components';
import lodashFilter from 'lodash/filter';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';
import {
  SECTION_TITLE_LIGHT,
  SECTION_SUB_TITLE,
} from '../../Theme/typographyProps';

const { CASH, NON_CASH } = constants.DELIVERY_PAYMENT_OPTION;
const paymentList = [
  { label: 'Include Delivery Fee in Checkout Total', value: NON_CASH },
  { label: 'Pay Delivery Fee in Cash to Rider', value: CASH },
];

function PaymentOptions({ disabled, value, onChange, paymentMethods }) {
  const filteredPaymentList = lodashFilter(paymentList, (p) => {
    return paymentMethods?.includes(p.value);
  });

  return (
    <Fragment>
      {!constants.isWeb && (
        <Text style={ThemeStyle.spacingBottomMedium} {...SECTION_TITLE_LIGHT}>
          Delivery Payment Options
        </Text>
      )}

      {lodashIsEmpty(filteredPaymentList) ? (
        <Text
          appearance="hint"
          style={[ThemeStyle.spacingTopMedium, ThemeStyle.spacingBottomMedium]}
          {...SECTION_SUB_TITLE}
        >
          No payment methods available
        </Text>
      ) : (
        filteredPaymentList.map((p, i) => {
          const isSelected = p.value === value?.value;
          return (
            <Radio
              status={isSelected ? 'success' : 'basic'}
              style={ThemeStyle.pageVerticalSpacingMedium}
              disabled={disabled}
              checked={isSelected}
              onChange={() => onChange(p)}
              key={i}
            >
              {({ style }) => (
                <Text
                  category="p2"
                  style={[ThemeStyle.spacingLeft, { color: style.color }]}
                >
                  {p.label}
                </Text>
              )}
            </Radio>
          );
        })
      )}
    </Fragment>
  );
}

PaymentOptions.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
  onChange: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.oneOf(paymentList.map((e) => e.value))
  ),
};

export { paymentList };
export default PaymentOptions;
