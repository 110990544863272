import React from 'react';
import { StyleSheet, View } from 'react-native';

import withNavigation from '../../Hooks/withNavigation';
import ThemeStyle from '../../Theme/styles';

import CartList from './SectionList';

const groupOrderIconSize = 25;

class Cart extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { navigation } = this.props;
    return (
      <View style={ThemeStyle.flex1}>
        <CartList navigation={navigation} />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  groupOrderIcon: {
    width: groupOrderIconSize,
    height: groupOrderIconSize,
  },
});

export default withNavigation(Cart);
