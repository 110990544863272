import React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import dayjs from 'dayjs';

import Button from '../../../Components/Button';
import Image from '../../../Components/Image';
import constants from '../../../Config/constants';
import { getCurrency } from '../../../Helper/Routines';
import ImageHelper from '../../../Helper/Image';
import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';
import { styles } from '../../../Screens/Orders/styles';

import { TITLE, SECTION_SUB_TITLE } from '../../../Theme/typographyProps';

const OrderItem = (props) => {
  const {
    completed_time,
    items,
    order_date,
    order_type,
    refunded_amount,
    status,
    store_details,
    total_amount,
    onOrderAgain,
  } = props;
  const image = ImageHelper.getOrderImage(store_details);
  const isDefaultPickupImage = ImageHelper.isPickupDefaultBanner(image);
  const isCompleted = status === 'completed';
  const isDigital = order_type === 'digital';

  const _getTitle = () => (isDigital ? items?.[0]?.name : store_details?.name);

  const _getSubTitle = () => {
    if (isDigital) return store_details?.name;
    return items?.map((item) => item.name).join(', ');
  };

  return (
    <View
      style={[
        ThemeStyle.flex1,
        ThemeStyle.pageHorizontalSpacing,
        ThemeStyle.pageVerticalSpacing,
      ]}
    >
      <View
        style={[ThemeStyle.flexDirectionRow, ThemeStyle.spacingBottomSmall]}
      >
        {/* Store image */}
        <View
          style={[
            ThemeStyle.alignItemsCenter,
            ThemeStyle.spacingRightMedium,
            styles.imageStyle,
            isDefaultPickupImage && {
              width: 70,
              backgroundColor: ThemeColor.green,
              borderRadius: 5,
            },
          ]}
        >
          <Image
            source={{ uri: image }}
            style={{ width: 70, height: 70, borderRadius: 5 }}
            resizeMode={isDefaultPickupImage ? 'contain' : 'cover'}
          />
        </View>

        <View style={[ThemeStyle.flex1, ThemeStyle.spacingRightMedium]}>
          {/* Store name */}
          <Text
            accessibilityRole="link"
            target="_blank"
            href={constants.isWeb && `/store?id=${store_details?.id}`}
            numberOfLines={isDigital ? 2 : 1}
            {...TITLE}
          >
            {_getTitle()}
          </Text>

          {/* Product names */}
          <Text
            numberOfLines={2}
            style={ThemeStyle.regular}
            {...SECTION_SUB_TITLE}
          >
            {_getSubTitle()}
          </Text>
        </View>

        {/* Price / Refunded */}
        {refunded_amount ? (
          <Text style={ThemeStyle.noticeText}>Refunded</Text>
        ) : (
          <Text>{getCurrency(total_amount)}</Text>
        )}
      </View>

      <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
        {/* Date */}
        <Text {...SECTION_SUB_TITLE}>
          {dayjs(`${order_date} ${completed_time}`).format(
            constants.DATE_DISPLAY_FORMAT
          )}
        </Text>

        {/* Add/View Rating button */}
        {isCompleted && !isDigital && (
          <Button size="small" status="success" onPress={onOrderAgain}>
            {() => (
              <Text
                category="p2"
                appearance="alternative"
                style={ThemeStyle.pageHorizontalSpacingSmall}
              >
                Order Again
              </Text>
            )}
          </Button>
        )}
      </View>
    </View>
  );
};

export default OrderItem;
