import React, { useEffect } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { useSelector } from 'react-redux';
import {
  createDrawerNavigator,
  DrawerNavigationOptions,
} from '@react-navigation/drawer';

//ROUTES
import routeList from '../list';

//HOOKS
import withDatePicker from '../../Hooks/withDatePicker';

//COMPONENTS
import SwitchTabOrderType from '../../Components/SwitchTabOrderType';
import CartList from '../../Components/Web/Cart/CartList';
import RightDrawerContent from '../../Components/RightDrawerContent';
//THEMES
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

//STYLES
//@ts-ignore: JS code error
import { styles as homeStyles } from '../../Screens/Home/styles';

//SCREENS
import { userCartFetchingSelector } from '../../RTK/utility/selectors';
import LeftDrawerScreen from './LeftDrawer';
import { useMediaQuery } from 'react-responsive';
import { ModalManager } from '../../Components/Web/Modal/hooks/useModalManager';
import { MODALPROMPT } from '../../Components/Web/Modal/ModalPrompt/config';
import { useNavigation, useRoute } from '@react-navigation/native';

const RightDrawer = createDrawerNavigator();

const RightDrawerScreen = (props) => {
  const route = useRoute();
  const navigation = useNavigation();
  const isUserCartLoading = useSelector(userCartFetchingSelector);
  const xl = useMediaQuery({ query: '(min-width: 1200px' });

  useEffect(() => {
    if (xl) props.navigation.getParent('RightDrawer').closeDrawer();
  }, [xl, props.navigation]);

  const isStorePage = routeList.STORE === window.location.pathname.slice(1);

  const onItemPress = (item) => {
    if (isStorePage) {
      ModalManager.show(MODALPROMPT.itemModal, {
        payload: {
          item: { ...item, isUpdate: true },
          route: route,
          navigation: navigation,
        },
      });
    }
  };
  return (
    <RightDrawerContent contentContainerStyle={ThemeStyle.flex1} {...props}>
      <View style={[ThemeStyle.flex1, styles.spacingRight]}>
        {isStorePage && <SwitchTabOrderType size="medium" showMealPlan />}
        <CartList
          isCartLoading={isUserCartLoading?.loading}
          isClickableHeader={true}
          contentContainerStyle={{ flexGrow: 1 }}
          onItemPress={onItemPress}
        />
      </View>
    </RightDrawerContent>
  );
};

const DrawerNavigator = (props) => {
  const screenOptions: DrawerNavigationOptions = {
    drawerPosition: 'right',
    drawerStyle: {
      width: 400,
    },
    headerShown: false,
  };

  return (
    <RightDrawer.Navigator
      id="RightDrawer"
      defaultStatus="closed"
      useLegacyImplementation
      drawerContent={(props) => <RightDrawerScreen {...props} />}
      screenOptions={screenOptions}
    >
      <RightDrawer.Screen
        name={'MainMenu'}
        component={LeftDrawerScreen}
        initialParams={props}
        options={{
          unmountOnBlur: true,
        }}
      />
    </RightDrawer.Navigator>
  );
};

export const { ids, styles } = StyleSheet.create({
  topLayout: {
    [`@media ${BreakPoints.xs}`]: {
      paddingTop: 10,
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.sm}`]: {
      alignItems: 'center',
    },
    [`@media ${BreakPoints.md}`]: {
      alignItems: 'center',
    },
    [`@media ${BreakPoints.lg}`]: {
      alignItems: 'center',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'none',
    },
  },
  topLayoutWithOutCredentials: {
    [`@media ${BreakPoints.xs}`]: {
      paddingTop: 10,
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingTop: 10,
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.md}`]: {
      paddingTop: 10,
      paddingHorizontal: 20,
      zIndex: -1,
    },
    [`@media ${BreakPoints.lg}`]: {
      paddingTop: 10,
      paddingHorizontal: 20,
      zIndex: -1,
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
      zIndex: -1,
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'none',
      zIndex: -1,
    },
  },
  mobileTopLayout: {
    paddingTop: 10,
    paddingHorizontal: 20,
  },
  addressAndDateFilterContainer: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    columnGap: 20,
    zIndex: -1,
  },
  webTopLayout: {
    alignItems: 'center',
  },
  searchContainer: {
    [`@media ${BreakPoints.xs}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'none',
    },
  },

  searchInput: {
    ...ThemeStyle.regular,
    borderColor: ThemeColor.gray,
    borderRadius: 30,
    borderWidth: 1,
    marginRight: 10,
    minWidth: 200,
  },
  modalContentContainer: {
    ...ThemeStyle.spacingBottomMedium,
    ...ThemeStyle.alignItemsCenter,
  },
  modalContentRegular: {
    textAlign: 'center',
    ...ThemeStyle.spacingBottomMedium,
  },
  mainContentWrapperStyle: { flex: null },
  confirmationModalHeader: {
    flex: null,
    paddingRight: 10,
    height: 40,
    alignItems: 'center',
  },
  confirmModalWrapper: {
    flex: null,
    top: 100,
    width: 400,
    height: 'auto',
    padding: null,
    paddingBottom: 10,
  },

  controlContainer: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingHorizontal: 20,
  },

  yesButtonContainer: {
    backgroundColor: ThemeColor.green,
    width: 100,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    ...ThemeStyle.fullRoundCorder,
  },
  controlButtonSeparator: {
    width: 30,
  },
  noButtonContainer: {
    backgroundColor: ThemeColor.white,
    width: 100,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    ...ThemeStyle.fullRoundCorder,
  },
  yesTxt: { color: ThemeColor.white },
  noTxt: { color: ThemeColor.black },
  spacingRight: { paddingRight: 15 },
  popupWrapper: {
    alignSelf: 'center',
    backgroundColor: ThemeColor.white,
    borderWidth: 0,
    cursor: 'pointer',
  },
  popContentTriggerButton: {
    ...ThemeStyle.flexDirectionRowCenterCenter,
    minWidth: 100,
    [`@media ${BreakPoints.xs}`]: {
      maxWidth: 150,
    },
    [`@media ${BreakPoints.sm}`]: {
      maxWidth: 150,
    },
    [`@media ${BreakPoints.md}`]: {
      maxWidth: 200,
    },
  },
  popupAddressContainer: {
    backgroundColor: 'transparent',
  },
  savingAddressContainer: {
    backgroundColor: ThemeColor.white,
    top: 30,
    maxWidth: 600,
    paddingTop: '2%',
    paddingBottom: '2%',
    [`@media ${BreakPoints.xs}`]: {
      width: 350,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 450,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 500,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 550,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 600,
    },
  },
  drawerIcon: { ...ThemeStyle.spacingLeftSmall, width: 25, height: 25 },
  ...homeStyles,
});

export default withDatePicker(DrawerNavigator);
