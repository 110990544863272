import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';

import { filter } from '../defaultValues';

const {
  useCase,
  filterType,
  parentCategory,
  homeInitialFilterValue,
  searchInitialFilterValue,
  eventsFilterValue,
} = filter;

export const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    parentCategory,
    home: {
      when: null,
      ...homeInitialFilterValue,
    },
    search: searchInitialFilterValue,
    events: eventsFilterValue,
  },
  reducers: {
    changeWhenFilter: (state, action) => {
      if (!lodashIsEmpty(action.payload)) {
        const filterValueWithASecond = dayjs(action.payload.value)
          .set('second', 1)
          .toISOString();
        action.payload.value = filterValueWithASecond;
      }
      state.home.when = action.payload;
    },
    changeEventFilter: (state, action) => {
      state.events = action.payload;
    },
    setParentCategory: (state, action) => {
      state.parentCategory = action.payload;
    },
    updateFilter: (state, action) => {
      const { useCase, type, ...payload } = action.payload;

      switch (type) {
        case filterType.CATEGORY:
          if (payload.all) {
            state[useCase].category = [];
          } else {
            const existingCategory = lodashFind(state[useCase].category, {
              id: payload.id,
            });
            state[useCase].category = lodashIsEmpty(existingCategory)
              ? [payload]
              : [];
          }
          break;

        case filterType.TAG:
          const result = lodashFind(state[useCase].tags, { id: payload.id });
          if (!lodashIsEmpty(result)) {
            if (!payload.values) {
              // remove if existing in array on normal filter and have values
              // meaning if no values in modal its reset
              state[useCase].tags = state[useCase].tags.filter(
                (t) => t.id !== payload.id
              );
            } else {
              // update data only if from modal filter
              state[useCase].tags = state[useCase].tags.map((e) =>
                e.id === payload.id ? payload : e
              );
            }
          } else {
            if (payload.value || payload.values) {
              state[useCase].tags.push(payload);
            }
          }
          break;

        default:
          break;
      }
    },
    removeFilter: (state, action) => {
      if (action.payload === useCase.HOME) {
        state.home = homeInitialFilterValue;
      } else if (action.payload === useCase.SEARCH) {
        state.search = searchInitialFilterValue;
      }
    },
  },
});

export const {
  changeWhenFilter,
  changeEventFilter,
  removeFilter,
  setParentCategory,
  updateFilter,
} = filterSlice.actions;

export default filterSlice.reducer;
