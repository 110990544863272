import React, { useRef, useState } from 'react';
import ActionSheet, { useScrollHandlers } from 'react-native-actions-sheet';
import { View } from 'react-native';
import { Divider, Text } from '@ui-kitten/components';
import { ScrollView } from 'react-native-gesture-handler';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsUndefined from 'lodash/isUndefined';

import Button from '../../Components/Button';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import commonProps from './commonProps';
import modals from './modals';

/*
  data shape should be
  {
    title: 'List of x', // optional
    value: 'object of option',
    options: [
      {
        label: 'Option 1',
        value: 'option_1
      }
    ],
    clearText: 'Reset', // optional
    clearStatus: 'ui kitten button status', // optional, default control
  }
*/
const defaultClearStatus = 'control';
function DropdownSheet({ sheetId }) {
  const sheetRef = useRef();
  const scrollHandlers = useScrollHandlers('dropdown', sheetRef);

  const [data, setData] = useState();

  const clearStatus = lodashIsUndefined(data?.clearStatus)
    ? defaultClearStatus
    : data.clearStatus;

  const _onSelectOnList = (data) => () => sheetRef.current.hide(data);

  const _onClear = () => modals.hide(modals.DROPDOWN);

  const size = {
    large: ThemeStyle.pageVerticalSpacing,
    medium: ThemeStyle.pageVerticalSpacingMedium,
    small: ThemeStyle.pageVerticalSpacingSmall,
  };

  return (
    <ActionSheet
      id={sheetId}
      ref={sheetRef}
      onBeforeShow={setData}
      {...commonProps}
    >
      {!!data?.title && (
        <Text
          style={[
            ThemeStyle.spacingTopMedium,
            ThemeStyle.pageHorizontalSpacing,
            ThemeStyle.spacingBottomMedium,
          ]}
          {...SECTION_TITLE}
        >
          {data.title}
        </Text>
      )}

      <View style={{ maxHeight: 300 }}>
        {lodashIsEmpty(data?.options) ? (
          <Text
            style={[
              ThemeStyle.pageVerticalSpacing,
              ThemeStyle.pageHorizontalSpacing,
              ThemeStyle.textCenter,
            ]}
          >
            Option props is required
          </Text>
        ) : (
          <ScrollView
            bounces={false}
            showsVerticalScrollIndicator={false}
            {...scrollHandlers}
          >
            {data.options.map((option, index) => {
              const isSelected = data?.value?.value === option.value;
              const isLast = index === data.options.length - 1;
              return (
                <View style={ThemeStyle.pageHorizontalSpacing} key={index}>
                  <Button
                    onPress={_onSelectOnList(option)}
                    style={[
                      size[data?.size] || size.large,
                      ThemeStyle.flexDirectionRowCenterSpaceBetween,
                      isSelected && { backgroundColor: ThemeColor.green100 },
                    ]}
                    plain
                  >
                    <Text style={[ThemeStyle.flex1, ThemeStyle.textCenter]}>
                      {option.label}
                    </Text>
                  </Button>
                  {!isLast && <Divider style={ThemeStyle.divider} />}
                </View>
              );
            })}
          </ScrollView>
        )}

        {!!data?.clearText && (
          <View
            style={[
              ThemeStyle.spacingTopMedium,
              ThemeStyle.pageHorizontalSpacing,
            ]}
          >
            <Button
              status={clearStatus}
              onPress={_onClear}
              disabled={
                clearStatus === defaultClearStatus
                  ? lodashIsEmpty(data.value)
                  : false
              }
            >
              {({ style: { fontWeight, ...rest } }) => (
                <Text style={rest}>{data.clearText}</Text>
              )}
            </Button>
          </View>
        )}
      </View>
    </ActionSheet>
  );
}

export default DropdownSheet;
