import { RAZZLE_BUILD_MODE } from '@env';

/*
  notice the /v{number} on the begining of the route
  that is only for api version reference and it will not call the actual api with /v1 on the begining
  Example: on /v1/user /v1 will be remove on the route and it will request on /user
  and the /v1 will remove the /v and pass the x-api-version 1 to the request header
      you can visualize it as below
          /v1/user
          /v = prefix
          1 = version number
          /user = actual route to be request on
  you can find the flow for it on App/Service/api/client.js
*/
export default function (root = 'v1') {
  let endpoints = {};
  const api = {};

  if (RAZZLE_BUILD_MODE === 'branded') {
    endpoints = {
      STORE_DETAILS: (storeId) => `/branded-website/stores/${storeId}/info`,
      STORE_MENUS: (storeId) => `/branded-website/stores/${storeId}/menu`,
      STORE_PROMOS: (storeId) =>
        `/branded-website/promos/store/${storeId}/branded`,
      ITEM_DETAILS: (productId) => `/branded-website/item/${productId}`,
      STORE_ORDER_DATES: (storeId) =>
        `/branded-website/stores/${storeId}/date-options?v=2`,
      PROMO: '/branded-website/promos/verify',
      CHECKOUT: '/branded-website/checkout',
      CHECKOUT_MEALPLAN: '/branded-website/checkout',
    };
  } else {
    endpoints = {
      USER_SIGNIN: '/user/sign-in',
      USER_SIGNIN_VERIFY: '/user/sign-in/verify',
      USER_AS_GUEST_LOGIN: '/user/guest',

      USER: '/user',
      USER_DETAILS: '/user/me',
      USER_ADDRESS: '/user/address',
      USER_CART: '/user/cart',
      CART_VALIDATE: '/cart/validate',
      ADDITIONAL_CHARGE: '/cart/additional-charge',
      USER_RESET_PASSWORD: '/user/reset-password',
      USER_FAVORITES: '/user/favorites',
      USER_ORDER: (orderId) => `/user/orders/${orderId}`,
      USER_ORDERS: '/user/orders',
      REORDER: (orderId) => `/user/orders/${orderId}/reorder`,
      REQUEST_EMAIL_VERIFICATION: '/user/verify-email',
      VERIFY_EMAIL: (token) => `/user/verify-email/${token}`,

      STORE_SEARCH: '/store/search',
      STORE_DETAILS: (storeId) => `/store/${storeId}`,
      STORE_ORDER_DATES: (storeId) => `/store/${storeId}/order-dates`,
      STORE_MENUS: (storeId) => `/store/${storeId}/menu`,
      STORE_RATING: (storeId) => `/store/${storeId}/rating`,
      STORE_TOTAL_RATING: (storeId) => `/store/${storeId}/rating/total`,
      STORE_RATING_DETAILS: (storeId, ratingId) =>
        `/store/${storeId}/rating/${ratingId}`,
      STORE_RATING_SUMMARY: (storeId, summary) =>
        `/store/${storeId}/rating/total?summary=${summary}`,
      STORE_ADS: '/store/ads', // still on static api

      ITEM: '/item',
      ITEM_DETAILS: (productId) => `/item/${productId}`,

      CART: '/cart',
      CHECKOUT: '/checkout',
      CHECKOUT_MEALPLAN: '/checkout/meal-plan',
      DELIVERY_QUOTATION: '/delivery',
      PROMO: '/promo',
      STORE_PROMOS: (storeId) => `/promo/${storeId}`,

      VERSION: '/version', // still on static api

      // reservations
      RESERVATION: '/reservation',
      RESERVATIONS: '/user/reservations',
      RESERVATION_DETAILS: (reservationId) => `/reservation/${reservationId}`,
      RESERVATION_CONFIRM: '/reservation/confirm',
      RESERVATION_ATTACH_PAYMENT: '/reservation/attach-payment',
      RESERVATION_SEARCH: '/reservation/stores/search',
      RESERVATION_TYPES: (storeId) => `/reservation/type/${storeId}`,
      RESERVATION_STATUS: (reservationNumber) =>
        `/reservation/check-status/${reservationNumber}`,
    };
  }

  Object.keys(endpoints).forEach((key) => {
    Object.defineProperty(api, key, {
      get: function () {
        let version = `/${root}`;

        if (RAZZLE_BUILD_MODE === 'branded') version = '';

        if (typeof endpoints[key] === 'function') {
          return (...prop) => `${version}${endpoints[key](...prop)}`.trim();
        }

        return `${version}${endpoints[key]}`.trim();
      },
    });
  });

  return Object.freeze(api);
}

export const assets = {
  // json
  RESERVATION_TYPES_JSON: '/reservation-types.json',
};
