import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import lodashIsUndefined from 'lodash/isUndefined';
import constants from '../Config/constants';
import { changeEventFilter, changeWhenFilter } from '../RTK/filter';
import { toggleDatePicker } from '../RTK/utility';
import modals from '../Components/Sheets/modals';
import { eventFilterSelector } from '../RTK/filter/selectors';

function useHomeDatePicker() {
  const eventFilter = useSelector(eventFilterSelector);
  const whenFilter = useSelector((state) => state.filter.home.when);
  const showDatePicker = useSelector((state) => state.utility.showDatePicker);
  const dispatch = useDispatch();
  /**
   * Toggle date picker on homepage.
   * @param {string | object} evt - Button event object or String for specific condition
   * @returns {Promise<void>} - A promise that resolves after dispatching the toggle action.
   */
  const _toggleDateTimePicker = (evt) => {
    dispatch(toggleDatePicker());
    if (typeof evt === 'string' && evt === 'cancel') {
      _onConfirm();
    }
  };

  const _toggleEventFilter = async () => {
    const result = await modals.show(modals.DROPDOWN, {
      title: 'Event Date',
      value: { value: eventFilter.value },
      options: constants.eventFilterData,
      size: 'medium',
    });
    if (!lodashIsUndefined(result)) {
      dispatch(changeEventFilter(result));
    }
  };

  const _onConfirm = (date) => {
    let updatedWhenFilter;
    if (date) {
      dispatch(toggleDatePicker());
      const dayjsObject = dayjs(date);
      updatedWhenFilter = {
        label: dayjsObject.format(constants.DATE_DISPLAY_FORMAT),
        value: dayjsObject.toISOString(),
        date: dayjsObject.format(constants.DBDATE_FORMAT),
        time: dayjsObject.format(constants.DBTIME_FORMAT),
      };
    }
    dispatch(changeWhenFilter(updatedWhenFilter));
  };

  return {
    showDatePicker,
    whenFilter,
    eventFilter,
    onConfirm: _onConfirm,
    toggleDateTimePicker: _toggleDateTimePicker,
    toggleEventFilter: _toggleEventFilter,
  };
}

export default useHomeDatePicker;
