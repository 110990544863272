import React from 'react';

import useCart from './useCart';

import useModalPrompt from '../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';

export default function (Component) {
  return (props) => {
    const {
      removing,
      addOrUpdateCart,
      getStoreCart,
      getThisProductOnCart,
      isCanReschedule,
      removeThisProductOnCart,
    } = useCart();

    const { showModalPrompt, hideModalPrompt, isOpenModalPrompt } =
      useModalPrompt();

    return (
      <Component
        {...props}
        isRemoving={removing}
        addOrUpdateCart={addOrUpdateCart}
        getStoreCart={getStoreCart}
        getThisProductOnCart={getThisProductOnCart}
        isCanReschedule={isCanReschedule}
        removeThisProductOnCart={removeThisProductOnCart}
        //This is for web only
        showModalPrompt={showModalPrompt}
        hideModalPrompt={hideModalPrompt}
        isOpenModalPrompt={isOpenModalPrompt}
      />
    );
  };
}
