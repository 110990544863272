// @ts-nocheck
import React, { Fragment, useRef, useState } from 'react';
import { FlatList, ActivityIndicator, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import StyleSheet from 'react-native-media-query';
import { Divider, Icon, Text } from '@ui-kitten/components';

import lodashIsEmpty from 'lodash/isEmpty';

import Button from '../../Button';

import SearchInput from '../../Search';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle from '../../../Theme/styles';

import DateHelper from '../../../Helper/Date';

import Image from '../../Image';

import RecentlyViewedSection from '../../../Screens/Search/RecentlyViewedSection';
import { pushRecentlySearch } from '../../../RTK/search';

import routeList from '../../../Routes/list';

import { subTitleProps } from '../../Card/styles';

import Service from '../../../Service';
import { useLazyGetEventsQuery } from '../../../RTK/api/events';

import constants from '../../../Config/constants';

const initialState = {
  searchValue: '',
};

const SearchEvents = () => {
  const searchRef = useRef<any>();
  const containerRef = useRef<FlatList>();

  const dispatch = useDispatch();
  const navigation = useNavigation<any>();

  const [getEvents, { data: eventsData, isLoading, isFetching, error }] =
    useLazyGetEventsQuery();

  const recentlyEventSearched = useSelector(
    (state: any) => state.search.recentlyEventSearched
  );

  const [isOpen, setOpen] = useState(false);
  const [state, setState] = useState(initialState);

  const divRef = useRef(null);

  const _goToEventDetails = (events) => (e) => {
    e?.preventDefault();
    navigation.navigate(routeList.EVENT_DETAILS, {
      eventId: events?.id,
    });
  };

  const _onCuisinesOrRecentlyViewedPressed = async (val) => {
    searchRef.current._onInputChange(val, true);
    containerRef.current.scrollToIndex({
      index: 0,
      animated: true,
      viewOffset: 0,
      viewPosition: 0,
    });

    dispatch(pushRecentlySearch({ value: val, isStoreSearch: false }));
  };

  const searchResultItem = ({ item }) => {
    return (
      <Fragment>
        <Button
          style={styles.storeItemSearch}
          onPress={_goToEventDetails(item)}
          plain
        >
          <View style={styles.searched_item_result_container}>
            {
              //@ts-ignore
              <Image
                source={{ uri: item?.image }}
                style={styles.image_search_result}
                resizeMode="cover"
                accessibilityRole="link"
                href={`/store?id=${item?.id}&distance=${item?.distance}6&duration=${item?.duration}`}
                target="_blank"
              />
            }
          </View>
          <View style={styles.description_search_result}>
            {/* Store name & distance */}
            <View
              accessibilityRole="link"
              href={`/event_details?eventId=${item?.id}`}
              target="_blank"
            >
              <Text category="p1" numberOfLines={1}>
                {item.name}
              </Text>

              <View style={ThemeStyle.flexDirectionRowCenter}>
                <Icon
                  name="pin"
                  fill={ThemeColor.green}
                  style={[styles.storeIcon, ThemeStyle.spacingRightSmall]}
                />

                <Text {...subTitleProps}>{item?.location_address}</Text>
              </View>

              <View style={ThemeStyle.flexDirectionRowCenter}>
                <Icon
                  name="calendar-outline"
                  fill={ThemeColor.green}
                  style={[styles.storeIcon, ThemeStyle.spacingRightSmall]}
                />

                <Text {...subTitleProps}>
                  {DateHelper.formatDateRange(item?.start_date, item?.end_date)}
                </Text>
              </View>
            </View>
          </View>
        </Button>
      </Fragment>
    );
  };

  const onChangeText = (txt) => {
    if (txt.trim().length !== 0) {
      _apiCallForSearchContain(txt);
    } else {
      setState({
        searchValue: txt,
      });
    }
  };

  const _apiCallForSearchContain = (searchValue) => {
    setState({ searchValue });
    getEvents({ keyword: searchValue, event_date: constants.EVENT_RANGE_DATE });
    dispatch(pushRecentlySearch({ value: searchValue, isStoreSearch: false }));
  };

  const _open = () => {
    setOpen(true);
  };
  const _onClose = () => {
    setOpen(false);
    setState({ searchValue: '' });
  };

  const _onLayout = (e) => {
    if (e.nativeEvent.layout.width === 0) {
      _onClose();
    }
  };

  return (
    <Fragment>
      <View
        ref={divRef}
        style={{
          zIndex: 999999999999999,
        }}
        onLayout={_onLayout}
      >
        <View style={styles.searchInputContainer}>
          <View
            style={[
              ThemeStyle.pageHorizontalSpacing,
              ThemeStyle.spacingTopSmall,
              ThemeStyle.spacingBottomMedium,
            ]}
          >
            <SearchInput
              ref={searchRef}
              placeholder="Search events"
              onSearch={onChangeText}
              onFocus={_open}
              inputStyle={[ThemeStyle.pageBackground, styles.searchInput]}
              textStyle={styles.textStyle}
            />
          </View>
        </View>

        {(!lodashIsEmpty(recentlyEventSearched) ||
          (!lodashIsEmpty(state.searchValue) &&
            !lodashIsEmpty(eventsData?.result))) &&
          isOpen && (
            <View style={styles.contentContainer}>
              <FlatList
                ref={containerRef}
                dataSet={{ media: ids.searchContainer }}
                style={styles.searchContainer}
                data={
                  !lodashIsEmpty(state.searchValue) ? eventsData?.result : []
                }
                contentContainerStyle={styles.flatlist_search_container}
                renderItem={searchResultItem}
                ListHeaderComponentStyle={{
                  marginHorizontal: -10,
                }}
                ListHeaderComponent={
                  lodashIsEmpty(state.searchValue) && (
                    <View style={{ paddingHorizontal: 20 }}>
                      <RecentlyViewedSection
                        isRecentlyStoreSearch={false}
                        onPress={_onCuisinesOrRecentlyViewedPressed}
                      />
                    </View>
                  )
                }
                ItemSeparatorComponent={() => (
                  <Divider
                    style={[ThemeStyle.divider, { marginHorizontal: 20 }]}
                  />
                )}
                ListEmptyComponent={
                  !lodashIsEmpty(state?.searchValue) && (
                    <View
                      style={[
                        ThemeStyle.alignItemsCenter,
                        ThemeStyle.justifyContentCenter,
                        { height: 100 },
                      ]}
                    >
                      <Text category="s1">
                        {!lodashIsEmpty(error)
                          ? 'An error has occurred'
                          : 'No results found.'}
                      </Text>
                      <Text category="p2" status="primary">
                        {!lodashIsEmpty(error)
                          ? Service.handleErrorMessage(error)
                          : "We couldn't find what you're looking for"}
                      </Text>
                    </View>
                  )
                }
              />

              {(isLoading || isFetching) && (
                <View style={styles.loaderWrapper}>
                  <ActivityIndicator size="large" color={ThemeColor.green} />
                </View>
              )}
            </View>
          )}
      </View>

      {isOpen && <div style={styles.overlay} onClick={_onClose} />}
    </Fragment>
  );
};

const { ids, styles } = StyleSheet.create({
  searchContainer: {
    ...ThemeStyle.flex1,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 99999,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  searchInput: {
    borderColor: ThemeColor.gray,
    borderRadius: 30,
    borderWidth: 1,
    minWidth: 200,
  },
  textStyle: { overflow: 'hidden', color: ThemeColor.black },
  popoverContentWrapper: {
    ...ThemeStyle.flex1,
    backgroundColor: 'transparent',
  },
  contentContainer: {
    top: 70,
    left: 20,
    right: 20,
    width: 'auto',
    padding: 10,
    position: 'absolute',
    backgroundColor: ThemeColor.white,
    borderColor: ThemeColor.lightGray,
    ...ThemeStyle.shadow,
    borderWidth: 1,
    borderRadius: 10,
  },
  storeItemSearch: {
    ...ThemeStyle.flex1,
    height: 60,
    paddingHorizontal: 10,
    paddingVertical: 15,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginHorizontal: 15,
  },
  image_search_result: {
    width: 60,
    height: 60,
    borderRadius: 30,
  },
  flatlist_search_container: {
    maxHeight: 600,
  },
  searched_item_result_container: {
    width: 60,
    height: 60,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: ThemeColor.gray,
    aspectRatio: 0.1,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  description_search_result: {
    marginLeft: 10,
    justifyContent: 'space-between',
    flexDirection: 'row',
    flex: 1,
  },
  loaderWrapper: {
    ...ThemeStyle.alignItemsCenter,
    ...ThemeStyle.justifyContentCenter,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  storeIcon: {
    width: 20,
    height: 20,
  },
  searchInputContainer: {
    ...ThemeStyle.pageBackground,
    backgroundColor: 'transparent',
  },
});

export default React.memo(SearchEvents);
