import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import dayjs from 'dayjs';

import constants from '../../Config/constants';
import Button from '../../Components/Button';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import {
  SECTION_TITLE_LIGHT,
  SECTION_SUB_TITLE,
} from '../../Theme/typographyProps';

function DateAndTimes({
  completedTime,
  isAsap,
  isComplete,
  isDigital,
  orderDate,
  orderTime,
  transactionNumber,
  onCopyPress,
}) {
  return (
    <View style={ThemeStyle.pageVerticalSpacing}>
      {/* Transaction Number */}
      <View
        style={[
          ThemeStyle.flexDirectionRowSpaceBetween,
          ThemeStyle.spacingBottomMedium,
        ]}
      >
        <Text {...SECTION_SUB_TITLE}>Transaction Number</Text>
        <View style={ThemeStyle.flexDirectionRow}>
          <Text {...SECTION_SUB_TITLE}>#{transactionNumber}</Text>
          <Button
            testID="copyTransactionNumberButton"
            onPress={() => onCopyPress(transactionNumber, 'transaction number')}
            style={ThemeStyle.spacingLeftMedium}
            plain
          >
            <Text status="success" {...SECTION_SUB_TITLE}>
              COPY
            </Text>
          </Button>
        </View>
      </View>

      {/* Order Date */}
      <View
        style={[
          ThemeStyle.flexDirectionRowSpaceBetween,
          ThemeStyle.spacingBottomMedium,
        ]}
      >
        <Text {...SECTION_SUB_TITLE}>
          {isDigital ? 'Booking' : 'Order'} Date
        </Text>
        <Text {...SECTION_SUB_TITLE}>
          {isAsap
            ? `${dayjs(orderDate).format(
                constants.DATE_ONLY_DISPLAY_FORMAT
              )}, ASAP`
            : dayjs(`${orderDate} ${orderTime}`).format(
                constants.DATE_DISPLAY_FORMAT
              )}
        </Text>
      </View>

      {/* Completed Time */}
      {isComplete && (
        <View
          testID="completedTimeView"
          style={ThemeStyle.flexDirectionRowSpaceBetween}
        >
          <Text {...SECTION_SUB_TITLE}>
            {isDigital ? 'Redeem' : 'Completed'} Date
          </Text>
          <Text {...SECTION_SUB_TITLE}>
            {dayjs(`${orderDate} ${completedTime}`).format(
              constants.DATE_DISPLAY_FORMAT
            )}
          </Text>
        </View>
      )}
    </View>
  );
}

DateAndTimes.propTypes = {
  transactionNumber: PropTypes.string.isRequired,
  isAsap: PropTypes.bool,
  isComplete: PropTypes.bool,
  orderDate: PropTypes.string,
  orderTime: PropTypes.string,
  completedTime: PropTypes.string,
  onCopyPress: PropTypes.func.isRequired,
};
export default DateAndTimes;
