import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';
import lodashPick from 'lodash/pick';

import constants from '../Config/constants';
import { saveShop, updateShop } from '../RTK/shopPersist';
import { shopPersist } from '../RTK/shopPersist/selectors';
import storeApi from '../Service/api/store';

const SAVE_TYPE = { INFO: 'info', MENU: 'menu' };

function useStorePersist(params) {
  const dispatch = useDispatch();
  const shops = useSelector(shopPersist);
  const whenFilter = useSelector((state) => state.filter.home.when);

  const paramDate = whenFilter?.date || params?.orderDateParams;
  const paramTime = whenFilter?.time || params?.orderTimeParams;
  const { mutateAsync: getStoreData } = useMutation({
    mutationFn: (storeId) => storeApi.getDetails(storeId),
    mutationKey: ['getStoreData'],
    cacheTime: constants.STORE_INFO_CACHE_DURATION * 60 * 60, // hours
  });
  const { mutateAsync: getStoreMenuData } = useMutation({
    mutationFn: (storeId) => storeApi.getMenu(storeId, paramDate, paramTime),
    mutationKey: ['getStoreMenuData', paramDate, paramTime],
    cacheTime: constants.STORE_MENU_CACHE_DURATION * 60, // minutes
  });

  const _createOrUpdateCache = async (storeId) => {
    const { ok: infoOk, data: infoData } = await _saveStore(
      storeId,
      SAVE_TYPE.INFO
    );
    if (infoOk) {
      const { ok: menuOk, data: menuData } = await _saveStore(
        storeId,
        SAVE_TYPE.MENU
      );
      if (menuOk) {
        return {
          ok: true,
          data: {
            ...infoData,
            ...menuData,
          },
        };
      } else {
        return { ok: false, data: menuData };
      }
    } else {
      return { ok: false, data: infoData };
    }
  };

  const _dispatchSaveStore = (data) => {
    const newData = {
      ...data,
      time_updated: dayjs().toISOString(), // used for checking if the data is 5min. older
      when_filter: whenFilter, // used for checking the whenFilter used on this data
    };
    return dispatch(saveShop(newData));
  };

  /**
   * Retrieves data required for cart validation asynchronously.
   * @param {Object | string} storeData - The store data or The identifier for the store. object if from store page else string
   * @param {string} itemId - The identifier for the item.
   * @param {Object} storeRawApiData - The complete data of store page including menu, categories and distance. this will be passed when user is from store page
   * @returns {Promise<Object>} A promise that resolves to an object containing stock and store information:
   * {
   *   error: "api sauce error object if ok is false",
   *   itemInfo: { id: string, stock: number, category_stock: number },
   *   storeInfo: {
   *      id: string,
   *      menu_list: Array,
   *      is_accepting_in_advanced_orders: Boolean,
   *      off_dates: Array,
   *      pre_order_to_order_queue_timer: Number,
   *      store_hours: Array,
   *   }
   * }
   */
  const _getCartValidationInfo = async (storeId, itemId, storeRawApiData) => {
    const savedStoreData = _getSavedStore(storeId, storeRawApiData);
    const isDifferentWhenFilter = !lodashIsEqual(
      savedStoreData?.when_filter,
      whenFilter
    );
    let storeCache = savedStoreData; // will overwrite on below condition if true
    if (!lodashIsEmpty(storeRawApiData)) {
      // if 3rd param is passed, save it to shop cache, no need to await
      _dispatchSaveStore(storeRawApiData);
    } else if (
      lodashIsEmpty(storeCache) || // if no store cache
      lodashIsEmpty(storeCache?.menu) || // if has store cache but no menu yet
      lodashIsEmpty(storeCache?.categories) || // if has store cache but no categories yet
      isDifferentWhenFilter // if when filter changed
    ) {
      // if has storeCache but no menu or categories, it usually happen when user open the store page and go back while menu still fetching
      const { ok, data } = await _createOrUpdateCache(storeId);
      if (ok) {
        storeCache = data;
      } else {
        return { error: data };
      }
    }
    // storeCache must initialized and not empty beyond this point
    const itemData = lodashFind(
      storeCache.menu,
      (items) => items.item.id === itemId
    ); // get item using itemid
    const categoryData = lodashFind(storeCache.categories, {
      category_name: itemData?.category,
    }); // get item category using itemData.category
    return {
      itemInfo: {
        id: itemId,
        stock: itemData?.stock,
        category_name: itemData?.category,
        category_stock: categoryData?.stock,
      },
      storeInfo: {
        id: storeId,
        menu_list: storeCache.menu,
        ...lodashPick(storeCache, [
          'is_accepting_in_advanced_orders',
          'off_dates',
          'pre_order_to_order_queue_timer',
          'store_hours',
        ]),
      },
    };
  };

  // get store info and save it to redux for caching
  const _getStoreInfo = async (storeId) => {
    const result = await getStoreData(storeId);
    const data = { ...result.data, ...params };
    if (result.ok) {
      _dispatchSaveStore(data);
    }
    return { ...result, data };
  };

  // get store menu and save it to redux for caching
  const _getStoreMenu = async (storeId) => {
    const result = await getStoreMenuData(storeId);
    if (result.ok) {
      _dispatchSaveStore(result.data);
    }
    return result;
  };

  const _getSavedStore = (storeId, storeRawApiData) =>
    storeRawApiData || lodashFind(shops, { id: storeId });

  /**
   * Save store info or menu for caching
   * @param {string | Object} data - The identifier for the store or the actual data
   * @param {SAVE_TYPE} infoOrMenu - Save type method can be found on SAVE_TYPE variable
   * @returns {Promise<null | Object>} A promise that resolves to an null or object containing apisauce data
   * {
   *   ok: boolean,
   *   data: object // response data
   * }.
   */
  const _saveStore = async (storeId, type) => {
    const isInfo = type === SAVE_TYPE.INFO;
    const mutationMethod = isInfo ? getStoreData : getStoreMenuData;
    return new Promise((resolve, reject) => {
      mutationMethod(storeId, {
        onSuccess: async (result) => {
          if (result.ok) {
            await _dispatchSaveStore({ ...result.data, ...params });
          }
          resolve(result);
        },
        onError: reject,
      });
    });
  };

  return {
    getStoreInfo: _getStoreInfo,
    getStoreMenu: _getStoreMenu,
    getCartValidationInfo: _getCartValidationInfo,
    getSavedStore: _getSavedStore,
  };
}

export default useStorePersist;
