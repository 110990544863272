import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Icon, useTheme } from '@ui-kitten/components';

import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

import { ScaleButtonAnimation } from './Web/Intereaction';

const scrollDistance = 400;
const ArrowControl = ({
  listRef,
  scrollOffset,
  isEndReached,
  children,
  position = 'top',
  arrowStyles,
  arrowContentStyles,
  arrowIcon,
  withBackground = false,
  isHideDisabledButton = true,
}) => {
  const disabledPrev = scrollOffset <= 0;
  const disabledNext = isEndReached;
  const store_theme = useTheme();
  const hideDisablePrevButton = isHideDisabledButton ? disabledPrev : false;
  const hideDisableNextButton = isHideDisabledButton ? disabledNext : false;

  const _prevSlide = () => {
    if (!disabledPrev) {
      const prevDistance = scrollOffset - scrollDistance;
      listRef.current._scrollToOffset({ animated: true, offset: prevDistance });
    }
  };

  const _nextSlide = () => {
    if (!disabledNext) {
      const nextDistance = scrollOffset + scrollDistance;
      listRef.current._scrollToOffset({ animated: true, offset: nextDistance });
    }
  };

  return (
    <View
      style={
        position === 'top'
          ? styles.controlButtonContainer
          : styles.controlButtonContainerMiddle
      }
    >
      {!hideDisablePrevButton && (
        <ScaleButtonAnimation
          testID="prev_btn"
          onPress={_prevSlide}
          disabled={disabledPrev && !hideDisablePrevButton}
          buttonStyle={[{ zIndex: 10 }, arrowStyles]}
          contentStyle={styles.storeControlButton}
          scale={1.1}
        >
          <View
            style={[
              styles.scaleControlButton,
              {
                left: -20,
                backgroundColor: withBackground
                  ? store_theme['icon_color'] || ThemeColor.green
                  : ThemeColor.white,
              },
              arrowContentStyles,
            ]}
          >
            <Icon
              name="chevron-left"
              fill={
                disabledPrev && !hideDisablePrevButton
                  ? ThemeColor.darkGray
                  : withBackground
                  ? ThemeColor.white
                  : store_theme['icon_color'] || ThemeColor.green
              }
              style={[styles.controlIcon, arrowIcon]}
            />
          </View>
        </ScaleButtonAnimation>
      )}

      {children}
      {!hideDisableNextButton && (
        <ScaleButtonAnimation
          testID="next_btn"
          onPress={_nextSlide}
          disabled={disabledNext && !hideDisableNextButton}
          buttonStyle={[{ zIndex: 10 }, arrowStyles]}
          contentStyle={styles.storeControlButton}
          scale={1.1}
        >
          <View
            style={[
              styles.scaleControlButton,
              {
                right: -20,
                backgroundColor: withBackground
                  ? store_theme['icon_color'] || ThemeColor.green
                  : ThemeColor.white,
              },
              arrowContentStyles,
            ]}
          >
            <Icon
              name="chevron-right"
              fill={
                disabledNext && !hideDisableNextButton
                  ? ThemeColor.darkGray
                  : withBackground
                  ? ThemeColor.white
                  : store_theme['icon_color'] || ThemeColor.green
              }
              style={[styles.controlIcon, arrowIcon]}
            />
          </View>
        </ScaleButtonAnimation>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  control_container: {
    ...ThemeStyle.flexDirectionRow,
  },
  controlButtonContainer: {
    width: 75,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    ...ThemeStyle.spacingBottomSmall,
  },
  controlButtonContainerMiddle: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
  scaleControlButton: {
    position: 'absolute',
    borderRadius: 30, // Make it circular
    width: 45, // Button width
    height: 45, // Button height
    justifyContent: 'center', // Center the icon
    alignItems: 'center', // Center the icon
    opacity: 0.8,
  },
  storeControlButton: {
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },

  controlIcon: {
    width: 30,
    height: 30,
  },
});

export default ArrowControl;
