import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';

import constants from '../Config/constants';
import { getCartLimit } from '../Helper/RemoteConfig';

import StoreHelper from './Store';
import PromoHelper from './Promo';

// cart total / subtotal computation: (base item price + all extras add/reduce charge) * quantity
// e.g: product price 100, quantity 2, with 2 extras first extras is '35' and 2nd is '-15
// (100 + 35 + -15) * 2
//        120       * 2
// result   240
// price, quantity extra must be in a object
const getTotal = (data) => {
  let result = 0;

  if (!data) {
    // return result which is 0 when data is falsy
    return result;
  }

  const getExtrasPrice = (item) => {
    let totalExtras = 0;
    if (item.extras) {
      // prettier-ignore
      totalExtras = item.extras.reduce((acc, obj) => {
        return acc + (Number(obj.price) || 0)
      }, 0)
    }
    return totalExtras;
  };

  if (Array.isArray(data)) {
    // if array it means the caller is getting them sum of all cart subtotal
    result = data.reduce((acc, cart) => {
      const extrasTotalCharge = getExtrasPrice(cart);
      const total = (Number(cart.price) + extrasTotalCharge) * cart.quantity;
      return acc + total;
    }, 0);
  } else {
    // if not array (means object) the caller is getting the sum of cart item
    const extrasTotalCharge = getExtrasPrice(data);
    result = (Number(data.price) + extrasTotalCharge) * data.quantity;
  }

  return result;
};

// return service fee of 10% of sub total with min and max
const getServiceFee = (subTotal) => {
  // if serviceFee is not set just return 0
  if (!constants.serviceFee) return 0;

  const tenPercent = subTotal * 0.1;
  let fee = tenPercent;

  if (fee <= constants.serviceFee.MIN) {
    fee = constants.serviceFee.MIN;
  } else if (fee >= constants.serviceFee.MAX) {
    fee = constants.serviceFee.MAX;
  }

  return fee;
};

const getBreakdown = ({
  cart,
  delivery = {
    fee: 0,
    priority_fee: 0,
    mode: constants.DELIVERY_PAYMENT_OPTION.NON_CASH,
  },
  isDelivery,
  promotion,
  refund,
  subTotalPromoPercent,
  totalAdditionalCharge = 0,
}) => {
  const cartTotalPrice = getTotal(cart);
  const discountedCartTotalPrice = subTotalPromoPercent
    ? Math.round(cartTotalPrice - cartTotalPrice * (subTotalPromoPercent / 100))
    : cartTotalPrice;
  const serviceFee = getServiceFee(discountedCartTotalPrice);
  const isDeliveryAndNC =
    isDelivery && delivery.mode === constants.DELIVERY_PAYMENT_OPTION.NON_CASH;
  const deliveryFeeToCompute = isDeliveryAndNC ? delivery.fee || 0 : 0;
  const priorityFee = isDelivery ? delivery.priority_fee || 0 : 0;
  const { total, deliveryFee } = PromoHelper.getDeductedPriceFromPromo(
    discountedCartTotalPrice,
    deliveryFeeToCompute,
    promotion
  );
  const t = total + deliveryFee + serviceFee + priorityFee;
  return {
    subTotal: discountedCartTotalPrice,
    subTotalOriginal: cartTotalPrice,
    serviceFee: serviceFee,
    total: refund ? t - refund : t + totalAdditionalCharge,
  };
};

const getCartDetailsId = ({
  id,
  cart_details_id,
  extras = [],
  extra_group,
}) => {
  if (cart_details_id) {
    // return cart_details_id if it has,
    return cart_details_id;
  }
  //  or create it
  const uniqueExtras = [...new Set(extras || [])];
  let mappedExtraObj = [];
  let extraIds = '';
  if (extra_group?.length > 0 && uniqueExtras?.length > 0) {
    extra_group.forEach((item) => {
      const { data: dataExtra } = item;
      if (dataExtra.length > 0) {
        const match = (dataExtra || [])
          .filter((data) => uniqueExtras.find((uniq) => uniq.id === data.id))
          .map(({ id, name, price }) => ({ id, name, price }));

        mappedExtraObj = mappedExtraObj.concat(match);
      }
    });

    extraIds = (mappedExtraObj || [])
      .map((extra) => extra.id)
      .sort((a, b) => a.localeCompare(b))
      .join('_');
  }
  return `${id}_${extraIds}`;
};

const isCartLimitExceeded = (alreadyAsked, storeId, cartData = []) => {
  const isNumberOfCartLimited = getCartLimit();

  return (
    !alreadyAsked && // if consent not yet given
    lodashIsEmpty(StoreHelper.getStoreCartData(storeId, cartData)) && // if this store is not yet on the cart
    isNumberOfCartLimited &&
    cartData.length >= getCartLimit() // if cart length is greater than equal to cart limit
  );
};

/* pameter shape example
{
  item_id: string,
  extras: [{ id: string, name: string, price: string|number }],
  instructions: { instruction: string, if_unavailable: { label: string, value: string } }
}
*/
const getConditionForItemVariant = (newCartItem) => {
  const getIds = (arr) => arr.map((e) => e.id);
  const sortExtras = (arr) => {
    const copiedArr = JSON.parse(JSON.stringify(arr));
    const sortedArr = copiedArr.sort((a, b) => (a.id > b.id ? 1 : -1));
    return getIds(sortedArr); // after sorting, get only the array of ids for matching
  };
  return (cartItem) => {
    return (
      cartItem.item_id === newCartItem.item_id &&
      lodashIsEqual(
        sortExtras(cartItem.extras),
        sortExtras(newCartItem.extras)
      ) &&
      lodashIsEqual(cartItem.instructions, newCartItem.instructions)
    );
  };
};

const getCartItem = (
  storeId,
  productId,
  extras = [],
  instructions = {},
  cartData = []
) => {
  // on cartData search store cart data on it using store id
  const storeCartData = StoreHelper.getStoreCartData(storeId, cartData);
  // on storeCartData search the match productId, extras and instruction on it to determine the existing item on the cart
  const condition = getConditionForItemVariant({
    item_id: productId,
    extras,
    instructions,
  });
  return lodashFind(storeCartData.items, condition);
};

const getCartItemByDetailsId = (storeId, cartDetailsId, cartData = []) => {
  if (!cartDetailsId) {
    return undefined;
  }
  // on cartData search store cart data on it using store id
  const storeCartData = StoreHelper.getStoreCartData(storeId, cartData);
  return lodashFind(storeCartData.items, { cart_details_id: cartDetailsId });
};

export default {
  getCartDetailsId,
  getTotal,
  getServiceFee,
  getBreakdown,
  isCartLimitExceeded,
  getCartItem,
  getCartItemByDetailsId,
  getConditionForItemVariant,
};
