import React, { useEffect, useRef } from 'react';
import { View, ScrollView, TouchableOpacity, Linking } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Icon, Layout } from '@ui-kitten/components';
import { isEmpty as lodashIsEmpty, find as lodashFind } from 'lodash';

///CONFIG
import constants from '../../Config/constants';

//COMPONENTS
import Button from '../../Components/Button';
import CheckoutRightContent from '../../Components/Web/CheckoutRightContent';

import Footer from '../../Components/Footer';

import SwitchTabOrderType from '../../Components/SwitchTabOrderType';
import ProfileForm from '../../Components/ProfileForm';

//CONTENT
import DeliveryEstimate from './DeliveryEstimate';

import ThirdPartyPickup from './ThirdPartyPickup';

///REDUX
import { checkout } from '../../RTK/defaultValues';
import { setAddressSelection, updateCheckoutDetails } from '../../RTK/checkout';

//THEMES
import ThemeColors from '../../Theme/colors';
//@ts-ignore: importing module
import ThemeStyle, { webPaddingHorizontal } from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

//HOOKS
import useCancellableRequest from '../../Hooks/useCancellableRequest';
import withController from '../../Hooks/withController';

import DropOffOptions from './DropOffOptions';

import VehicleOptions from './VehicleOptions';
import VehicleInformation from './VehicleInformation';

import { useCheckoutNavigatorContext } from '../../Routes/CheckoutNavigatorContext';
import { whenFilterSelector } from '../../RTK/filter/selectors';
import { checkoutStoreInfoSelector } from '../../RTK/checkout/selectors';
import Service from '../../Service';

const { ORDER_TYPES, ORDER_TYPE_DATA } = constants;
const pickupOrderType = lodashFind(ORDER_TYPE_DATA, {
  value: ORDER_TYPES.PICKUP,
});

const Checkout = () => {
  const dispatch = useDispatch();
  const currentAddress = useRef();
  const deliveryEstimate = useRef();
  const {
    cartItemUpdatingById,
    deliveryQuotation,
    deliverySchedule,
    hasBulk,
    noUserDetails,
    placingOrder,
    state,
    storeCartData,
    storeCheckoutData,
    storeMealPlanDeliveryDiscount,
    storeMealPlanDiscount,
    toBeApply,
    onPromoCodeChange,
    onDeliveryScheduleChange,
    onLastUsedAddressPress,
    onOrderTypeChange,
    onPaymentOptionChanged,
    onThirdPartyPickupPress,
    onUpdateQuantity,
    onUserDetailsChange,
    onVehicleInformationChange,
    onVehicleOptionChanged,
    proceedCheckout,
    toggleConfirmDeleteModal,
    toggleLocateAddressModal,
    toggleScheduleListModal,
    toggleViewAddressModal,
  } = useCheckoutNavigatorContext();
  const checkoutStoreInfo = useSelector(checkoutStoreInfoSelector);
  const whenFilter = useSelector(whenFilterSelector);

  const dAddress = storeCheckoutData?.[checkout.keys.DELIVERY_ADDRESS] || {};
  const promotion = storeCheckoutData[checkout.keys.PROMO_CODE];
  const orderType = storeCheckoutData[checkout.keys.ORDER_TYPE]?.value;
  const isDelivery = orderType === ORDER_TYPES.DELIVERY;
  const isPickup = orderType === ORDER_TYPES.PICKUP;
  const isCurbside = orderType === ORDER_TYPES.CURBSIDE;
  useEffect(() => {
    return () => {
      // if has payment method, clear it
      if (!lodashIsEmpty(storeCheckoutData[checkout.keys.PAYMENT_METHOD])) {
        _updateCheckoutReduxState(checkout.keys.PAYMENT_METHOD);
      }
      dispatch(setAddressSelection(false));
    };
  }, []);

  const _updateCheckoutReduxState = (key: any, value?: any) => {
    const data = {
      store_id: checkoutStoreInfo.store_id,
      keyToUpdate: key,
      keyValue: value,
    };
    dispatch(updateCheckoutDetails(data));
  };

  const goToStoreLocation = () => {
    const url = Service.getStoreMapUrl(
      checkoutStoreInfo?.store_location,
      checkoutStoreInfo?.store_geocode_location?.lat,
      checkoutStoreInfo?.store_geocode_location?.lng
    );
    Linking.openURL(url);
  };
  return (
    <Layout style={[ThemeStyle.flex1, ThemeStyle.pageVerticalSpacing]}>
      <ScrollView
        dataSet={{ media: ids.contentContainer }}
        contentContainerStyle={styles.contentContainer}
        showsVerticalScrollIndicator
        scrollEventThrottle={16}
      >
        <View style={styles.wrapperOfColumn}>
          <View style={styles.wrapperFirstColumn}>
            <SwitchTabOrderType />

            {noUserDetails && (
              <View style={styles.details_wrapper}>
                <Text category="s2">Customer Information</Text>
                <ProfileForm
                  values={
                    storeCheckoutData[checkout.keys.USER_DETAILS] as {
                      firstName: string;
                      lastName: string;
                      phone: string;
                      email: string;
                    }
                  }
                  onChange={onUserDetailsChange}
                />
              </View>
            )}

            {
              //SHIPPING ADDRESS
            }
            {isDelivery ? (
              <DropOffOptions
                selectedAddress={dAddress}
                disabled={placingOrder}
                onAddressPress={toggleViewAddressModal}
                onSelectedAddressPress={toggleLocateAddressModal}
                onLastUsedAddressPress={onLastUsedAddressPress}
              />
            ) : (
              <View style={styles.details_wrapper}>
                <Text category="s2">Pickup Location</Text>
                <TouchableOpacity onPress={goToStoreLocation}>
                  <Text category="p2" style={[ThemeStyle.underline]}>
                    {checkoutStoreInfo.store_location}
                  </Text>
                </TouchableOpacity>
              </View>
            )}

            {
              //DELIVERY SCHEDULE
            }
            <View style={styles.details_wrapper}>
              <DeliveryEstimate
                ref={deliveryEstimate}
                //@ts-ignore: JS code error
                whenFilter={whenFilter}
                prepTime={checkoutStoreInfo.prep_time}
                daysAcceptingInAdvance={
                  checkoutStoreInfo.days_accepting_in_advance
                }
                deliverySchedule={deliverySchedule}
                disabled={placingOrder}
                orderType={orderType}
                storeHours={checkoutStoreInfo.store_hours}
                storeOffDates={checkoutStoreInfo.store_off_dates}
                toggleScheduleModal={toggleScheduleListModal}
                onDeliveryScheduleChange={onDeliveryScheduleChange}
              />
            </View>

            {
              //DELIVERY VEHICLE OPTIONS
            }
            {isDelivery ? (
              <View style={styles.details_wrapper}>
                <VehicleOptions
                  disabled={deliveryQuotation.loading || placingOrder}
                  value={storeCheckoutData[checkout.keys.VEHICLE_OPTIONS]}
                  onChange={onVehicleOptionChanged}
                  hasBulk={hasBulk}
                />
              </View>
            ) : null}

            {isPickup && (
              <View style={styles.details_wrapper}>
                <ThirdPartyPickup
                  isChecked={
                    storeCheckoutData[checkout.keys.THIRD_PARTY_PICKUP]
                  }
                  values={storeCheckoutData[checkout.keys.THIRD_PARTY_PICKUP]}
                  onChange={onThirdPartyPickupPress}
                />
              </View>
            )}

            {isCurbside && (
              <View style={styles.details_wrapper}>
                <VehicleInformation
                  disabled={placingOrder}
                  values={storeCheckoutData[checkout.keys.VEHICLE_INFORMATION]}
                  onChange={onVehicleInformationChange}
                />
              </View>
            )}
          </View>
          <View
            dataSet={{ media: ids.wrapperSecondColumn }}
            style={styles.wrapperSecondColumn}
          >
            <CheckoutRightContent
              isMealPlan={false}
              mealPlanData={{}}
              checkoutState={{
                state,
                toBeApply,
                storeMealPlanDeliveryDiscount,
                storeMealPlanDiscount,
                onPromoCodeChange,
              }}
              checkoutData={storeCheckoutData}
              //@ts-ignore: JS code error
              checkoutItems={storeCartData?.[0]?.items}
              promotion={promotion}
              incrementPress={onUpdateQuantity}
              decrementPress={onUpdateQuantity}
              isUpdatingItem={cartItemUpdatingById}
              hasPickupOrderType={
                checkoutStoreInfo.order_types[pickupOrderType.apiDataKey]
              }
              onOrderTypeChange={onOrderTypeChange}
              deliveryQuotation={deliveryQuotation}
              toggleConfirmModal={toggleConfirmDeleteModal}
              hasDeliveryFee={isDelivery}
              placeOrder={proceedCheckout}
              paymentMethods={checkoutStoreInfo.delivery_payment_methods}
              onPaymentOptionChanged={onPaymentOptionChanged}
              isPlacingOrder={placingOrder}
              error={{}}
            />
          </View>
        </View>
      </ScrollView>
    </Layout>
  );
};

const { ids, styles } = StyleSheet.create({
  contentContainer: {
    flexGrow: 1,
    ...ThemeStyle.pageBackground,
    [`@media ${BreakPoints.xs}`]: {
      paddingHorizontal: webPaddingHorizontal - 70,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingHorizontal: webPaddingHorizontal - 60,
    },
    [`@media ${BreakPoints.md}`]: {
      paddingHorizontal: webPaddingHorizontal - 50,
    },
    [`@media ${BreakPoints.lg}`]: {
      paddingHorizontal: webPaddingHorizontal - 40,
    },
    [`@media ${BreakPoints.xl}`]: {
      paddingHorizontal: webPaddingHorizontal - 30,
    },
    [`@media ${BreakPoints.xxl}`]: {
      paddingHorizontal: webPaddingHorizontal,
    },
  },
  main_wrapper: {
    flex: 1,
    justifyContent: 'space-between',
    height: '100vh',
    borderColor: 'red',
    borderWidth: 1,
  },
  wrapperOfColumn: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.flexDirectionRow,
    columnGap: 40,
  },
  wrapperFirstColumn: {
    ...ThemeStyle.flex4,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: 10,
    rowGap: 10,
  },
  wrapperSecondColumn: {
    ...ThemeStyle.flex2,
    paddingTop: 10,
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'flex',
    },
  },

  label: {
    ...ThemeStyle.medium,
    fontSize: 14,
    color: ThemeColors.gray,
  },
  store_title: {
    ...ThemeStyle.medium,
    fontSize: 14,
    color: ThemeColors.black,
  },
  store_container: {
    ...ThemeStyle.flexDirectionRow,
    ...ThemeStyle.alignItemsCenter,
    ...ThemeStyle.spacingTopSmall,
    ...ThemeStyle.medium,
    justifyContent: 'flex-start',
  },

  icon_size: { width: 20, height: 20 },

  details_wrapper: {
    ...ThemeStyle.spacingTopMedium,
    padding: '2%',
    minHeight: 50,
    borderWidth: 1,
    borderColor: ThemeColors.gray,
    borderRadius: 5,
    marginBottom: 20,
  },
  saving_address_container: {
    backgroundColor: ThemeColors.white,
  },
});

export default withController(Checkout, useCancellableRequest, 'api');
