import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';

import { ModalManager } from '../Components/Web/Modal/hooks/useModalManager';
import { MODALPROMPT } from '../Components/Web/Modal/ModalPrompt/config';
import constants from '../Config/constants';
import UrlParameterHelper from '../Helper/UrlParameter';
import routeList from '../Routes/list';
import { setDeeplinkingData } from '../RTK/noSession';
import { isLoggedInSelector } from '../RTK/user/selectors';

type DeepLinkRoutes = {
  route: string;
  reqArgs: boolean;
  parentTab?: string;
};

type GetDeepLink = (link: string) => DeepLinkRoutes;

const ratingRoute = constants.isWeb
  ? routeList.ORDER_DETAILS
  : routeList.ORDER_RATING;

// for not logged in deep linking whitelist
const DEEP_LINK_ROUTES: DeepLinkRoutes[] = [
  { route: ratingRoute, reqArgs: true },
  { route: routeList.EVENT_TAB, reqArgs: false },
  { route: routeList.STORE, reqArgs: true }, // no need parentTab on store since it belong to the initial route of bottom tab
  {
    route: routeList.EVENT_DETAILS,
    reqArgs: true,
    parentTab: routeList.EVENT_TAB,
  },
];

const getDeepLink: GetDeepLink = (link) => {
  return lodashFind(DEEP_LINK_ROUTES, { route: link });
};

// Deep linking for when user is not logged in
const useDeepLinking = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(isLoggedInSelector);
  const deepLinkingData = useSelector(
    // @ts-ignore - must create selector for this
    (state) => state.noSession.deepLinkingData
  );

  if (constants.isWeb) {
    // effect for web to show login modal when user is not logged in and has deeplinking data
    useEffect(() => {
      if (!isLoggedIn && !lodashIsEmpty(deepLinkingData)) {
        setTimeout(() => {
          ModalManager.hide(MODALPROMPT.deliveryAddress);
          ModalManager.show(MODALPROMPT.authentication, {
            payload: { isLogin: true },
          });
        }, 500);
      }
    }, [deepLinkingData]);
  }

  const _prepareDeepLinking = (deepLinkingLink: string) => {
    const deepLinkingPath =
      UrlParameterHelper.getUrlStringPath(deepLinkingLink); // get deep link path
    const deepLink = getDeepLink(deepLinkingPath);
    // do nothing if:
    if (
      isLoggedIn || // user is already logged in
      !deepLinkingLink || // has no deep linking link (args)
      lodashIsEmpty(deepLink) // the link is not in the routes that has deep link
    ) {
      return;
    }
    // if no account is logged in, save the deeplinking data so we can use it to redirect upon logging in
    const deepLinkingParams =
      UrlParameterHelper.getUrlParameter(deepLinkingLink); // get deep link params
    let shouldSetDeepLinkData = true;
    if (deepLink.reqArgs) {
      // if deepLink has requiredParams
      if (lodashIsEmpty(deepLinkingParams)) {
        shouldSetDeepLinkData = false; // if no deep link params, do not set deep link data
      } else {
        shouldSetDeepLinkData = true; // if has deep link params, set the deep link data
      }
    }
    if (shouldSetDeepLinkData) {
      dispatch(
        setDeeplinkingData({
          route: deepLinkingPath,
          params: deepLinkingParams,
        })
      );
    }
  };

  const _redirectDeepLinking = (navigation?: any) => {
    dispatch(setDeeplinkingData(undefined)); // clear deep linking data before redirection
    // add timeout to propagate the clearing of deep linking data, to prevent multiple times redirection
    setTimeout(
      () => {
        if (constants.isWeb) {
          window.location.href = `/${
            deepLinkingData.route
          }${UrlParameterHelper.objectToString(deepLinkingData.params)}`;
        } else if (navigation) {
          const deepLink = getDeepLink(deepLinkingData.route); // get the available deeplink routes using the route of deeplinkdata
          if (deepLink.parentTab) {
            // if the route is inside a tab, we need to load the parent tab first before navigating to the route
            navigation.navigate(deepLink.parentTab, {
              screen: deepLinkingData.route,
              params: deepLinkingData.params,
            });
          } else {
            navigation.navigate(deepLinkingData.route, deepLinkingData.params);
          }
        } else {
          throw new Error(
            'On mobile, navigation should be pass on this function'
          );
        }
      },
      constants.isWeb ? 0 : 500 // this delay is needed in order to propagate the flip of flag isLoggedIn on redux
    );
  };

  return {
    prepareDeepLinking: _prepareDeepLinking,
    redirectDeepLinking: _redirectDeepLinking,
  };
};

export default useDeepLinking;
