import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';
import StyleSheet from 'react-native-media-query';

import { UAParser } from 'ua-parser-js';

import { getCartTerm } from '../../Helper/RemoteConfig';
import { getCurrency } from '../../Helper/Routines';
import ThemeStyle from '../../Theme/styles';
import ThemeColor from '../../Theme/colors';
import BreakPoints from '../../Theme/styles/breakpoints';

import Button from '../Button';
import LimitReached from './LimitReached';
import Loader from './Loader';
import Selector from './Selector';
import constants from '../../Config/constants';

import useQuantitySelector from './useQuantitySelector';

function QuantitySelector({
  isUpdate,
  mealPlan,
  onLimitReached,
  totalPrice,
  isAvailable,
  disabled,
  addToCartPress,
  ...rest
}) {
  const store_theme = useTheme();
  const { device } = UAParser();

  const isMobileWebView = constants.isWeb && device.type === 'mobile';

  const {
    limitReachedProps,
    selectorProps,
    stocks,
    displayZeroValue,
    totalSelectorValue,
    maxQty,
    isMaxReached,
    qtyValueSelector,
    changeCounter,
  } = useQuantitySelector({
    isUpdate,
    mealPlan,
    onLimitReached,
    ...rest,
  });

  const _renderSelector = () => {
    if (stocks?.isLoading) {
      return <Loader />;
    } else {
      return (
        <Selector
          {...selectorProps}
          value={displayZeroValue ? 0 : qtyValueSelector}
          isMaxReached={isMaxReached}
          onChange={changeCounter}
        />
      );
    }
  };

  const buttonText = `${
    isUpdate ? 'Update' : 'Add to'
  } ${getCartTerm()} ${getCurrency(totalPrice?.())}`;

  return (
    <View style={[ThemeStyle.flex1, { rowGap: 20, paddingTop: 20 }]}>
      {!stocks?.isLoading && isMaxReached && (
        <LimitReached
          maxLimit={maxQty}
          mealPlan={mealPlan}
          selectorValue={totalSelectorValue}
          {...limitReachedProps}
        />
      )}
      <View style={styles.wrapperButton}>
        <View
          dataSet={{
            media: isMobileWebView
              ? ids.subMobileWrapperButton
              : ids.subWebWrapperButton,
          }}
          style={
            isMobileWebView
              ? styles.subMobileWrapperButton
              : styles.subWebWrapperButton
          }
        >
          {_renderSelector()}

          <Button
            disabled={disabled}
            onPress={addToCartPress}
            dataSet={{
              media: isMobileWebView
                ? ids.addToCartMobileButton
                : ids.addToCartWebButton,
            }}
            style={[
              isMobileWebView
                ? styles.addToCartMobileButton
                : styles.addToCartWebButton,
              {
                border: store_theme['button_color'] || ThemeColor.green,
                backgroundColor:
                  store_theme['button_color'] || ThemeColor.green,
              },
            ]}
            plain
          >
            {isAvailable ? (
              <Text
                category="c1"
                status="control"
                style={{
                  color: store_theme['button_text_color'] || ThemeColor.white,
                }}
              >
                {buttonText}
              </Text>
            ) : (
              <ActivityIndicator color={ThemeColor.white} size="small" />
            )}
          </Button>
        </View>
      </View>
    </View>
  );
}

export default QuantitySelector;

const { ids, styles } = StyleSheet.create({
  wrapper: {
    ...ThemeStyle.spacingLeft,
    ...ThemeStyle.spacingRight,
    rowGap: 10,
    [`@media ${BreakPoints.xs}`]: {
      ...ThemeStyle.spacingBottomMedium,
    },
  },
  wrapperButton: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.alignItemsCenter,
    width: '100%',
  },
  subWebWrapperButton: {
    width: 350,
    rowGap: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    [`@media ${BreakPoints.xs}`]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },

  subMobileWrapperButton: {
    width: '100%',
    rowGap: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    [`@media ${BreakPoints.xs}`]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },

  addToCartWebButton: {
    padding: 10,
    width: 200,
    height: 40,
    borderRadius: 120,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    [`@media ${BreakPoints.xs}`]: {
      ...ThemeStyle.spacingTopMedium,
      width: 'auto',
    },
  },
  addToCartMobileButton: {
    padding: 10,
    height: 40,
    borderRadius: 120,
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    [`@media ${BreakPoints.xs}`]: {
      ...ThemeStyle.spacingTopMedium,
      borderRadius: 0,
      width: '100%',
    },
  },
});
