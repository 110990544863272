import React, { Fragment } from 'react';
import { Alert, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import lodashFilter from 'lodash/filter';

import { getCartTerm } from '../Helper/RemoteConfig';
import { mealPlan } from '../RTK/defaultValues';
import { mealPlanItemsSelector } from '../RTK/mealPlan/selectors';
import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

import Button from './Button';
import constants from '../Config/constants';
import { MODALPROMPT } from './Web/Modal/ModalPrompt/config';
import useModalPrompt from './Web/Modal/ModalPrompt/hooks/useModalPrompt';

type Type = {
  value: string;
};

type Props = {
  small?: boolean;
  value?: string;
  onChange: (data: Type) => void;
};

const types: Type[] = [
  { value: mealPlan.type.threeDay },
  { value: mealPlan.type.fiveDay },
];

const textCategory = constants.isWeb ? 'p2' : 'p1';
const textCategorySmall = constants.isWeb ? 'c1' : 'p2';

const MealPlanTypes = ({ small, value, onChange }: Props) => {
  const store_theme = useTheme();
  const { showModalPrompt } = useModalPrompt();
  const mealPlanItems = useSelector(mealPlanItemsSelector);

  const _onTypeSelection = (type: Type) => {
    const nonExclusiveItems = lodashFilter(mealPlanItems, {
      is_exclusive: false,
    });
    if (
      type.value === mealPlan.type.threeDay &&
      nonExclusiveItems.length >= 3
    ) {
      const title = 'Ooops!';
      const message = `Kindly remove some items from your ${getCartTerm()} before changing your meal plan type.`;
      if (constants.isWeb) {
        return showModalPrompt(MODALPROMPT.prompt, {
          title,
          message,
          buttonText: 'Ok',
          buttonStatus: 'success',
        });
      } else {
        return Alert.alert(title, message);
      }
    }

    onChange(type);
  };

  return (
    <View style={[ThemeStyle.flexDirectionRow, small && ThemeStyle.flex1]}>
      {types.map((type: Type, index: number) => {
        const isActive = value === type.value;
        const style = constants.isWeb
          ? ({ hovered }) => [
              styles.contentContainer,
              small && ThemeStyle.flex1,
              hovered && {
                backgroundColor:
                  `${store_theme?.button_color}1A` || ThemeColor.green100,
              },
              isActive && {
                backgroundColor: store_theme?.button_color || ThemeColor.green,
              },
            ]
          : small && ThemeStyle.flex1;
        return (
          <Fragment key={index}>
            <Button
              onPress={() => _onTypeSelection(type)}
              style={style}
              isHoverable={constants.isWeb}
              plain
            >
              <View
                style={[
                  !constants.isWeb && styles.contentContainer, // mobile style only
                  !constants.isWeb && isActive && styles.active, // mobile style only
                  small ? styles.containerSmall : styles.container,
                ]}
              >
                <View style={ThemeStyle.flexDirectionRowCenter}>
                  <Icon
                    name="calendar-outline"
                    fill={
                      isActive
                        ? store_theme?.button_text_color || ThemeColor.white
                        : store_theme?.icon_color || ThemeColor.green
                    }
                    style={small ? styles.iconSmall : styles.icon}
                  />
                  <Text
                    appearance={isActive ? 'alternative' : 'default'}
                    category={small ? textCategorySmall : textCategory}
                    style={ThemeStyle.capitalize}
                  >
                    {type.value.replace('_', ' ')}
                  </Text>
                </View>
                <Text
                  appearance={isActive ? 'alternative' : 'default'}
                  category={small ? textCategorySmall : textCategory}
                >
                  Meal Plan
                </Text>
              </View>
            </Button>

            {index !== types.length - 1 && (
              <View
                style={
                  small
                    ? ThemeStyle.rowSeparator
                    : ThemeStyle.pageHorizontalSpacing
                }
              />
            )}
          </Fragment>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: 'center',
    backgroundColor: ThemeColor.lightGray,
    borderRadius: 10,
  },
  container: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.pageVerticalSpacing,
  },
  containerSmall: {
    ...ThemeStyle.pageHorizontalSpacingSmall,
    ...ThemeStyle.pageVerticalSpacingMedium,
  },
  active: {
    backgroundColor: ThemeColor.green,
  },
  icon: {
    ...ThemeStyle.spacingRightMedium,
    width: 20,
    height: 20,
  },
  iconSmall: {
    ...ThemeStyle.spacingRightSmall,
    width: 15,
    height: 15,
  },
});

export default MealPlanTypes;
