import React, { Fragment, useEffect } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Layout, Divider, Icon, Text } from '@ui-kitten/components';
import { SafeAreaView } from 'react-native-safe-area-context';
import lodashIsUndefined from 'lodash/isUndefined';
import lodashIsEmpty from 'lodash/isEmpty';

import otherApi from '../../Service/api/other';
import Form from '../../Components/FormFields/Form';
import GooglePlaces_Autocomplete from '../../Components/Map/GooglePlacesAutocomplete';
import MapView, { Marker } from '../../Components/Map';
import SubmitButton from '../../Components/FormFields/SubmitButton';
import ScreenWrapper from '../../Components/ScreenWrapper';

import validation from '../../Config/validationRules';

import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

import addEditAddressController from './controller/addEditAddress';
import withController from './controller/withController';

import AddLabelForm from './AddLabelForm';

import Header from '../../Components/Header';

let formInitialValues = {};
const validationSchema = validation.createSchema({
  label: validation.fieldRequired('Address label'),
});
const formDefaultValues = { label: null };
const AddEditAddress = ({ ...props }) => {
  const {
    autoCompleteRef,
    state,
    isUpdate,
    onSearchItemPressed,
    onSubmit,
    onRegionChangeComplete,
  } = props.controller;
  const { selectedAddress, selectedLabel, submitting } = state;
  const { update } = !lodashIsUndefined(props.route.params)
    ? props.route.params
    : '';

  const location = {
    lat: lodashIsUndefined(props.route.params)
      ? selectedAddress?.latitude
      : update?.geo_json_point?.coordinates[1],
    lng: lodashIsUndefined(props.route.params)
      ? selectedAddress?.longitude
      : update?.geo_json_point?.coordinates[0],
  };

  if (props.controller.formInitialValues) {
    formInitialValues = props.controller.formInitialValues;
  } else {
    formInitialValues = formDefaultValues;
  }

  useEffect(() => {
    props.controller.askForUserLocation();
  }, []);

  const onSelectedAddress = async ({ place_id }) => {
    const { ok, data } = await otherApi.getAddressDetails(place_id);
    if (ok) {
      let convertToString = JSON.stringify(data); // need to convert first to string so we can get the raw value for lat and lng. if not it will read as constructor
      let result = JSON.parse(convertToString); // revert it to JSON again...
      onSearchItemPressed({ place_id: place_id }, result);
    }
  };

  return (
    <Fragment>
      <Header plain hasBackButton={false} />
      <ScreenWrapper scroll={true}>
        <Layout style={ThemeStyle.flex1}>
          <Form
            innerRef={props.controller.formRef}
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={async (...args) => {
              const center = {
                latitude: location.lat,
                longitude: location.lng,
              };
              onSubmit(center, ...args);
            }}
          >
            <Layout style={ThemeStyle.spacingTopSmall}>
              <AddLabelForm />
            </Layout>
            <Divider style={ThemeStyle.divider} />

            <Layout style={ThemeStyle.flex2}>
              {/* Search suggestion container */}
              <Layout style={styles.searchContainer}>
                <GooglePlaces_Autocomplete
                  testID="gpautocomplete"
                  ref={autoCompleteRef}
                  initialValue={selectedAddress?.address}
                  onSelected={onSelectedAddress}
                  style={styles.googleplace_autocomplete}
                />
              </Layout>

              {/* Map Container */}
              {lodashIsEmpty(selectedAddress) ? (
                <Layout style={styles.emptyContainer}>
                  <Text category="h6">Search address to show map</Text>
                </Layout>
              ) : (
                <Layout style={ThemeStyle.flex1}>
                  <Layout style={styles.mapContainer}>
                    <MapView testID="mapView" region={location}>
                      <Marker
                        coordinate={location}
                        coordinateChanged={onRegionChangeComplete}
                      />
                    </MapView>
                  </Layout>

                  <Layout style={styles.submitButtonContainer}>
                    <SafeAreaView edges={['right', 'bottom']}>
                      <SubmitButton
                        size="giant"
                        style={[styles.saveIcon, ThemeStyle.shadow]}
                        customLoader
                      >
                        <View>
                          {submitting ? (
                            <ActivityIndicator
                              size="large"
                              color={ThemeColor.white}
                            />
                          ) : (
                            <Icon
                              name="save-outline"
                              fill={ThemeColor.white}
                              style={styles.saveIcon}
                            />
                          )}
                        </View>
                      </SubmitButton>
                    </SafeAreaView>
                  </Layout>
                </Layout>
              )}
            </Layout>
          </Form>
        </Layout>
      </ScreenWrapper>
    </Fragment>
  );
};

const styles = StyleSheet.create({
  screenWrapper: {
    ...ThemeStyle.pageContainer,
  },
  searchContainer: {},
  saveIcon: {
    width: 30,
    height: 30,
  },
  modal_wrapper: {
    height: 600,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 5,
  },
  googleplace_autocomplete: {
    ...ThemeStyle.regular,
    marginTop: '2%',
  },
  emptyContainer: [
    ThemeStyle.flex1,
    ThemeStyle.justifyContentCenter,
    ThemeStyle.alignItemsCenter,
  ],
  mapContainer: {
    marginTop: 20,
    ...ThemeStyle.flex1,
  },
  submitButtonContainer: {
    position: 'absolute',
    right: globalSpacing,
    bottom: globalSpacing,
    backgroundColor: 'transparent',
  },
});

export { formDefaultValues };
export default withController(AddEditAddress, addEditAddressController);
