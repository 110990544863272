import React from 'react';
import { StyleSheet } from 'react-native';
import { Icon, useTheme } from '@ui-kitten/components';

import ThemeColor from '../../../Theme/colors';

import ScaleButtonAnimation from '../Intereaction/ScaleButtonAnimation';

const CloseButton = ({ onPress }) => {
  const store_theme = useTheme();
  return (
    <ScaleButtonAnimation
      onPress={onPress}
      contentStyle={styles.contentStyle}
      scale={1.2}
    >
      <Icon
        name="close"
        fill={store_theme['icon_color'] || ThemeColor.green}
        style={styles.close_icon}
      />
    </ScaleButtonAnimation>
  );
};

const styles = StyleSheet.create({
  close_icon: {
    width: 18,
    height: 18,
  },
  contentStyle: {
    borderWidth: 1,
    borderColor: ThemeColor.gray100,
    backgroundColor: ThemeColor.white,
    borderRadius: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    height: 25,
    borderRadius: 22,
  },
});
export default CloseButton;
