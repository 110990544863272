import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Platform, ScrollView, StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Divider, Text } from '@ui-kitten/components';
import lodashGet from 'lodash/get';

import { RAZZLE_BUILD_MODE } from '@env';

import Button from '../../../Components/Button';
import List from '../../../Components/List';
import MealPlanItem from '../../../Components/StoreCart/MealPlanItem';
import MinimumOrderNote from '../../../Components/StoreCart/MinimumOrderNote';
import StoreCartSummary from '../../../Components/StoreCart/CartSummary';
import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';

import { pluralize } from '../../../Helper';
import CartHelper from '../../../Helper/Cart';
import DateHelper from '../../../Helper/Date';
import MealPlanHelper from '../../../Helper/MealPlan';
import { getCartTerm } from '../../../Helper/RemoteConfig';

import usePromoCart from '../../../Hooks/usePromoCart';
import useSetCheckoutStoreInfo from '../../../Hooks/useSetCheckoutStoreInfo';

import { checkoutStoreInfoSelector } from '../../../RTK/checkout/selectors';
import {
  mealPlanItemsSelector,
  mealPlanTypeSelector,
} from '../../../RTK/mealPlan/selectors';
import { removeItem } from '../../../RTK/mealPlan';
import { shopSelector } from '../../../RTK/shop/selectors';

import routeList from '../../../Routes/list';

import ThemeColor from '../../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../../Theme/styles';

import checkoutApi from '../../../Service/api/checkout';

const MealPlanList = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { showModalPrompt } = useModalPrompt();

  const mealPlanItems = useSelector(mealPlanItemsSelector);
  const mealPlanType = useSelector(mealPlanTypeSelector);
  const shopData = useSelector(shopSelector);
  const storeInfo = useSelector(checkoutStoreInfoSelector);
  let paramMealPlanType = '';

  if (RAZZLE_BUILD_MODE === 'branded') {
    const params = new URLSearchParams(document?.location?.search); // check url params for order_type
    paramMealPlanType = params.get('meal_plan_type') || paramMealPlanType; // default order type as string
  }

  // Branded loader state
  const [isPaymentLinkLoading, setPaymentLinkLoaderStatus] = useState(false);

  const orderTimeInitial = DateHelper.getDayjsAtTime(
    shopData?.meal_plan_order_time
  );
  const mealPlanDispatchTime = `${orderTimeInitial.format(
    'hh:mm A'
  )} to ${orderTimeInitial.add(30, 'minute').format('hh:mm A')}`;
  const list = mealPlanItems || [];
  const dayCount = (mealPlanType || paramMealPlanType)?.substring?.(0, 1) || 0;
  const dispatchTime = mealPlanDispatchTime || '';
  const schedule = MealPlanHelper.generateSchedule(`${dayCount}_day`);

  const { promotion, promotionMinimum, storePromo, onPromoRemove } =
    usePromoCart({
      fetchPromo: list.length >= dayCount && dayCount != 0,
    });
  const setCheckoutStoreInfo = useSetCheckoutStoreInfo();

  const { subTotal } = CartHelper.getBreakdown({
    isDelivery: true,
    promotion,
    cart: list,
  });
  const isLessThanMinimum = subTotal < promotionMinimum;

  const _onCheckout = async () => {
    if (list.length > 0 && list.length == dayCount) {
      const storeData = shopData;
      if (RAZZLE_BUILD_MODE === 'branded') {
        setPaymentLinkLoaderStatus(true);

        const { ok, data } = await checkoutApi.placeOrderMealPlan({
          items: list.map(({ quantity, id, extras = [] }) => ({
            quantity,
            id,
            extras: extras.map(({ id }) => id),
          })),
          store_id: storeData.id,
          promo_code: lodashGet(promotion, 'promo_code', ''),
        });

        if (ok) {
          window?.localStorage?.setItem('BRANDED_LVS', window?.location?.href);
          navigation.push('checkout', {
            storeId: storeInfo.id,
            url: data?.redirect,
          });
          setPaymentLinkLoaderStatus(false);
        } else {
          setPaymentLinkLoaderStatus(false);
        }
      } else {
        setCheckoutStoreInfo(shopData);
        navigation.push(routeList.CHECKOUT_NAVIGATOR);
      }
    }
  };
  const _onRemove = (index) => () => dispatch(removeItem(index));

  const _onRemoveAll = () => {
    dispatch(removeItem(null));
  };
  const _toggleRemoveModal = () =>
    showModalPrompt(MODALPROMPT.prompt, {
      title: 'Are you sure?',
      message: `Do you really want to remove ${
        shopData?.name
      } from your ${getCartTerm()}?`,
      buttons: [
        {
          size: 'small',
          status: 'danger',
          text: 'Yes',
          onPress: _onRemoveAll,
        },
        { size: 'small', text: 'No' },
      ],
    });
  const _renderItem = ({ index, item }) => {
    // exclusive item use end of mealPlanSchedule date (friday)
    const dateIndex = item.is_exclusive ? schedule?.length - 1 : index;
    const date = schedule[dateIndex];
    return <MealPlanItem {...item} date={date} onRemove={_onRemove(index)} />;
  };

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      style={ThemeStyle.spacingTopMedium}
    >
      <View style={ThemeStyle.flex1}>
        {/* MealPlan Info */}
        <View>
          {/* Title */}
          <Text category="s2">Your Meal Plan Order</Text>
          <View style={ThemeStyle.pageVerticalSpacingMedium}>
            <Divider style={ThemeStyle.divider} />
          </View>
          <View style={ThemeStyle.flexDirectionRowCenterSpaceBetween}>
            {/* Dispatch details */}
            <View style={ThemeStyle.spacingBottomMedium}>
              <Text category="p1">{dayCount} DAY MEAL PLAN</Text>
              <Text category="p2">Dispatch Time: {dispatchTime}</Text>
            </View>
            {/* Remove button */}
            {list.length > 0 && (
              <Button onPress={_toggleRemoveModal} plain>
                <View style={styles.itemRemoveButton}>
                  <Text category="c1" status="control">
                    Remove
                  </Text>
                </View>
              </Button>
            )}
          </View>
        </View>
        {/* MealPlan Items */}
        <View style={ThemeStyle.flex1}>
          <List
            contentContainerStyle={{ flexGrow: 1 }}
            data={list}
            ListEmptyComponent={
              <View
                style={[
                  ThemeStyle.flex1,
                  ThemeStyle.flexDirectionRowCenterCenter,
                ]}
              >
                <Text
                  category="p1"
                  status="primary"
                  style={ThemeStyle.spacingBottomMedium}
                >
                  No item added to your {getCartTerm()}
                </Text>
              </View>
            }
            renderItem={_renderItem}
            scrollEnabled={false} // use the main scroller
            withSeparator
            plain
          />
        </View>
        {/* Item remaining, Summary, Create meal plan button */}
        <View>
          {/* Item Remaining */}
          {list.length < dayCount ? (
            (() => {
              const itemRemaining = dayCount - list.length;
              return (
                <Text
                  category="c1"
                  status="danger"
                  style={[
                    ThemeStyle.textCenter,
                    ThemeStyle.spacingBottomMedium,
                  ]}
                >
                  You need to add {itemRemaining} more{' '}
                  {pluralize(itemRemaining, 'item')} for this {dayCount} day
                  meal plan.
                </Text>
              );
            })()
          ) : (
            // Meal Plan Summary
            <View style={isLessThanMinimum && ThemeStyle.spacingBottomMedium}>
              <StoreCartSummary
                cart={list}
                isDelivery={false} // always false on cart page, since we only need to display the promo details here
                promotion={promotion}
                subTotalPromoPercent={shopData?.meal_plan_discount}
                noHspacing
              />
              <MinimumOrderNote
                current={subTotal}
                minimum={promotionMinimum}
                show={isLessThanMinimum}
                voucher={storePromo}
                style={{}} // to suppress error on typescript, its not requred. The MinimumOrderNote is js file
                onRemove={onPromoRemove}
              />
            </View>
          )}
          {/* Proceed button */}
          <Button
            status="success"
            disabled={list.length == 0 || list.length != dayCount}
            onPress={_onCheckout}
            loading={isPaymentLinkLoading}
          >
            {() => (
              <Text category="c1" status="control">
                Create meal plan
              </Text>
            )}
          </Button>
        </View>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  itemRemoveButton: {
    ...ThemeStyle.pageVerticalSpacingSmall,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    backgroundColor: ThemeColor.red,
    borderRadius: 10,
  },
});

export default MealPlanList;
