import { SheetManager } from 'react-native-actions-sheet';
import lodashIsUndefined from 'lodash/isUndefined';

const getOptions = (data, context) => {
  const options = {};
  if (!lodashIsUndefined(data)) {
    options.payload = data;
  }
  if (!lodashIsUndefined(context)) {
    options.context = context;
  }
  return options;
};

function hide(name, data, context) {
  return SheetManager.hide(name, getOptions(data, context));
}

function isOpen(name) {
  return SheetManager.get(name)?.current?.isOpen() || false;
}

async function show(name, data, context) {
  return SheetManager.show(name, getOptions(data, context));
}

export const CONTEXT = {
  SEARCH_PRODUCT: 'search-menu-context',
};

const modals = {
  hide,
  isOpen,
  show,
  DISTANCE_SELECTOR: 'DistanceSelector',
  DROPDOWN: 'Dropdown',
  eventOrderSummary: 'EventOrderSummary',
  FULL_MENU_LIST: 'FullMenuList',
  MEAL_PLAN_LIST: 'MealPlanList',
  MEAL_PLAN_TYPE_SELECTOR: 'MealPlanTypeSelector',
  MULTIPLE_VARIANT: 'MultiVariant',
  ORDER_AGAIN: 'OrderAgain',
  PRICE_SELECTOR: 'PriceSelector',
  PRODUCT: 'Product',
  PROMO_DETAILS: 'PromoDetails',
  PROMPT_WITH_INPUT: 'PromptWithInput',
  PROMPT: 'prompt',
  PUBLIC_REVIEW: 'PublicReview',
  QR_PREVIEW: 'QRPreview',
  RATING_SELECTOR: 'RatingSelector',
  VIEW_REVIEW: 'ViewReview',
};

export default modals;
