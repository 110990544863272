import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';

import ThemeStyle from '../../Theme/styles';

import Image from '../Image';

import styles, { titleProps, subTitleProps } from './styles';
import constants from '../../Config/constants';

function Base({ image, imageSize, imageStyle, imageOverlay, subTitle, title }) {
  const hasTitle = Boolean(title);
  const hasSubTitle = Boolean(subTitle);
  return (
    <View>
      <View
        style={[
          styles.baseImageContainer,
          (hasTitle || hasSubTitle) && ThemeStyle.spacingBottomSmall,
        ]}
      >
        {/* Image Overlay */}
        {Boolean(imageOverlay) && (
          <View style={[styles.baseImage, styles.baseImageOverlay]}>
            {imageOverlay}
          </View>
        )}
        {/* Card Image */}

        <Image
          resizeMode={'cover'}
          size={imageSize}
          source={{
            uri: image,
          }}
          style={[
            styles.baseImage,
            constants.isWeb && styles.additionalWebImageStyle,
            StyleSheet.flatten(imageStyle),
          ]}
        />
      </View>
      {/* Card title */}
      {hasTitle && <Text {...titleProps}>{title}</Text>}
      {/* Card sub title */}
      {hasSubTitle && <Text {...subTitleProps}>{subTitle}</Text>}
    </View>
  );
}

Base.propTypes = {
  image: PropTypes.string.isRequired,
  imageOverlay: PropTypes.node,
  imageSize: PropTypes.string,
  imageStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]),
  subTitle: PropTypes.string,
  title: PropTypes.string,
};

export default Base;
